import React, { useCallback, useContext, useEffect, useState } from 'react';
import { chain } from 'lodash';

import TCClient from '../../client';
import { mobxInjectSelect } from '../../common/utils';
import type { Appointment, Template, User, Workflow, WorkflowEvent } from '../../types';

type AMContextProviderProps = { children: React.ReactNode };
type MobxProps = {
  currentOrganizationId: string;
  findUser: (id: string, org: string) => Promise<User>;
  loadTemplate: (id: string) => Promise<Template>;
};

type AmContext = {
  appointments: Appointment[];
  cancelNewWorkflow: (clearSelected?: boolean) => void;
  createWorkflow: (label: string) => Promise<Workflow>;
  currentOrganizationId: string;
  deleteWorkflow: (workflowId: string | null) => void;
  deleteWorkflowEvent: () => Promise<void>;
  getUser?: (id?: string) => Promise<User | undefined>;
  isEditingWorkflowName: boolean;
  isLoading: boolean;
  loadAppointments: (patientId: string) => void;
  loadTemplate: (id: string) => Promise<Template>;
  loadWorkflows: () => void;
  saveWorkflowEvent: (templateUpdated: boolean) => Promise<void>;
  selectAppointment: (id: string | null) => void;
  selectedAppointment: Appointment | null;
  selectedWorkflow: Workflow | null;
  selectedWorkflowEvent: WorkflowEvent | null;
  selectWorkflow: (id: string | null) => void;
  selectWorkflowEvent: (event: WorkflowEvent | null) => void;
  setIsEditingWorkflowName: (isEditingWorkflowName: boolean) => void;
  setWorkflowError: React.Dispatch<React.SetStateAction<string>>;
  setWorkflowEventChanged?: (workflowEventChanged: boolean) => void;
  startNewWorkflow: () => void;
  updateSelectedWorkflowEvent: (field: string, value: number | string | null) => void;
  updateWorkflow: (options: { id: string; active?: boolean; label?: string }) => void;
  workflowError: string;
  workflowEventChanged?: boolean;
  workflowEvents: WorkflowEvent[];
  workflows: Workflow[];
};

const AMContext = React.createContext<AmContext>({} as AmContext);

export const useAMContext = () => useContext(AMContext);

const AMContextProvider: React.FC<AMContextProviderProps & MobxProps> = ({
  children,
  currentOrganizationId,
  findUser,
  loadTemplate,
}) => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [isEditingWorkflowName, setIsEditingWorkflowName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(null);
  const [selectedWorkflowEvent, _setSelectedWorkflowEvent] = useState<WorkflowEvent | null>(null);
  const [workflowEvents, setWorkflowEvents] = useState<WorkflowEvent[]>([]);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [workflowError, setWorkflowError] = useState('');
  const [workflowEventChanged, setWorkflowEventChanged] = useState(false);

  const setSelectedWorkflowEvent = (workflowEvent: WorkflowEvent | null) => {
    _setSelectedWorkflowEvent(workflowEvent);
    setWorkflowEventChanged(false);
  };

  const cancelNewWorkflow = useCallback(
    (clearSelected?: boolean) => {
      clearSelected && setSelectedWorkflow(null);
      setWorkflows(workflows.filter((w) => !w.isPlaceholder));
    },
    [workflows]
  );

  useEffect(() => {
    const loadWorkflowEvents = async (id: string, organizationId: string) => {
      setIsLoading(true);
      try {
        const sdkWorkflowEvents = await TCClient.workflows.findAllEvents(id, organizationId);

        setWorkflowEvents(sdkWorkflowEvents);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedWorkflow && !selectedWorkflow.isPlaceholder) {
      loadWorkflowEvents(selectedWorkflow.id, currentOrganizationId);
    }
  }, [currentOrganizationId, selectedWorkflow]);

  useEffect(() => {
    setAppointments([]);
    setSelectedAppointment(null);
    setSelectedWorkflow(null);
    setSelectedWorkflowEvent(null);
    setWorkflowEvents([]);
    setWorkflows([]);
  }, [currentOrganizationId]);

  return (
    <AMContext.Provider
      value={{
        appointments,
        currentOrganizationId,
        cancelNewWorkflow,
        createWorkflow: async (label) => {
          setIsLoading(true);
          try {
            const newFlow = await TCClient.workflows.create({
              label,
              organizationId: currentOrganizationId,
            });

            setSelectedWorkflow(newFlow);
            setWorkflows([...workflows.filter((w) => !w.isPlaceholder), newFlow]);

            return newFlow;
          } catch (err) {
            console.error(err);
            const { error, status } = err.response.body;
            const { message } = error;
            if (status === 'fail' && message === 'label must be unique') {
              setWorkflowError('Workflows must have a unique label.');
              return false;
            }
          } finally {
            setIsLoading(false);
          }
        },
        getUser: async (id?: string) => {
          if (selectedWorkflowEvent?.deliveryMethod !== 'sms' && id) {
            return await findUser(id, currentOrganizationId);
          }
        },
        isLoading,
        loadAppointments: async (patientId) => {
          setIsLoading(true);
          try {
            const sdkAppointments = await TCClient.appointments.findAll(
              patientId,
              currentOrganizationId
            );

            setAppointments(sdkAppointments);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        },
        loadTemplate,
        loadWorkflows: async () => {
          setIsLoading(true);
          try {
            const sdkWorkflows = await TCClient.workflows.findAll(currentOrganizationId);

            setWorkflows(sdkWorkflows);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        },
        updateWorkflow: async ({ id, active, label }) => {
          setIsLoading(true);
          try {
            await TCClient.workflows.update({
              active,
              id,
              label,
              organizationId: currentOrganizationId,
            });
          } catch (err) {
            console.error(err);
            const { error, status } = err.response.body;
            const { message } = error;
            if (status === 'fail' && message === 'label must be unique') {
              setWorkflowError('Workflows must have a unique label.');
              return false;
            }
          } finally {
            setIsLoading(false);
          }
        },
        deleteWorkflow: async (workflowId) => {
          setIsLoading(true);
          try {
            await TCClient.workflows.delete({
              id: workflowId,
              organizationId: currentOrganizationId,
            });
            setSelectedWorkflow(null);
            setWorkflows(workflows.filter((w) => w.id !== workflowId));
          } catch (error) {
            let errorMessage = 'An error occurred deleting the workflow, please try again later';
            try {
              const body = JSON.parse(error.response.text);
              if (body.error.message.appointment_attached) {
                errorMessage = 'Please remove any attached appointments before deleting';
              }
            } finally {
              setWorkflowError(errorMessage);
            }
          } finally {
            setIsLoading(false);
          }
        },
        deleteWorkflowEvent: async () => {
          if (!selectedWorkflowEvent?.id || !selectedWorkflow) return;
          setIsLoading(true);
          try {
            await TCClient.workflows.deleteEvent({
              id: selectedWorkflowEvent.id,
              workflowId: selectedWorkflow?.id,
              organizationId: currentOrganizationId,
            });

            setWorkflowEvents(
              chain(workflowEvents)
                .filter((e) => e.id !== selectedWorkflowEvent.id)
                .value()
            );
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        },
        saveWorkflowEvent: async (templateUpdated) => {
          if (!selectedWorkflowEvent || !selectedWorkflow) return;
          setIsLoading(true);
          const {
            appointmentTimeOffset,
            appointmentTimeOffsetUnit,
            deliveryMethod,
            id,
            sender,
            templateId,
          } = selectedWorkflowEvent;
          try {
            const newEvent = await TCClient.workflows[id ? 'updateEvent' : 'createEvent']({
              deliveryMethod,
              id,
              offset: appointmentTimeOffset,
              offsetUnit:
                appointmentTimeOffsetUnit + (appointmentTimeOffsetUnit.endsWith('s') ? '' : 's'),
              organizationId: currentOrganizationId,
              ...(deliveryMethod === 'sms' ? null : { sender }),
              templateId: templateUpdated ? templateId : undefined,
              workflowId: selectedWorkflow?.id,
            });

            setWorkflowEvents(
              chain(workflowEvents)
                .filter((e) => e.id !== newEvent.id)
                .concat(newEvent)
                .value()
            );

            return newEvent;
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        },
        selectAppointment: (id) => {
          setSelectedAppointment(id ? TCClient.appointments.getById(id) : null);
        },
        selectedAppointment,
        selectedWorkflow,
        selectedWorkflowEvent,
        isEditingWorkflowName,
        setIsEditingWorkflowName,
        selectWorkflow: (id) => {
          setSelectedWorkflow(id ? TCClient.workflows.getById(id) : null);
        },
        selectWorkflowEvent: (event: WorkflowEvent | null) => {
          setSelectedWorkflowEvent(event);
        },
        setWorkflowError,
        setWorkflowEventChanged,
        startNewWorkflow: () => {
          const placeHolderFlow = {
            isPlaceholder: true,
            label: 'New Workflow',
            isActive: false,
            eventsCount: 0,
            id: '',
            organizationId: '',
          };
          setSelectedWorkflow(placeHolderFlow);
          setWorkflows([...workflows.filter((w) => !w.isPlaceholder), placeHolderFlow]);
          setWorkflowEvents([]);
        },
        updateSelectedWorkflowEvent: (field: string, value: number | string | null) => {
          _setSelectedWorkflowEvent({ ...selectedWorkflowEvent, [field]: value } as WorkflowEvent);
          setWorkflowEventChanged(true);
        },
        workflowError,
        workflowEvents,
        workflows,
        workflowEventChanged,
      }}
    >
      {children}
    </AMContext.Provider>
  );
};

export default mobxInjectSelect<AMContextProviderProps, MobxProps>({
  messengerStore: ['currentOrganizationId'],
  patientAdminStore: ['loadTemplate'],
  userStore: ['findUser'],
})(AMContextProvider);
