import { hasOrgFeatureOrSettings, isIndividual, isNotOff } from './renderSettingConditions';
import { UserOrganizationSettings } from 'admin/types';

interface RenderConditionParams {
  requiredOrgSettings?: Array<keyof UserOrganizationSettings>;
  requiredIndividualCondition?: keyof UserOrganizationSettings;
  forbiddenOffCondition?: keyof UserOrganizationSettings;
  orgSettings: UserOrganizationSettings;
}

export const BlockedByPersonaText = 'Locked by Permission Group';

export const shouldRender = ({
  requiredOrgSettings,
  requiredIndividualCondition,
  forbiddenOffCondition,
  orgSettings,
}: RenderConditionParams) => {
  const isOrgFeatureRequiredMet = requiredOrgSettings
    ? hasOrgFeatureOrSettings(orgSettings, requiredOrgSettings)
    : true;
  const isIndividualConditionMet = requiredIndividualCondition
    ? isIndividual(orgSettings, requiredIndividualCondition)
    : true;
  const isNotOffConditionMet = forbiddenOffCondition
    ? isNotOff(orgSettings, forbiddenOffCondition)
    : true;
  return isOrgFeatureRequiredMet && isIndividualConditionMet && isNotOffConditionMet;
};
