import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import moment from 'moment-timezone';
import { FROZEN_EMPTY_ARRAY } from '../common/utils';
import scheduledMessageFormats from '../common/utils/scheduledMessageFormats';
import { ScheduleOption } from '../models/enums';
import { DeliveryMethods } from '../models/enums/DeliveryMethods';

const { LINK } = DeliveryMethods;
const {
  NONE_SELECTED_TEMPLATE,
  REPEAT_SCHEDULE_TO_SERVER_VALUE,
  SERVER_DATE_FORMAT,
  SERVER_TIME_FORMAT,
  SERVER_VALUE_TO_REPEAT_SCHEDULE,
} = scheduledMessageFormats;
export const DEFAULT_SCHEDULED_MESSAGE_STATE = {
  appointmentId: null,
  attachments: FROZEN_EMPTY_ARRAY,
  body: '',
  deliveryMethod: LINK,
  noReply: false,
  recipient: null,
  recipients: FROZEN_EMPTY_ARRAY,
  recipientType: null,
  repeatEnabled: false,
  repeatSchedule: SERVER_VALUE_TO_REPEAT_SCHEDULE.weekly,
  scheduledTime: null,
  scheduleOption: ScheduleOption.NOT_SELECTED,
  selectedEndDate: null,
  selectedStartDate: null,
  selectedTemplate: NONE_SELECTED_TEMPLATE,
  selectedTime: null,
  selectedTimezone: null,
  sender: null,
  status: null,
  timezone: null,
  timezoneName: null,
};

export default class ScheduleMessageStore {
  @observable scheduledMessage = null;

  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  mounted() {
    this._resetScheduledMessage();
  }

  @computed get noneSelectedTemplate() {
    return NONE_SELECTED_TEMPLATE;
  }

  get smsMessageLengthLimit() {
    return 280;
  }

  get dynamicSmsMessageLengthLimit() {
    return 200;
  }

  _resetScheduledMessage = () => {
    this.scheduledMessage = { ...DEFAULT_SCHEDULED_MESSAGE_STATE };
  };

  _setTimezone = async () => {
    if (this.scheduledMessage.timezone) return;

    const { patientAdminStore } = this.stores;
    const { name } = await patientAdminStore.determineCoarseTimezone();

    this.updateScheduledMessage('timezone', name);
  };

  @action('ScheduleMessageStore.createScheduledMessage')
  createScheduledMessage = async () => {
    const { messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;
    const {
      attachments,
      body,
      deliveryMethod,
      noReply,
      recipients,
      repeatEnabled,
      repeatSchedule,
      scheduleOption,
      selectedEndDate,
      selectedStartDate,
      selectedTemplate,
      selectedTime,
      timezone,
    } = this.scheduledMessage;
    const recipientIds = recipients.map(({ id }) => id);

    const { attachment, endDate, repeating, startDate, startTime, templateId } =
      this.formatValuesForServer({
        attachments,
        repeatEnabled,
        repeatSchedule,
        scheduleOption,
        selectedEndDate,
        selectedStartDate,
        selectedTemplate,
        selectedTime,
      });

    const scheduledMessage = await this.client.scheduledMessages.create({
      attachment,
      body,
      deliveryMethod,
      endDate,
      noReply,
      organizationId: currentOrganizationId,
      recipients: recipientIds,
      repeating,
      sendNow: scheduleOption === ScheduleOption.SEND_NOW,
      startDate,
      startTime,
      templateId,
      timezoneName: timezone,
    });
    return this.entityStore.sync(scheduledMessage);
  };

  @action('ScheduleMessageStore.formatValuesForServer')
  formatValuesForServer = ({
    attachments,
    isEditing,
    repeatEnabled,
    repeatSchedule,
    scheduleOption,
    selectedEndDate,
    selectedTemplate,
    selectedTime,
  }) => {
    let attachment, endDate, repeating, startDate, startTime, templateId;

    if (selectedTemplate.id === NONE_SELECTED_TEMPLATE.id) {
      if (attachments.length > 0) {
        attachment = attachments[0];
      }
    } else {
      const hasAttachments = attachments.length > 0;
      const hasLocalAttachment = hasAttachments && attachments[0] instanceof File;
      if (hasLocalAttachment) {
        attachment = attachments[0];
      } else if (isEditing && hasAttachments) {
        attachment = attachments[0];
      }

      const removedTemplateAttachment = selectedTemplate.hasAttachment && attachments.length === 0;
      if (removedTemplateAttachment) {
        attachment = null;
      }

      templateId = selectedTemplate.id;
    }

    if (scheduleOption === ScheduleOption.SET_DATE_TIME) {
      if (selectedTime) {
        startDate = selectedTime.format(SERVER_DATE_FORMAT);
        startTime = selectedTime.format(SERVER_TIME_FORMAT);
      }

      if (repeatEnabled) {
        if (repeatSchedule) {
          repeating = REPEAT_SCHEDULE_TO_SERVER_VALUE[repeatSchedule];
        }

        if (selectedEndDate) {
          endDate = selectedEndDate.toISOString(true).split('T')[0];
        }
      }
    }

    return { attachment, endDate, repeating, startDate, startTime, templateId };
  };

  @action('ScheduleMessageStore.editScheduledMessage')
  editScheduledMessage = async (id, isTemplateChanged) => {
    const { messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;
    const {
      attachments,
      body,
      repeatEnabled,
      repeatSchedule,
      selectedEndDate,
      scheduleOption,
      selectedStartDate,
      selectedTemplate,
      selectedTime,
      timezone: timezoneName,
    } = this.scheduledMessage;

    const { attachment, endDate, repeating, startDate, startTime, templateId } =
      this.formatValuesForServer({
        attachments,
        isEditing: true,
        repeatEnabled,
        repeatSchedule,
        scheduleOption,
        selectedEndDate,
        selectedStartDate,
        selectedTemplate,
        selectedTime,
      });

    const scheduledMessage = await this.client.scheduledMessages.update({
      ...(!attachment ? { attachment: null } : attachment instanceof File && { attachment }),
      body,
      endDate,
      id,
      organizationId: currentOrganizationId,
      repeating,
      startDate,
      startTime,
      ...(isTemplateChanged ? { templateId } : null),
      timezoneName,
    });
    return this.entityStore.syncOne(scheduledMessage);
  };

  @action('ScheduleMessageStore.downloadTemplateFile')
  downloadTemplateFile = async ({ templateId, fileName }) => {
    const { messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    await this.client.messageTemplates.downloadTemplateFile({
      templateId,
      fileName,
      organizationId: currentOrganizationId,
    });
  };

  @action('ScheduleMessageStore.downloadScheduledMessageFile')
  downloadScheduledMessageFile = async ({ scheduleId, fileName }) => {
    const { messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    await this.client.scheduledMessages.downloadScheduledMessageFile({
      scheduleId,
      fileName,
      organizationId: currentOrganizationId,
    });
  };

  @action('ScheduleMessageStore.openScheduleMessageModal')
  openScheduleMessageModal = (onClose, feed) => {
    this.resetScheduledMessageState();
    const { modalStore } = this.stores;

    modalStore.openModal('scheduleMessage', { onClose, feed });
    this._setTimezone();
  };

  @action('ScheduleMessageStore.findScheduledMessageInHistory')
  findScheduledMessageInHistory = async (id) => {
    const { entityStore, messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;
    const scheduledMessageInHistorySdk = await this.client.scheduledMessages.find({
      id,
      organizationId: currentOrganizationId,
      category: 'history',
    });
    const scheduledMessageInHistory = entityStore.syncOne(scheduledMessageInHistorySdk);
    if (scheduledMessageInHistorySdk.hasAttachment) {
      this.updateScheduledMessage('attachments', [
        {
          name: scheduledMessageInHistory.attachmentName,
          type: scheduledMessageInHistory.attachmentType,
        },
      ]);
    } else {
      this.updateScheduledMessage('attachments', []);
    }

    return scheduledMessageInHistory;
  };

  @action('ScheduleMessageStore.openEditScheduleMessageModal')
  openEditScheduleMessageModal = async (id, options) => {
    const { entityStore, modalStore, messengerStore, patientAdminStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    const scheduledMessageSdk = await this.client.scheduledMessages.find({
      id,
      organizationId: currentOrganizationId,
    });
    const scheduledMessage = entityStore.syncOne(scheduledMessageSdk);
    const {
      appointmentId,
      attachmentName,
      attachmentType,
      body,
      deliveryMethod,
      endDate,
      hasAttachment,
      noReply,
      repeating,
      scheduledTime,
      templateId,
      timezoneName,
    } = scheduledMessage;

    runInAction(() => {
      this.scheduledMessage = Object.assign(
        {},
        this.scheduledMessage,
        {
          appointmentId,
          body,
          id,
          scheduleOption: ScheduleOption.SET_DATE_TIME,
          selectedStartDate: moment.tz(scheduledTime, timezoneName),
          selectedTime: moment.tz(scheduledTime, timezoneName),
          selectedEndDate: moment.tz(endDate, timezoneName),
          timezone: timezoneName,
        },
        options
      );
    });

    if (hasAttachment) {
      this.updateScheduledMessage('attachments', [
        {
          name: attachmentName,
          type: attachmentType,
        },
      ]);
    }
    if (repeating) {
      this.updateScheduledMessage('repeatEnabled', !!SERVER_VALUE_TO_REPEAT_SCHEDULE[repeating]);
      this.updateScheduledMessage('repeatSchedule', SERVER_VALUE_TO_REPEAT_SCHEDULE[repeating]);
    }
    if (templateId) {
      let template = this.client.messageTemplates.getById(templateId);
      if (!template) {
        template = await patientAdminStore.loadTemplate(templateId);
      }
      this.updateScheduledMessage('selectedTemplate', template);
    }

    this.updateScheduledMessage('noReply', noReply);
    this.updateScheduledMessage('deliveryMethod', deliveryMethod);

    modalStore.openModal('editScheduleMessage', scheduledMessage);
  };

  @action('ScheduleMessageStore.setIsBackButtonPressed')
  setIsBackButtonPressed = (value) => {
    this.isBackButtonPressed = value;
  };

  @action('ScheduleMessageStore.resetScheduledMessageState')
  resetScheduledMessageState = () => {
    this._resetScheduledMessage();
  };

  @action('ScheduleMessageStore.updateScheduledMessage')
  updateScheduledMessage = (field, value) => {
    this.scheduledMessage = { ...this.scheduledMessage, [field]: value };
  };

  @action('ScheduleMessageStore.bulkUpdateScheduledMessage')
  bulkUpdateScheduledMessage = (fields) => {
    this.scheduledMessage = { ...this.scheduledMessage, ...fields };
  };
}
