export default function settingsDiff<T extends Record<string, unknown> = Record<string, string>>(
  settingOne: T,
  settingTwo: T
) {
  return Object.keys(settingOne)
    .filter((k) => {
      return settingOne[k as keyof typeof settingOne] !== settingTwo[k as keyof typeof settingTwo];
    })
    .reduce((memo, k) => {
      return { ...memo, [k]: settingOne[k as keyof typeof settingOne] };
    }, {});
}
