import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { last, partial } from 'lodash';
import classnames from 'classnames';
import Select from 'react-select-plus';
import { MultiSelectOption, MultiSelectProps } from '../../types';
import multiSelectOptionFilter from '../../utils/multiSelectOptionFilter';
import styles from './MultiSelect.module.css';

const APPLY_ALL_VALUE = '___apply_all___';

export const MultiSelect = <Value extends string = string>({
  allowApplyAll = false,
  dataTestId,
  disabled,
  onChange,
  options = [] as MultiSelectOption<Value>[],
  placeholder = 'Select your options...',
  value,
}: MultiSelectProps<Value>) => {
  const selectRef = useRef<{
    input?: { input?: HTMLInputElement };
    _visibleOptions: MultiSelectOption[];
  }>();
  const [selectValue, setSelectValue] = useState<Value[]>(value || []);
  const [matches, setMatches] = useState<string[]>([]);

  const applyAllOption = {
    label: ' - Add All - ',
    value: APPLY_ALL_VALUE,
    className: classnames(styles.applyAllSelectOption, styles.multiSelectOption),
  };

  useEffect(() => {
    if (!dataTestId) return;
    selectRef.current?.input?.input?.setAttribute('data-test-id', dataTestId);
  }, [dataTestId]);

  const onValueChange = (newOptions: MultiSelectOption[]) => {
    const applyAll = last(newOptions)?.value === APPLY_ALL_VALUE;
    const newValues = [
      ...newOptions.map((o) => o.value as Value).filter((o) => o !== APPLY_ALL_VALUE),
      ...(applyAll ? (matches.filter((o) => o !== APPLY_ALL_VALUE) as Value[]) : []),
    ];
    setSelectValue(newValues);
    onChange && onChange(newValues);
  };

  const customFilter = partial(multiSelectOptionFilter, applyAllOption);

  return (
    <Select
      className={clsx(styles.multiSelect, { [styles.disabled]: disabled })}
      disabled={disabled}
      escapeClearsValue={false}
      filterOptions={allowApplyAll ? customFilter : undefined}
      menuStyle={{
        overflow: 'scroll',
        height: 200,
      }}
      multi={true}
      onChange={onValueChange}
      onInputChange={() => setMatches(selectRef.current?._visibleOptions.map((x) => x.value) || [])}
      openOnClick={true}
      options={options.map((o) => ({ ...o, className: styles.multiSelectOption }))}
      placeholder={placeholder}
      ref={selectRef}
      searchable={true}
      value={selectValue}
    />
  );
};
