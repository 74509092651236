import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import propTypes from '../propTypes';
import BEM from '../bem';
import { UserAvatar } from './';

const classes = BEM.with('AvatarDropzone');

class AvatarDropzone extends Component {
  static propTypes = {
    entity: PropTypes.oneOfType([propTypes.user, propTypes.group]),
    onDropAccepted: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
  };

  render() {
    const { entity, onDropAccepted, size } = this.props;

    return (
      <div className={classes()} onClick={this._changeAvatar}>
        <Dropzone
          className={classes('avatar-upload-container')}
          disableClick
          ref={this._setDropzone}
          accept="image/*"
          multiple={false}
          onDropAccepted={onDropAccepted}
        >
          <div className={classes('overlay')}>
            <div className={classes('edit-avatar-text')}>Edit</div>
          </div>
          <UserAvatar
            className={classes('avatar')}
            indicatorSize={'LARGE'}
            showPresenceIndicator={true}
            size={size}
            user={entity}
          />
        </Dropzone>
      </div>
    );
  }

  _setDropzone = (ref) => {
    this.dropzone = ref;
  };

  _changeAvatar = () => {
    this.dropzone.open();
  };
}

export default observer(AvatarDropzone);
