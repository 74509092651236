import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import PersonaSelect from '../PersonaSelect';
import styles from './PersonaForm.module.css';
import { Input } from 'admin/shared-components/Input/Input';
import { CtaButton } from 'admin/shared-components/CtaButton/CtaButton';
import { PermissionMap, PersonaRowData, Persona, PersonaToast, UserSettings } from 'admin/types';
import { DotsIndicator } from 'common/components';
import { mobxInjectSelect } from 'common/utils';
import { PersonaCreateFields, PersonaUpdateFields } from 'js-sdk/src/types/Persona';
import { useAppSelector } from 'redux-stores';
import { MultiSelect } from 'admin/shared-components/MultiSelect/MultiSelect';
import UserSettingsAndAccessForm from 'admin/pages/Users/User/UserSettingsAndAccessForm';

type MobxProps = {
  loadTitles: (selectedOrganizationId: string) => Promise<string[]>;
  createPersona: (
    payload: PersonaCreateFields,
    selectedOrganizationId: string
  ) => Promise<Persona[]>;
  updatePersona: (
    payload: PersonaUpdateFields,
    personaId: string,
    selectedOrganizationId: string
  ) => Promise<Persona>;
};

type PersonaFormProps = {
  setShowPersonaForm: (shouldShow: boolean) => void;
  selectedPersona?: PersonaRowData;
  setSelectedPersona: (persona: PersonaRowData | undefined) => void;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  setToastMessageData: React.Dispatch<React.SetStateAction<PersonaToast | undefined>>;
};

type PersonaOption = {
  label: string;
  type: 'input' | 'dropdown';
  optionName: keyof Persona;
  listOptions?: { label: string; value: string }[];
  defaultValue?: string;
};

function PersonaForm({
  createPersona,
  editMode,
  loadTitles,
  selectedPersona,
  setEditMode,
  setSelectedPersona,
  setShowPersonaForm,
  setToastMessageData,
  updatePersona,
}: PersonaFormProps & MobxProps) {
  const [personaName, setPersonaName] = useState(selectedPersona?.name);
  const [selectedTitles, setSelectedTitles] = useState(selectedPersona?.titles || []);
  const [description, setDescription] = useState(selectedPersona?.description || '');
  const [userPermissions, setUserPermissions] = useState<PermissionMap | undefined>({});
  const [userSettings, setUserSettings] = useState<UserSettings>();
  const [template, setTemplate] = useState<PersonaRowData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [titles, setTitles] = useState<string[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [canRevert, setCanRevert] = useState(false);

  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);

  const loadRequirements = useCallback(async () => {
    setLoading(true);
    try {
      const foundTitles = await loadTitles(selectedOrganizationId);
      setUserPermissions((selectedPersona?.permissions || {}) as PermissionMap);
      setUserSettings(selectedPersona?.settings as UserSettings);
      setTitles(foundTitles);
      setSelectedTitles(selectedPersona?.titles || []);
    } finally {
      setLoading(false);
    }
  }, [loadTitles, selectedOrganizationId, selectedPersona]);

  useEffect(() => {
    loadRequirements();
  }, [loadRequirements]);

  useEffect(() => {
    let allowRevert = false;
    if (selectedPersona) {
      const { settings, permissions } = selectedPersona;
      allowRevert = !isEqual(settings, userSettings) || !isEqual(permissions, userPermissions);
    } else {
      const emptyOrAllFalse = (item: Record<string, unknown> | undefined) => {
        return !item || Object.keys(item).every((key) => item[key] === false);
      };
      allowRevert = !emptyOrAllFalse(userPermissions) || !emptyOrAllFalse(userSettings);
    }
    setCanRevert(allowRevert);
  }, [editMode, selectedPersona, userPermissions, userSettings]);

  useEffect(() => {
    if (!template) return;
    setUserPermissions(template.permissions);
    setUserSettings(template.settings as UserSettings);
  }, [template]);

  const personaInputs: PersonaOption[] = [
    { label: 'Name: ', type: 'input', optionName: 'name' },
    { label: 'Description: ', type: 'input', optionName: 'description' },
    {
      label: 'Title: ',
      optionName: 'titles',
      type: 'dropdown',
      listOptions: titles.map((title) => ({ label: title, value: title })),
    },
  ];

  const onEditSaveBtn = async () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      validateForm();
    }
  };
  const validateForm = async () => {
    const formErrors: Record<string, string> = {};
    if (!selectedTitles.length) formErrors.titles = 'Title is required';
    if (!personaName || !personaName.trim()) formErrors.name = 'Name is required';

    if (isEmpty(formErrors)) {
      await savePersona();
    } else {
      setErrors(formErrors);
    }
  };

  const revertChanges = () => {
    if (selectedPersona) {
      setUserPermissions(selectedPersona.permissions);
      setUserSettings(selectedPersona.settings as UserSettings);
    } else {
      setUserPermissions({});
      setUserSettings(undefined);
    }
    setToastMessageData({
      type: 'SUCCESS',
      message: 'Your changes have been successfully reverted',
    });
  };

  const savePersona = async () => {
    try {
      if (selectedPersona) {
        await updatePersona(
          {
            name: personaName || '',
            description: description || '',
            titles: selectedTitles,
            permissions: userPermissions,
            settings: userSettings,
          },
          selectedPersona.personaId,
          selectedOrganizationId
        );
        setSelectedPersona(undefined);
        setToastMessageData({ type: 'SUCCESS', message: 'Permission Group updated' });
      } else {
        await createPersona(
          {
            name: personaName || '',
            description: description || '',
            titles: selectedTitles,
            parentPersonaId: template?.personaId,
            permissions: userPermissions,
            settings: userSettings,
          },
          selectedOrganizationId
        );
        setToastMessageData({ type: 'SUCCESS', message: 'Permission Group created' });
      }
      setShowPersonaForm(false);
      setErrors({});
    } catch (error) {
      let errorMessage = 'Response error please try again.';
      if (error?.response?.status === 409) {
        errorMessage = error?.response?.text;
      }
      setErrors((prev) => ({ ...prev, general: errorMessage }));
      setToastMessageData({ type: 'FAILURE', message: errorMessage });
    }
  };

  if (loading) {
    return (
      <div className={classNames(styles.loadingHolder)}>
        <DotsIndicator color="#0e0d0d" size={12} marginTop={30} marginRight={5} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.personaForm)}>
      <div className={classNames(styles.personaHeader)}>
        {selectedPersona
          ? `${editMode ? 'Edit ' : ''}${selectedPersona.name}`
          : 'Create New Permission Group'}
      </div>
      {personaInputs.map(({ label, type, listOptions, optionName }) => (
        <div key={optionName} className={classNames(styles.personaRoot)}>
          <div className={classNames(styles.personaLabel)}>{label}</div>
          <div className={classNames(styles.personaChild)}>
            {optionName === 'name' && (
              <Input
                disabled={!editMode}
                value={personaName}
                onInputChange={(e) => setPersonaName(e.target.value)}
                dataTestId="newPersonaId"
              />
            )}
            {optionName === 'description' && (
              <Input
                disabled={!editMode}
                value={description}
                onInputChange={(e) => setDescription(e.target.value)}
                dataTestId="persona description"
              />
            )}
            {optionName === 'titles' && listOptions && (
              <MultiSelect
                allowApplyAll={true}
                dataTestId={'titleDropdown'}
                disabled={!editMode}
                onChange={setSelectedTitles}
                options={listOptions}
                placeholder={'Select titles...'}
                value={selectedTitles}
              />
            )}
          </div>
          <div className={classNames(styles.errorLabel)}>{errors[optionName]}</div>
        </div>
      ))}
      {!selectedPersona && (
        <div className={classNames(styles.personaRoot)}>
          <div className={classNames(styles.personaLabel)}>Template:</div>
          <div className={classNames(styles.personaChild)}>
            <PersonaSelect
              dataTestId={'personaDropdown'}
              onChange={(template: PersonaRowData) => setTemplate(template)}
              placeholder={'Select a template...'}
            />
          </div>
        </div>
      )}

      <UserSettingsAndAccessForm
        disableAll={!editMode}
        isPersonaForm={true}
        userPermissions={(userPermissions || {}) as PermissionMap}
        setUserPermissions={setUserPermissions}
        setUserSettings={setUserSettings}
        userSettings={(userSettings || {}) as UserSettings}
      />

      <div className={classNames(styles.personaButtons)}>
        <CtaButton primary label={`${!editMode ? 'Edit' : 'Save'}`} onClick={onEditSaveBtn} />
        <CtaButton primary={false} label="Cancel" onClick={() => setShowPersonaForm(false)} />
        {canRevert && (
          <CtaButton primary={false} label="Revert Changes" onClick={() => revertChanges()} />
        )}
      </div>
    </div>
  );
}

export default mobxInjectSelect<PersonaFormProps, MobxProps>({
  personaStore: ['createPersona', 'loadTitles', 'updatePersona'],
})(PersonaForm);
