import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { difference, filter, map, pipe, union } from 'ramda';

export default class GroupEditorStore {
  @observable.shallow addedMembers = [];
  @observable group = null;
  @observable groupId = null;
  @observable.shallow highlightedMembers = [];
  @observable.shallow realMembers = [];
  @observable.shallow removedMembers = [];
  @observable saveInProgress = false;
  membersSyncedOnce = {};

  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;

    this.entityStore.events.on(
      'afterInject',
      action(async (group) => {
        if (group.$entityType !== 'group' || group.id !== this.groupId) {
          if (group.$entityType === 'groupMembersChange') {
            await this.syncGroupMembers();
          }
          return;
        }

        this.group = this.entityStore.group.getById(group.id);

        await this.syncGroupMembers();

        const addedMembers = this.addedMembers.slice();
        const removedMembers = this.removedMembers.slice();
        const realMembers = this.realMembers.slice();

        if (
          this.saveInProgress &&
          difference(addedMembers, realMembers).length === 0 &&
          difference(removedMembers, realMembers).length === removedMembers.length
        ) {
          runInAction(() => {
            this.saveInProgress = false;
            this.addedMembers.clear();
            this.highlightedMembers.clear();
            this.removedMembers.clear();
          });
        }
      })
    );
  }

  mounted() {
    const { conversationStore, groupStore } = this.stores;

    conversationStore.events.on('conversationSelected', this.resetGroupEditor);
    groupStore.events.on(
      'addMembers',
      action(() => this.addedMembers.clear())
    );
    groupStore.events.on(
      'saveMembersFailed',
      action(() => {
        this.saveInProgress = false;
      })
    );
  }

  @action('GroupEditorStore.initializeGroupEditor') initializeGroupEditor = ({ group }) => {
    this.group = group;
    this.groupId = group.id;
    this.saveInProgress = false;
    this.addedMembers.clear();
    this.highlightedMembers.clear();
    this.realMembers.clear();
    this.removedMembers.clear();
  };

  @action('GroupEditorStore.showGroupEditor') showGroupEditor = ({ group }) => {
    const { messengerStore } = this.stores;

    this.initializeGroupEditor({ group });
    messengerStore.showInfoPane({ group, type: 'GroupEditor' });
  };

  @action('GroupEditorStore.closeGroupEditor') closeGroupEditor = () => {
    this.group = null;
    this.groupId = null;
    this.resetGroupEditor();
  };

  @action('GroupEditorStore.syncGroupMembers') syncGroupMembers = async ({ continuation } = {}) => {
    const newMembers = [];
    const rolesInGroup =
      this.group && this.group.members
        ? this.group.members
            .filter((member) => member.isRoleBot && member.botRole)
            .map((member) => member.botRoleId)
        : [];

    if (this.group && this.group.groupType === 'FORUM') {
      const { memberIds, metadata } = await this.client.groups.findMemberIds(this.groupId, {
        continuation,
      });

      if (continuation) {
        newMembers.push(...this.realMembers.slice());
      }

      newMembers.push(...memberIds);

      runInAction(() => {
        this.realMembers = newMembers;
        this.syncEachMember();
      });

      return {
        metadata,
        results: [],
      };
    } else {
      this.realMembers =
        this.group && this.group.members
          ? this.group.members
              .filter((member) => {
                return member.roles.length === 0 || !rolesInGroup.includes(member.roles[0].id);
              })
              .map((member) => member.id)
          : [];
      this.syncEachMember();
      return {
        metadata: {},
        results: [],
      };
    }
  };

  @action('GroupEditorStore.syncEachMember') syncEachMember = async () => {
    const { userStore } = this.stores;

    const needsSync = [];
    for (const memberId of this.realMembers) {
      const member = userStore.getUserById(memberId);
      const hasProfile = member && Object.keys(member.profileByOrganizationId).length > 0;

      if (this.membersSyncedOnce[memberId]) continue;

      if (!member || member.$placeholder || !hasProfile) {
        needsSync.push(memberId);
        this.membersSyncedOnce[memberId] = true;
      }
    }

    if (needsSync.length === 0) return;

    const organizationId = this.group.organizationId;

    const updatedUsers = await Promise.all(
      needsSync.map((userId) =>
        this.client.users.find(userId, { bypassCache: true, organizationId })
      )
    );

    runInAction(() => {
      this.entityStore.sync(updatedUsers.filter(Boolean));
      this.group = this.entityStore.group.getById(this.group?.id);

      this.realMembers = this.realMembers.slice();
    });
  };

  @action('GroupEditorStore.showLeaveGroupModal') showLeaveGroupModal = (group) => {
    const { modalStore } = this.stores;
    const { id: groupId } = group;

    this.group = this.entityStore.group.getById(groupId);
    this.groupId = groupId;
    modalStore.openModal('leaveGroup');
  };

  @computed get members() {
    const { userStore } = this.stores;
    const group = this.group;
    if (!group) return [];

    let members = pipe(
      union(this.addedMembers),
      (members) => difference(members, this.removedMembers),
      map(userStore.getUserById),
      filter(Boolean)
    )(this.realMembers);

    const usersAlsoInARole = [];
    for (const member of members) {
      const role = member.isRoleBot && member.botRole;
      if (role && role.members) {
        for (const roleMember of role.members) {
          usersAlsoInARole.push(roleMember);
        }
      }
    }

    members = difference(members, usersAlsoInARole);

    return members;
  }

  @computed get memberCount() {
    return this.realMembers.length;
  }

  @computed get isMembersDirty() {
    return this.highlightedMembers.length > 0 || this.removedMembers.length > 0;
  }

  @action('GroupEditorStore.resetGroupEditor') resetGroupEditor = async () => {
    this.saveInProgress = false;
    this.addedMembers.clear();
    this.highlightedMembers.clear();
    this.removedMembers.clear();
  };

  @action('GroupEditorStore.prepareAddMember') prepareAddMember = async (id) => {
    const { userStore } = this.stores;

    if (!this.removedMembers.remove(id)) {
      this.addedMembers.push(id);
      const member = userStore.getUserById(id);
      const role = member.isRoleBot && member.botRole;
      if (role && role.memberIds) {
        for (const roleMemberId of role.memberIds) {
          this.addedMembers.remove(roleMemberId);
        }
      }
    }

    this.highlightedMembers.push(id);
  };

  @action('GroupEditorStore.prepareRemoveMember') prepareRemoveMember = async (id) => {
    if (!this.addedMembers.remove(id)) {
      this.removedMembers.push(id);
    }
  };

  @action('GroupEditorStore.saveMembers') saveMembers = async () => {
    const { addMembers, removeMembers } = this.stores.groupStore;

    this.checkForMemberChanges();
    if (this.removedMembers.length === 0 && this.addedMembers.length === 0) {
      return;
    }

    this.saveInProgress = true;
    await Promise.all([
      removeMembers(this.groupId, this.removedMembers.slice()),
      addMembers(this.groupId, this.addedMembers.slice()),
    ]);
    this.resetGroupEditor();
  };

  @action('GroupEditorStore.addMember') addMember = async (memberId) => {
    const { groupStore } = this.stores;
    await groupStore.addMember(this.groupId, memberId);
  };

  @action('GroupEditorStore.removeMember') removeMember = async (memberId) => {
    const { groupStore } = this.stores;
    await groupStore.removeMember(this.groupId, memberId);
  };

  checkForMemberChanges = () => {
    const currentMembers = this.group.members.map(({ id }) => id);
    this.addedMembers = this.addedMembers.filter(
      (memberToAdd) => !currentMembers.includes(memberToAdd)
    );
    this.removedMembers = this.removedMembers.filter((memberToRemove) =>
      currentMembers.includes(memberToRemove)
    );
  };
}
