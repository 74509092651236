import EventEmitter from 'events';
import uniq from 'lodash/uniq';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import queue from 'emitter-queue';
import { ActionTexts, TeamRequestStatus } from '../models/enums';

export const JOIN_DECISION_TIMEOUT = 5000;
export const UNDO_TIMEOUT = 7000;

class RequestState {
  @observable pending = false;

  constructor() {
    makeObservable(this);
  }
}

export default class TeamStore {
  @observable _isTeamProfileOpen = false;
  @observable _requestState = new Map();
  @observable currentTeamId = null;
  @observable.shallow currentTeam = null;
  @observable isActionPending = false;
  @observable localTeamConversationsLoaded = false;
  @observable activeTeamIds = [];
  @observable savedTeamIds = [];
  @observable undoTeam = undefined;

  events = queue(new EventEmitter());

  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  mounted() {
    this.client.models.Team.on('afterInject', (base, injectedTeam) => {
      if (this.currentTeamId && this.currentTeamId === injectedTeam.id) {
        this.setCurrentTeam(this.currentTeamId, injectedTeam);
      }
    });

    this.client.teams.on(
      'saved',
      action((event) => {
        const { action, id, organizationId } = event;
        if (organizationId !== this.stores.messengerStore.currentOrganizationId) return;
        if (action === 'del') {
          this.savedTeamIds = this.savedTeamIds.filter((roleId) => roleId !== id);
        } else {
          this.savedTeamIds = uniq([...this.savedTeamIds, id]);
        }
      })
    );
  }

  @computed get isTeamProfileOpen() {
    return this.stores.rosterStore.selectedFilter === 'Teams' && this._isTeamProfileOpen;
  }

  @computed get teamMembers() {
    return this.currentTeam?.members || [];
  }

  _getRequestState = (requestId) => {
    let requestState = this._requestState.get(requestId);
    if (!requestState) {
      requestState = new RequestState();
      runInAction(() => {
        this._requestState.set(requestId, requestState);
      });
    }

    return requestState;
  };

  @action('TeamStore.setCurrentTeam') setCurrentTeam = (id, team) => {
    this.currentTeamId = id;
    this.currentTeam = id ? (team ? team : this.entityStore.getById('team', id)) : null;
  };

  @action('TeamStore.actionButtonOnClick') actionButtonOnClick = async () => {
    const { composeMessageStore, conversationStore, messengerStore, rosterStore } = this.stores;
    const team = this.currentTeam;
    const { hasCurrentUserOrRole } = team;
    const action = hasCurrentUserOrRole ? ActionTexts.message : ActionTexts.activate;

    switch (action) {
      case ActionTexts.message:
        const { group } = team;
        if (!group) return;
        conversationStore.selectConversationWithEntity({
          group,
          markAsRead: false,
        });
        break;

      case ActionTexts.activate:
        messengerStore.openMessages();
        rosterStore.setFilter('Inbox');
        composeMessageStore.setConversationOrigin('Team Card');
        composeMessageStore.setSelectedRecipients([team]);
        composeMessageStore.composeNewMessage();
        break;
      default:
    }
  };

  @action('TeamStore.findTeam') findTeam = async (
    teamId,
    { organizationId = this.stores.messengerStore.currentOrganizationId } = {}
  ) => {
    try {
      const team = await this.client.teams.find(teamId, { organizationId });
      return this.entityStore.syncOne(team);
    } catch (e) {
      console.error(e);
    }
  };

  @action('TeamStore.findAllTeams') findAllTeams = async () => {
    try {
      const teams = await this.client.teams.findAll();
      return this.entityStore.sync(teams);
    } catch (e) {
      console.error(e);
    }
  };

  @action('TeamStore.findSavedTeamIds') findSavedTeamIds = async (organizationId) => {
    const { teamIds = [] } = await this.client.teams.findSavedTeams(organizationId);
    return teamIds;
  };

  @action('TeamStore.loadActiveTeamIds') loadActiveTeamIds = async (organizationId) => {
    const { organizationStore } = this.stores;
    const { showTeams = false } = await organizationStore.find(organizationId);
    runInAction(() => (this.activeTeamIds = []));
    if (!showTeams) return;
    const { results = [] } = await this.client.search.query({
      organizationId,
      types: ['team'],
      returnFields: ['token'],
      filterType: 'active',
      sort: ['display_name'],
      version: 'SEARCH_PARITY',
    });
    runInAction(() => {
      this.activeTeamIds = results.map(({ entity: { id } }) => id);
    });
  };

  @action('TeamStore.loadSavedTeamIds') loadSavedTeamIds = async (organizationId) => {
    const { organizationStore } = this.stores;
    const { showTeams = false } = await organizationStore.find(organizationId);
    runInAction(() => (this.savedTeamIds = []));
    if (!showTeams) return;
    const savedTeamIds = await this.findSavedTeamIds(organizationId);
    runInAction(() => {
      this.savedTeamIds = savedTeamIds;
    });
  };

  @action('TeamStore.toggleSaveTeam') toggleSaveTeam = async (teamId) => {
    const { messengerStore } = this.stores;
    const { currentOrganizationId: organizationId } = messengerStore;

    if (this.savedTeamIds.includes(teamId)) {
      await this.client.teams.removeSavedTeam(teamId, organizationId);
      await this.setUndoTeam(teamId);
      runInAction(() => {
        this.savedTeamIds = this.savedTeamIds.filter((id) => id !== teamId);
        clearTimeout(this._undoButtonTimeout);
        this._undoButtonTimeout = setTimeout(() => {
          this.setUndoTeam(undefined);
        }, UNDO_TIMEOUT);
      });
    } else {
      await this.client.teams.saveTeam(teamId, organizationId);
      runInAction(() => {
        this.savedTeamIds = [...this.savedTeamIds, teamId];
      });
    }
  };

  @action('TeamStore.undoUnsave') undoUnsave = async () => {
    const { messengerStore } = this.stores;
    const { currentOrganizationId: organizationId } = messengerStore;

    const teamId = this.undoTeam?.id;
    await this.client.teams.saveTeam(teamId, organizationId);
    runInAction(() => {
      this.savedTeamIds = [...this.savedTeamIds, teamId];
      this.undoTeam = undefined;
    });
  };

  @action('TeamStore.setUndoTeam') setUndoTeam = async (teamId) => {
    let team = undefined;
    if (teamId) {
      team = await this.findTeam(teamId);
    }
    runInAction(() => {
      this.undoTeam = team;
    });
  };

  @action('TeamStore.refreshTeam') refreshTeam = async (
    teamId,
    { organizationId = this.stores.messengerStore.currentOrganizationId } = {}
  ) => {
    try {
      const team = await this.client.teams.refresh(teamId, { organizationId });
      return this.entityStore.syncOne(team);
    } catch (e) {
      console.error(e);
    }
  };

  @action('TeamStore.refreshTeams') refreshTeams = async () => {
    this.unselectTeam();
    this.localTeamConversationsLoaded = false;

    let teams;
    try {
      const sdkTeams = await this.client.teams.refreshAll();
      teams = this.entityStore.sync(sdkTeams);
    } catch (e) {
      console.error(e);
      return;
    }

    for (const team of teams) {
      const { id: teamId, organizationId } = team;
      this._getConversationFromTeam({ teamId, organizationId });
    }

    runInAction(() => {
      this.localTeamConversationsLoaded = true;
      this.events.emit('teamsRefreshed');
    });
  };

  @action('TeamStore.refreshTeamsByOrg') refreshTeamsByOrg = async ({
    organizationId = this.stores.messengerStore.currentOrganizationId,
  } = {}) => {
    this.unselectTeam();
    this.localTeamConversationsLoaded = false;

    let teams;
    try {
      const sdkTeams = await this.client.teams.findByOrganizationId(organizationId);
      teams = this.entityStore.sync(sdkTeams);
    } catch (e) {
      console.error(e);
      return;
    }

    for (const team of teams) {
      const { id: teamId, organizationId } = team;
      this._getConversationFromTeam({ teamId, organizationId });
    }

    runInAction(() => {
      this.localTeamConversationsLoaded = true;
      this.events.emit('teamsRefreshed');
    });
  };

  @action('TeamStore.deleteTeam') deleteTeam = async (
    teamId,
    { organizationId = this.stores.messengerStore.currentOrganizationId } = {}
  ) => {
    const { modalStore } = this.stores;
    try {
      const status = await this.client.teams.delete(teamId, { organizationId });

      if (status === 204) {
        this.unselectTeam();
        return status;
      } else {
        modalStore.openModal('failure');
      }
    } catch (e) {
      modalStore.openModal('failure');
      console.error(e);
    }
  };

  _handleRequestToJoin = async (
    requestId,
    teamId,
    { expectedStatus = TeamRequestStatus.DECLINED } = {}
  ) => {
    const JOIN_REQUEST_HANDLER = {
      [TeamRequestStatus.ACCEPTED]: this.client.teams.acceptJoinRequest,
      [TeamRequestStatus.DECLINED]: this.client.teams.declineJoinRequest,
    };

    const { composeMessageStore, messengerStore, modalStore } = this.stores;
    const { currentOrganizationId } = messengerStore;
    const { openModal } = modalStore;
    const { senderId } = composeMessageStore;

    const requestState = this._getRequestState(requestId);
    requestState.pending = true;

    let onTeamRequestChange;
    let timeout;
    try {
      const joinRequestHandler = JOIN_REQUEST_HANDLER[expectedStatus].bind(this.client.teams);
      const joinPromise = joinRequestHandler(requestId, {
        teamId,
        organizationId: currentOrganizationId,
        handledAsId: senderId,
      });

      const injectRequestPromise = new Promise((resolve, reject) => {
        timeout = setTimeout(() => reject(new Error('timed out')), JOIN_DECISION_TIMEOUT);

        onTeamRequestChange = (resource, sdkTeamRequest) => {
          if (
            sdkTeamRequest &&
            sdkTeamRequest.id === requestId &&
            sdkTeamRequest.status === expectedStatus
          ) {
            this.entityStore.syncOne(sdkTeamRequest);
            requestState.pending = false;
            if (onTeamRequestChange) {
              this.client.models.TeamRequest.removeListener('afterInject', onTeamRequestChange);
            }
            onTeamRequestChange = null;
            resolve();
          }
        };

        this.client.models.TeamRequest.on('afterInject', onTeamRequestChange);
      });

      await Promise.all([joinPromise, injectRequestPromise]);
    } catch (err) {
      console.error(err);
      openModal('failure');
    } finally {
      runInAction(() => {
        if (onTeamRequestChange) {
          this.client.models.TeamRequest.removeListener('afterInject', onTeamRequestChange);
        }
        requestState.pending = false;
        clearTimeout(timeout);
      });
    }
  };

  @action('TeamStore.acceptRequestToJoin') acceptRequestToJoin = async (requestId, teamId) => {
    await this._handleRequestToJoin(requestId, teamId, {
      expectedStatus: TeamRequestStatus.ACCEPTED,
    });
  };

  @action('TeamStore.declineRequestToJoin') declineRequestToJoin = async (requestId, teamId) => {
    await this._handleRequestToJoin(requestId, teamId, {
      expectedStatus: TeamRequestStatus.DECLINED,
    });
  };

  @action('TeamStore.requestToJoin') requestToJoin = async (
    teamId,
    { organizationId = this.stores.messengerStore.currentOrganizationId, requestedById }
  ) => {
    const { modalStore } = this.stores;
    try {
      await this.client.teams.requestToJoin(teamId, {
        requestedById,
        organizationId,
      });
    } catch (err) {
      modalStore.openModal('failure');
      console.error(err);
    }
  };

  @action('TeamStore.leaveTeam') leaveTeam = async (
    teamId,
    { organizationId = this.stores.messengerStore.currentOrganizationId } = {}
  ) => {
    const { modalStore } = this.stores;

    let status;
    try {
      status = await this.client.teams.leave(teamId, { organizationId });
    } catch (err) {
      modalStore.openModal('failure');
      console.error(err);
    }

    return status;
  };

  @action('TeamStore.selectTeam') selectTeam = (
    teamId,
    { conversationId = null, openTeamsTab = false } = {}
  ) => {
    const { conversationStore, messengerStore, rosterStore } = this.stores;
    const { currentOrganizationId: organizationId } = messengerStore;

    const selectTeamConversation = () => {
      this.setCurrentTeam(teamId);
      this._isTeamProfileOpen = true;

      if (!conversationId) {
        const conversation = this._getConversationFromTeam({
          teamId,
          organizationId,
        });
        if (conversation) {
          conversationId = conversation.id;
        }
      }

      if (conversationId) {
        conversationStore.setCurrentConversationId(conversationId);
      }
    };

    if (openTeamsTab) {
      this.events.once('teamsRefreshed', selectTeamConversation);
      rosterStore.setFilter('Teams');
    } else {
      selectTeamConversation();
    }
  };

  @action('TeamStore.unselectTeam') unselectTeam = () => {
    this.stores.conversationStore.setCurrentConversationId(null);
    this._isTeamProfileOpen = false;
    this.setCurrentTeam(null);
  };

  _getConversationFromTeam = ({ teamId, organizationId }) => {
    const conversation = this.stores.conversationStore.findOrCreateConversationWithListEntity(
      'team',
      teamId,
      organizationId,
      {
        shouldDisplay: true,
      }
    );

    return conversation;
  };

  @action('TeamStore.resetDecline') resetDecline = () => {
    this.client.teams.resetDeclinedUserOnTeam(this.currentTeamId);
  };
}
