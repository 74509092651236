import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { attachments, mobxInjectSelect } from '../../utils';
import { ReactComponent as QuickReplySvg } from '../../images/quick-reply.svg';
import DotsIndicator from '../DotsIndicator';
import RoleSelector from '../Roles/RoleSelector';
import ExistingPatientConversations from '../ExistingPatientConversations';
import { MentionsButton } from '../Mentions';
import {
  AttachmentButton,
  EmojiPickerButton,
  EscalationButton,
  MessageBodyInput,
  MessageBodyContentEditableInput,
  MessageFormIndicator,
  PriorityMessageButton,
  QuickReplyPortal,
} from './';

const CommandEditor = lazy(() => import('./CommandEditor'));

const classes = BEM.with('MessageForm');

class MessageForm extends Component {
  static propTypes = {
    addAttachments: PropTypes.func.isRequired,
    allowAttachments: PropTypes.bool,
    allowedSenders: propTypes.userOrRoleArray.isRequired,
    alwaysEscalate: PropTypes.bool,
    allowTypedMentions: PropTypes.bool,
    attachmentDescriptors: propTypes.observableArray.isRequired,
    body: PropTypes.string.isRequired,
    canEscalate: PropTypes.bool.isRequired,
    composeEntity: PropTypes.object,
    currentConversation: propTypes.conversation,
    currentMessageMultiSelectOption: PropTypes.string,
    currentUserId: PropTypes.string,
    hasAttachments: PropTypes.bool.isRequired,
    hasRecipients: PropTypes.bool,
    hideMessageMultiSelect: PropTypes.func,
    isComposeTo: PropTypes.bool.isRequired,
    isComposing: PropTypes.bool.isRequired,
    isEscalated: PropTypes.bool.isRequired,
    isExistingPatientConversationsOpen: PropTypes.bool.isRequired,
    isMentionsEnabled: PropTypes.bool,
    isMessengerHidden: PropTypes.bool.isRequired,
    isNoSearchResultsOnEmptyQueryEnabled: PropTypes.bool,
    isProviderNetwork: PropTypes.bool.isRequired,
    logPendoAnalytics: PropTypes.func.isRequired,
    numberOfSelectedMessages: PropTypes.number.isRequired,
    onInputClick: PropTypes.func,
    onTyping: PropTypes.func,
    organization: propTypes.organization.isRequired,
    priority: PropTypes.string.isRequired,
    richTextFormat: PropTypes.bool.isRequired,
    scrollToBottomIfPresent: PropTypes.func.isRequired,
    selectedMessages: PropTypes.object.isRequired,
    selectedRecipients: propTypes.counterPartyArray.isRequired,
    selectRecipients: PropTypes.func.isRequired,
    sender: propTypes.user,
    sendMessage: PropTypes.func.isRequired,
    setBody: PropTypes.func.isRequired,
    setDefaultSender: PropTypes.func.isRequired,
    setLastSenderId: PropTypes.func.isRequired,
    setSenderId: PropTypes.func.isRequired,
    showSendProgress: PropTypes.bool,
    tabIndex: PropTypes.number,
    isComposeFromLink: PropTypes.bool.isRequired,
    setIsComposeFromLink: PropTypes.func.isRequired,
  };

  static defaultProps = {
    allowAttachments: true,
    hasRecipients: true,
    showSendProgress: false,
  };

  state = {
    quickReplyPortalIsOpen: false,
    commandType: '',
    commandValue: '',
  };

  componentDidMount() {
    this._setDefaultValues();
  }

  componentDidUpdate() {
    this._setDefaultValues();
  }

  _setDefaultValues = () => {
    const {
      alwaysEscalate,
      composeEntity,
      isEscalated,
      organization,
      priority,
      setDefaultSender,
      setMessageFormIndicator,
    } = this.props;

    const { allowPriorityMessage } = organization;
    const isPriority = allowPriorityMessage && priority !== 'NORMAL';

    setDefaultSender(composeEntity);
    setMessageFormIndicator(alwaysEscalate || isEscalated || isPriority);
  };

  _onBodyChange = () => {
    if (this.state.quickReplyMessage) this.setState({ quickReplyMessage: false });
    this.roleSelector && this.roleSelector.close();
  };

  _setSenderId = (userId) => {
    const { setSenderId, selectRecipients, selectedRecipients } = this.props;

    setSenderId(userId);
    selectRecipients(selectedRecipients);
  };

  render() {
    const {
      addAttachments,
      allowAttachments,
      allowedSenders,
      allowTypedMentions,
      alwaysEscalate,
      body,
      canEscalate,
      composeEntity,
      currentConversation,
      currentMessageMultiSelectOption,
      currentUserId,
      hasAttachments,
      hasRecipients,
      hideMessageMultiSelect,
      isCommandBotEnabled,
      isCommandEditorOpen,
      isComposing,
      isEscalated,
      isExistingPatientConversationsOpen,
      isGiphyBotEnabled,
      isMentionsEnabled,
      isProviderNetwork,
      isSending,
      numberOfSelectedMessages,
      onInputClick,
      onTyping,
      organization,
      priority,
      rejectAttachments,
      richTextFormat,
      selectedRecipients,
      sender,
      sendMessage,
      setBody,
      setLastSenderId,
      showSendProgress,
      tabIndex,
      toggleCommandEditor,
    } = this.props;
    const { commandType, commandValue } = this.state;
    const { allowPriorityMessage } = organization;
    const isPriority = allowPriorityMessage && priority !== 'NORMAL';
    const hasBody = body !== '';
    const showRoleSelector = sender && allowedSenders.length > 1;
    const botRole = sender && sender.isRoleBot && sender.botRole;
    const tag = botRole && botRole.tag;
    const isSendable = hasRecipients && (hasAttachments || hasBody) && !isCommandEditorOpen;
    const canQuickReply = hasRecipients && !hasBody && !hasAttachments;
    const isVWRConversation =
      currentConversation &&
      currentConversation.featureService &&
      currentConversation.featureService === 'vwr';
    let attachmentButtonFragment, dropzoneRef, escalationButtonFragment;
    let priorityButtonFragment, roleSelectorFragment, senderTagColor;
    const InputComponent = isMentionsEnabled ? MessageBodyContentEditableInput : MessageBodyInput;
    const isMultiSelectMode = currentMessageMultiSelectOption !== '';

    if (allowedSenders.length === 0 && !currentMessageMultiSelectOption) return null;

    if (showRoleSelector) {
      senderTagColor = tag && tag.color;
      roleSelectorFragment = (
        <div className={classes('role-selector-row')}>
          <RoleSelector
            allowedSenders={allowedSenders}
            composeEntity={composeEntity}
            ref={this._setRoleSelector}
            sender={sender}
            setLastSenderId={setLastSenderId}
            setSenderId={this._setSenderId}
          />
        </div>
      );
    }

    if (allowPriorityMessage && isProviderNetwork && !isVWRConversation) {
      priorityButtonFragment = (
        <div className={classes('button-container')}>
          <PriorityMessageButton alwaysEscalate={alwaysEscalate} isPriority={isPriority} />
        </div>
      );
    }

    if (canEscalate) {
      escalationButtonFragment = (
        <div className={classes('button-container')}>
          <EscalationButton alwaysEscalate={alwaysEscalate} isEscalated={isEscalated} />
        </div>
      );
    }

    if (allowAttachments) {
      attachmentButtonFragment = (
        <Dropzone
          className={classes('attachment-button-container', {
            'has-attachment': hasAttachments,
          })}
          accept={(attachments.contentType = '*.*')}
          disablePreview
          disableClick
          multiple={true}
          inputProps={{ tabIndex: -1 }}
          onDropAccepted={addAttachments}
          onDropRejected={rejectAttachments}
          ref={(node) => {
            dropzoneRef = node;
          }}
        >
          {() => (
            <AttachmentButton
              onAttachmentButton={() => {
                dropzoneRef.open();
              }}
              hasAttachments={hasAttachments}
            />
          )}
        </Dropzone>
      );
    }

    return (
      <div className={classes()}>
        {isCommandEditorOpen && (
          <Suspense fallback={<DotsIndicator color="#3080df" size={20} />}>
            <CommandEditor
              close={toggleCommandEditor}
              sendMessage={sendMessage}
              type={commandType}
              value={commandValue}
            />
          </Suspense>
        )}
        <MessageFormIndicator
          alwaysEscalate={alwaysEscalate}
          isEscalated={isEscalated}
          isPriority={isPriority}
        />
        {!isMultiSelectMode && roleSelectorFragment}
        <div
          className={classes('body-row', {
            'role-selector-visible': showRoleSelector,
          })}
        >
          {!isMultiSelectMode && (
            <>
              {priorityButtonFragment}
              {escalationButtonFragment}
              {attachmentButtonFragment}
              <InputComponent
                addAttachments={addAttachments}
                allowTypedMentions={allowTypedMentions}
                body={body}
                borderColor={senderTagColor || null}
                currentConversation={currentConversation}
                currentUserId={currentUserId}
                className={classes('body-container')}
                isGiphyBotEnabled={isGiphyBotEnabled}
                isCommandBotEnabled={isCommandBotEnabled}
                isCommandEditorOpen={isCommandEditorOpen}
                isComposing={isComposing}
                onClick={onInputClick}
                onHeightChange={this._onInputHeightChange}
                onShiftTab={this._onShiftTab}
                onSubmit={this._onSubmit}
                onTyping={onTyping}
                ref={this._setMessageBodyInput}
                richTextFormat={richTextFormat}
                selectedRecipients={selectedRecipients}
                setBody={setBody}
                setCommandInfo={this.setCommandInfo}
                toggleCommandEditor={toggleCommandEditor}
                tabIndex={tabIndex}
                onBodyChange={this._onBodyChange}
                isComposeFromLink={this.props.isComposeFromLink}
                setIsComposeFromLink={this.props.setIsComposeFromLink}
                isProviderNetwork={isProviderNetwork}
                logPendoTypedMention={this._logPendoTypedMention}
                isNoSearchResultsOnEmptyQueryEnabled={
                  this.props.isNoSearchResultsOnEmptyQueryEnabled
                }
              />
              {isMentionsEnabled && isProviderNetwork && (
                <div className={classes('mention-button-container')}>
                  <MentionsButton onClick={this._insertMentionAtCursor} />
                </div>
              )}
              <div className={classes('emoji-button-container')}>
                <EmojiPickerButton onPick={this._insertAtCursor.bind(this, 'emoji')} />
              </div>
              <QuickReplyPortal
                isOpen={this.state.quickReplyPortalIsOpen}
                togglePortal={this._toggleQuickReplyPortal}
                setMessage={this._insertAtCursor.bind(this, 'quick-reply')}
              />
              <div className={classes('send-button-container')}>
                <button
                  data-test-id="send-message-button"
                  aria-label={canQuickReply ? 'Select Quick Reply' : 'Send message'}
                  className={classes('send-button', {
                    quickReply: canQuickReply,
                    isSendable,
                    progress: showSendProgress && isSending,
                  })}
                  onClick={canQuickReply ? this._toggleQuickReplyPortal : this._onSubmit}
                  ref={this._setMessageSendButton}
                >
                  {showSendProgress && isSending ? (
                    <div className={classes('dots-container')}>
                      <DotsIndicator
                        className={classes('dots')}
                        size={10}
                        speed={0.5}
                        color={'#fff'}
                      />
                    </div>
                  ) : canQuickReply ? (
                    <QuickReplySvg />
                  ) : (
                    'Send'
                  )}
                </button>
              </div>
            </>
          )}
        </div>
        <div
          className={classes('multi-select-menu', {
            'show-multi-select': !!currentMessageMultiSelectOption,
          })}
        >
          <div className={classes('left-container')}>
            <button
              className={classes('cancel-button')}
              onClick={hideMessageMultiSelect}
              tabIndex={isMultiSelectMode ? 0 : -1}
            >
              Cancel
            </button>
          </div>
          <div className={classes('right-container')}>
            <button
              className={classes('continue-button', {
                option: currentMessageMultiSelectOption,
              })}
              onClick={this._handleMessageMultiSelect}
              tabIndex={isMultiSelectMode ? 0 : -1}
            >
              {`${currentMessageMultiSelectOption} (${numberOfSelectedMessages})`}
            </button>
          </div>
        </div>
        {isExistingPatientConversationsOpen && <ExistingPatientConversations />}
      </div>
    );
  }

  _handleMessageMultiSelect = () => {
    const {
      currentMessageMultiSelectOption: option,
      hideMessageMultiSelect,
      numberOfSelectedMessages,
      selectedMessages,
      showDeleteModal,
      showForwardModal,
      showRecallModal,
      recallMessages,
    } = this.props;
    if (numberOfSelectedMessages === 0) return;

    if (option === 'forward') {
      showForwardModal(selectedMessages);
    } else if (option === 'delete') {
      showDeleteModal(selectedMessages);
    } else if (option === 'recall') {
      if (numberOfSelectedMessages > 1) {
        showRecallModal(selectedMessages);
      } else {
        recallMessages(Object.values(selectedMessages));
        hideMessageMultiSelect();
      }
    }
  };

  _setRoleSelector = (ref) => {
    this.roleSelector = ref;
  };

  _setMessageSendButton = (ref) => {
    this.messageSendButton = ref;
  };

  _onInputHeightChange = () => {
    const { scrollToBottomIfPresent } = this.props;
    scrollToBottomIfPresent();
  };

  _onSubmit = (event) => {
    const { isCommandEditorOpen } = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (isCommandEditorOpen) {
      return;
    }
    this._sendMessage();
  };

  setCommandInfo = ({ commandValue, commandType }) => {
    const { isCommandBotEnabled, isCommandEditorOpen, isGiphyBotEnabled, toggleCommandEditor } =
      this.props;

    if (isCommandBotEnabled || isGiphyBotEnabled) {
      if (!isCommandEditorOpen) toggleCommandEditor();
      this.setState({
        commandValue,
        commandType,
      });
    }
  };

  _toggleQuickReplyPortal = () => {
    this.setState({
      quickReplyPortalIsOpen: !this.state.quickReplyPortalIsOpen,
    });
    this.messageSendButton.blur();
  };

  _onShiftTab = (event) => {
    const { isComposeTo } = this.props;

    if (isComposeTo) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  _setMessageBodyInput = (ref) => {
    this.messageBodyInput = ref;
  };

  _insertAtCursor = (source, message) => {
    this.setState({ quickReplyMessage: source === 'quick-reply' });
    this.messageBodyInput && this.messageBodyInput.insertAtCursor(message + ' ');
  };

  _insertMentionAtCursor = (entities) => {
    const { logPendoAnalytics } = this.props;

    this.messageBodyInput && this.messageBodyInput.insertMentionAtCursor(entities);

    logPendoAnalytics({
      tracker: {
        name: `Inbox | Messenger - Mention`,
        props: {
          MentionStart: 'UI Button',
        },
      },
    });
  };

  _currentMentionMembersData = () => {
    return (
      this.messageBodyInput &&
      typeof this.messageBodyInput.getMentionMembersData === 'function' &&
      this.messageBodyInput?.getMentionMembersData()
    );
  };

  _sendMessage = async () => {
    const {
      attachmentDescriptors,
      hasAttachments,
      hasRecipients,
      isEscalated: escalate,
      logPendoAnalytics,
      priority,
      sendMessage,
      setBody,
    } = this.props;
    if (typeof this.messageBodyInput?.trimMessageBody === 'function') {
      const trim = this.messageBodyInput?.trimMessageBody();
      await setBody(trim);
    }

    const {
      individualMentionsCount,
      mentionMembersPayload,
      messageEditableBody,
      roleMentionsCount,
    } = this._currentMentionMembersData();

    const messageBody = messageEditableBody ? messageEditableBody.trim() : this.props.body.trim();

    const isSendable = hasRecipients && (hasAttachments || messageBody);
    if (!isSendable) return;

    let attachmentFiles;

    if (attachmentDescriptors) {
      attachmentFiles = attachmentDescriptors.map((d) => d.file);
    }

    sendMessage(messageBody, {
      attachmentFiles,
      escalate,
      membersMentionInConversation: mentionMembersPayload,
      priority,
    });

    logPendoAnalytics({
      tracker: {
        name: `Inbox | Messenger - Send`,
        props: {
          NumberOfMentions: mentionMembersPayload?.length,
          IndividualMentions: individualMentionsCount,
          RoleMentions: roleMentionsCount,
        },
      },
    });

    if (this.state.quickReplyMessage) {
      this.setState({ quickReplyMessage: false });
    }
  };

  _logPendoTypedMention = () => {
    const { logPendoAnalytics } = this.props;
    logPendoAnalytics({
      tracker: {
        name: `Inbox | Messenger - Mention`,
        props: {
          MentionStart: 'Keyboard Symbol',
        },
      },
    });
  };
}

export default mobxInjectSelect({
  composeMessageStore: [
    'addAttachments',
    'attachmentDescriptors',
    'body',
    'canEscalate',
    'composeEntity',
    'hasAttachments',
    'isCommandEditorOpen',
    'isComposing',
    'isEscalated',
    'isExistingPatientConversationsOpen',
    'isComposeTo',
    'isSending',
    'priority',
    'rejectAttachments',
    'resetMessage',
    'selectedRecipients',
    'selectRecipients',
    'sender',
    'setBody',
    'setDefaultSender',
    'setLastSenderId',
    'setMessageFormIndicator',
    'setSenderId',
    'toggleCommandEditor',
    'isComposeFromLink',
    'setIsComposeFromLink',
  ],
  conversationStore: ['currentConversation', 'scrollToBottomIfPresent'],
  sessionStore: ['currentUserId'],
  messageStore: [
    'numberOfSelectedMessages',
    'selectedMessages',
    'showDeleteModal',
    'showForwardModal',
    'showRecallModal',
    'recallMessages',
  ],
  messengerStore: [
    'allowTypedMentions',
    'currentMessageMultiSelectOption',
    'hideMessageMultiSelect',
    'isCommandBotEnabled',
    'isGiphyBotEnabled',
    'isMentionsEnabled',
    'isMessengerHidden',
    'isNoSearchResultsOnEmptyQueryEnabled',
  ],
  networkStore: ['isProviderNetwork'],
  localStore: ['richTextFormat'],
  trackerStore: ['logPendoAnalytics'],
})(MessageForm);
