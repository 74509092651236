import moment from 'moment';

import { isToday } from './';

const generateValidTimes = (_date, timeInterval, timezone, timeBuffer) => {
  const date = moment(_date || {}).tz(timezone);
  const timeIntervals = [];
  const buffer = timeBuffer || timeInterval;
  let startTime;
  let endTime;

  if (date && isToday(date)) {
    startTime = moment().add(
      buffer + (timeInterval - ((moment().minute() + buffer) % timeInterval)),
      'm'
    );
    endTime = moment(date).endOf('day');
  } else {
    startTime = moment(date).startOf('day');
    endTime = moment(date).endOf('day');
  }

  startTime = startTime.set('second', 0);
  endTime = endTime.set('second', 0);

  while (startTime < endTime) {
    // eslint-disable-next-line new-cap
    timeIntervals.push(new moment.tz(startTime, timezone));
    startTime.add('m', timeInterval);
  }

  return timeIntervals;
};

export default generateValidTimes;
