import React, { useRef, Component } from 'react';
import classnames from 'classnames';
import Highlight from 'react-highlight-words';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react';
import { Card, EntityDetail } from '../../../../common/components/WebComponents';
import { useIsHovered } from '../../../../common/hooks/useIsHovered';
import CallButton from '../../../../common/components/Call/CallButton';
import VideoCallButton from '../../../../common/components/Call/VideoCallButton';
import { PatientSidebarTab } from '../../../../models/enums/PatientSidebarTabs';
import mobxInjectSelect from '../../../../common/utils/mobxInjectSelect';
import SmsOptedOut from '../../../../common/components/SmsOptedOut';

import { isTokenTigerPage } from '../../../../common/utils';
import {
  Organization,
  Entity,
  User,
  PatientEntity,
  PatientContactEntity,
  TeamEntity,
  Conversation,
} from '../../../../types';
import styles from './SearchParityResults.module.css';
import { PatientReferenceInfo } from 'common/components/PatientContext';
import { groupByEntity, validateEntityOrder } from 'common/utils/searchParity';
import { KEYMAP } from 'common/constants';
import { getNotInConversationLabel } from 'common/utils/getNotInConversationLabel';

type PresenceStatuses = 'available' | 'unavailable' | 'dnd' | 'dndAf' | undefined;

type SearchParityResultsProps = {
  network: 'provider' | 'patient';
  results: Entity[];
  showPresenceIndicator?: boolean;
  presenceSize?: 'large' | 'medium' | 'small' | 'tiny';
  typeOfUser?: 'searchSideBarUser' | 'composeUser';
  query: string;
};

type Filter = 'Inbox' | 'Teams' | 'Groups' | 'Forums';

type MobxProps = {
  composeToSearchResult: (entity: Entity) => void;
  currentOrganization: Organization;
  currentUserId: string;
  entitySortMap: Map<string, number>;
  hideDnd: boolean;
  isAddFamilyAndContacts: boolean;
  isAvailabilityFeatureFlagEnabled: boolean;
  isUserDndAfEnabledForCurrentOrg: (user: User) => boolean;
  isPresenceFeatureFlagEnabled: boolean;
  isGroupVideoCallEnabled: boolean;
  isGroupVoiceCallEnabled: boolean;
  isPFVideoCallEnabled: boolean;
  isVideoCallEnabled: boolean;
  isVoiceCallEnabled: boolean;
  promptToJoinForum: (entity: Entity) => void;
  showPatientFilteredConversations: (entity: Entity) => void;
  setFilter: (filter: Filter) => void;
  setSidebarTab: (tab: PatientSidebarTab) => void;
  selectedFilter: Filter;
  shouldValidateSearchResults: boolean;
  subscribeUser: (userid: string) => void;
  unsubscribeUser: (userid: string) => void;
  currentConversation: Conversation;
  setEntityInboxSearchPendo: (entity: Entity) => void;
};

const Highlighter = ({
  text = '',
  searchText = '',
  style,
}: {
  text: string;
  searchText: string;
  style?: string;
}) => {
  return (
    <Highlight
      className={style || styles.providerHighlight}
      textToHighlight={text}
      searchWords={searchText.split(',')}
      autoEscape
    />
  );
};

export const Team = observer(
  ({
    query,
    team,
    varient = 'team',
  }: {
    query?: string;
    team: TeamEntity;
    varient?: 'team' | 'activated_team';
  }) => {
    const tagName = team.tag?.displayName;
    const tagColor = team.tag?.color;
    if (varient === 'activated_team') {
      return (
        <EntityDetail
          avatar={{
            type: 'team',
            ...(tagColor && { bgColor: tagColor }),
          }}
          name={
            query ? <Highlighter text={team.displayName} searchText={query} /> : team.displayName
          }
          options={{
            type: 'search results',
            details: {
              theme: 'provider',
              ...(team.description && { subtext: team.description }),
              ...(tagName && {
                description: query ? <Highlighter text={tagName} searchText={query} /> : tagName,
              }),
            },
          }}
        />
      );
    }
    return (
      <EntityDetail
        avatar={{
          type: 'initials',
          initialsString: team.displayName.split(' ').slice(0, 2).join(' '),
          ...(!tagColor && { initialsTheme: 'team' }),
          ...(tagColor && { bgColor: tagColor }),
        }}
        name={query ? <Highlighter text={team.displayName} searchText={query} /> : team.displayName}
        options={{
          type: 'search results',
          details: {
            theme: 'provider',
            ...(team.description && { subtext: team.description }),
            ...(tagName && {
              description: query ? <Highlighter text={tagName} searchText={query} /> : tagName,
            }),
          },
        }}
      />
    );
  }
);

type RoleProps = {
  role: Entity;
  isAvailabilityFeatureFlagEnabled?: boolean;
  isEntityInConversationCheck?: boolean;
  query?: string;
  subscribeUser?: (userid: string) => void;
  unsubscribeUser?: (userid: string) => void;
  currentConversation?: Conversation;
  currentUserId?: string;
  selectedRecipients?: Entity[];
};

class RoleClass extends Component<RoleProps> {
  componentDidMount() {
    const { role, isAvailabilityFeatureFlagEnabled, subscribeUser } = this.props;
    if (isAvailabilityFeatureFlagEnabled) {
      const roleUser = role?.members && role.members.length === 1 && role.members[0];
      if (roleUser) {
        subscribeUser?.(roleUser.id);
      }
    }
  }

  componentDidUpdate(prevProps: RoleProps) {
    const { role, isAvailabilityFeatureFlagEnabled, subscribeUser, unsubscribeUser } = this.props;
    if (isAvailabilityFeatureFlagEnabled) {
      const { role: prevPropRole } = prevProps;
      const roleUser = (role?.members && role.members.length === 1 && role.members[0]) as User;
      const prevRoleUser = (prevPropRole?.members &&
        prevPropRole.members.length === 1 &&
        prevPropRole.members[0]) as User;

      unsubscribeUser?.(prevRoleUser.id);
      subscribeUser?.(roleUser.id);
    }
  }

  componentWillUnmount() {
    const { role, isAvailabilityFeatureFlagEnabled, unsubscribeUser } = this.props;
    if (isAvailabilityFeatureFlagEnabled) {
      const roleUser = (role?.members && role.members.length === 1 && role.members[0]) as User;
      unsubscribeUser?.(roleUser.id);
    }
  }

  render() {
    const {
      query,
      role,
      isAvailabilityFeatureFlagEnabled,
      isEntityInConversationCheck,
      currentConversation,
      selectedRecipients,
    } = this.props;
    const roleUser = (role?.members && role.members.length === 1 && role.members[0]) as User;

    let presenceStatus: PresenceStatuses;
    if (isAvailabilityFeatureFlagEnabled && roleUser?.presenceStatus === 'AVAILABLE') {
      presenceStatus = 'available';
    } else {
      presenceStatus = undefined;
    }

    const notInConversationLabel = isEntityInConversationCheck
      ? getNotInConversationLabel(
          currentConversation,
          isEntityInConversationCheck,
          role?.botUser?.id || role.id,
          selectedRecipients
        )
      : null;

    return (
      <div className={styles.customizableEntity}>
        <EntityDetail
          avatar={{
            type: 'role',
            shape: 'squircle',
            ...(role.tag && !role.tag.isDefault && { bgColor: role.tag.color }),
            presenceStatus,
            presenceSize: 'small',
          }}
          name={
            query ? (
              <Highlighter text={role.displayName} searchText={query} />
            ) : (
              <span className={classnames({ [styles.nameContainer]: isEntityInConversationCheck })}>
                {role.displayName}
              </span>
            )
          }
          options={{
            type: 'search results',
            details: {
              theme: 'provider',
              subtext:
                query && role.tag?.displayName ? (
                  <Highlighter text={role.tag?.displayName} searchText={query} />
                ) : (
                  role.tag?.displayName
                ),
              description:
                role.members && role.members.length
                  ? `On Duty: ${role.members[0].displayName}`
                  : `No one is on duty`,
            },
          }}
        />
        {notInConversationLabel ? (
          <div className={styles.textContainer}>
            <span className={styles.notInGroupText}>{notInConversationLabel}</span>
          </div>
        ) : null}
      </div>
    );
  }
}

export const Role = observer(RoleClass);

export const Patient = ({ patient, query }: { patient: PatientEntity; query?: string }) => {
  const {
    patient: { mrn, dob, gender, smsOptedOut },
  } = patient;

  const patientInformation = [
    mrn ? `MRN ${mrn}` : null,
    dob ? `DOB ${dob}` : null,
    gender ? gender : null,
  ]
    .filter((v) => v)
    .join(' | ');

  return (
    <EntityDetail
      avatar={{ type: 'patient', smsOptedOut }}
      name={
        query ? (
          <Highlighter
            style={styles.patientHighlight}
            text={patient?.displayName}
            searchText={query}
          />
        ) : (
          patient.displayName
        )
      }
      options={{
        type: 'search results',
        details: { theme: 'patient', patientInformation },
        smsOptedOut,
        smsOptedOutSubText: 'Opted out via SMS',
      }}
    />
  );
};

export const PatientContact = observer(
  ({ patientContact }: { patientContact: PatientContactEntity }) => {
    const { smsOptedOut } = patientContact.patientContact;
    return (
      <EntityDetail
        avatar={{ type: 'empty' }}
        name={patientContact.displayName}
        options={{
          type: 'search results',
          details: {
            theme: 'patientContact',
            relation: patientContact.patientContact.relation,
          },
          smsOptedOut,
          smsOptedOutSubText: 'Opted out via SMS',
        }}
      />
    );
  }
);

export const Individual = observer(
  ({
    currentConversation,
    currentOrganization,
    currentUserId,
    hideDnd,
    individual,
    isDisabled,
    isPresenceFeatureFlagEnabled,
    isAvailabilityFeatureFlagEnabled,
    showPresenceIndicator,
    presenceSize,
    showDndAfStatus,
    query,
    isEntityInConversationCheck,
    typeOfUser = 'composeUser',
    selectedRecipients,
  }: {
    currentConversation?: Conversation;
    currentOrganization?: Organization;
    currentUserId?: string;
    hideDnd?: boolean;
    individual: Entity;
    isDisabled?: boolean;
    isPresenceFeatureFlagEnabled?: boolean;
    isAvailabilityFeatureFlagEnabled?: boolean;
    showPresenceIndicator?: boolean | undefined;
    presenceSize?: 'large' | 'medium' | 'small' | 'tiny' | undefined;
    showDndAfStatus?: boolean;
    typeOfUser?: 'searchSideBarUser' | 'composeUser';
    isEntityInConversationCheck?: boolean;
    query?: string;
    selectedRecipients?: Entity[];
  }) => {
    let presenceStatus: PresenceStatuses;
    const subtext = [individual.title, individual.department].filter((v) => v).join(', ');

    const presenceFlag = isPresenceFeatureFlagEnabled || isAvailabilityFeatureFlagEnabled;
    const hasPresenceIndicator =
      showPresenceIndicator &&
      !individual.isPatient &&
      !individual.isPatientContact &&
      !individual.isVisitor &&
      !(individual.id && isTokenTigerPage(individual.id));

    let initialsString = [individual.firstName, individual.lastName]
      .filter((v) => v)
      .filter((v) => v)
      .join(' ');
    if (!initialsString.length) initialsString = individual.displayName;

    if (individual.dnd && showDndAfStatus) {
      presenceStatus = 'dndAf';
    } else if (individual.dnd && (currentUserId !== individual.id || individual || !hideDnd)) {
      presenceStatus = 'dnd';
    } else if (
      presenceFlag &&
      (currentUserId === individual.id || individual.presenceStatus === 'AVAILABLE')
    ) {
      presenceStatus = 'available';
    } else if (presenceFlag && individual.presenceStatus === 'UNAVAILABLE') {
      presenceStatus = isAvailabilityFeatureFlagEnabled ? undefined : 'unavailable';
    } else {
      presenceStatus = undefined;
    }

    const notInConversationLabel = isEntityInConversationCheck
      ? getNotInConversationLabel(
          currentConversation,
          isEntityInConversationCheck,
          individual.id,
          selectedRecipients
        )
      : null;

    return (
      <div className={styles.customizableEntity}>
        <EntityDetail
          avatar={{
            ...(individual.avatarUrl
              ? {
                  type: 'image',
                  imgSrc: individual.avatarUrl,
                  presenceStatus: hasPresenceIndicator ? presenceStatus : undefined,
                  presenceSize,
                }
              : {
                  initialsTheme: 'user',
                  isDisabled,
                  theme: currentOrganization?.isContacts ? 'empty' : undefined,
                  type: currentOrganization?.isContacts ? typeOfUser : 'initials',
                  initialsString,
                  presenceStatus: hasPresenceIndicator ? presenceStatus : undefined,
                  presenceSize,
                }),
          }}
          name={
            query ? (
              <Highlighter text={individual.displayName} searchText={query} />
            ) : (
              <span
                className={classnames({
                  [styles.isDisabled]: isDisabled,
                  [styles.nameContainer]: isEntityInConversationCheck,
                })}
              >
                {individual.displayName}
              </span>
            )
          }
          options={{
            type: 'search results',
            details: {
              theme: 'provider',
              subtext: query ? <Highlighter text={subtext} searchText={query} /> : subtext,
            },
          }}
        />
        {notInConversationLabel ? (
          <div className={styles.textContainer}>
            <span className={styles.notInGroupText}>{notInConversationLabel}</span>
          </div>
        ) : null}
      </div>
    );
  }
);

const PROVIDER_NETWORK_MEMBER_LIMIT = 10;

export const Group = observer(({ group, query }: { group: Entity; query?: string }) => {
  const avatarUrl = group.avatarUrl;
  const patientReferencePill = group.patientContextDetails && (
    <div className={styles.patientReferencePill}>
      <PatientReferenceInfo
        patientReferenceId={group.patientContextId}
        patientReference={group.patientContextDetails}
        type={'pill'}
      />
    </div>
  );
  return (
    <EntityDetail
      avatar={avatarUrl ? { type: 'image', imgSrc: avatarUrl } : { type: 'group' }}
      name={query ? <Highlighter text={group.displayName} searchText={query} /> : group.displayName}
      options={{
        type: 'search results',
        details: { theme: 'provider', patientContext: patientReferencePill },
      }}
    />
  );
});

export const DistributionList = observer(
  ({ distributionList, query }: { distributionList: Entity; query?: string }) => {
    return (
      <EntityDetail
        avatar={{ type: 'broadcast' }}
        name={
          query ? (
            <Highlighter text={distributionList.displayName} searchText={query} />
          ) : (
            distributionList.displayName
          )
        }
        options={{ type: 'search results', details: { theme: 'provider' } }}
      />
    );
  }
);

export const Forum = observer(({ forum, query }: { forum: Entity; query?: string }) => {
  const avatarUrl = forum?.avatarUrl;
  return (
    <EntityDetail
      avatar={avatarUrl ? { type: 'image', imgSrc: avatarUrl } : { type: 'forum' }}
      name={query ? <Highlighter text={forum.displayName} searchText={query} /> : forum.displayName}
      options={{ type: 'search results', details: { theme: 'provider' } }}
    />
  );
});

const SearchResults = ({
  composeToSearchResult,
  currentOrganization,
  currentUserId,
  entitySortMap,
  hideDnd,
  isAddFamilyAndContacts,
  isUserDndAfEnabledForCurrentOrg,
  isAvailabilityFeatureFlagEnabled,
  isGroupVideoCallEnabled,
  isGroupVoiceCallEnabled,
  isPFVideoCallEnabled,
  isPresenceFeatureFlagEnabled,
  isVideoCallEnabled,
  isVoiceCallEnabled,
  network,
  presenceSize,
  promptToJoinForum,
  query,
  results,
  selectedFilter,
  setFilter,
  setSidebarTab,
  showPatientFilteredConversations,
  showPresenceIndicator,
  shouldValidateSearchResults,
  subscribeUser,
  unsubscribeUser,
  typeOfUser,
  setEntityInboxSearchPendo,
}: SearchParityResultsProps & MobxProps) => {
  const groupedEntities = groupByEntity(currentOrganization, entitySortMap, results);

  if (shouldValidateSearchResults) {
    const entityTypes = groupedEntities.map((entity) => entity.entity_type);
    const isValid = validateEntityOrder(entityTypes);
    if (!isValid) {
      const e = new Error(
        `SearchSidebar Invalid entity order; entityTypes: ${entityTypes}; entitySortMap: ${Array.from(
          entitySortMap.entries()
        )}`
      );
      Sentry.captureException(e);
    }
  }

  const _setFilter = (entity: Entity): Filter => {
    const { $entityType: entityType, groupType } = entity;
    if (selectedFilter === 'Forums' && entityType === 'group' && groupType === 'FORUM') {
      return 'Forums';
    } else if (selectedFilter === 'Groups' && entityType === 'group' && groupType === 'GROUP') {
      return 'Groups';
    } else if (selectedFilter === 'Teams' && entityType === 'team') {
      return 'Teams';
    }
    return 'Inbox';
  };

  const onClick = async (entity: Entity) => {
    const {
      $entityType: entityType,
      groupType,
      hasCurrentUser,
      isPatient,
      isPatientContact,
    } = entity;
    const filter = _setFilter(entity);
    setFilter(filter);
    if (entityType === 'group' && groupType === 'FORUM' && !hasCurrentUser) {
      promptToJoinForum(entity);
    } else if (isPatient || isPatientContact) {
      setSidebarTab('messages');
      showPatientFilteredConversations(entity);
    } else {
      composeToSearchResult(entity);
    }
    setEntityInboxSearchPendo(entity);
  };

  const Item = ({
    children,
    entity,
    lastItem,
    itemKey,
  }: {
    children?: React.ReactNode;
    entity: Entity;
    lastItem?: boolean;
    itemKey?: number | string;
  }) => {
    const setDivider = () => {
      if (lastItem === true) return null;
      if ('patient' in entity && entity.patient?.contacts && entity.patient?.contacts.length > 0) {
        return styles.partialBorder;
      }
      if (
        ('patient' in entity && entity.patient) ||
        ('patientContact' in entity && entity.patientContact)
      ) {
        return styles.fullBorder;
      } else {
        return styles.partialBorder;
      }
    };

    return (
      <div
        key={itemKey}
        className={classnames(styles.item, setDivider())}
        onClick={() => onClick(entity)}
        onKeyDown={(e) => {
          if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
            onClick(entity);
          }
        }}
        role="button"
      >
        {children}
      </div>
    );
  };

  const IndividualItem = ({
    currentOrganization,
    currentUserId,
    hideDnd,
    individual,
    isLast,
    isAvailabilityFeatureFlagEnabled,
    isPresenceFeatureFlagEnabled,
    presenceSize,
    showDndAfStatus,
    showPresenceIndicator,
    typeOfUser,
    itemKey,
  }: {
    currentOrganization: Organization;
    currentUserId: string;
    hideDnd: boolean;
    isAvailabilityFeatureFlagEnabled: boolean;
    isPresenceFeatureFlagEnabled: boolean;
    individual: Entity;
    isLast: boolean;
    presenceSize?: 'large' | 'medium' | 'small' | 'tiny' | undefined;
    showDndAfStatus?: boolean;
    showPresenceIndicator?: boolean | undefined;
    typeOfUser?: 'searchSideBarUser' | 'composeUser';
    itemKey: string | number;
  }) => {
    const ref = useRef<HTMLDivElement>(null);
    const isHovered = useIsHovered(ref);

    return (
      <div ref={ref}>
        <Item itemKey={itemKey} entity={individual} lastItem={isLast}>
          <div className={styles.entityDetail}>
            <Individual
              query={query}
              currentOrganization={currentOrganization}
              currentUserId={currentUserId}
              hideDnd={hideDnd}
              individual={individual}
              isPresenceFeatureFlagEnabled={isPresenceFeatureFlagEnabled}
              isAvailabilityFeatureFlagEnabled={isAvailabilityFeatureFlagEnabled}
              showDndAfStatus={showDndAfStatus}
              showPresenceIndicator={showPresenceIndicator}
              presenceSize={presenceSize}
              typeOfUser={typeOfUser}
            />
          </div>
          {isHovered && (isVideoCallEnabled || isVoiceCallEnabled) && (
            <div className={styles.callButton}>
              <CallButton entity={individual} origin={'Search'} />
            </div>
          )}
        </Item>
      </div>
    );
  };

  const GroupItem = ({ group, isLast }: { group: Entity; isLast: boolean }) => {
    const ref = useRef<HTMLDivElement>(null);
    const isHovered = useIsHovered(ref);

    return (
      <div ref={ref}>
        <Item entity={group} lastItem={isLast}>
          <div className={styles.entityDetail}>
            <Group group={group} query={query} />
          </div>
          {isHovered &&
            (isGroupVideoCallEnabled || isGroupVoiceCallEnabled) &&
            group.memberCount <= PROVIDER_NETWORK_MEMBER_LIMIT && (
              <div className={styles.callButton}>
                <CallButton entity={group} origin={'Search'} />
              </div>
            )}
        </Item>
      </div>
    );
  };

  return (
    <div className={styles.results}>
      {groupedEntities.map((entities, index) => {
        if (entities.entity_type === 'team') {
          return (
            <Card key={`team-${index}`} header="Teams" theme="provider" type="category">
              {entities.values.map((team, index) => {
                if (team.$entityType === 'team') {
                  const lastItem = index === entities.values.length - 1;
                  return (
                    <Item key={index} entity={team} lastItem={lastItem}>
                      <Team team={team} query={query} />
                    </Item>
                  );
                }
                return <></>;
              })}
            </Card>
          );
        }

        if (entities.entity_type === 'role') {
          return (
            <Card key={`role-${index}`} header="Roles" theme="provider" type="category">
              {entities.values.map((role, index) => {
                const lastItem = index === entities.values.length - 1;
                return (
                  <Item key={index} entity={role} lastItem={lastItem}>
                    <Role
                      role={role}
                      query={query}
                      isAvailabilityFeatureFlagEnabled={isAvailabilityFeatureFlagEnabled}
                      subscribeUser={subscribeUser}
                      unsubscribeUser={unsubscribeUser}
                    />
                  </Item>
                );
              })}
            </Card>
          );
        }

        if (entities.entity_type === 'user') {
          if (network === 'patient') {
            return (
              <Card
                key={`user-patient-${index}`}
                header="Individuals"
                theme="patient"
                type="category"
              >
                {entities.values.map((patient, index) => {
                  const lastItem = index === entities.values.length - 1;
                  if (patient.isPatient && 'patient' in patient) {
                    return (
                      <Item key={index} entity={patient} lastItem={lastItem}>
                        <div className={styles.entityDetail}>
                          <Patient patient={patient} query={query} />
                          {patient.patient.smsOptedOut && (
                            <div className={styles.smsOptedOut}>
                              <SmsOptedOut tooltip={!isAddFamilyAndContacts} />
                            </div>
                          )}
                        </div>
                        {isPFVideoCallEnabled && !patient.patient.smsOptedOut && (
                          <div className={styles.callButton}>
                            <VideoCallButton
                              entity={{
                                recipientId: patient.id,
                                recipientName: patient.displayName,
                              }}
                              isSmall={true}
                              origin={'Search'}
                            />
                          </div>
                        )}
                      </Item>
                    );
                  }
                  if (patient.isPatientContact && 'patientContact' in patient) {
                    return (
                      <Item key={index} entity={patient} lastItem={lastItem}>
                        <div className={styles.entityDetail}>
                          <PatientContact patientContact={patient} />
                          {patient.patientContact.smsOptedOut && (
                            <div className={styles.smsOptedOut}>
                              <SmsOptedOut tooltip={!isAddFamilyAndContacts} />
                            </div>
                          )}
                        </div>
                        {isPFVideoCallEnabled && !patient.patientContact.smsOptedOut && (
                          <div className={styles.callButton}>
                            <VideoCallButton
                              entity={{
                                recipientId: patient.id,
                                recipientName: patient.displayName,
                              }}
                              isSmall={true}
                              origin={'Search'}
                            />
                          </div>
                        )}
                      </Item>
                    );
                  }
                  return <></>;
                })}
              </Card>
            );
          }
          return (
            <Card
              key={`user-provider-${index}`}
              header="Individuals"
              theme="provider"
              type="category"
            >
              {entities.values.map((individual, index) => {
                const lastItem = index === entities.values.length - 1;
                const userDndAfStatusForCurrentOrg = isUserDndAfEnabledForCurrentOrg(
                  individual as unknown as User
                );
                return (
                  <IndividualItem
                    key={index}
                    itemKey={index}
                    currentOrganization={currentOrganization}
                    currentUserId={currentUserId}
                    hideDnd={hideDnd}
                    individual={individual}
                    isLast={lastItem}
                    isPresenceFeatureFlagEnabled={isPresenceFeatureFlagEnabled}
                    isAvailabilityFeatureFlagEnabled={isAvailabilityFeatureFlagEnabled}
                    presenceSize={presenceSize}
                    showDndAfStatus={userDndAfStatusForCurrentOrg}
                    showPresenceIndicator={showPresenceIndicator}
                    typeOfUser={typeOfUser}
                  ></IndividualItem>
                );
              })}
            </Card>
          );
        }

        if (entities.entity_type === 'group') {
          return (
            <Card key={`group-${index}`} header="Groups" theme="provider" type="category">
              {entities.values.map((group, index) => {
                const lastItem = index === entities.values.length - 1;
                if (group.groupType === 'ACTIVATED_TEAM') {
                  return (
                    <Item key={index} entity={group} lastItem={lastItem}>
                      <Team team={group as TeamEntity} varient="activated_team" query={query} />
                    </Item>
                  );
                }
                return <GroupItem group={group} isLast={lastItem} />;
              })}
            </Card>
          );
        }

        if (entities.entity_type === 'distributionList') {
          return (
            <Card
              key={`distributionList-${index}`}
              header="Broadcast"
              theme={network}
              type="category"
            >
              {entities.values.map((distributionList, index) => {
                const lastItem = index === entities.values.length - 1;
                return (
                  <Item key={index} entity={distributionList} lastItem={lastItem}>
                    <DistributionList distributionList={distributionList} query={query} />
                  </Item>
                );
              })}
            </Card>
          );
        }

        if (entities.entity_type === 'forum') {
          return (
            <Card key={`forum-${index}`} header="Forums" theme="provider" type="category">
              {entities.values.map((forum, index) => {
                const lastItem = index === entities.values.length - 1;
                return (
                  <Item key={index} entity={forum} lastItem={lastItem}>
                    <Forum forum={forum} query={query} />
                  </Item>
                );
              })}
            </Card>
          );
        }
        return <></>;
      })}
    </div>
  );
};

export default mobxInjectSelect<SearchParityResultsProps, MobxProps>({
  callStore: [
    'isGroupVideoCallEnabled',
    'isGroupVoiceCallEnabled',
    'isPFVideoCallEnabled',
    'isVideoCallEnabled',
    'isVoiceCallEnabled',
  ],
  composeMessageStore: ['composeToSearchResult', 'isAddFamilyAndContacts'],
  conversationStore: ['showPatientFilteredConversations'],
  groupStore: ['promptToJoinForum'],
  messengerStore: [
    'currentOrganization',
    'isAvailabilityFeatureFlagEnabled',
    'isPresenceFeatureFlagEnabled',
    'shouldValidateSearchResults',
  ],
  presenceStore: ['subscribeUser', 'unsubscribeUser'],
  patientStore: ['setSidebarTab'],
  rosterStore: ['setFilter', 'selectedFilter'],
  searchSidebarStore: ['entitySortMap'],
  sessionStore: ['currentUserId'],
  userStore: ['hideDnd', 'isUserDndAfEnabledForCurrentOrg'],
  trackerStore: ['setEntityInboxSearchPendo'],
})(SearchResults);
