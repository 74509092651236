import Highlight from 'react-highlight-words';
import BEM from '../../../common/bem';
import MessageAttachment from './MessageAttachment';
import { ReactComponent as AttachmentIcon } from 'common/images/attach_file.svg';
import { Message } from 'types';
import { useAppSelector } from 'redux-stores';

const classes = BEM.with('MessageAttachmentList');

type MessageAttachmentProps = {
  message: Message;
  multiSelectable: unknown;
  printMode?: boolean;
};

const MessageAttachmentList = ({ message, multiSelectable, printMode }: MessageAttachmentProps) => {
  const { attachments } = message;
  const { isKeywordSearchModeOn, keywordSearchTerm } = useAppSelector(
    (state) => state.keywordSearch
  );
  if (!attachments || attachments.length === 0) return null;

  // TODO load attachments only if in view

  const isKeywordSearchTermInAttachmentName = (attachmentName: string): boolean => {
    if (!isKeywordSearchModeOn || keywordSearchTerm === '') return false;
    const keywordSearchTermArray = keywordSearchTerm.split(' ');
    return keywordSearchTermArray.some((term) =>
      attachmentName.toLowerCase().includes(term.toLowerCase())
    );
  };

  return (
    <div className={classes()}>
      {attachments.map((attachment) => (
        <div key={attachment.id} className={classes('attachment-list-item')}>
          {isKeywordSearchTermInAttachmentName(attachment.name) ? (
            <div className={classes('attachment-container')}>
              <AttachmentIcon />
              <div className={classes('attachment-name')}>
                <Highlight
                  highlightClassName={classes('highlight')}
                  textToHighlight={attachment.name}
                  searchWords={keywordSearchTerm.split(' ')}
                  autoEscape
                />
              </div>
            </div>
          ) : (
            <MessageAttachment
              attachment={attachment}
              message={message}
              multiSelectable={multiSelectable}
              printMode={printMode}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default MessageAttachmentList;
