import {
  UserSettings,
  ServerAccountSettings,
  ServerBool,
  ServerUserSettings,
} from '../services/Admin/utils/types';
import {
  formatServerAccountSettings,
  parseServerUserSettings,
} from '../services/Admin/utils/userSettingsHelpers';
import {
  Persona,
  PersonaCreateFields,
  PersonaGetFields,
  PersonaGetAllFields,
  PersonaRowData,
  PersonaSearchResponse,
  PersonaUpdateFields,
} from '../types/Persona';
import BaseAPI from './BaseAPI';

export default class PersonaAPI extends BaseAPI {
  async findPersona({ organizationId, personaId }: PersonaGetFields): Promise<PersonaRowData> {
    const { data } = (await this.httpClient.get(
      `${this.host.tcUrl}/v1/user-service/Personas/${personaId}`,
      {
        headers: {
          'tt-x-organization-key': organizationId,
        },
      }
    )) as unknown as { data: PersonaRowData };

    return {
      ...data,
      settings: parseServerUserSettings(data.settings as ServerUserSettings),
    };
  }

  async findPersonas({
    organizationId,
    persona_name = '',
    pageSize = 10,
    page = 1,
  }: PersonaGetAllFields): Promise<PersonaSearchResponse> {
    const { data } = (await this.httpClient.get(`${this.host.tcUrl}/v1/user-service/Personas`, {
      headers: {
        'tt-x-organization-key': organizationId,
      },
      query: {
        page,
        pageSize,
        persona_name,
      },
    })) as unknown as { data: PersonaSearchResponse };

    return {
      ...data,
      data: data.data.map((persona) => ({
        ...persona,
        settings: parseServerUserSettings(persona.settings as ServerUserSettings),
      })),
    };
  }

  async findTitles(organizationId: string) {
    const endpoint = `${this.host.tcUrl}/v1/user-service/Titles/GetOrgTitles`;
    const res = await this.httpClient.get(endpoint, {
      headers: { 'TT-X-Organization-Key': organizationId },
    });
    return res.data;
  }

  async createPersona(payload: PersonaCreateFields, orgId: string): Promise<Persona> {
    const endpoint = `${this.host.tcUrl}/v1/user-service/Personas`;

    try {
      const res = (await this.httpClient.post(endpoint, {
        data: {
          ...payload,
          ...{ settings: this._formatSettings(payload.settings) },
          ...this._formatPermissions(payload.permissions),
        },
        headers: {
          'TT-X-Organization-Key': orgId,
        },
      })) as unknown as Promise<Persona>;
      return res;
    } catch (e) {
      console.error('API - Failed to create persona: ', JSON.stringify(e));
      throw e;
    }
  }

  async updatePersona(payload: PersonaUpdateFields, personaId: string, orgId: string) {
    const endpoint = `${this.host.tcUrl}/v1/user-service/Personas/${personaId}`;

    try {
      const res = (await this.httpClient.put(endpoint, {
        data: {
          ...payload,
          ...{ settings: this._formatSettings(payload.settings) },
          ...this._formatPermissions(payload.permissions),
        },
        headers: {
          'TT-X-Organization-Key': orgId,
        },
      })) as unknown as Promise<Persona>;
      return res;
    } catch (e) {
      console.error('API - Failed to update persona: ', JSON.stringify(e));
      throw e;
    }
  }

  async deletePersona(personaId: string, orgId: string) {
    const endpoint = `${this.host.tcUrl}/v1/user-service/Personas/${personaId}`;

    try {
      const res = (await this.httpClient.del(endpoint, {
        headers: {
          'TT-X-Organization-Key': orgId,
        },
      })) as unknown as Promise<Persona>;
      return res;
    } catch (e) {
      console.error('API - Failed to delete persona: ', JSON.stringify(e));
      throw e;
    }
  }

  _formatSettings(settings: UserSettings | undefined): ServerAccountSettings {
    if (!settings) return {};
    const formattedUserSettings = formatServerAccountSettings(settings, true);
    const keyFields = Object.keys(formattedUserSettings) as (keyof ServerAccountSettings)[];
    // TODO: This is temporary to work around server bug (USR-96), needs to be removed
    //       once the issue is resolved.
    const tempKeyFilter: (keyof ServerAccountSettings)[] = [
      'voip',
      'ttl',
      'repeat_push',
      'forward',
      'delivery_escalation',
      'dor',
      'alternative_ma_routing',
      'pin_lock',
      'click_to_call',
      'group_audio_call',
      'video_call',
    ];
    return keyFields
      .filter((k) => !tempKeyFilter.includes(k))
      .filter(
        (k) =>
          formattedUserSettings[k] &&
          formattedUserSettings[k] !== '0' &&
          formattedUserSettings[k] !== 'false'
      )
      .reduce((memo, k) => {
        return { ...memo, [k]: formattedUserSettings[k] };
      }, {});
  }

  _formatPermissions(permissions: { [k: string]: boolean } | undefined) {
    return permissions
      ? {
          permissions: Object.entries(permissions)
            .filter(([_, v]) => v)
            .map(([k]) => k),
        }
      : {};
  }
}
