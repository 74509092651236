import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@tigerconnect/web-component-library';

import { ReactComponent as DownArrowSvg } from '../../images/down-arrow.svg';
import { ReactComponent as PatientArrows } from '../../images/patients-arrows.svg';
import { ReactComponent as ProviderArrows } from '../../images/provider-arrows.svg';
import OrganizationSelector from './OrganizationSelector';
import propTypes from 'common/propTypes';
import { UnreadBadgeCount } from 'common/components';
import { mobxInjectSelect } from 'common/utils';
import { AppTypes } from 'models/enums';
import BEM from 'common/bem';

const classes = BEM.with('SidebarOrganizationSelector');

function SidebarOrganizationSelector({
  conversationsLoaded,
  currentAppSelected,
  currentOrganization,
  isProviderNetwork,
  organizations = [],
  otherOrganizationsAlertsUnreadCount,
  otherOrganizationsUnreadCount,
  otherOrganizationsUnreadPriorityCount,
  otherOrganizationsUnreadVWRCount,
  switchNetwork,
  totalUnreadAlertsCount,
  totalUnreadCount,
  totalUnreadVWRCount,
}) {
  const [switchText, setSwitchText] = useState(isProviderNetwork ? 'Providers' : 'Patients');
  const [isOpenSelector, setIsOpenSelector] = useState(false);
  const classNetwork = isProviderNetwork ? 'provider' : 'patient';
  const SvgComponent = isProviderNetwork ? ProviderArrows : PatientArrows;
  const clickable = organizations.length > 0 && !!currentOrganization;
  const showPatientNetwork = !!currentOrganization && currentOrganization.isPatientNetworkEnabled;
  const getUnreadMessageLabel = (count) =>
    count > 0 ? ` with ${count} unread message${count > 1 ? 's' : ''}` : '';
  let enableButtonFragment, unreadOrArrowFragment;
  let orgName = 'Loading...';
  let priority = false;

  function changeButtonLabel(text) {
    return () => {
      setSwitchText(text);
    };
  }

  if (currentOrganization) {
    if (clickable) orgName = currentOrganization.displayName;
    priority = otherOrganizationsUnreadPriorityCount > 0;

    const unreadCount =
      currentAppSelected === AppTypes.MESSAGES || currentAppSelected === AppTypes.VIRTUAL_WAITING
        ? otherOrganizationsAlertsUnreadCount +
          otherOrganizationsUnreadCount +
          (otherOrganizationsUnreadVWRCount ? otherOrganizationsUnreadVWRCount : 0)
        : totalUnreadAlertsCount +
          totalUnreadCount +
          (totalUnreadVWRCount ? totalUnreadVWRCount : 0);

    if (unreadCount > 0 && conversationsLoaded) {
      unreadOrArrowFragment = (
        <span>
          <UnreadBadgeCount count={unreadCount} priority={priority} />
        </span>
      );
    } else if (organizations.length > 1) {
      unreadOrArrowFragment = (
        <span className={classes('image-container')}>
          <DownArrowSvg className={classes('image')} />
        </span>
      );
    }

    if (showPatientNetwork) {
      let patientUnreadOrArrowFragment;
      const { patient, provider } = currentOrganization.networks;
      if (isProviderNetwork && patient.unreadCount > 0) {
        patientUnreadOrArrowFragment = (
          <UnreadBadgeCount className={classes('unread-badge')} count={patient.unreadCount} />
        );
      } else if (!isProviderNetwork && provider.unreadCount > 0) {
        patientUnreadOrArrowFragment = (
          <UnreadBadgeCount className={classes('unread-badge')} count={provider.unreadCount} />
        );
      } else {
        patientUnreadOrArrowFragment = <SvgComponent className={classes('button-arrows')} />;
      }
      const switchNetworkLabelBtn = `Switch to ${isProviderNetwork ? 'Patients' : 'Providers'}`;
      enableButtonFragment = (
        <button
          className={classes(`${classNetwork}-button`)}
          aria-label={
            switchNetworkLabelBtn +
            getUnreadMessageLabel(!isProviderNetwork ? provider.unreadCount : patient.unreadCount)
          }
          onClick={switchNetwork}
          onFocus={changeButtonLabel(switchNetworkLabelBtn)}
          onBlur={changeButtonLabel(isProviderNetwork ? 'Providers' : 'Patients')}
          onMouseEnter={changeButtonLabel(switchNetworkLabelBtn)}
          onMouseLeave={changeButtonLabel(isProviderNetwork ? 'Providers' : 'Patients')}
        >
          <div className={classes(`${classNetwork}-button-text`)} aria-hidden>
            {switchText}
          </div>
          {patientUnreadOrArrowFragment}
        </button>
      );
    }
  }

  return (
    <div className={classes()}>
      <div className={classes('selector-container')}>
        <div title={orgName} className={classes('selected-org')}>
          <button
            className={classes('selected-hover', { priority })}
            onClick={clickable ? () => setIsOpenSelector(!isOpenSelector) : undefined}
            id="org-selector"
            aria-label="Change Organization"
          >
            <span className={classes('name')}>{orgName}</span>
            {unreadOrArrowFragment}
          </button>
          {enableButtonFragment}
        </div>
      </div>
      <Dropdown
        aria-label="organization-selector-dropdown"
        triggerHandler={() => setIsOpenSelector(false)}
      >
        {isOpenSelector && <OrganizationSelector closeSelector={() => setIsOpenSelector(false)} />}
      </Dropdown>
    </div>
  );
}

SidebarOrganizationSelector.propType = {
  conversationsLoaded: PropTypes.bool.isRequired,
  currentOrganization: propTypes.organization,
  organizations: propTypes.organizationArray,
  otherOrganizationsAlertsUnreadCount: PropTypes.number.isRequired,
  otherOrganizationsUnreadCount: PropTypes.number.isRequired,
  otherOrganizationsUnreadPriorityCount: PropTypes.number.isRequired,
  otherOrganizationsUnreadVWRCount: PropTypes.number.isRequired,
  switchNetwork: PropTypes.func.isRequired,
  totalUnreadAlertsCount: PropTypes.number.isRequired,
  totalUnreadCount: PropTypes.number.isRequired,
  totalUnreadVWRCount: PropTypes.number.isRequired,
};

export default mobxInjectSelect({
  messengerStore: [
    'conversationsLoaded',
    'currentAppSelected',
    'currentOrganization',
    'organizations',
    'otherOrganizationsAlertsUnreadCount',
    'otherOrganizationsUnreadCount',
    'otherOrganizationsUnreadPriorityCount',
    'otherOrganizationsUnreadVWRCount',
    'totalUnreadAlertsCount',
    'totalUnreadCount',
    'totalUnreadVWRCount',
  ],
  networkStore: ['isProviderNetwork', 'switchNetwork'],
})(SidebarOrganizationSelector);
