import {
  PersonaCreateFields,
  PersonaUpdateFields,
  PersonaGetFields,
  PersonaGetAllFields,
  PersonaRowData,
  PersonaSearchResponse,
} from '../types/Persona';
import BaseService from './BaseService';

export default class PersonaService extends BaseService {
  async findPersona(payload: PersonaGetFields): Promise<PersonaRowData> {
    return await this.host.api.persona.findPersona(payload);
  }

  async findPersonas(payload: PersonaGetAllFields): Promise<PersonaSearchResponse> {
    return await this.host.api.persona.findPersonas(payload);
  }

  async findTitles(organizationId: string): Promise<{ titles: string[] }> {
    return await this.host.api.persona.findTitles(organizationId);
  }

  async updatePersona(payload: PersonaUpdateFields, personaId: string, organizationId: string) {
    return await this.host.api.persona.updatePersona(payload, personaId, organizationId);
  }

  async createPersona(payload: PersonaCreateFields, organizationId: string) {
    return await this.host.api.persona.createPersona(payload, organizationId);
  }

  async deletePersona(personaId: string, organizationId: string) {
    return await this.host.api.persona.deletePersona(personaId, organizationId);
  }
}
