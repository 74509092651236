import { action, observable, runInAction, makeObservable } from 'mobx';
import { FROZEN_EMPTY_ARRAY } from '../common/utils';

const DEFAULT_TEAM_STATE = {
  addYourself: false,
  anyoneCanJoin: false,
  canMembersLeave: false,
  description: '',
  id: '',
  members: FROZEN_EMPTY_ARRAY,
  name: '',
};
const MAX_TEAM_MEMBERS = 48;

export default class CreateTeamStore {
  @observable createInProgress = false;
  @observable.shallow team = null;
  @observable teamNameError = false;

  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  mounted() {
    this._resetTeam();
  }

  get maxTeamMembers() {
    return MAX_TEAM_MEMBERS;
  }

  _resetTeam = () => {
    this.team = { ...DEFAULT_TEAM_STATE };
  };

  @action('CreateTeamStore.updateTeam') updateTeam = (field, value) => {
    this.team[field] = value;
  };

  @action('CreateGroupStore.openEditTeamModal') openEditTeamModal = async (team) => {
    const { modalStore, sessionStore } = this.stores;
    const { currentUserId } = sessionStore;
    const {
      canMembersLeave,
      canRequestToJoin,
      description,
      displayName,
      hasCurrentUserOrRole,
      id,
    } = team;
    let { members } = team;
    this.createInProgress = false;

    if (hasCurrentUserOrRole) {
      members = members.filter(({ id }) => id !== currentUserId);
    }

    members = members.map((item) => {
      if (item.botRole) item.botRole.displayName = item.displayName;
      return item.botRole ? item.botRole : item;
    });

    this.team = {
      addYourself: hasCurrentUserOrRole,
      anyoneCanJoin: canRequestToJoin,
      canMembersLeave,
      description,
      id,
      members,
      name: displayName,
    };
    this.teamNameError = false;

    modalStore.openModal('saveTeam');
  };

  @action('CreateTeamStore.createTeam') createTeam = async () => {
    const { messengerStore, modalStore, sessionStore, conversationStore, rosterStore } =
      this.stores;
    const { currentUserId } = sessionStore;
    const organizationId = messengerStore.currentOrganizationId;
    const { addYourself, anyoneCanJoin, canMembersLeave, description, id, members, name } =
      this.team;

    if (this.createInProgress) return;

    const membersIds = members.map(({ id }) => id);
    if (addYourself) {
      membersIds.push(currentUserId);
    }
    const teamName = name.trim();
    const options = {
      canMembersLeave,
      canRequestToJoin: anyoneCanJoin,
      description,
      displayName: teamName,
      memberIds: membersIds.slice(),
      organizationId,
    };
    if (id) options.id = id;

    this.createInProgress = true;
    let succeeded = false;

    let onConversationChange, team, timeout;
    try {
      let remainInTeamsInbox = false,
        sdkTeam;
      if (id) {
        sdkTeam = await this.client.teams.update(options);
        remainInTeamsInbox = true;
      } else {
        sdkTeam = await this.client.teams.create(options);
      }

      team = this.entityStore.syncOne(sdkTeam);

      if (addYourself && !remainInTeamsInbox) {
        await new Promise((resolve, reject) => {
          if (team && team.conversation) return resolve();
          timeout = setTimeout(() => reject(new Error('timed out')), 5000);
          onConversationChange = (resource, sdkConversation) => {
            rosterStore.setFilter('Inbox');
            const conversation = this.entityStore.syncOne(sdkConversation);
            conversationStore.selectConversation(conversation);
            if (onConversationChange) {
              this.client.models.Conversation.removeListener('afterInject', onConversationChange);
            }
            onConversationChange = null;
            resolve();
          };

          this.client.models.Conversation.on('afterInject', onConversationChange);
        });
      }
      succeeded = true;
    } catch (err) {
      if (err.response) {
        const { error, status } = err.response.body;
        const { message } = error;
        if (status === 'fail' && message === 'This team name has been taken') {
          runInAction(() => {
            this.teamNameError = true;
          });
        }
      } else {
        console.error(err);
        modalStore.openModal('failure', { reopenModal: 'saveTeam' });
      }
    } finally {
      runInAction(() => {
        this.createInProgress = false;
        if (onConversationChange) {
          this.client.models.Conversation.removeListener('afterInject', onConversationChange);
        }
        clearTimeout(timeout);
        if (succeeded) {
          this.stores.conversationStore.findOrCreateConversationWithListEntity(
            'team',
            team.id,
            organizationId,
            {
              shouldDisplay: true,
            }
          );
        }
      });
    }

    return succeeded;
  };
}
