import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../common/bem';
import { DotsIndicator, ScrollingList } from '../../../../common/components';
import propTypes from '../../../../common/propTypes';
import { FROZEN_EMPTY_ARRAY, mobxInjectSelect } from '../../../../common/utils';
import { ConversationItem, ConversationItemDetails, DummyConversationItem } from './';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';

const classes = BEM.with('ConversationList');
const noTypers = () => FROZEN_EMPTY_ARRAY;
const FIRST_RENDER_ROWS = 5;
const FORUM_LABEL_HEIGHT = 30;
const FORUM_TYPES = ['my-forums', 'all-other-forums'];
const SECTION_SEPARATOR_PX = 9;
const THUMB_MARGIN_TOP = 15;

// needed to offset custom Scrollbars which have a padding-bottom of 100px to hide native scrollbars;
// subtract one to keep the border-bottom of the last tile offscreen and hidden
const MARGIN_BOTTOM = 99;

class ConversationList extends Component {
  static propTypes = {
    canAutoSelectConversation: PropTypes.bool.isRequired,
    composeEntity: propTypes.counterParty,
    conversations: propTypes.conversationArray,
    conversationsLoaded: PropTypes.bool.isRequired,
    currentConversation: propTypes.conversation,
    currentOrganizationId: PropTypes.string,
    currentRoles: propTypes.roleArray.isRequired,
    hasConversations: PropTypes.bool.isRequired,
    isComposing: PropTypes.bool.isRequired,
    isGroupAlertsVCAllowed: PropTypes.bool.isRequired,
    isMentionsEnabled: PropTypes.bool.isRequired,
    localTeamConversationsLoaded: PropTypes.bool.isRequired,
    pinnedConversationIds: propTypes.arrayOrObservableArrayOf(PropTypes.string.isRequired)
      .isRequired,
    selectConversation: PropTypes.func.isRequired,
    selectedFilter: PropTypes.string.isRequired,
    selectedFilterBar: PropTypes.string.isRequired,
    setFilterUnreadCount: PropTypes.func.isRequired,
    setFilterUnreadPriorityCount: PropTypes.func.isRequired,
    setInactiveRosterUnreadCount: PropTypes.func.isRequired,
    setInactiveRosterUnreadPriorityCount: PropTypes.func.isRequired,
    stopComposing: PropTypes.func.isRequired,
  };

  _lastIsScrolling = false;
  _lastOrganizationId = null;
  _lastRowsLimited = false;
  _lastSelectedFilter = null;
  _lastVisibleMinIndex = 1;
  _lastVisibleMaxIndex = 1;
  _lastInactiveRosterUnreadCount = 0;
  _lastInactiveRosterUnreadPriorityCount = 0;
  _lastUnreadCount = 0;
  _lastUnreadPriorityCount = 0;
  _nextInactiveRosterUnreadCount = 0;
  _nextInactiveRosterUnreadPriorityCount = 0;
  _nextUnreadCount = 0;
  _nextUnreadPriorityCount = 0;
  _rowsLimited = false;
  _rowsLimitedTimeout = null;
  _rows = FROZEN_EMPTY_ARRAY;
  _selectionPending = false;
  _selectionTimeout = null;

  componentDidMount() {
    this._didUpdate();
  }

  componentDidUpdate() {
    this._didUpdate();
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this._rowsLimitedTimeout);
    window.cancelAnimationFrame(this._selectionTimeout);
  }

  _didUpdate() {
    const {
      canAutoSelectConversation,
      conversationsLoaded,
      setInactiveRosterUnreadCount,
      setInactiveRosterUnreadPriorityCount,
      setFilterUnreadCount,
      setFilterUnreadPriorityCount,
    } = this.props;

    if (!conversationsLoaded) return;

    if (this._rowsLimited && !this._lastRowsLimited) {
      this._lastRowsLimited = this._rowsLimited;
      this._rowsLimitedTimeout = window.requestAnimationFrame(() => {
        this._lastRowsLimited = false;
        this._rowsLimited = false;
        this.forceUpdate();

        this._selectionTimeout = window.requestAnimationFrame(() => {
          this._selectionPending = false;
          this.forceUpdate();
        });
      });

      this._selectionPending = true;
    }

    if (canAutoSelectConversation) {
      if (this._lastUnreadCount !== this._nextUnreadCount) {
        this._lastUnreadCount = this._nextUnreadCount;
        setFilterUnreadCount(this._nextUnreadCount);
      }
      if (this._lastUnreadPriorityCount !== this._nextUnreadPriorityCount) {
        this._lastUnreadPriorityCount = this._nextUnreadPriorityCount;
        setFilterUnreadPriorityCount(this._nextUnreadPriorityCount);
      }
      if (this._lastInactiveRosterUnreadCount !== this._nextInactiveRosterUnreadCount) {
        this._lastInactiveRosterUnreadCount = this._nextInactiveRosterUnreadCount;
        setInactiveRosterUnreadCount(this._nextInactiveRosterUnreadCount);
      }
      if (
        this._lastInactiveRosterUnreadPriorityCount !== this._nextInactiveRosterUnreadPriorityCount
      ) {
        this._lastInactiveRosterUnreadPriorityCount = this._nextInactiveRosterUnreadPriorityCount;
        setInactiveRosterUnreadPriorityCount(this._nextInactiveRosterUnreadPriorityCount);
      }
    }

    this._autoSelectConversation();

    if (this.scrollbars && !this._rowsLimited) this.scrollbars.recomputeRowHeights();
  }

  _autoSelectConversation = () => {
    const {
      canAutoSelectConversation,
      currentConversation = null,
      selectConversation,
      selectedFilter,
    } = this.props;

    if (selectedFilter === 'Teams') return;

    if (this._selectionPending) {
      if (currentConversation && !this._rows.find((row) => !!row.conversation)) {
        selectConversation(null);
      }
      return;
    }

    if (this._rowsLimited || !canAutoSelectConversation) return;

    const currentIsShown =
      currentConversation && this._rows.find((row) => row.conversation === currentConversation);
    if (currentIsShown) return;

    let next = this._rows.find((row) => row.autoSelectable && !!row.conversation) || null;
    if (next) next = next.conversation;
    if (currentConversation !== next) {
      selectConversation(next);
      this.reduxEscapeHatch?.focusMessageBodyInput();
    }
  };

  render() {
    const { conversationsLoaded, hasConversations, localTeamConversationsLoaded, selectedFilter } =
      this.props;

    let showLoadingIndicator = !conversationsLoaded;
    if (selectedFilter === 'Teams') {
      showLoadingIndicator = !localTeamConversationsLoaded;
    }

    let conversationsFragment;
    if (showLoadingIndicator) {
      conversationsFragment = (
        <div className={classes('placeholder-container')}>
          <div className={classes('dots-container')}>
            <DotsIndicator size={13} speed={0.7} />
          </div>
        </div>
      );
    } else {
      this._updateRows();

      if (hasConversations) {
        conversationsFragment = (
          <div className={classes('conversations')}>
            <ScrollingList
              ref={this._setScrollbars}
              rowCount={this._rows.length}
              rowHeight={this._getRowHeight}
              rowRenderer={this._renderRow}
              thumbMarginTop={THUMB_MARGIN_TOP}
            />
          </div>
        );
      }
    }

    return (
      <div className={classes()}>
        {conversationsFragment}
        <div className={classes('fade')} />
        <ReduxEscapeHatch
          ref={(ref) => {
            this.reduxEscapeHatch = ref;
          }}
        />
      </div>
    );
  }

  _updateRows = () => {
    const {
      composeEntity,
      conversations,
      currentOrganizationId,
      isComposing,
      pinnedConversationIds,
      selectedFilter,
      selectedFilterBar,
    } = this.props;

    this._lastOrganizationId = currentOrganizationId;
    this._lastSelectedFilter = selectedFilter;

    const allOtherForums = [];
    const escalation = [];
    const normal = [];
    const pinned = [];
    const priority = [];
    const requested = [];
    let conversationsAdded = 0;
    let filterUnreadCount = 0;
    let filterUnreadPriorityCount = 0;
    let inactiveRosterUnreadCount = 0;
    let inactiveRosterUnreadPriorityCount = 0;

    for (const conversation of conversations) {
      const {
        counterParty,
        counterPartyType,
        id: conversationId,
        shouldDisplay,
        unreadCount,
        unreadPriorityCount,
      } = conversation;

      if (!shouldDisplay || !counterParty) continue;

      const { escalationExecution, groupType, hasCurrentUser } = counterParty;
      if (
        (selectedFilterBar === 'Right' && groupType !== 'PATIENT_CARE') ||
        (selectedFilterBar === 'Left' && groupType === 'PATIENT_CARE')
      ) {
        if (unreadCount) inactiveRosterUnreadCount += unreadCount;
        if (unreadPriorityCount) inactiveRosterUnreadPriorityCount += unreadPriorityCount;
        continue;
      }

      const shouldLimit = this._rowsLimited && conversationsAdded >= FIRST_RENDER_ROWS;
      const isFilteredOut =
        groupType !== 'PATIENT_CARE' &&
        ((selectedFilter === 'Teams' && counterPartyType !== 'team') ||
          (counterPartyType === 'team' && selectedFilter !== 'Teams') ||
          (selectedFilter === 'Groups' &&
            groupType !== 'GROUP' &&
            groupType !== 'ESCALATION' &&
            groupType !== 'ACTIVATED_TEAM') ||
          (selectedFilter === 'Forums' && groupType !== 'FORUM') ||
          (groupType === 'FORUM' && !hasCurrentUser));
      const isPinned = pinnedConversationIds.includes(conversationId);

      if (selectedFilter === 'Teams' && counterPartyType === 'team') {
        if (counterParty.hasCurrentUserPending || counterParty.hasCurrentUserBeenDeclined) {
          requested.push({
            type: 'normal',
            conversation,
            autoSelectable: false,
          });
        } else {
          conversationsAdded++;
          normal.push({ type: 'normal', conversation, autoSelectable: false });
        }
      } else if (groupType === 'FORUM' && !hasCurrentUser) {
        if (selectedFilter === 'Forums') {
          allOtherForums.push({
            type: 'normal',
            conversation,
            autoSelectable: false,
          });
        }
      } else if (isPinned) {
        conversationsAdded++;
        pinned.push({ type: 'pinned', conversation, autoSelectable: true });
      } else if (unreadPriorityCount > 0) {
        if (isFilteredOut) {
          filterUnreadPriorityCount += unreadPriorityCount;
        } else if (!shouldLimit) {
          conversationsAdded++;
          priority.push({
            type: 'priority',
            conversation,
            autoSelectable: true,
          });
        }
      } else if (escalationExecution) {
        if (isFilteredOut) {
          filterUnreadPriorityCount += unreadPriorityCount;
        } else if (!shouldLimit) {
          conversationsAdded++;
          escalation.push({
            type: 'escalation',
            conversation,
            autoSelectable: true,
          });
        }
      } else {
        if (isFilteredOut) {
          filterUnreadCount += unreadCount;
        } else if (!shouldLimit) {
          conversationsAdded++;
          normal.push({ type: 'normal', conversation, autoSelectable: true });
        }
      }
    }

    if (selectedFilter === 'Teams' && requested.length) {
      requested.unshift({
        type: 'team-requested',
        conversation: null,
        count: requested.length,
      });
      if (normal.length) {
        normal.unshift({
          type: 'team-requested-filler',
          conversation: null,
          count: normal.length,
        });
      }
    }

    if (selectedFilter === 'Forums' && allOtherForums.length) {
      if (normal.length) {
        normal.unshift({
          type: 'my-forums',
          conversation: null,
          count: normal.length,
        });
      }
      allOtherForums.unshift({
        type: 'all-other-forums',
        conversation: null,
        count: allOtherForums.length,
      });
    }

    const lastItem =
      escalation.length > 0
        ? escalation[escalation.length - 1]
        : priority.length > 0
        ? priority[priority.length - 1]
        : pinned.length > 0
        ? pinned[pinned.length - 1]
        : null;
    if (lastItem) lastItem.sectionSeparator = true;

    this._rows = [
      { type: 'top-spacer', conversation: null },
      ...(isComposing ? [{ type: 'new-message', entity: composeEntity }] : []),
      ...pinned,
      ...priority,
      ...escalation,
      ...requested,
      ...normal,
      ...allOtherForums,
      { type: 'bottom-spacer', conversation: null },
    ];
    this._nextUnreadCount = filterUnreadCount;
    this._nextUnreadPriorityCount = filterUnreadPriorityCount;
    this._nextInactiveRosterUnreadCount = inactiveRosterUnreadCount;
    this._nextInactiveRosterUnreadPriorityCount = inactiveRosterUnreadPriorityCount;
  };

  _setScrollbars = (ref) => {
    this.scrollbars = ref;
  };

  _getKey = ({ index }) => {
    const row = this._rows[index];
    const { conversation, type } = row;

    return conversation ? conversation.id : `${type}-${index}`;
  };

  _getRowHeight = ({ index }) => {
    const { currentRoles, isGroupAlertsVCAllowed, isMentionsEnabled, selectedFilterBar } =
      this.props;
    const { conversation, sectionSeparator, type } = this._rows[index];
    const isPinned = type === 'pinned';

    if (type === 'top-spacer') {
      return THUMB_MARGIN_TOP;
    } else if (type === 'bottom-spacer') {
      return MARGIN_BOTTOM;
    } else if (type === 'new-message') {
      return selectedFilterBar === 'Left' ? DummyConversationItem.getHeight() : 0;
    } else if (
      FORUM_TYPES.includes(type) ||
      type === 'team-requested' ||
      type === 'team-requested-filler'
    ) {
      return FORUM_LABEL_HEIGHT;
    }

    const hasGroupAlert = isGroupAlertsVCAllowed && conversation?.featureService === 'group_alerts';
    let height = ConversationItemDetails.getHeight({
      conversation,
      currentRoles,
      getTypersForCounterParty: noTypers,
      hasGroupAlert,
      isMentionsEnabled,
      isPinned,
      isSelected: false,
    });
    if (sectionSeparator) height += SECTION_SEPARATOR_PX;

    return height;
  };

  _renderRow = (props) => {
    if (!props) return null;

    const { index, isScrolling, isVisible, key, style } = props;
    const {
      currentConversation,
      selectedFilterBar,
      stopComposing,
      isNewConversationUIFeatureFlagEnabled,
    } = this.props;
    const row = this._rows[index];
    const { conversation, count, entity, sectionSeparator, type } = row;
    const isPinned = type === 'pinned';
    const isSelected = currentConversation === conversation;
    const lastIsScrolling = this._lastIsScrolling;
    this._lastIsScrolling = isScrolling;
    let className;
    let contentsFragment;
    let isPatientCareConversation;

    if (conversation) {
      const { counterParty } = conversation;
      isPatientCareConversation =
        counterParty.groupType && counterParty.groupType === 'PATIENT_CARE';
    }

    if (['top-spacer', 'bottom-spacer'].includes(type)) {
      // render empty div
    } else if (type === 'new-message') {
      className = classes('list-item', { selected: true });
      if (selectedFilterBar === 'Left') {
        contentsFragment = (
          <DummyConversationItem
            entity={entity}
            isNewConversationUIFeatureFlagEnabled={isNewConversationUIFeatureFlagEnabled}
            stopComposing={stopComposing}
          />
        );
      }
    } else if (['my-forums', 'all-other-forums'].includes(type)) {
      if (count > 0) {
        const label = type === 'my-forums' ? 'MY FORUMS' : 'EXPLORE FORUMS';
        className = classes('forum-indicator');
        contentsFragment = (
          <div>
            {label} ({count})
          </div>
        );
      }
    } else if (type === 'team-requested') {
      if (count > 0) {
        const label = 'REQUEST';
        className = classes('forum-indicator');
        contentsFragment = (
          <div>
            {label} ({count})
          </div>
        );
      }
    } else if (type === 'team-requested-filler') {
      className = classes('team-filler');
      contentsFragment = <div />;
    } else {
      if (!isScrolling) {
        if (lastIsScrolling === true) {
          this._lastVisibleMinIndex = index;
          this._lastVisibleMaxIndex = index;
        } else if (isVisible) {
          if (index < this._lastVisibleMinIndex) this._lastVisibleMinIndex = index;
          if (index > this._lastVisibleMaxIndex) this._lastVisibleMaxIndex = index;
        }
      }

      className = classes('list-item', {
        [conversation.counterParty.$entityType]: true,
        isScrolling,
        sectionSeparator,
        sectionSeparatorPatient: isPatientCareConversation && sectionSeparator,
      });

      contentsFragment = (
        <ConversationItem
          conversation={conversation}
          isPinned={isPinned}
          isSelected={isSelected}
          isPatientCare={!!isPatientCareConversation}
        />
      );
    }

    return (
      <div key={key} className={className} style={style}>
        {contentsFragment}
      </div>
    );
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['composeEntity', 'isComposing', 'stopComposing'],
  conversationStore: [
    'canAutoSelectConversation',
    'conversations',
    'hasConversations',
    'selectConversation',
  ],
  localStore: ['pinnedConversationIds'],
  messengerStore: [
    'conversationsLoaded',
    'currentOrganizationId',
    'isGroupAlertsVCAllowed',
    'isMentionsEnabled',
    'isNewConversationUIFeatureFlagEnabled',
  ],
  roleStore: ['currentRoles'],
  rosterStore: [
    'selectedFilter',
    'setFilterUnreadCount',
    'setFilterUnreadPriorityCount',
    'setInactiveRosterUnreadCount',
    'setInactiveRosterUnreadPriorityCount',
  ],
  teamStore: ['localTeamConversationsLoaded'],
})(ConversationList);
