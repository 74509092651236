import { installSdkUtils } from '../common/utils';
import AdminConsoleStore from './AdminConsoleStore';
import AlertStore from './AlertStore';
import AppointmentsStore from './AppointmentsStore';
import CallStore from './CallStore';
import CollaborationStore from './CollaborationStore';
import ComposeMessageStore from './ComposeMessageStore';
import ConversationStore from './ConversationStore';
import CreateGroupStore from './CreateGroupStore';
import CreateTeamStore from './CreateTeamStore';
import DesktopAppStore from './DesktopAppStore';
import DownloadedMessagesProgressStore from './DownloadedMessagesProgressStore';
import EntityStore from './EntityStore';
import EscalationStore from './EscalationStore';
import FeatureStore from './FeatureStore';
import GroupEditorStore from './GroupEditorStore';
import GroupStore from './GroupStore';
import LaunchStore from './LaunchStore';
import LocalStore from './LocalStore';
import MessageAttachmentStore from './MessageAttachmentStore';
import MessageStore from './MessageStore';
import MessengerStore from './MessengerStore';
import ModalStore from './ModalStore';
import NetworkStatusStore from './NetworkStatusStore';
import NetworkStore from './NetworkStore';
import OperatorConsoleStore from './OperatorConsoleStore';
import OrganizationStore from './OrganizationStore';
import PatientAdminStore from './PatientAdminStore';
import PatientStore from './PatientStore';
import PresenceStore from './PresenceStore';
import ProfileStore from './ProfileStore';
import ReactAdminStore from './ReactAdminStore';
import RecipientPickerStore from './RecipientPickerStore';
import RoleStore from './RoleStore';
import RosterStore from './RosterStore';
import ScheduleMessageStore from './ScheduleMessageStore';
import ScimStore from './ScimStore';
import PersonaStore from './PersonaStore';
import SearchSidebarStore from './SearchSidebarStore';
import SessionStore from './SessionStore';
import StaticStore, { staticStore } from './StaticStore';
import TeamStore from './TeamStore';
import TrackerStore from './TrackerStore';
import TypingStatusStore from './TypingStatusStore';
import UserStore from './UserStore';

export {
  AdminConsoleStore,
  AlertStore,
  AppointmentsStore,
  CallStore,
  CollaborationStore,
  ComposeMessageStore,
  ConversationStore,
  CreateGroupStore,
  CreateTeamStore,
  DesktopAppStore,
  DownloadedMessagesProgressStore,
  EntityStore,
  EscalationStore,
  FeatureStore,
  GroupEditorStore,
  GroupStore,
  LaunchStore,
  LocalStore,
  MessageAttachmentStore,
  MessageStore,
  MessengerStore,
  ModalStore,
  NetworkStatusStore,
  NetworkStore,
  OperatorConsoleStore,
  OrganizationStore,
  PatientAdminStore,
  PatientStore,
  PresenceStore,
  ProfileStore,
  ReactAdminStore,
  RecipientPickerStore,
  RoleStore,
  RosterStore,
  ScheduleMessageStore,
  SearchSidebarStore,
  SessionStore,
  StaticStore,
  TeamStore,
  TrackerStore,
  TypingStatusStore,
  UserStore,
};

export function createStores({ client, organizationId, params = {} }) {
  installSdkUtils(client);

  const stores = {};
  const entityStore = new EntityStore({ client, stores });
  const options = { client, entityStore, params, stores };

  const groupStore = new GroupStore(options);
  const modalStore = new ModalStore(options);
  const organizationStore = new OrganizationStore(options);

  Object.assign(stores, {
    adminConsoleStore: new AdminConsoleStore(options),
    alertStore: new AlertStore(options),
    appointmentsStore: new AppointmentsStore(options),
    callStore: new CallStore(options),
    collaborationStore: new CollaborationStore(options),
    composeMessageStore: new ComposeMessageStore(options),
    conversationStore: new ConversationStore(options),
    createGroupStore: new CreateGroupStore(options),
    createTeamStore: new CreateTeamStore(options),
    desktopAppStore: new DesktopAppStore(options),
    downloadedMessagesProgressStore: new DownloadedMessagesProgressStore(options),
    entityStore,
    escalationStore: new EscalationStore(options),
    featureStore: new FeatureStore(options),
    groupEditorStore: new GroupEditorStore(options),
    groupStore,
    launchStore: new LaunchStore(options),
    localStore: new LocalStore(options),
    messageAttachmentStore: new MessageAttachmentStore({
      ...options,
      modalStore,
    }),
    messageStore: new MessageStore(options),
    messengerStore: new MessengerStore({ ...options, organizationId }),
    modalStore,
    networkStatusStore: new NetworkStatusStore(options),
    networkStore: new NetworkStore(options),
    operatorConsoleStore: new OperatorConsoleStore(options),
    organizationStore,
    patientAdminStore: new PatientAdminStore(options),
    patientStore: new PatientStore(options),
    personaStore: new PersonaStore(options),
    presenceStore: new PresenceStore(options),
    profileStore: new ProfileStore(options),
    reactAdminStore: new ReactAdminStore(options),
    recipientPickerStore: new RecipientPickerStore(options),
    roleStore: new RoleStore(options),
    rosterStore: new RosterStore(options),
    scheduleMessageStore: new ScheduleMessageStore(options),
    scimStore: new ScimStore(options),
    searchSidebarStore: new SearchSidebarStore(options),
    sessionStore: new SessionStore(options),
    staticStore,
    teamStore: new TeamStore(options),
    trackerStore: new TrackerStore(options),
    typingStatusStore: new TypingStatusStore(options),
    userStore: new UserStore(options),
  });

  stores.localStore.loadGlobalSettings();

  for (const store of Object.values(stores)) {
    if (store.mounted) store.mounted();
  }

  return stores;
}
