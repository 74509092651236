import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../common/bem';
import { ReactComponent as CloseButtonSvg } from '../../../../common/images/close-icon.svg';
import getRecipientEntity from '../../../../common/utils/getRecipientEntity';
import DummyConversationAvatar from './DummyConversationAvatar';

const classes = BEM.with('DummyConversationItem');
const TILE_SPACE_PX = 20;
const AVATAR_PX = 52;
const TILE_HEIGHT = TILE_SPACE_PX + AVATAR_PX;

class DummyConversationItem extends Component {
  static propTypes = {
    isNewConversationUIFeatureFlagEnabled: PropTypes.bool,
    showBlankAvatar: PropTypes.bool,
    stopComposing: PropTypes.func,
    placeholderName: PropTypes.string,
  };

  static defaultProps = {
    showBlankAvatar: false,
    showRoleIndicator: true,
  };

  static getHeight() {
    return TILE_HEIGHT;
  }

  render() {
    const {
      entity: inEntity,
      isNewConversationUIFeatureFlagEnabled,
      showBlankAvatar,
      stopComposing,
    } = this.props;
    const entity = inEntity ? getRecipientEntity({ entity: inEntity }).entity : null;

    const placeholderName = isNewConversationUIFeatureFlagEnabled
      ? 'New Conversation'
      : 'New Message';
    return (
      <div className={classes()}>
        <div className={classes('left-pane')}>
          <DummyConversationAvatar entity={entity} showBlankAvatar={showBlankAvatar} />
        </div>
        <div className={classes('details')}>
          <div className={classes('row')}>
            <div className={classes('text')}>{entity ? entity.displayName : placeholderName}</div>
          </div>
          <div className={classes('row')}>
            <div />
            {stopComposing && (
              <div className={classes('close-button-container')} onClick={stopComposing}>
                <CloseButtonSvg className={classes('close-button')} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedComponent = DummyConversationItem;

WrappedComponent.getHeight = DummyConversationItem.getHeight;

export default WrappedComponent;
