import { action, makeObservable } from 'mobx';
import { Stores, TCClient } from '../types';
import { PersonaCreateFields } from '../js-sdk/src/types/Persona';

type Params = {
  scimStatus: number;
  scimBaseUrl: string;
};

export default class PersonaStore {
  client: TCClient;
  stores: Stores;
  params: Params;

  constructor({ client, stores, params }: { client: TCClient; params: Params; stores: Stores }) {
    makeObservable(this);
    this.client = client;
    this.stores = stores;
    this.params = params;
  }

  mounted() {}

  @action('PersonaStore.loadTitles') loadTitles = async (selectedOrganizationId: string) => {
    try {
      const { titles } = await this.client.persona.findTitles(selectedOrganizationId);
      return titles;
    } catch (e) {
      console.error('Loading titles error: ', JSON.stringify(e));
      return [];
    }
  };

  @action('PersonaStore.loadPermissions') loadPermissions = async (orgId: string) => {
    try {
      const { roles } = await this.client.adminOrganizations.getAllPermissionRoles({ orgId });
      return roles;
    } catch (e) {
      console.error('Loading permissions error: ', JSON.stringify(e));
      return [];
    }
  };

  @action('PersonaStore.createPersona') createPersona = async (
    payload: PersonaCreateFields,
    selectedOrganizationId: string
  ) => {
    try {
      await this.client.persona.createPersona(payload, selectedOrganizationId);
    } catch (e) {
      throw e;
    }
  };

  @action('PersonaStore.updatePersona') updatePersona = async (
    payload: PersonaCreateFields,
    personaId: string,
    selectedOrganizationId: string
  ) => {
    try {
      await this.client.persona.updatePersona(payload, personaId, selectedOrganizationId);
    } catch (e) {
      throw e;
    }
  };

  @action('PersonaStore.deletePersona') deletePersona = async (
    personaId: string,
    selectedOrganizationId: string
  ) => {
    try {
      await this.client.persona.deletePersona(personaId, selectedOrganizationId);
    } catch (e) {
      throw e;
    }
  };

  @action('PersonaStore.findPersonas') findPersona = async ({
    personaId,
    selectedOrganizationId,
  }: {
    personaId: string;
    selectedOrganizationId: string;
  }) => {
    try {
      const response = await this.client.persona.findPersona({
        organizationId: selectedOrganizationId,
        personaId,
      });
      return response;
    } catch (e) {
      console.error('Loading persona error: ', JSON.stringify(e));
      return undefined;
    }
  };

  @action('PersonaStore.findPersonas') findPersonas = async ({
    page,
    query,
    selectedOrganizationId,
    pageSize = 10,
  }: {
    page: number;
    query: string;
    selectedOrganizationId: string;
    pageSize?: number;
  }) => {
    try {
      const response = await this.client.persona.findPersonas({
        organizationId: selectedOrganizationId,
        page,
        pageSize,
        persona_name: query,
      });
      return response;
    } catch (e) {
      console.error('Loading persona error: ', JSON.stringify(e));
      return [];
    }
  };
}
