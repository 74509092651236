import throttle from 'lodash.throttle';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import moment from 'moment-timezone';
import { MessageTemplateRepositories } from '../models/enums/MessageTemplateRepositories';
import PatientAdminPages from '../models/enums/PatientAdminPages';

const { PERSONAL } = MessageTemplateRepositories;
const {
  AUTOMATED_MESSAGES,
  CUSTOM_LABELING,
  MESSAGE_TEMPLATES,
  PATIENT_BROADCAST_LISTS,
  PATIENTS_LIST,
  SCHEDULED_MESSAGES,
  VIRTUAL_WAITING_ROOM,
} = PatientAdminPages;
const HITS_THROTTLE_TIME = 10000;
const WHITE_LABEL_LOGO_MAXIMUM_FILE_SIZE = 5000000;
const WHITE_LABEL_LOGO_MAXIMUM_WIDTH = 50;
const WHITE_LABEL_LOGO_MAXIMUM_HEIGHT = 50;

export default class PatientAdmin {
  @observable coarseTimezone = null;
  @observable currentPatientAdminPage = PATIENTS_LIST;
  @observable.shallow whiteLabelData = null;

  constructor({ client, entityStore, params, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.params = params;
    this.stores = stores;
  }

  @computed get allowPatientScheduledMessages() {
    const { currentOrganization } = this.stores.messengerStore;
    return !!(currentOrganization && currentOrganization.isPatientScheduledMessagesEnabled);
  }

  @computed get allowPatientListAccess() {
    const { currentOrganization } = this.stores.messengerStore;
    return !!(currentOrganization && currentOrganization.isPatientListAccessEnabled);
  }

  @computed get allowPatientBroadcastLists() {
    const { currentOrganization } = this.stores.messengerStore;
    return !!(currentOrganization && currentOrganization.isPatientBroadcastEnabled);
  }

  @computed get allowVirtualWaitingRoomSettings() {
    const { currentOrganization, getAdminRolesInOrganization } = this.stores.messengerStore;
    const { isVirtualWaitingRoomAdmin } = getAdminRolesInOrganization();

    return !!(
      currentOrganization &&
      currentOrganization.isPatientVirtualWaitingRoomEnabled &&
      isVirtualWaitingRoomAdmin
    );
  }

  @computed get isAsyncCsvUpload() {
    return this.stores.featureStore.featureFlags['csv-async-upload'];
  }

  @computed get allowAutomatedAppointmentReminders() {
    const { allowAar } = this.params;
    if (!(this.stores.featureStore.featureFlags?.aar || allowAar === 'true')) return false;
    const { currentOrganization, getAdminRolesInOrganization } = this.stores.messengerStore;
    if (!currentOrganization) return false;
    const { isPatientAdmin } = getAdminRolesInOrganization(currentOrganization.id);
    return isPatientAdmin && currentOrganization.isAutomatedAppointmentRemindersEnabled;
  }

  @computed get allowPatientDeliveryMethod() {
    const { currentOrganization } = this.stores.messengerStore;
    if (!currentOrganization) return false;
    return currentOrganization.isPatientDeliveryMethodEnabled;
  }

  @computed get isIhis() {
    return this.client.config.apiEnv === 'ihis';
  }

  @action('PatientAdmin.reactToPermissionChange') reactToPermissionChange = (
    currentOrganizationId,
    isPatientAdmin
  ) => {
    if (!currentOrganizationId) return;

    if (
      ([CUSTOM_LABELING, PATIENTS_LIST].includes(this.currentPatientAdminPage) &&
        !isPatientAdmin) ||
      ([MESSAGE_TEMPLATES, SCHEDULED_MESSAGES].includes(this.currentPatientAdminPage) &&
        !this.allowPatientScheduledMessages) ||
      (PATIENT_BROADCAST_LISTS === this.currentPatientAdminPage &&
        !this.allowPatientBroadcastLists) ||
      (AUTOMATED_MESSAGES === this.currentPatientAdminPage &&
        !this.allowAutomatedAppointmentReminders) ||
      (this.currentPatientAdminPage === VIRTUAL_WAITING_ROOM &&
        !this.allowVirtualWaitingRoomSettings)
    ) {
      this.recalculateCurrentPatientAdminPage(currentOrganizationId, isPatientAdmin);
    }
  };

  @action('PatientAdmin.recalculateCurrentPatientAdminPage')
  recalculateCurrentPatientAdminPage = (currentOrganizationId, isPatientAdmin) => {
    const {
      messengerStore: { openMessages },
    } = this.stores;

    if (!currentOrganizationId) return;
    if (isPatientAdmin || this.allowPatientListAccess) {
      this.currentPatientAdminPage = PATIENTS_LIST;
    } else if (this.allowPatientScheduledMessages) {
      this.currentPatientAdminPage = SCHEDULED_MESSAGES;
    } else if (this.allowPatientBroadcastLists) {
      this.currentPatientAdminPage = PATIENT_BROADCAST_LISTS;
    } else if (this.allowVirtualWaitingRoomSettings) {
      this.currentPatientAdminPage = VIRTUAL_WAITING_ROOM;
    } else {
      openMessages();
    }
  };

  @action('PatientAdmin.setCurrentPatientAdminPage')
  setCurrentPatientAdminPage = (page, messageTemplate) => {
    const { trackerStore } = this.stores;
    this.currentPatientAdminPage = page;
    if (['scheduledMessages', 'messageTemplates'].includes(page) && messageTemplate) {
      page = `${page}/${messageTemplate}`;
    }
    trackerStore.logPendoAnalytics({
      parentPathName: 'Patient Settings',
      pathName: page,
    });
  };

  /*
    BROADCAST LISTS
  */

  @action('PatientAdmin.addBroadcastListMembers')
  addBroadcastListMembers = async ({ id, members }) => {
    const { messengerStore } = this.stores;

    await this.client.distributionLists.addMembers({
      id,
      members,
      organizationId: messengerStore.currentOrganizationId,
    });

    return true;
  };

  @action('PatientAdmin.createBroadcastList')
  createBroadcastList = async ({ adminOnly, members = [], name }) => {
    const { messengerStore } = this.stores;

    const broadcastList = await this.client.distributionLists.create({
      adminOnly,
      members,
      name,
      network: 'patient',
      organizationId: messengerStore.currentOrganizationId,
    });
    return this.entityStore.syncOne(broadcastList);
  };

  @action('PatientAdmin.deleteBroadcastList') deleteBroadcastList = async (id) => {
    const { messengerStore } = this.stores;
    await this.client.distributionLists.delete(id, messengerStore.currentOrganizationId);

    return true;
  };

  @action('PatientAdmin.deleteBroadcastList') deleteBroadcastLists = async (ids) => {
    const { messengerStore } = this.stores;
    await this.client.distributionLists.batchDelete({
      ids,
      organizationId: messengerStore.currentOrganizationId,
    });

    return true;
  };

  @action('PatientAdmin.loadBroadcastList') loadBroadcastList = async (
    id,
    { bypassCache = false } = {}
  ) => {
    const { messengerStore } = this.stores;

    const broadcastList = await this.client.distributionLists.find(id, {
      bypassCache,
      organizationId: messengerStore.currentOrganizationId,
    });

    return this.entityStore.syncOne(broadcastList);
  };

  @action('PatientAdmin.loadBroadcastLists') loadBroadcastLists = async ({
    continuation,
    query,
    sortBy,
    sortOrder,
  }) => {
    const { messengerStore } = this.stores;

    const { metadata, results } = await this.client.distributionLists.search({
      continuation,
      network: 'patient',
      organizationId: messengerStore.currentOrganizationId,
      query,
      sortBy,
      sortOrder,
    });

    return {
      metadata,
      results: this.entityStore.sync(results),
    };
  };

  @action('PatientAdmin.loadBroadcastListMembers')
  loadBroadcastListMembers = async ({ continuation, id, query, sortBy, sortOrder } = {}) => {
    const { messengerStore } = this.stores;

    const { metadata, results } = await this.client.distributionLists.findMembers(id, {
      continuation,
      network: 'patient',
      organizationId: messengerStore.currentOrganizationId,
      query,
      sortBy,
      sortOrder,
    });

    return {
      metadata,
      results: this.entityStore.sync(results),
    };
  };

  @action('PatientAdmin.findAllBroadcastListMemberIds')
  findAllBroadcastListMemberIds = async (id) => {
    const { messengerStore } = this.stores;
    const memberIds = [];
    let continuation;

    do {
      const { results, metadata } = await this.client.distributionLists.findMemberIds(
        id,
        messengerStore.currentOrganizationId,
        { continuation }
      );
      memberIds.push(...results);
      continuation = metadata.continuation;
    } while (continuation);

    return memberIds;
  };

  @action('PatientAdmin.removeBroadcastListMembers')
  removeBroadcastListMembers = async ({ id, members }) => {
    const { messengerStore } = this.stores;

    await this.client.distributionLists.removeMembers({
      id,
      members,
      organizationId: messengerStore.currentOrganizationId,
    });

    return true;
  };

  @action('PatientAdmin.updateBroadcastList') updateBroadcastList = async ({
    adminOnly,
    id,
    name,
  }) => {
    const { messengerStore } = this.stores;

    const broadcastList = await this.client.distributionLists.update({
      adminOnly,
      id,
      name,
      organizationId: messengerStore.currentOrganizationId,
    });
    return this.entityStore.syncOne(broadcastList);
  };

  @action('PatientAdmin.deleteScheduledMessage')
  deleteScheduledMessage = async (id) => {
    const { messengerStore } = this.stores;
    await this.client.scheduledMessages.delete(id, messengerStore.currentOrganizationId);

    return true;
  };

  @action('PatientAdmin.deleteScheduledMessages')
  deleteScheduledMessages = async (ids) => {
    const { messengerStore } = this.stores;
    await this.client.scheduledMessages.batchDelete({
      ids,
      organizationId: messengerStore.currentOrganizationId,
    });

    return true;
  };

  @action('PatientAdmin.determineCoarseTimezone')
  determineCoarseTimezone = async () => {
    if (this.coarseTimezone) return this.coarseTimezone;
    const { localStore, messengerStore } = this.stores;

    try {
      if (this.isIhis) {
        const ihisTz = {
          abbr: 'SGT',
          name: 'Asia/Singapore',
        };

        this.updateCoarseTimezone(ihisTz);

        return ihisTz;
      }

      const deviceTimezone = moment.tz.guess(true);
      const coarseTimezone = await this.client.scheduledMessages.findCoarseTimezone(
        deviceTimezone,
        messengerStore.currentOrganizationId
      );

      this.updateCoarseTimezone(coarseTimezone);
      return coarseTimezone;
    } catch (error) {
      if (localStore.coarseTimezone) {
        return localStore.coarseTimezone;
      }

      return {
        abbr: 'PT',
        name: 'US/Pacific',
      };
    }
  };

  @action('PatientAdmin.updateCoarseTimezone')
  updateCoarseTimezone = (coarseTimezone) => {
    const { localStore } = this.stores;
    this.coarseTimezone = coarseTimezone;
    localStore.setCoarseTimezone(this.coarseTimezone);
  };

  _fetchScheduledMessageAllHits = throttle(
    (organizationId) => this.client.scheduledMessages.fetchHits('all', organizationId),
    HITS_THROTTLE_TIME
  );

  _fetchScheduledMessageAutomatedHits = throttle(
    (organizationId) => this.client.scheduledMessages.fetchHits('automated', organizationId),
    HITS_THROTTLE_TIME
  );

  _fetchScheduledMessagePersonalHits = throttle(
    (organizationId) => this.client.scheduledMessages.fetchHits('personal', organizationId),
    HITS_THROTTLE_TIME
  );

  @action('PatientAdmin.fetchScheduledMessageHits')
  fetchScheduledMessageHits = (feed, shouldForce) => {
    const { messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    let fetchHitsFunc;
    if (feed === 'all') {
      fetchHitsFunc = this._fetchScheduledMessageAllHits;
    } else if (feed === 'automated') {
      fetchHitsFunc = this._fetchScheduledMessageAutomatedHits;
    } else {
      fetchHitsFunc = this._fetchScheduledMessagePersonalHits;
    }

    if (shouldForce) {
      fetchHitsFunc.cancel();
    }

    return fetchHitsFunc(currentOrganizationId);
  };

  @action('PatientAdmin.loadScheduledMessage') loadScheduledMessage = async (id) => {
    const { messengerStore } = this.stores;
    const scheduledMessage = await this.client.scheduledMessages.find(
      id,
      messengerStore.currentOrganizationId
    );

    return this.entityStore.syncOne(scheduledMessage);
  };

  @action('PatientAdmin.loadScheduledMessages') loadScheduledMessages = async ({
    category = 'history',
    continuation,
    feed,
    range,
    sortBy,
    sortOrder,
    query,
  }) => {
    const { messengerStore, entityStore } = this.stores;

    const { metadata, results } = await this.client.scheduledMessages.search({
      category,
      continuation,
      feed,
      network: 'patient',
      organizationId: messengerStore.currentOrganizationId,
      query,
      range,
      sortBy,
      sortOrder,
    });

    return {
      metadata,
      results: entityStore.sync(results),
    };
  };

  /*
    MESSAGE TEMPLATES
  */

  @action('PatientAdmin.createTemplate') createTemplate = async ({
    attachment,
    body,
    isSmsCompatible,
    repository = PERSONAL,
    title,
  }) => {
    const { messengerStore } = this.stores;

    const messageTemplate = await this.client.messageTemplates.create({
      attachment,
      body,
      isSmsCompatible,
      organizationId: messengerStore.currentOrganizationId,
      repository,
      title,
    });

    return this.entityStore.syncOne(messageTemplate);
  };

  @action('PatientAdmin.deleteTemplate') deleteTemplate = async (id) => {
    const { messengerStore } = this.stores;
    await this.client.messageTemplates.delete(id, messengerStore.currentOrganizationId);

    return true;
  };

  @action('PatientAdmin.deleteTemplates') deleteTemplates = async (ids) => {
    const { messengerStore } = this.stores;
    await this.client.messageTemplates.batchDelete({
      ids,
      organizationId: messengerStore.currentOrganizationId,
    });

    return true;
  };

  @action('PatientAdmin.loadTemplate') loadTemplate = async (id) => {
    const { messengerStore } = this.stores;
    const messageTemplate = await this.client.messageTemplates.find(
      id,
      messengerStore.currentOrganizationId
    );

    return this.entityStore.syncOne(messageTemplate);
  };

  @action('PatientAdmin.loadTemplates') loadTemplates = async ({
    continuation,
    isSmsCompatible,
    query,
    repository,
    sortBy,
    sortOrder,
  }) => {
    const { messengerStore } = this.stores;

    const { metadata, results } = await this.client.messageTemplates.search({
      continuation,
      isSmsCompatible,
      network: 'patient',
      organizationId: messengerStore.currentOrganizationId,
      query,
      repository,
      sortBy,
      sortOrder,
    });

    return {
      metadata,
      results: this.entityStore.sync(results),
    };
  };

  @action('PatientAdmin.updateTemplate') updateTemplate = async ({
    attachment,
    body,
    id,
    isSmsCompatible,
    repository = PERSONAL,
    title,
  }) => {
    const { messengerStore } = this.stores;

    const messageTemplate = await this.client.messageTemplates.update({
      attachment,
      body,
      id,
      isSmsCompatible,
      organizationId: messengerStore.currentOrganizationId,
      repository,
      title,
    });

    return this.entityStore.syncOne(messageTemplate);
  };

  @action('PatientAdmin.processWhiteLabelLogo') processWhiteLabelLogo = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          const validType = file.type.includes('jpeg') || file.type.includes('png');

          if (!validType) {
            resolve({ error: 'filetype' });
          }
          if (width > WHITE_LABEL_LOGO_MAXIMUM_WIDTH || height > WHITE_LABEL_LOGO_MAXIMUM_HEIGHT) {
            resolve({ error: 'resolution' });
          }
          if (file.size > WHITE_LABEL_LOGO_MAXIMUM_FILE_SIZE) {
            resolve({ error: 'filesize' });
          }

          resolve({
            data: e.target.result,
            type: file.type.split('/')[1],
          });
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  @action('PatientAdmin.fetchWhiteLabelData') fetchWhiteLabelData = async (organizationId) => {
    const data = await this.client.patients.getWhiteLabel(organizationId);
    runInAction(() => {
      this.whiteLabelData = Object.assign({}, this.whiteLabelData, data);
    });
  };

  @action('PatientAdmin.setWhiteLabelData') setWhiteLabelData = async (
    organizationId,
    { orgName, orgLogo, orgLogoImageType, orgUrl, orgPhone }
  ) => {
    const payload = {};

    if (this.whiteLabelData.orgName !== orgName) {
      payload.orgName = orgName;
    }
    if (this.whiteLabelData.orgLogoUrl !== '' && orgLogo === '') {
      payload.orgLogo = null;
    } else if (this.whiteLabelData.orgLogoUrl !== orgLogo) {
      payload.orgLogo = orgLogo;
      payload.orgLogoImageType = orgLogoImageType;
    }
    if (this.whiteLabelData.orgPhone !== orgPhone) {
      payload.orgPhone = orgPhone;
      if (orgPhone === '') payload.orgPhone = null;
    }
    if (this.whiteLabelData.orgUrl !== orgUrl) {
      payload.orgUrl = orgUrl;
      if (orgUrl === '') payload.orgUrl = null;
    }

    return await this.client.patients.setWhiteLabel(organizationId, payload);
  };
}
