import {
  SettingContainer,
  SettingContainerProps,
} from 'admin/shared-components/SettingContainer/SettingContainer';
import { UserOrganizationSettings } from 'admin/types';
import { shouldRender } from 'admin/utils/shouldRender';

export type RenderSettingsParams = {
  show?: boolean;
  span?: boolean;
} & RenderConditionParams &
  SettingContainerProps;

type RenderConditionParams = {
  requiredOrgSettings?: Array<keyof UserOrganizationSettings>;
  requiredIndividualCondition?: keyof UserOrganizationSettings;
  forbiddenOffCondition?: keyof UserOrganizationSettings;
};

export const renderSettings = (
  orgSettings: UserOrganizationSettings,
  renderSettingsParams: RenderSettingsParams,
  key: number
): JSX.Element | null => {
  const {
    requiredOrgSettings,
    requiredIndividualCondition,
    forbiddenOffCondition,
    show = true,
    ...rest
  } = renderSettingsParams;
  const showSetting =
    show &&
    shouldRender({
      orgSettings,
      requiredOrgSettings,
      requiredIndividualCondition,
      forbiddenOffCondition,
    });

  return showSetting ? <SettingContainer key={key} {...rest} /> : null;
};
