import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { Button } from '@tigerconnect/web-component-library';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import { useInfiniteSearchResults } from '../../hooks';

import { CollapsingSearchBar, DotsIndicator } from '../';
import { PatientBroadcastFormHeader } from '../PatientSettings/PatientBroadcastLists';
import RefreshButton from '../RefreshButton';
import { ReactComponent as DeleteButtonSvg } from '../../images/delete_button.svg';
import { loadProviderBroadcastListMembers } from '../../../contexts/BroadcastListSettings';
import ProviderSearchBox from './ProviderSearchBox';
import ProviderBroadcastListTable from './ProviderBroadcastListTable';

const classes = BEM.with('PatientBroadcastForm');

const DEBOUNCE_TIMEOUT = 500;
const WAIT_FOR_ES_DATA_TO_LOAD = 2000;
const DEFAULT_SORT_BY = ['added_on'];
const DEFAULT_SORT_ORDER = ['desc'];

const ProviderBroadcastListView = ({
  addBroadcastListMembers,
  currentAppSelected,
  currentOrganizationId,
  destroy,
  findAllBroadcastListMemberIds,
  id: _id,
  isAdmin,
  isADSync,
  isPregen,
  loadBroadcastList,
  openModal,
  viewLists,
  setIsADSync,
  customDirectories,
}) => {
  const [addingPendingMembers, setAddingPendingMembers] = useState(false);
  const [broadcastListDetails, setBroadcastListDetails] = useState({
    displayName: '',
  });
  const [currentListId, setCurrentListId] = useState(_id);
  const [currentMemberIds, setCurrentMemberIds] = useState([]);
  const [currentMembersSearchQuery, setCurrentMembersSearchQuery] = useState('');
  const [fetchingBroadcastList, setFetchingBroadcastList] = useState(!!_id);
  const [groupedMembers, setGroupedMembers] = useState([]);
  const [groupedPendingMembers, setGroupedPendingMembers] = useState([]);
  const [id, setId] = useState(_id);
  const [isSaving, setIsSaving] = useState(false);
  const [listError, setListError] = useState('');
  const [pendingMemberIds, setPendingMemberIds] = useState([]);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [shouldFetchBroadcastList, setShouldFetchBroadcastList] = useState(false);
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY[0]);
  const [sortOrder, setSortOrder] = useState(DEFAULT_SORT_ORDER[0]);
  const groupedMembersMap = useRef(new Map());
  const groupedPendingMembersMap = useRef(new Map());
  const setCurrentMembersSearchQueryDebounced = useRef(
    debounce(setCurrentMembersSearchQuery, DEBOUNCE_TIMEOUT)
  );

  const {
    isLoading: isLoadingMore,
    totalHits,
    results,
    resetSearch,
    scrollContainerRef,
    updateOptions,
  } = useInfiniteSearchResults(loadProviderBroadcastListMembers, {
    sortBy: [sortBy],
    sortOrder: [sortOrder],
    currentOrganizationId: currentOrganizationId,
  });

  const isLoading = fetchingBroadcastList || isLoadingMore;

  const fetchBroadcastList = useCallback(async () => {
    setFetchingBroadcastList(true);

    const broadcastList = await loadBroadcastList(id, { bypassCache: true });
    const {
      adminOnly,
      createdBy,
      createdOn,
      displayName,
      isAdSync,
      memberCount,
      securityGroupName,
      syncStatus,
      updatedOn,
    } = broadcastList;

    setBroadcastListDetails({
      adminOnly,
      createdBy,
      createdOn,
      displayName,
      isAdSync,
      memberCount,
      securityGroupName,
      syncStatus,
      updatedOn,
    });
    setFetchingBroadcastList(false);
  }, [id, loadBroadcastList]);

  const handleCurrentMembersSearchQueryChange = (value) => {
    if (value !== currentMembersSearchQuery) {
      setCurrentMembersSearchQueryDebounced.current(value);
    }
  };

  const refreshListMembers = useRef((options) => {
    groupedMembersMap.current.clear();
    setSelectedMemberIds([]);
    setGroupedMembers([]);
    resetSearch();
    updateOptions(options);
  });

  const resetCurrentMembers = () => {
    if (id) {
      findAllBroadcastListMemberIds(id).then((memberIds) => {
        setCurrentMemberIds(memberIds);
      });
    }

    setCurrentMembersSearchQuery('');
    setSelectedMemberIds([]);
    setShouldFetchBroadcastList(true);
    refreshListMembers.current({ id });
  };

  const resetPendingMembers = () => {
    groupedMembersMap.current.clear();
    setGroupedPendingMembers([]);
    groupedPendingMembersMap.current.clear();
    setPendingMemberIds([]);
  };

  const handleBulkAdd = async () => {
    setAddingPendingMembers(true);

    try {
      await addBroadcastListMembers({ id, members: pendingMemberIds });

      // ES data is not updated immediately
      setTimeout(() => {
        resetCurrentMembers();
        resetPendingMembers();
        setAddingPendingMembers(false);
      }, WAIT_FOR_ES_DATA_TO_LOAD);
    } catch (err) {
      console.error(err);
      setAddingPendingMembers(false);
      openModal('patientAdminFailure', {
        body: 'Unable to update broadcast list. Please try again.',
      });
    }
  };

  const handleBulkRemove = () => {
    openModal('removePatientBroadcastListMember', {
      id,
      members: selectedMemberIds,
      resetCurrentMembers,
      network: 'provider',
    });
  };

  const resetForm = useRef((newId) => {
    setId(newId);
    if (newId) {
      findAllBroadcastListMemberIds(newId).then((memberIds) => {
        setCurrentMemberIds(memberIds);
      });
    }

    destroy();
    setCurrentMembersSearchQuery('');
    setGroupedPendingMembers([]);
    groupedPendingMembersMap.current.clear();
    setShouldFetchBroadcastList(true);
    refreshListMembers.current({ id: newId });
  });

  const togglePendingMembers = (member) => {
    const memberId = member.$entityType === 'role' ? member.botUserId : member.id;
    const groupedMembers = groupedPendingMembersMap.current.get(memberId);
    if (!groupedMembers || groupedMembers.length === 0) {
      groupedPendingMembersMap.current.set(memberId, [member]);
    } else if (groupedMembers.length) {
      groupedPendingMembersMap.current.set(memberId, []);
    }

    const newGroupedPendingMembers = [];
    const newPendingMemberIds = [];
    groupedPendingMembersMap.current.forEach((groupedMembers) => {
      groupedMembers.forEach((entity) => {
        if (entity.$entityType === 'role') {
          newPendingMemberIds.push(entity.botUserId);
        } else {
          newPendingMemberIds.push(entity.id);
        }

        newGroupedPendingMembers.push([entity]);
      });
    });

    setPendingMemberIds(newPendingMemberIds);
    setGroupedPendingMembers(newGroupedPendingMembers);
  };

  const toggleSelectedMember = (token) => {
    if (selectedMemberIds.includes(token)) {
      setSelectedMemberIds(selectedMemberIds.filter((id) => id !== token));
    } else {
      setSelectedMemberIds([...selectedMemberIds, token]);
    }
  };

  const toggleSort = (newSortBy) => {
    if (isPregen) return;
    setSortOrder(sortBy !== newSortBy ? 'asc' : sortOrder === 'asc' ? 'desc' : 'asc');
    setSortBy(newSortBy);
  };

  const createOrEditCustomDirectories = () => {
    openModal('createOrEditCustomDirectories', { id, orgSecurityGroups: customDirectories });
  };

  const saveADSyncListName = async (name, listId) => {
    let canSaveList;
    const checkForErrors = new Promise((inResolve) => {
      canSaveList = inResolve;
    });
    openModal('addADSync', {
      adminOnly: broadcastListDetails.adminOnly,
      securityGroupName: broadcastListDetails.securityGroupName,
      currentOrganizationId,
      canSaveList,
      isSaving,
      listError,
      listId,
      name,
      resetForm: resetForm.current,
      setBroadcastListDetails,
      setCurrentListId,
      setIsSaving,
      setListError,
    });
    await checkForErrors;
  };

  useEffect(() => {
    refreshListMembers.current({
      id,
      query: currentMembersSearchQuery,
      sortBy: [sortBy],
      sortOrder: [sortOrder],
    });
  }, [id, currentMembersSearchQuery, sortBy, sortOrder]);

  useEffect(() => {
    resetForm.current(_id);
  }, [_id]);

  useEffect(() => {
    if (id && shouldFetchBroadcastList) {
      setShouldFetchBroadcastList(false);
      fetchBroadcastList();
    }
  }, [fetchBroadcastList, id, shouldFetchBroadcastList]);

  useEffect(() => {
    results.forEach((member) => {
      const memberId = member.$entityType === 'role' ? member.botUserId : member.id;

      if (!groupedMembersMap.current.has(memberId)) {
        groupedMembersMap.current.set(memberId, []);
      }

      const groupedMembers = groupedMembersMap.current.get(memberId);
      if (groupedMembers.length === 0) {
        groupedMembers.push(member);
      }
    });
    setGroupedMembers([...groupedMembersMap.current.values()]);
  }, [results]);

  const isSecurityGroupPresent = !!broadcastListDetails.securityGroupName;
  const isAdSyncEnabled = broadcastListDetails.isAdSync;
  const isADSyncBL = isSecurityGroupPresent || isAdSyncEnabled || isADSync;

  return (
    <div className={classes()}>
      <div className={classes('breadcrumbs')}>
        <span className={classes('parent')} onClick={viewLists}>
          PROVIDER BROADCAST LISTS
        </span>
        &gt;
        <span className={classes('child')}>{broadcastListDetails.displayName || 'UNSAVED'}</span>
      </div>
      <div className={classes('form')}>
        <PatientBroadcastFormHeader
          broadcastListDetails={broadcastListDetails}
          currentAppSelected={currentAppSelected}
          isAdmin={isAdmin}
          isPregen={isPregen}
          isADSync={isADSync}
          isSaving={isSaving}
          listId={id}
          listError={listError}
          setListError={setListError}
          resetForm={resetForm.current}
          saveADSyncListName={saveADSyncListName}
          setBroadcastListDetails={setBroadcastListDetails}
          setIsADSync={setIsADSync}
          setIsSaving={setIsSaving}
          peopleCount={isPregen ? broadcastListDetails.memberCount : totalHits}
        />
        <div className={classes('buttons-and-search')}>
          {(isADSync || broadcastListDetails.isAdSync) && id && (
            <Button
              onClick={() =>
                saveADSyncListName(broadcastListDetails.displayName.listName, currentListId)
              }
              label={'Setup List'}
              alignment="right"
            />
          )}
          {!isADSyncBL && (
            <Button
              dataTestId={'broadcast-add-members'}
              disabled={!id || isPregen || !isAdmin}
              onClick={() => setSearchTriggered(true)}
              label={'Add Members'}
              alignment="right"
            />
          )}
          {isAdmin && customDirectories.length > 0 && id && !isPregen && (
            <Button
              dataTestId={'broadcast-custom-directories'}
              onClick={createOrEditCustomDirectories}
              label={'Custom Directories'}
              alignment="right"
            />
          )}
          {id && (
            <>
              <Button
                dataTestId={'broadcast-delete-list'}
                disabled={!id || isPregen || !isAdmin}
                onClick={() =>
                  openModal('deleteBroadcastList', {
                    id,
                    searchBroadcastLists: viewLists,
                  })
                }
                color={'danger'}
                label={'Delete List'}
                alignment="right"
              />
              <div className={classes('refresh-button-container')}>
                <RefreshButton
                  currentAppSelected={currentAppSelected}
                  refreshList={() => {
                    refreshListMembers.current({
                      id,
                      query: currentMembersSearchQuery,
                    });
                  }}
                  disabled={isPregen}
                />
              </div>
              <CollapsingSearchBar
                className={classes('search-bar')}
                currentAppSelected={currentAppSelected}
                disabled={isPregen}
                handleQueryChange={handleCurrentMembersSearchQueryChange}
                query={currentMembersSearchQuery}
              />
            </>
          )}
          {selectedMemberIds.length > 0 && (
            <button
              data-test-id={'broadcast-remove'}
              onClick={handleBulkRemove}
              className={classes('actions-right-button')}
            >
              <DeleteButtonSvg className={classes('delete-icon')} />
              <span className={classes('delete-icon-text')}>Remove</span>
            </button>
          )}
        </div>
        {searchTriggered && (
          <OutsideClickHandler onOutsideClick={() => setSearchTriggered(false)}>
            <ProviderSearchBox
              currentMemberIds={currentMemberIds}
              pendingMemberIds={pendingMemberIds}
              togglePendingMembers={togglePendingMembers}
            />
          </OutsideClickHandler>
        )}
        <ProviderBroadcastListTable
          groupedMembers={groupedMembers}
          groupedPendingMembers={groupedPendingMembers}
          hasQuery={currentMembersSearchQuery !== ''}
          id={id}
          isAdmin={isAdmin}
          isPregen={isPregen}
          isLoading={isLoading}
          queryValue={currentMembersSearchQuery}
          removePendingMember={togglePendingMembers}
          resetPendingMembers={resetPendingMembers}
          saveInProgress={addingPendingMembers}
          savePendingMembers={handleBulkAdd}
          scrollContainerRef={scrollContainerRef}
          selectedMemberIds={selectedMemberIds}
          sortBy={sortBy}
          sortOrder={sortOrder}
          toggleSelectedMember={toggleSelectedMember}
          toggleSort={toggleSort}
          isSecurityGroupPresent={isSecurityGroupPresent || isADSync}
        />
        <div className={classes('loading', { isLoading })}>
          <DotsIndicator color={'#969696'} size={13} />
        </div>
      </div>
    </div>
  );
};

ProviderBroadcastListView.propTypes = {
  addBroadcastListMembers: PropTypes.func.isRequired,
  currentAppSelected: PropTypes.string.isRequired,
  currentOrganizationId: PropTypes.string.isRequired,
  destroy: PropTypes.func.isRequired,
  findAllBroadcastListMemberIds: PropTypes.func.isRequired,
  id: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  isADSync: PropTypes.bool.isRequired,
  isPregen: PropTypes.bool.isRequired,
  loadBroadcastList: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  viewLists: PropTypes.func.isRequired,
};

export default mobxInjectSelect({
  messengerStore: ['currentAppSelected', 'currentOrganizationId'],
  patientAdminStore: [
    'addBroadcastListMembers',
    'findAllBroadcastListMemberIds',
    'loadBroadcastList',
  ],
  modalStore: ['openModal'],
  patientStore: ['destroy'],
})(ProviderBroadcastListView);
