import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { KeywordSearchState } from '../../types/KeywordSearch/state';
import { sessionSlice } from '../session';

const keywordSearchSlice = createSlice({
  name: 'keywordSearch',
  initialState: {
    isKeywordSearchModeOn: false,
    previousConversationId: '',
    selectedMessageId: '',
    selectedConversationId: '', // ID FROM DB
    isFetching: false,
    keywordSearchTerm: '',
  } as KeywordSearchState,
  reducers: {
    setKeywordSearchMode: (state, action: PayloadAction<boolean>) => {
      state.isKeywordSearchModeOn = action.payload;
    },
    setPreviousConversationId: (state, action: PayloadAction<string>) => {
      state.previousConversationId = action.payload;
    },
    setSelectedMessageId: (state, action: PayloadAction<string>) => {
      state.selectedMessageId = action.payload;
    },
    setSelectedConversationId: (state, action: PayloadAction<string>) => {
      state.selectedConversationId = action.payload;
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setKeywordSearchTerm: (state, action: PayloadAction<string>) => {
      state.keywordSearchTerm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sessionSlice.actions.setCurrentOrganizationId, (state, action) => {
      if (action.payload) {
        state.isKeywordSearchModeOn = false;
      }
    });
  },
});

export { keywordSearchSlice };
