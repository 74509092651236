import { observable, runInAction, makeObservable } from 'mobx';
import { TCClient } from 'types';
import type { Params } from 'stores/FeatureStore';

type Stores = Record<string, never>;

export interface CallResponse {
  email?: string | null;
  firstName: string;
  lastName: string;
  npi?: string | null;
  sysLogin?: string;
  jwt: string;
}

export interface PCMResponse {
  dob: string;
  mrn: string;
  name: string;
  sex: string;
  email?: string | null;
  firstName?: string;
  lastName?: string;
  npi?: string | null;
  jwt?: string;
  sysLogin?: string;
}

export type LaunchData =
  | { type: 'pcm'; data: PCMResponse }
  | { type: 'call'; data: CallResponse }
  | undefined;

export default class LaunchStore {
  client: TCClient;
  stores: Stores;
  params: Params;

  @observable launchServiceFlow = false;
  @observable launchError = false;
  @observable didFHIRLaunchFail = false;
  @observable jwt = null;
  @observable launchType: Params['launchType'] | undefined = undefined;
  @observable.shallow launchData: LaunchData = undefined;

  constructor({ client, params, stores }: { client: TCClient; params: Params; stores: Stores }) {
    makeObservable(this);
    this.client = client;
    this.params = params;
    this.stores = stores;
  }

  mounted() {
    const { iss, launch } = this.params;
    if (iss && launch) this.launchRequest();
  }

  get isFHIRLaunchSession() {
    const { iss, launch } = this.params;
    return (
      typeof iss === 'string' && iss.length > 0 && typeof launch === 'string' && launch.length > 0
    );
  }

  async launchRequest() {
    try {
      const { iss, launch, launchType } = this.params;
      const baseUrl = this.getUrl();
      const url = new URL(baseUrl);

      const params = [
        ['type', launchType || 'pcm'],
        ['iss', iss],
        ['launch', launch],
      ].filter((v): v is [string, string] => typeof v[0] === 'string' && typeof v[1] === 'string');

      for (const [key, value] of params) {
        url.searchParams.set(key, value);
      }

      const res = await fetch(url.href);
      const data = await res.json();

      if (launchType === 'pcm') {
        if (data) {
          this.launchData = {
            type: 'pcm',
            data,
          };
          this.launchServiceFlow = true;
        } else {
          console.log('Invalid FHIR Launch Response for PCM');
          this.launchError = true;
        }
      } else if (launchType === 'call') {
        if (data) {
          this.launchData = {
            type: 'call',
            data,
          };
          this.launchServiceFlow = true;
        } else {
          this.launchError = true;
        }
      }

      if (data.jwt) {
        this.jwt = data.jwt;
        await this.client.signInWithJwtToken(data.jwt, { jwt: data.jwt });
      } else {
        runInAction(() => {
          console.log('JWT missing or invalid in FHIR Launch Response');
          this.didFHIRLaunchFail = true;
        });
      }
    } catch (e) {
      runInAction(() => {
        console.log('Failure to initiate FHIR launch request: ', e);
        this.didFHIRLaunchFail = true;
      });
    }
  }

  getUrl() {
    const { launchServiceUrl } = this.params;
    return launchServiceUrl || `${this.client.webInfraUrl}/v1/fhir-launch/launch`;
  }
}
