import React, { useCallback, useEffect, useState } from 'react';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import TCClient from '../../../client';

import BroadcastLists from '../PatientSettings/PatientBroadcastLists';

const classes = BEM.with('ProviderBroadcastListsView');

type MobxProps = {
  isBroadcastListAdmin: boolean;
  setAppResetter: (fn: () => void) => void;
  currentOrganizationId: string;
};

function BroadcastListSettings({
  isBroadcastListAdmin,
  setAppResetter,
  currentOrganizationId,
}: MobxProps) {
  const [customDirectories, setCustomDirectories] = useState<string[]>([]);

  const getOrgCustomDirectories = useCallback(async () => {
    try {
      const res = await TCClient.adminOrganizations.getOrgCustomDirectories({
        orgId: currentOrganizationId,
      });
      setCustomDirectories(res);
    } catch (e) {
      console.error(e);
    }
  }, [currentOrganizationId]);

  useEffect(() => {
    getOrgCustomDirectories();
  }, [currentOrganizationId, getOrgCustomDirectories]);

  return (
    <div className={classes()}>
      <BroadcastLists
        isAdmin={isBroadcastListAdmin}
        network={'provider'}
        providerNetworkCustomDirectories={customDirectories}
        refreshProviderNetworkCustomDirectories={getOrgCustomDirectories}
        setCurrentAdminPageRefresher={() => {}}
        setAppResetter={setAppResetter}
      />
    </div>
  );
}
export default mobxInjectSelect<{}, MobxProps>({
  messengerStore: ['isBroadcastListAdmin', 'setAppResetter', 'currentOrganizationId'],
})(BroadcastListSettings);
