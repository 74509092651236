import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DotsIndicator from '../../../DotsIndicator';
import BEM from '../../../../bem';
import mobxInjectSelect from '../../../../utils/mobxInjectSelect';
import { Modal } from '../../..';
import { PatientAndContactFormItem } from '..';
import PatientAdminFormValidation from '../../../../../models/enums/PatientAdminFormValidation';

const { PATIENT_AND_CONTACT } = PatientAdminFormValidation;
const classes = BEM.with('CreatePatientModal');

class CreatePatientModal extends Component {
  static propTypes = {
    actionInProgress: PropTypes.bool.isRequired,
    addContact: PropTypes.func.isRequired,
    addPatient: PropTypes.func.isRequired,
    formErrorsString: PropTypes.string,
    hideCreatePatientModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isSendDisabled: PropTypes.bool,
    patient: PropTypes.object.isRequired,
    patientContactList: PropTypes.array.isRequired,
    validatePatientOrContactForm: PropTypes.func.isRequired,
  };

  render() {
    const {
      actionInProgress,
      formErrorsString,
      isOpen,
      isSendDisabled,
      patient,
      patientContactList,
    } = this.props;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        headerClass={classes('header')}
        header="Create a Patient"
        onRequestClose={this._onRequestClose}
        size={'variable'}
        isOpen={isOpen}
        footerClass={classes('footer')}
        footerPrimaryActions={
          <button
            className={classes(
              'save-btn',
              { progress: actionInProgress },
              { isSendDisabled: isSendDisabled }
            )}
            onClick={this._checkValues}
            disabled={isSendDisabled}
            type="button"
          >
            {actionInProgress ? (
              <div className={classes('dots-container')}>
                <DotsIndicator className={classes('dots')} size={10} speed={0.5} color={'#fff'} />
              </div>
            ) : (
              'SAVE'
            )}
          </button>
        }
        footerSecondaryActions={
          <button
            className={classes('cancel-btn', { progress: actionInProgress })}
            disabled={actionInProgress}
            onClick={this._onRequestClose}
            type="button"
          >
            CANCEL
          </button>
        }
      >
        <div className={classes('errors', { hasError: !!formErrorsString })}>
          {formErrorsString}
        </div>
        <div className={classes('patient-admin-container')}>
          <PatientAndContactFormItem entity={patient} isPatient={true} />
          {patientContactList.map((contact, index) => (
            <PatientAndContactFormItem
              key={index.toString()}
              entity={contact}
              isPatient={false}
              indexOfContact={index}
            />
          ))}
        </div>

        {patientContactList.length < 2 && (
          <button
            className={classes('add-patient-button', {
              progress: actionInProgress,
            })}
            onClick={this._addContact}
          >
            <div>Add a contact +</div>
          </button>
        )}
      </Modal>
    );
  }

  _checkValues = () => {
    const { actionInProgress, addPatient, validatePatientOrContactForm } = this.props;
    if (validatePatientOrContactForm(PATIENT_AND_CONTACT) && !actionInProgress) {
      addPatient();
    }
  };

  _addContact = () => {
    const { actionInProgress, addContact, patientContactList } = this.props;
    if (actionInProgress) {
      return;
    }

    if (patientContactList.length < 2) {
      addContact();
    }
  };

  _onRequestClose = () => {
    const { hideCreatePatientModal } = this.props;

    this.setState({ showError: false });
    hideCreatePatientModal();
  };
}

export default mobxInjectSelect({
  patientStore: [
    'actionInProgress',
    'addContact',
    'addPatient',
    'formErrorsString',
    'hideCreatePatientModal',
    'patient',
    'patientContactList',
    'isSendDisabled',
    'validatePatientOrContactForm',
  ],
})(CreatePatientModal);
