import React from 'react';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';
import PropTypes from 'prop-types';
import Highlight from 'react-highlight-words';
import BEM from '../../bem';

const classes = BEM.with('Linkify');

export const linkify = new LinkifyIt();
linkify.tlds(tlds);

class Linkify extends React.Component {
  static MATCH = 'LINKIFY_MATCH';

  static propTypes = {
    component: PropTypes.any,
    inlineImages: PropTypes.bool,
    properties: PropTypes.object,
    urlRegex: PropTypes.object,
    emailRegex: PropTypes.object,
    keyword: PropTypes.string,
  };

  static defaultProps = {
    component: 'a',
    inlineImages: false,
    properties: {},
  };

  parseCounter = 0;

  getMatches(string) {
    return linkify.match(string);
  }

  isImage(url = '') {
    return url.split('?')[0].match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  parseString(string) {
    const elements = [];
    if (string === '') {
      return elements;
    }

    const matches = this.getMatches(string);
    if (!matches) {
      return this.props.keyword ? (
        <Highlight
          highlightClassName={classes('highlight')}
          textToHighlight={string}
          searchWords={this.props.keyword.split(' ')}
          autoEscape
        />
      ) : (
        string
      );
    }

    let lastIndex = 0;
    let image;
    matches.forEach((match, idx) => {
      // Push the preceding text if there is any
      if (match.index > lastIndex) {
        elements.push(string.substring(lastIndex, match.index));
      }
      // Shallow update values that specified the match
      const props = { href: match.url, key: `parse${this.parseCounter}match${idx}` };
      for (const key in this.props.properties) {
        let val = this.props.properties[key];
        if (val === Linkify.MATCH) {
          val = match.url;
        }

        props[key] = val;
      }
      if (this.props.inlineImages && this.isImage(match.url)) {
        image = React.createElement('img', {
          src: match.url,
          key: `parse${this.parseCounter}match${idx}image`,
        });
      }
      let matchText = match.text;
      if (this.props.keyword) {
        matchText = (
          <Highlight
            highlightClassName={classes('highlight')}
            textToHighlight={match.text}
            searchWords={this.props.keyword.split(' ')}
            autoEscape
          />
        );
      }
      elements.push(React.createElement(this.props.component, props, matchText));
      lastIndex = match.lastIndex;
    });

    if (lastIndex < string.length) {
      elements.push(string.substring(lastIndex));
    }

    if (image) {
      elements.push(React.createElement('br', { key: 'image-br' }));
      elements.push(image);
    }

    return elements.length === 1 ? elements[0] : elements;
  }

  parse(children) {
    let parsed = children;

    if (typeof children === 'string') {
      parsed = this.parseString(children);
    } else if (
      React.isValidElement(children) &&
      children.type !== 'a' &&
      children.type !== 'button'
    ) {
      parsed = React.cloneElement(
        children,
        { key: `parse${++this.parseCounter}` },
        this.parse(children.props.children)
      );
    } else if (children instanceof Array) {
      parsed = children.map((child) => {
        return this.parse(child);
      });
    }

    return parsed;
  }

  render() {
    this.parseCounter = 0;
    const parsedChildren = this.parse(this.props.children);

    return <span className={classes('')}>{parsedChildren}</span>;
  }
}

export default Linkify;
