import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import shallowequal from 'shallowequal';
import { FROZEN_EMPTY_ARRAY } from '../common/utils';

export default class CreateGroupStore {
  @observable avatarPreviewUrl = null;
  @observable createInProgress = false;
  @observable forumDescription = '';
  @observable.shallow forumMembers = FROZEN_EMPTY_ARRAY;
  @observable forumName = '';
  @observable lastSenderId = null;
  @observable groupSenderId = null;
  avatarFile = null;

  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  @computed get allowedSenders() {
    const { messengerStore } = this.stores;
    const { currentOrganization } = messengerStore;

    return currentOrganization ? currentOrganization.allowedSenders : FROZEN_EMPTY_ARRAY;
  }

  @action('CreateGroupStore.setAvatar') setAvatar = (file) => {
    const { sessionStore } = this.stores;

    this.avatarFile = file;
    this.avatarPreviewUrl = file ? file.preview : null;
    sessionStore.resetAutoLogout();
  };

  @action('CreateGroupStore.setMembers') setForumMembers = (forumMembers) => {
    const { sessionStore } = this.stores;

    if (shallowequal(this.forumMembers.slice(), forumMembers)) return;

    this.forumMembers = forumMembers;
    sessionStore.resetAutoLogout();
  };

  @action('CreateGroupStore.setName') setForumName = (name) => {
    const { sessionStore } = this.stores;
    this.forumName = name;
    sessionStore.resetAutoLogout();
  };

  @action('CreateGroupStore.setForumDescription') setForumDescription = (description) => {
    const { sessionStore } = this.stores;
    this.forumDescription = description;
    sessionStore.resetAutoLogout();
  };

  @action('CreateGroupStore.setSenderId') setSenderId = (senderId) => {
    const { sessionStore } = this.stores;
    this.groupSenderId = senderId;
    sessionStore.resetAutoLogout();
  };

  @action('CreateGroupStore.openNewGroupModal') openNewGroupModal = async (type) => {
    const { composeMessageStore, modalStore, sessionStore } = this.stores;
    const { selectedRecipients, sender, setLastSenderId, setSelectedRecipients, stopComposing } =
      composeMessageStore;

    this.avatarFile = null;
    this.avatarPreviewUrl = null;
    this.createInProgress = false;
    this.description = '';
    if (sender) {
      setLastSenderId(sender.id);
      this.setSenderId(sender.id);
    }

    if (
      selectedRecipients?.length === 1 &&
      selectedRecipients[0].$entityType === 'distributionList'
    ) {
      setSelectedRecipients([]);
      stopComposing();
    }

    modalStore.openModal('newGroup', type);
    sessionStore.resetAutoLogout();
  };

  @action('CreateGroupStore.fetchSetters') fetchSetters = (entityType) => {
    const { composeMessageStore, conversationStore } = this.stores;
    const { currentConversationId } = conversationStore;
    const { type } = entityType;
    const { setNewGroupName, setSelectedRecipients, setSenderId } = composeMessageStore;

    return {
      setDescription: this.setForumDescription,
      setMembers: type === 'Group' ? setSelectedRecipients : this.setForumMembers,
      setName: type === 'Group' ? setNewGroupName : this.setForumName,
      setSenderId: type === 'Group' && !currentConversationId ? setSenderId : this.setSenderId,
    };
  };

  @action('CreateGroupStore.getDefaultSender') getDefaultSender = () => {
    const { composeMessageStore } = this.stores;
    const { getDefaultUser, selectorDefaultsToUser } = composeMessageStore;
    const allowedSenders = this.allowedSenders;
    const lastSenderId = this.lastSenderId;

    if (allowedSenders.length === 0) return null;

    if (selectorDefaultsToUser) {
      return getDefaultUser({ allowedSenders, setSenderId: false });
    }

    const idOfSender = (sender) => (sender.$entityType === 'role' ? sender.botUser.id : sender.id);
    const senderIdAllowed = (id) =>
      id && allowedSenders.some((sender) => idOfSender(sender) === id);

    if (lastSenderId && senderIdAllowed(lastSenderId)) {
      return lastSenderId;
    } else {
      return idOfSender(allowedSenders[0]);
    }
  };

  @action('CreateGroupStore.createGroup') createGroup = async (isPublic) => {
    const { composeMessageStore, conversationStore, groupStore, messengerStore, modalStore } =
      this.stores;
    const { currentConversationId } = conversationStore;
    const { newGroupName, selectedRecipients, senderId } = composeMessageStore;
    const memberIds = isPublic ? this.forumMembers.slice() : selectedRecipients;

    let groupName;
    if (memberIds.length === 1 && memberIds[0].$entityType === 'team') {
      groupName = memberIds[0].displayName;
    } else {
      groupName = newGroupName || undefined;
    }

    let currentSenderId;
    if (isPublic) {
      currentSenderId = this.client.currentUserId;
    } else {
      if (currentConversationId) {
        currentSenderId = this.groupSenderId;
      } else {
        currentSenderId = senderId;
      }
    }

    if (this.createInProgress) return;

    this.createInProgress = true;
    let succeeded = false;

    let onConversationChange, timeout;
    try {
      const sdkGroup = await groupStore.createGroup({
        avatarFile: this.avatarFile,
        description: isPublic ? this.forumDescription : undefined,
        isPublic,
        memberIds,
        name: isPublic ? this.forumName : groupName,
        organizationId: messengerStore.currentOrganizationId,
        senderId: currentSenderId,
      });

      await new Promise((resolve, reject) => {
        if (sdkGroup && sdkGroup.conversation) return resolve();
        timeout = setTimeout(() => reject(new Error('timed out')), 5000);

        onConversationChange = (resource, sdkConversation) => {
          if (sdkConversation && sdkConversation.counterPartyId === sdkGroup.id) {
            const conversation = this.entityStore.syncOne(sdkConversation);
            conversationStore.selectConversation(conversation);
            if (onConversationChange) {
              this.client.models.Conversation.removeListener('afterInject', onConversationChange);
            }
            onConversationChange = null;
            resolve();
          }
        };

        this.client.models.Conversation.on('afterInject', onConversationChange);
      });
      succeeded = true;
    } catch (err) {
      console.error(err);
      const type = isPublic ? 'Forum' : 'Group';
      modalStore.openModal('failure', { reopenModal: 'newGroup', optionsReopenModal: { type } });
    } finally {
      runInAction(() => {
        this.createInProgress = false;
        if (succeeded && isPublic) this._clearForumValues();
        if (onConversationChange) {
          this.client.models.Conversation.removeListener('afterInject', onConversationChange);
        }
        clearTimeout(timeout);
      });
    }

    return succeeded;
  };

  _clearForumValues() {
    this.forumDescription = '';
    this.forumMembers = FROZEN_EMPTY_ARRAY;
    this.forumName = '';
  }
}
