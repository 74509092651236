import React, { useContext, useReducer } from 'react';
import { mobxInjectSelect } from '../../common/utils';
import { ProviderBLAction } from './actions';
import { providerBLReducer } from './reducer';

type ProviderBLContextProviderProps = {
  children: React.ReactNode;
};

export type MobxProps = {
  currentOrganizationId: string;
};

export type ProviderBLState = {
  mobxProps: MobxProps;
  isBLAdmin: boolean;
};

export type ProviderBLDispatch = React.Dispatch<ProviderBLAction>;

type ProviderBlContext = {
  state: ProviderBLState;
  dispatch: ProviderBLDispatch;
};

const ProviderBLContext = React.createContext<ProviderBlContext>({} as ProviderBlContext);
export const useProviderBLContext = () => useContext(ProviderBLContext);

const ProviderBLContextProvider: React.FC<ProviderBLContextProviderProps & MobxProps> = ({
  children,
  currentOrganizationId,
}) => {
  const initialState: ProviderBLState = {
    mobxProps: {
      currentOrganizationId,
    },
    isBLAdmin: false,
  };

  const [state, dispatch] = useReducer(providerBLReducer, initialState);
  const value = { state, dispatch };

  return <ProviderBLContext.Provider value={value}>{children}</ProviderBLContext.Provider>;
};

export default mobxInjectSelect<ProviderBLContextProviderProps, MobxProps>({
  messengerStore: ['currentOrganizationId'],
})(ProviderBLContextProvider);
