const PerformanceKPITypes = {
  COMPOSE: 'Performance KPI: Time to compose',
  MESSAGE_SEND: 'Performance KPI: Message send',
  TYPING: 'Performance KPI: Typing',
  LOGIN: 'LoginTimings',
  VOIP_CALL: 'Twilio VoIP Call',
};

export type PerformanceKPIType = (typeof PerformanceKPITypes)[keyof typeof PerformanceKPITypes];
export default PerformanceKPITypes;
