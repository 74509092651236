import EventEmitter from 'events';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import queue from 'emitter-queue';
import MessageSendEntityTypes from '../models/enums/MessageSendEntityTypes';
import { PerformanceKPITypes } from '../models/enums';
import { emojiMap } from '../emoji/emojiMap';

const CONSECUTIVE_ATTACHMENT_DELAY = 200;
export const DELETED_MESSAGE_EXPIRATION = 2592000000; // 30 days in ms
export const DELETED_MESSAGE_STORAGE_LIMIT = 16000;

export default class MessageStore {
  @observable failedPhoneNumbersList = [];
  @observable failureModalErrorMessage = 'Something went wrong, please try again.';
  @observable currentActionMenuMessageId = null;
  @observable currentGroupMessageId = null;
  @observable currentMessageInfoId = null;
  @observable.shallow selectedMessages = {};
  @observable numberOfSelectedMessages = 0;

  events = queue(new EventEmitter());

  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  @computed get currentGroupMessage() {
    return this.entityStore.message.getById(this.currentGroupMessageId);
  }

  @computed get currentMessageInfo() {
    return this.entityStore.message.getById(this.currentMessageInfoId, {
      includeStatuses: true,
    });
  }

  @computed get canEscalate() {
    const { composeMessageStore } = this.stores;

    if (!this.currentActionMenuMessageId) return false;

    const message = this.entityStore.message.getById(this.currentActionMenuMessageId);

    const {
      conversation,
      counterParty,
      counterPartyType,
      isOutgoing,
      escalationExecution,
      senderStatus,
    } = message;

    const isRoleP2P =
      counterPartyType === 'group' && counterParty && counterParty.groupType === 'ROLE_P2P';
    const msgDelivered = senderStatus === 'SENT';

    return !!(
      conversation &&
      composeMessageStore.canEscalate &&
      isRoleP2P &&
      msgDelivered &&
      !escalationExecution &&
      isOutgoing
    );
  }

  @action('MessageStore.sendMessageToConversation')
  sendMessageToConversation = async (conversationId, body, options) => {
    const message = await this._sendMessage({
      body,
      entityId: conversationId,
      entityType: MessageSendEntityTypes.CONVERSATION,
      ...options,
    });

    return message;
  };

  @action('MessageStore.sendMessageToDistributionList')
  sendMessageToDistributionList = async (distributionListId, body, options) => {
    const message = await this._sendMessage({
      body,
      entityId: distributionListId,
      entityType: MessageSendEntityTypes.DISTRIBUTION_LIST,
      ...options,
    });

    return message;
  };

  @action('MessageStore.sendMessageToGroupOfUsers')
  sendMessageToGroupOfUsers = async (userIds, body, options) => {
    const message = await this._sendMessage({
      body,
      entityId: userIds,
      entityType: MessageSendEntityTypes.GROUP_OF_USERS,
      ...options,
    });

    return message;
  };

  @action('MessageStore.sendMessageToPatientGroup')
  sendMessageToPatientGroup = async (userIds, body, options) => {
    const message = await this._sendMessage({
      body,
      entityId: userIds,
      entityType:
        userIds.length === 1
          ? MessageSendEntityTypes.PATIENT
          : MessageSendEntityTypes.GROUP_OF_USERS,
      ...options,
    });

    return message;
  };

  @action('MessageStore.sendMessageToRole')
  sendMessageToRole = async (roleId, body, options) => {
    const message = await this._sendMessage({
      body,
      entityId: roleId,
      entityType: MessageSendEntityTypes.ROLE,
      ...options,
    });

    return message;
  };

  @action('MessageStore.sendMessageToUser')
  sendMessageToUser = async (userId, body, options) => {
    const message = await this._sendMessage({
      body,
      entityId: userId,
      entityType: MessageSendEntityTypes.USER,
      ...options,
    });

    return message;
  };

  _sendMessage = async ({
    attachmentFiles,
    body,
    entityId,
    entityType,
    escalate,
    groupName,
    membersMentionInConversation,
    organizationId,
    priority = 'NORMAL',
    senderId,
    setConversationToCurrent = false,
    patientContextId,
  } = {}) => {
    const {
      createGroupStore,
      composeMessageStore,
      conversationStore,
      messengerStore,
      modalStore,
      sessionStore,
      trackerStore,
    } = this.stores;
    const { resetMessage, setIsSending, stashMessage } = composeMessageStore;
    const { performanceKpiStart } = trackerStore;

    if (sessionStore.currentUser.roleIds.includes(entityId)) {
      modalStore.openModal('allowedSenderModal', {
        reason: 'CONVERSATION_WITH_ROLE_YOU_ARE_CURRENTLY_IN',
      });
      return;
    }

    setIsSending(true);
    const previousMessageState = stashMessage();

    performanceKpiStart(PerformanceKPITypes.MESSAGE_SEND);

    // allow mobx updates to fire to fully clear the message body
    await new Promise((resolve) => setTimeout(resolve, 1));

    // before starting to send, listen to message:sending
    // so we can get a reference to the message
    const onMessageSending = (sdkMessage) => {
      const message = this.entityStore.syncOne(sdkMessage);
      if (setConversationToCurrent) {
        conversationStore.selectConversationWithEntity({ message });
      }
    };
    this.client.once('message:sending', onMessageSending);

    const sendOptionsDefault = {
      escalate,
      groupName,
      membersMentionInConversation,
      organizationId,
      priority,
      senderId,
      patientContextId,
      avatarFile: createGroupStore.avatarFile,
    };

    const sdkMessages = [];
    const sendSingleMessage = async ({ body, attachmentFile }) => {
      let sdkMessage;
      const sendOptions = attachmentFile
        ? { attachmentFiles: [attachmentFile], ...sendOptionsDefault }
        : sendOptionsDefault;
      if (entityType === MessageSendEntityTypes.PATIENT) {
        sdkMessage = await this.client.messages.sendToPatient(entityId, body, sendOptions);
      } else if (entityType === MessageSendEntityTypes.CONVERSATION) {
        sdkMessage = await this.client.messages.sendToConversation(entityId, body, sendOptions);
      } else if (entityType === MessageSendEntityTypes.GROUP_OF_USERS) {
        sdkMessage = await this.client.messages.sendToGroupOfUsers(entityId, body, sendOptions);
      } else if (entityType === MessageSendEntityTypes.DISTRIBUTION_LIST) {
        sdkMessage = await this.client.messages.sendToDistributionList(entityId, body, sendOptions);
      } else if (entityType === MessageSendEntityTypes.ROLE) {
        sdkMessage = await this.client.messages.sendToRole(entityId, body, sendOptions);
      } else if (entityType === MessageSendEntityTypes.USER) {
        sdkMessage = await this.client.messages.sendToUser(entityId, body, sendOptions);
      }

      sdkMessages.push(sdkMessage);
      setIsSending(false);
    };

    try {
      if (attachmentFiles && attachmentFiles.length > 0) {
        let firstFile = true;
        let conversationId;

        for (const attachmentFile of attachmentFiles) {
          if (firstFile) {
            await sendSingleMessage({ body, attachmentFile });
            const messages = this.entityStore.sync(sdkMessages);
            if (messages.length > 0 && messages[0] && messages[0].conversationId)
              conversationId = messages[0].conversationId;
            firstFile = false;
          } else {
            // eslint-disable-next-line no-loop-func
            await new Promise((resolve) => setTimeout(resolve, CONSECUTIVE_ATTACHMENT_DELAY));

            if (conversationId) {
              await this.client.messages.sendToConversation(conversationId, '', {
                attachmentFiles: [attachmentFile],
                ...sendOptionsDefault,
              });
            } else {
              await sendSingleMessage({ body: '', attachmentFile });
            }
          }
        }
      } else {
        await sendSingleMessage({ body });
      }
    } catch (err) {
      console.error(err);
      if (
        messengerStore.currentOrganization.displayName === 'Contacts' &&
        err.status === 400 &&
        err.text
      ) {
        runInAction(() => {
          this.failedPhoneNumbersList = !err.arrayOfFailedPhoneNumbers
            ? [entityId]
            : err.arrayOfFailedPhoneNumbers;

          this.failureModalErrorMessage = err.text;
        });
      } else {
        runInAction(() => {
          this.failedPhoneNumbersList = [];
          this.failureModalErrorMessage = 'Something went wrong, please try again.';
        });
      }

      modalStore.openModal('failure');
    }

    let messages;
    if (sdkMessages.length > 0) {
      messages = this.entityStore.sync(sdkMessages);
      for (const message of messages) {
        conversationStore.sentMessage(message.conversationId);
      }
    } else {
      resetMessage(previousMessageState);
      setIsSending(false);
    }

    this.client.removeListener('message:sending', onMessageSending);

    return messages;
  };

  @action('MessageStore.markAsRead') markAsRead = async (messageIds: string[]) => {
    this.stores.sessionStore.resetAutoLogout();
    await this.client.messages.markAsRead(messageIds);
  };

  @action('MessageStore.selectMessage') selectMessage = (message) => {
    if (this.selectedMessages[message.id] || this.numberOfSelectedMessages >= 50) return;
    this.numberOfSelectedMessages += 1;
    this.selectedMessages = Object.assign({}, this.selectedMessages, {
      [message.id]: message,
    });
  };

  @action('MessageStore.unselectMessage') unselectMessage = (message) => {
    delete this.selectedMessages[message.id];
    this.numberOfSelectedMessages -= 1;
    this.selectedMessages = Object.assign({}, this.selectedMessages);
  };

  @action('MessageStore.clearSelectedMessages') clearSelectedMessages = () => {
    this.numberOfSelectedMessages = 0;
    this.selectedMessages = {};
  };

  @action('MessageStore.retrySend') retrySend = async (messageId: string) => {
    await this.client.messages.retrySend(messageId);
  };

  @action('MessageStore.resend') resend = async (messageId, { senderId } = {}) => {
    const message = await this.client.messages.resend(messageId, { senderId });
    this.events.emit('resend', { message });
  };

  @action('MessageStore.resendAsPriority') resendAsPriority = async (
    messageId,
    { senderId } = {}
  ) => {
    const message = await this.client.messages.resend(messageId, {
      priority: 'HIGH',
      senderId,
    });
    this.events.emit('resendAsPriority', { message });
  };

  @action('MessageStore.recallMessages') recallMessages = async (messages) => {
    const messageIds = messages.map(({ id }) => id);

    await this.client.messages.recall(messageIds);

    for (const message of messages) {
      this.events.emit('recall', { message });
    }
  };

  @action('MessageStore.forwardMultiple') forwardMultiple = async (
    senderId,
    recipients,
    messages,
    { asPriority }
  ) => {
    messages = messages.slice();
    const {
      messengerStore: { currentOrganizationId },
      roleStore,
    } = this.stores;

    if (!recipients) return;

    const recipientIds = recipients.map((recipient) => recipient.id);
    let entityId = recipientIds[0];
    const role = roleStore.getRoleById(entityId);
    let entityType = recipients[0].$entityType;
    let conversationId;

    if (recipientIds.length > 1) {
      // we need to create the group first
      const message = await this.forward(
        messages[0].id,
        recipientIds,
        MessageSendEntityTypes.GROUP_OF_USERS,
        {
          organizationId: currentOrganizationId,
          priority: asPriority || messages[0].priority || null,
          senderId,
        }
      );
      entityId = message.groupId;
      entityType = MessageSendEntityTypes.GROUP;
      messages.shift();
    } else if (entityType === 'team') {
      const firstMessage = messages.shift();
      const sdkMessage = await this.client.messages.forwardToGroupOfUsers(
        firstMessage.id,
        recipientIds,
        {
          organizationId: currentOrganizationId,
          priority: asPriority || firstMessage.priority || null,
          senderId,
        }
      );
      conversationId = sdkMessage.conversationId;
      entityType = MessageSendEntityTypes.TEAM;
    } else if (entityType === 'distributionList') {
      entityType = MessageSendEntityTypes.DISTRIBUTION_LIST;
    } else if (entityType === 'group') {
      entityType = MessageSendEntityTypes.GROUP;
    } else if (role) {
      entityType = MessageSendEntityTypes.ROLE;
    } else {
      entityType = MessageSendEntityTypes.USER;
    }

    for (let idx = 0; idx < messages.length; idx += 1) {
      await this.forward(messages[idx].id, entityId, entityType, {
        organizationId: currentOrganizationId,
        priority: asPriority || messages[idx].priority || null,
        senderId,
        conversationId,
      });
    }
  };

  @action('MessageStore.forward') forward = async (
    messageId,
    entityId,
    entityType,
    {
      organizationId,
      conversationId,
      priority = 'NORMAL',
      senderId,
      setConversationToCurrent = false,
    } = {}
  ) => {
    const { conversationStore, modalStore } = this.stores;
    let message;

    // before starting to forward, listen to message:sending
    // so we can get a reference to the message
    const onMessageSending = (sdkMessage) => {
      message = this.entityStore.syncOne(sdkMessage);
      if (setConversationToCurrent) {
        conversationStore.selectConversationWithEntity({ message });
      }
    };
    this.client.once('message:sending', onMessageSending);

    try {
      let sdkMessage;
      const forwardArgs = {
        organizationId,
        priority,
        senderId,
      };

      if (entityType === MessageSendEntityTypes.DISTRIBUTION_LIST) {
        sdkMessage = await this.client.messages.forwardToDistributionList(
          messageId,
          entityId,
          forwardArgs
        );
      } else if (entityType === MessageSendEntityTypes.GROUP) {
        sdkMessage = await this.client.messages.forwardToGroup(messageId, entityId, forwardArgs);
      } else if (entityType === MessageSendEntityTypes.GROUP_OF_USERS) {
        sdkMessage = await this.client.messages.forwardToGroupOfUsers(
          messageId,
          entityId,
          forwardArgs
        );
      } else if (entityType === MessageSendEntityTypes.ROLE) {
        sdkMessage = await this.client.messages.forwardToRole(messageId, entityId, forwardArgs);
      } else if (entityType === MessageSendEntityTypes.USER) {
        sdkMessage = await this.client.messages.forwardToUser(messageId, entityId, forwardArgs);
      } else if (entityType === MessageSendEntityTypes.TEAM) {
        sdkMessage = await this.client.messages.forwardToConversation(
          messageId,
          conversationId,
          forwardArgs
        );
      }
      message = this.entityStore.syncOne(sdkMessage);
      if (message.senderStatus === 'FAILED') {
        modalStore.openModal('failure');
      } else {
        this.events.emit('forward', { message });
      }
    } catch (err) {
      console.error(err);
      modalStore.openModal('failure');
    }

    this.client.removeListener('message:sending', onMessageSending);

    return message;
  };

  @action('MessageStore.ensureRecipientStatus')
  ensureRecipientStatus = async (messageId: string, { includeUsers = false } = {}) => {
    await this.client.messages.ensureRecipientStatus(messageId, {
      includeUsers,
    });
  };

  @action('MessageStore.findRecipientStatus')
  findRecipientStatus = async (
    messageId: string,
    { includeUsers = false, isGroupReplay = false } = {}
  ) => {
    await this.client.messages.findRecipientStatus(messageId, { includeUsers, isGroupReplay });
  };

  @action('MessageStore.showMessageInfoModal') showMessageInfoModal = (messageId: string) => {
    const { modalStore } = this.stores;
    this.currentMessageInfoId = messageId;
    modalStore.openModal('messageInfo');
  };

  @action('MessageStore.showForwardModal') showForwardModal = (messages) => {
    const { modalStore } = this.stores;
    modalStore.openModal('messageForward', { messages });
  };

  @action('MessageStore.showRecallModal') showRecallModal = (messages) => {
    const { modalStore } = this.stores;
    modalStore.openModal('messageRecall', { messages });
  };

  @action('MessageStore.showDeleteModal') showDeleteModal = (messages) => {
    const { modalStore } = this.stores;
    const messageArr = Object.keys(messages);
    modalStore.openModal('messageDelete', { messages: messageArr });
  };

  @action('MessageStore.deleteMessagesLocally') deleteMessagesLocally = (messages) => {
    const { localStore } = this.stores;
    const { deletedMessages, setDeletedMessages } = localStore;
    const messagesToDelete = {};

    for (let idx = 0; idx < messages.length; idx += 1) {
      messagesToDelete[messages[idx]] = new Date().getTime();
    }

    this.expireLocallyDeletedMessages();
    setDeletedMessages(Object.assign({}, deletedMessages, messagesToDelete));
  };

  @action('MessageStore.expireLocallyDeletedMessages')
  expireLocallyDeletedMessages = () => {
    const { localStore } = this.stores;
    const { deletedMessages, setDeletedMessages } = localStore;
    const thirtyDays = DELETED_MESSAGE_EXPIRATION;
    const currentTime = new Date().getTime();
    let keysArr = Object.keys(deletedMessages);

    keysArr = keysArr.filter((message) => {
      const passedTime = currentTime - deletedMessages[message];

      if (passedTime > thirtyDays) {
        delete deletedMessages[message];
        return false;
      }

      return true;
    });

    if (keysArr.length > DELETED_MESSAGE_STORAGE_LIMIT) {
      keysArr = keysArr.slice(0, 1600);
      keysArr.forEach((message) => {
        delete deletedMessages[message];
      });
    }

    setDeletedMessages(Object.assign({}, deletedMessages));
  };

  @action('MessageStore.showGroupMessageStatusModal')
  showGroupMessageStatusModal = (messageId) => {
    const { modalStore } = this.stores;

    this.currentGroupMessageId = messageId;
    modalStore.openModal('groupMessageStatus');
  };

  @action('MessageStore.hideGroupMessageStatusModal')
  hideGroupMessageStatusModal = () => {
    this.currentGroupMessageId = null;
  };

  @action('MessageStore.compareRecipientStatus') compareRecipientStatus = (status1, status2) => {
    return this.client.messages.compareRecipientStatus(status1, status2);
  };

  @action('MessageStore.findMessage') findMessage = async (id) => {
    let sdkMessage = this.client.messages.getById(id);
    if (!sdkMessage) {
      sdkMessage = await this.client.messages.find(id);
    }

    return sdkMessage ? this.entityStore.syncOne(sdkMessage) : null;
  };

  @action('MessageStore.openMessageActionMenu') openMessageActionMenu = (message) => {
    this.currentActionMenuMessageId = message.id;
  };

  @action('MessageStore.closeMessageActionMenu')
  closeMessageActionMenu = () => {
    this.currentActionMenuMessageId = null;
  };

  @action('MessageStore.getComputedReactionValues')
  getComputedReactionValues = (reaction) => {
    const skinModifiers = ['1f3fb', '1f3fc', '1f3fd', '1f3fe', '1f3ff'];
    let skinTone, emojiUnicode;

    if (reaction.includes('-')) {
      emojiUnicode = emojiMap[reaction]?.baseHex;
      skinTone = reaction.split('-').filter((r) => skinModifiers.includes(r));
      skinTone = skinTone.length > 0 ? skinTone.join(', ') : null;
    } else {
      emojiUnicode = reaction;
      skinTone = null;
    }

    return { emojiUnicode, skinTone };
  };

  @action('MessageStore.addReaction') addReaction = async ({
    messageId,
    reaction,
    userId = this.client.currentUserId,
  }) => {
    const { conversationStore, trackerStore } = this.stores;
    const { clearNewMessagesIndicator, currentConversation, markConversationAsRead } =
      conversationStore;
    const { emojiUnicode, skinTone } = this.getComputedReactionValues(reaction);

    const message = this.entityStore.message.getById(messageId);

    const findTheSameEmojiReactionBase = message?.reactions?.find(
      (r) =>
        r?.userIds.includes(userId) &&
        emojiMap[r?.emojiUnicode]?.baseHex === emojiMap[reaction]?.baseHex
    );

    if (findTheSameEmojiReactionBase) {
      this.client.messages.removeReaction({
        messageId,
        userId,
        reaction: findTheSameEmojiReactionBase.emojiUnicode,
      });

      if (reaction === findTheSameEmojiReactionBase.emojiUnicode) return;
    }

    await this.client.messages.addReaction({ messageId, reaction, userId });

    trackerStore.logPendoAnalytics({
      tracker: {
        name: ` Inbox | Messenger - Reaction Add`,
        props: {
          ReactionEmojiUnicode: emojiUnicode,
          ReactionSkinTone: skinTone,
          MessageId: messageId,
        },
      },
    });

    clearNewMessagesIndicator(currentConversation);
    await markConversationAsRead(currentConversation);
  };

  @action('MessageStore.removeReaction') removeReaction = async ({
    messageId,
    userId = this.client.currentUserId,
    reaction,
  }) => {
    const { trackerStore } = this.stores;
    const { emojiUnicode, skinTone } = this.getComputedReactionValues(reaction);
    await this.client.messages.removeReaction({ messageId, userId, reaction });

    trackerStore.logPendoAnalytics({
      tracker: {
        name: ` Inbox | Messenger - Reaction Delete`,
        props: {
          ReactionEmojiUnicode: emojiUnicode,
          ReactionSkinTone: skinTone,
          MessageId: messageId,
        },
      },
    });
  };
}
