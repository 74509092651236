import { action, observable, makeObservable } from 'mobx';

export default class ModalStore {
  @observable currentModalType = null;
  @observable isModalOpen = false;
  @observable.shallow options = {};

  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  @action('ModalStore.closeModal') closeModal = () => {
    this.currentModalType = null;
    this.isModalOpen = false;
    this.stores.sessionStore.resetAutoLogout();
  };

  @action('ModalStore.openModal') openModal = (modalType, options = {}) => {
    this.currentModalType = modalType;
    this.isModalOpen = true;
    this.options = options;
    this.stores.sessionStore.resetAutoLogout();
  };
}
