import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { MessageSubTypes } from '../../../models/enums/MessageSubTypes';
import RoleAvatar from '../RoleAvatar';
import TeamAvatar from '../TeamAvatar';
import UserAvatar from '../UserAvatar/UserAvatar';
import MessageReactionSummary from '../MessageReactionSummary';

import { ReactComponent as MultiSelectCheckboxEmpty } from '../../../common/images/multi-select-checkbox-empty.svg';
import { ReactComponent as MultiSelectCheckboxEmptyHover } from '../../../common/images/multi-select-checkbox-empty-hover.svg';
import { ReactComponent as MultiSelectCheckboxChecked } from '../../../common/images/multi-select-checkbox-checked.svg';
import { ReactComponent as MultiSelectCheckboxCheckedHover } from '../../../common/images/multi-select-checkbox-checked-hover.svg';
import { ReactComponent as SupportSvg } from '../../../common/images/icon-support.svg';
import { MessageBubble, MessageDropdown } from './';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('MessageItem');

class MessageItem extends Component {
  static propTypes = {
    closeMessageActionMenu: PropTypes.func.isRequired,
    composeToEntity: PropTypes.func.isRequired,
    currentActionMenuMessageId: PropTypes.string,
    currentMessageMultiSelectOption: PropTypes.string,
    currentUserId: PropTypes.string.isRequired,
    getEntityData: PropTypes.func,
    isModal: PropTypes.bool,
    isReactionsEnabled: PropTypes.bool,
    isRolesTransitionFeatureFlagEnabled: PropTypes.bool,
    message: propTypes.message,
    openMessageActionMenu: PropTypes.func.isRequired,
    precededByDateSeparator: PropTypes.bool,
    precededByOtherMessage: PropTypes.bool,
    selectMessage: PropTypes.func.isRequired,
    selectedMessages: PropTypes.object.isRequired,
    shouldTransition: PropTypes.bool,
    showEscalation: PropTypes.bool,
    standalone: PropTypes.bool,
    unselectMessage: PropTypes.func.isRequired,
    isKeywordSearchModeOn: PropTypes.bool.isRequired,
    selectedMessageId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isModal: false,
    shouldTransition: false,
    showEscalation: true,
    standalone: false,
  };

  state = {
    didTransition: !this.props.shouldTransition,
    isDropdownHovered: false,
    isHovered: false,
    accessibilityMode: false,
  };

  constructor(props) {
    super(props);
    this.messageBubbleRef = React.createRef();
    this.hoverRef = null;
    this.selectMessageRef = null;
    this.dropdownRef = null;
  }

  _shouldTransition = false;

  componentDidMount() {
    const { message, findUser } = this.props;
    this._updateTransition();

    if (message.sender.$placeholder) {
      findUser(message.senderId, message.senderOrganizationId);
    }

    this.focusDropdownOutHandler = () => {
      const activeEl = document.activeElement;
      if (activeEl !== this.messageBubble && !this.hoverRef.contains(activeEl)) {
        if (this.dropdownRef) {
          this.dropdownRef.tabIndex = -1;
          this.dropdownRef?.classList.remove('tc-MessageDropdown--flex-display');
        }
      }
    };

    this.focusInHandler = () => {
      this.dropdownRef = this.hoverRef?.querySelector(
        '.tc-MessageDropdown__show-onhover-actions-button'
      );
      if (this.dropdownRef) {
        this.dropdownRef.classList.add('tc-MessageDropdown--flex-display');
        this.dropdownRef.tabIndex = 0;
        this.dropdownRef.addEventListener('focusout', this.focusDropdownOutHandler);
      }
    };

    this.focusOutHandler = () => {
      if (
        this.messageBubble
          .getElementsByClassName('tc-MessageBubble__content-status ')[0]
          .getAttribute('tabindex') === '-1'
      ) {
        if (this.dropdownRef) {
          this.dropdownRef.tabIndex = -1;
          this.dropdownRef.classList.remove('tc-MessageDropdown--flex-display');
        }
      }
    };

    this.messageBubble?.addEventListener('focusin', this.focusInHandler);
    this.messageBubble?.addEventListener('focusout', this.focusOutHandler);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedMessageId !== this.props.message.serverId) return;
    const scrollProps = ['selectedMessageId', 'isLoadingConversation', 'showLoadingDots'];
    if (
      !isEqual(pick(this.props, scrollProps), pick(prevProps, scrollProps)) &&
      this.props.isKeywordSearchModeOn &&
      !this.props.isLoadingConversation &&
      !this.props.showLoadingDots
    ) {
      this.scrollToMessage();
    }
  }

  componentWillUnmount() {
    this.messageBubble?.removeEventListener('focusin', this.focusInHandler);
    this.messageBubble?.removeEventListener('focusout', this.focusOutHandler);
    this.dropdownRef?.removeEventListener('focusout', this.focusDropdownOutHandler);
  }

  _updateTransition = () => {
    if (this._shouldTransition && !this.state.didTransition) {
      this.setState({ didTransition: true });
    }
  };

  render() {
    const {
      currentActionMenuMessageId,
      currentMessageMultiSelectOption,
      getEntityData,
      isModal,
      isRolesTransitionFeatureFlagEnabled,
      isReactionsEnabled,
      message,
      precededByDateSeparator,
      precededByMessageGroup,
      precededByOtherMessage,
      selectedMessages,
      shouldTransition,
      showEscalation,
      standalone,
      openMessageActionMenu,
    } = this.props;
    const { didTransition, isDropdownHovered, isHovered } = this.state;
    const {
      counterParty,
      currentSenderRole,
      escalationExecution,
      id,
      isInjected,
      patientCareCard,
      sender = {},
      senderRole,
      shouldEscalate,
      subType,
    } = message;
    let { isOutgoing } = message;
    if (subType === MessageSubTypes.SYSTEM) {
      isOutgoing = false;
    }
    const { $entityType, displayName, groupType } = counterParty || {};
    const isSelected = !!selectedMessages[message.id];
    const isEscalated = !!escalationExecution || !!shouldEscalate;
    const isDropdownActive = isDropdownHovered || currentActionMenuMessageId === id;
    const isGroupConversation = $entityType === 'group' && groupType !== 'ROLE_P2P';
    const role = currentSenderRole
      ? currentSenderRole
      : sender && sender.isRoleBot
      ? sender.botRole
      : isRolesTransitionFeatureFlagEnabled && senderRole
      ? senderRole
      : null;
    const isPatientCareCard = patientCareCard.length > 0;
    const isSmsOptMessage =
      subType === MessageSubTypes.SMS_OPT_OUT_NOTIFICATION ||
      subType === MessageSubTypes.SMS_OPT_IN_NOTIFICATION;
    const isVwrBotMessage = subType === MessageSubTypes.CHATBOT_MESSAGE;
    const isVwrCallInvite = subType === MessageSubTypes.VIRTUAL_WAITING_ROOM_CALL_INVITE;
    const direction = isOutgoing && !isVwrCallInvite ? 'OUTGOING' : 'INCOMING';
    this._shouldTransition = shouldTransition && !didTransition;
    const messageItemClassName = classes({
      precededByDateSeparator,
      precededByOtherMessage,
      shouldTransition: this._shouldTransition,
      standalone,
      multiSelect: !isModal && !!currentMessageMultiSelectOption,
      isSelected: !isModal && !!currentMessageMultiSelectOption && isSelected,
      isSelectable: !isModal && !!currentMessageMultiSelectOption && isHovered,
      isKeywordMatch:
        this.props.isKeywordSearchModeOn &&
        this.props.message.serverId === this.props.selectedMessageId,
      precededByMessageGroup,
    });

    let avatarFragment, dropdownFragment;
    if (
      (!isSmsOptMessage &&
        isGroupConversation &&
        !isOutgoing &&
        !currentMessageMultiSelectOption &&
        subType !== MessageSubTypes.WORKFLOW_AUTOMATION_NOTIFICATION) ||
      (isGroupConversation &&
        isModal &&
        !isOutgoing &&
        subType !== MessageSubTypes.WORKFLOW_AUTOMATION_NOTIFICATION) ||
      isVwrBotMessage ||
      isVwrCallInvite
    ) {
      const avatar = role ? (
        <RoleAvatar
          ariaLabel={'Conversation Avatar ' + role.displayName}
          indicatorSize={'SMALL'}
          role={role}
          size={30}
          squareAvatar={false}
          showPresenceIndicator={true}
          presenceStatus={role.members?.[0]?.presenceStatus}
          userId={role.members?.[0]?.id}
        />
      ) : subType === MessageSubTypes.SYSTEM && groupType === 'INTRA_TEAM' ? (
        <TeamAvatar
          ariaLabel={'Conversation Avatar ' + sender.displayName}
          displayName={displayName}
          group={counterParty}
          size="fit"
        />
      ) : isVwrBotMessage || isVwrCallInvite ? (
        <SupportSvg />
      ) : (
        <UserAvatar
          ariaLabel={'Conversation Avatar ' + sender.displayName}
          user={sender}
          size="fit"
          showPresenceIndicator={true}
          indicatorSize={'SMALL'}
        />
      );
      avatarFragment = (
        <div
          className={classNames(
            classes('user-avatar-wrapper'),
            classes({ 'avatar-link': !isModal && !sender.removedFromOrg && !sender.isVisitor })
          )}
          onClick={sender.removedFromOrg || sender.isVisitor ? null : this._onDirectMessage}
        >
          {avatar}
        </div>
      );
    }

    if (
      !isInjected &&
      !isModal &&
      !isPatientCareCard &&
      !isSmsOptMessage &&
      !currentMessageMultiSelectOption &&
      !isVwrBotMessage &&
      !isVwrCallInvite
    ) {
      dropdownFragment = (
        <MessageDropdown
          message={message}
          openActionsMenu={this._openActionsMenu}
          closeActionsMenu={this._closeActionsMenu}
          onMouseEnter={this._setDropdownHovered(true)}
          onMouseLeave={this._setDropdownHovered(false)}
          relativeTo={this.messageBubble}
          selectMessageRef={this.selectMessageRef}
          accessibilityMode={this.state.accessibilityMode}
        />
      );
    }

    const multiSelectFragment = !isModal && !!currentMessageMultiSelectOption && (
      <div className={classNames(classes('multi-select-checkbox-container', { isHovered }))}>
        {isSelected ? (
          isHovered ? (
            <MultiSelectCheckboxCheckedHover />
          ) : (
            <MultiSelectCheckboxChecked />
          )
        ) : isHovered ? (
          <MultiSelectCheckboxEmptyHover />
        ) : (
          <MultiSelectCheckboxEmpty />
        )}
      </div>
    );

    let showIncomingMultiSelectCheckbox = false;
    let showOutgoingMultiSelectCheckbox = false;

    if (currentMessageMultiSelectOption) {
      if (direction === 'INCOMING') {
        if (currentMessageMultiSelectOption !== 'recall') {
          showIncomingMultiSelectCheckbox = true;
        }
        if (currentMessageMultiSelectOption === 'delete' && isEscalated) {
          showIncomingMultiSelectCheckbox = false;
        }
      }
      if (direction === 'OUTGOING') {
        if (currentMessageMultiSelectOption !== 'delete') {
          showOutgoingMultiSelectCheckbox = true;
        }
        if (currentMessageMultiSelectOption === 'recall' && isEscalated) {
          showOutgoingMultiSelectCheckbox = false;
        }
      }
    }

    const selectable = showOutgoingMultiSelectCheckbox || showIncomingMultiSelectCheckbox;

    const multiSelectHandlers = {};
    if (selectable) {
      multiSelectHandlers.onClick = this.handleMultiSelect;
      multiSelectHandlers.onMouseEnter = () => this.setState({ isHovered: true });
      multiSelectHandlers.onMouseLeave = () => this.setState({ isHovered: false });
    }

    return (
      <div
        className={messageItemClassName}
        {...multiSelectHandlers}
        ref={this._setSelectMessageRef}
        onKeyDown={(e) => {
          if (!selectable) return;
          this.handleMultiSelectKeyDown(e);
        }}
      >
        <div
          className={classNames(classes('hover-container'), classes({ direction }))}
          ref={this._setRef}
        >
          {showIncomingMultiSelectCheckbox && multiSelectFragment}
          {avatarFragment}
          <MessageBubble
            getEntityData={getEntityData}
            isDropdownActive={isDropdownActive}
            isEscalated={showEscalation ? isEscalated : false}
            isModal={isModal}
            message={message}
            multiSelectMode={isHovered}
            multiSelectable={selectable}
            setMessageBubble={this._setMessageBubble}
            openMessageActionMenu={openMessageActionMenu}
          />
          {dropdownFragment}
          {showOutgoingMultiSelectCheckbox && multiSelectFragment}
        </div>
        {isReactionsEnabled && (
          <>
            <MessageReactionSummary
              isGroup={isGroupConversation}
              messageId={message.id}
              reactions={message.reactions}
              direction={direction}
            />
          </>
        )}
        <ReduxEscapeHatch
          ref={(ref) => {
            this.reduxEscapeHatch = ref;
            if (
              this.reduxEscapeHatch &&
              this.reduxEscapeHatch?.accessibilityMode !== this.state.accessibilityMode
            ) {
              this.setState({ accessibilityMode: this.reduxEscapeHatch?.accessibilityMode });
            }
          }}
        />
      </div>
    );
  }

  handleMultiSelectKeyDown = (e) => {
    if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
      e.preventDefault();
      this.handleMultiSelect();
    }
    if (e.key === KEYMAP.TAB) {
      if (e.shiftKey) return;
      e.preventDefault();
      const cancelButton = document.querySelector('.tc-MessageForm__cancel-button');
      if (cancelButton) cancelButton.focus();
    }
  };

  handleMultiSelect = () => {
    const { message, selectMessage, selectedMessages, unselectMessage } = this.props;
    if (selectedMessages[message.id]) {
      unselectMessage(message);
    } else {
      selectMessage(message);
    }
  };

  _setSelectMessageRef = (ref) => {
    this.selectMessageRef = ref;
  };

  scrollToMessage = () => {
    setTimeout(() => {
      this.selectMessageRef?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 0);
  };

  _setRef = (ref) => {
    this.hoverRef = ref;
  };

  _closeActionsMenu = () => {
    const { closeMessageActionMenu } = this.props;

    closeMessageActionMenu();
  };

  _onDirectMessage = () => {
    const { composeToEntity, isModal, message } = this.props;
    const { currentSenderRole, sender, subType } = message;
    if (subType === MessageSubTypes.SYSTEM) return;
    if (!isModal) composeToEntity(currentSenderRole || sender);
  };

  _openActionsMenu = () => {
    const { message, openMessageActionMenu } = this.props;

    openMessageActionMenu(message);
    this._setDropdownHovered(false);
  };

  _setMessageBubble = (ref) => {
    this.messageBubble = ref;
  };

  _setDropdownHovered = (isDropdownHovered) => {
    return () => {
      this.setState({ isDropdownHovered });
    };
  };
}

const mapStateToProps = (state) => ({
  isKeywordSearchModeOn: state.keywordSearch.isKeywordSearchModeOn,
  selectedMessageId: state.keywordSearch.selectedMessageId,
});

export default connect(mapStateToProps)(
  mobxInjectSelect({
    composeMessageStore: ['composeToEntity'],
    conversationStore: ['isLoadingConversation'],
    entityStore: ['getEntityData'],
    messageStore: [
      'addReaction',
      'closeMessageActionMenu',
      'currentActionMenuMessageId',
      'selectMessage',
      'selectedMessages',
      'openMessageActionMenu',
      'unselectMessage',
    ],
    messengerStore: [
      'currentMessageMultiSelectOption',
      'isRolesTransitionFeatureFlagEnabled',
      'isReactionsEnabled',
    ],
    sessionStore: ['currentUserId'],
    userStore: ['findUser'],
  })(MessageItem)
);
