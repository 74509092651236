import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import queue from 'emitter-queue';
import EventEmitter from 'events';
import { get, map, set, sortBy, throttle } from 'lodash';
import uuid from 'uuid';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/sampleTime';
import * as Sentry from '@sentry/react';
import { debounce } from 'lodash';

import { FROZEN_EMPTY_ARRAY } from '../common/utils';
import { AppTypes, Timings } from '../models/enums';

const DELAY_LOAD_AFTER_LOGIN = 100;
const DELAY_VWR_TO_MESSENGER = 500;
const ORG_INJECT_THROTTLE = 1000;

export default class MessengerStore {
  @observable appResetter = () => {};
  @observable collabSelection = '';
  @observable conversationsLoaded = false;
  @observable currentAppSelected = 'Messages';
  @observable currentMessageMultiSelectOption = '';
  @observable currentOrganizationId = null;
  @observable generalStatus = null;
  @observable infoPaneRef = null;
  @observable isAdminOrgSelectorOpen = false;
  @observable isBroadcastListOpen = false;
  @observable isBuildInfoShown = false;
  @observable isCollaborationOpen = false;
  @observable isIframeOpen = false;
  @observable isLoading = false;
  @observable isLoadingPrintConversation = true;
  @observable isMessengerDisplayed = false;
  @observable isMessengerHidden = true;
  @observable isNewAdminOpen = false;
  @observable isPatientSettingsOpen = false;
  @observable isPatientUserSettingsOpen = false;
  @observable isPrintModeOpen = false;
  @observable isProfileOpen = false;
  @observable isSearching = false;
  @observable isStaticRender = true;
  @observable isSwitchingApp = false;
  @observable isVirtualWaitingRoomOpen = false;
  @observable isPatientDirectoryOpen = false;
  @observable newMessageAnnouncement = null;
  @observable organizationIds = [];
  @observable preloaded = null;
  @observable.shallow infoPane = null;
  @observable.shallow product = null;
  @observable.shallow quickReplies = {};

  events = queue(new EventEmitter());

  constructor({ client, entityStore, params, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.params = params;
    this.stores = stores;
    this.buildInfo = buildInfo;
    this.initialTitle = document.title;
  }

  mounted() {
    const { desktopAppStore, sessionStore } = this.stores;

    autorun(
      () => {
        const currentUser = sessionStore.currentUser;
        const totalUnreadCount =
          this.totalUnreadAlertsCount + this.totalUnreadCount + this.totalUnreadVWRCount;
        const titleParts = [];

        if (totalUnreadCount > 999) {
          titleParts.push('(1K+)');
        } else if (totalUnreadCount > 0) {
          titleParts.push(`(${totalUnreadCount})`);
        }

        titleParts.push(this.initialTitle);
        if (currentUser) titleParts.push(' -', currentUser.displayName);

        const newTitle = titleParts.join(' ');
        if (document.title !== newTitle) document.title = newTitle;

        desktopAppStore.setBadgeCount({ unreadCount: totalUnreadCount });
      },
      {
        delay: Timings.FAVICON_DELAY,
        onError(err) {
          console.error('error in MessengerStore.autorun', err);
        },
      }
    );

    this.entityStore.events.once('conversations:loading:stop', this._onConversationsLoaded);
    this.entityStore.events.on('afterInject', this._onAfterInject);
    this.entityStore.events.on('beforeEject', this._onBeforeEject);

    this.client.on('message', () => {
      if (this.condensedReplays) {
        const [product] = this.entityStore.sync(this.client.products.getAll());
        this.setProduct(product);
      }
    });

    sessionStore.events.on(
      'signedIn',
      action((user) => {
        this.preloadAll();

        // Give the preloaded calls time to get established before tying up the browser by
        // loading messenger UI
        setTimeout(() => {
          this.displayMessenger();
          sessionStore.setSignInLoading(false);
        }, DELAY_LOAD_AFTER_LOGIN);
      })
    );

    const handlePreferenceChangeSSE = (replies, network, organizationId) => {
      set(
        this.quickReplies,
        this.quickReplyKey(network, organizationId),
        replies.map((reply) => ({
          text: reply,
          id: uuid(),
        }))
      );
    };

    this.client.on('organization:preferences:change', ({ data, type }) => {
      const replies = get(data, 'network.patient.quickReplies');
      if (replies?.length) {
        handlePreferenceChangeSSE(replies, 'patient', data.organizationId);
      }
    });

    this.client.on('user:preferences:change', ({ data, type }) => {
      const replies = get(data, 'preferences.quickReplies');
      if (replies?.length) {
        handlePreferenceChangeSSE(replies, 'provider');
      }
    });

    this.client.models.Organization.on(
      'afterInject',
      throttle(() => this.setOrganizationIds(this.organizationIds), ORG_INJECT_THROTTLE)
    );

    this.client.on('message', this._handleMessageNotification());
  }

  @computed get isAppDarkModeSupported() {
    const { networkStore } = this.stores;
    const isSupportedPage =
      !this.isMessengerHidden ||
      this.isProfileOpen ||
      this.isPatientSettingsOpen ||
      this.isPatientUserSettingsOpen ||
      this.isCollaborationOpen ||
      this.isPatientDirectoryOpen ||
      this.isNewAdminOpen ||
      this.isPrintModeOpen ||
      this.isBroadcastListOpen;
    return isSupportedPage;
  }

  @action('MessengerStore.toggleBuildInfo') toggleBuildInfo = () => {
    this.isBuildInfoShown = !this.isBuildInfoShown;
  };

  @action('MessengerStore.setGeneralStatus') setGeneralStatus = (status = null) => {
    this.generalStatus = status;
  };

  @action('MessengerStore.connectEvents') connectEvents = () => {
    if (!this.client.events.eventSource) this.client.events.connect();
  };

  @action('MessengerStore.setIsMessengerHidden') setIsMessengerHidden = (isHidden) => {
    this.isMessengerHidden = isHidden;
  };

  @action('MessengerStore.preloadAll') preloadAll = async () => {
    this.isLoading = true;

    if (!this.preloaded) {
      const organizationsPromise = (async () => {
        try {
          const organizations = await this.client.organizations.findAll({
            filter: { onlyApproved: true },
          });

          const [product] = this.entityStore.sync(this.client.products.getAll());
          this.entityStore.sync(organizations);
          const organizationIds = organizations.map(({ id }) => id);

          await runInAction(async () => {
            this.setOrganizationIds(organizationIds);
            this.setProduct(product);
            // Send the first organizationId even if it may not be the true
            // sign in organization id so that we can send the representative
            // organization id to LaunchDarkly and potentially connect to
            // WebSocket event source before message replay has started.
            // LaunchDarkly will identify again with the true sign in
            // organization id if it is different than this one.
            await this.stores.featureStore.registerNewLDUserKey(organizationIds[0]);
          });
        } catch (err) {
          console.error(err);
        }
      })();
      const conversationsPromise = (async () => {
        try {
          this.events.emit('startRosterDownload');
          const conversations = await this.client.conversations.findAll();
          this.events.emit('finishRosterDownload', conversations);
          this.entityStore.sync(conversations);
        } catch (err) {
          console.error(err);
        }
      })();

      this.preloaded = Promise.all([
        this.client.users.findMe({ bypassCache: true }),
        conversationsPromise,
        organizationsPromise,
      ]);
    }

    await this.preloaded;

    this.connectEvents();
  };

  @action('MessengerStore.setSignInOrganization')
  setSignInOrganization = () => {
    const { trackerStore } = this.stores;
    const activeOrgs = [];
    const contactsOrgs = [];
    const inactiveOrgs = [];
    let currentOrganizationId = null;

    for (const organization of this.organizations) {
      const {
        $placeholder: isPlaceholder,
        isContacts,
        lastIncomingMessageSortNumber,
      } = organization;
      if (isPlaceholder) continue;

      if (isContacts) {
        contactsOrgs.push(organization);
      } else if (!lastIncomingMessageSortNumber) {
        inactiveOrgs.push(organization);
      } else {
        activeOrgs.push(organization);
      }
    }

    if (activeOrgs.length > 0) {
      const latestOrg = activeOrgs.reduce((latest, current) =>
        current.lastIncomingMessageSortNumber < latest.lastIncomingMessageSortNumber
          ? latest
          : current
      );
      currentOrganizationId = latestOrg.id;
    } else {
      const fallbackOrgs = [...inactiveOrgs, ...contactsOrgs];
      if (fallbackOrgs.length > 0) currentOrganizationId = fallbackOrgs[0].id;
    }

    trackerStore.logPendoAnalytics({
      pathName: 'Messages',
    });

    this.currentOrganizationId = currentOrganizationId;
    this.stores.featureStore.registerNewLDUserKey(currentOrganizationId, true);
    this.stores.roleStore.loadSavedRoleIds(currentOrganizationId);
    this.stores.teamStore.loadSavedTeamIds(currentOrganizationId);
    Sentry.setTag('organizationId', currentOrganizationId);

    return currentOrganizationId;
  };

  @action('MessengerStore.clearPreloaded')
  async clearPreloaded() {
    this.preloaded = null;
  }

  @action('MessengerStore.displayMessenger') displayMessenger = async () => {
    this.isMessengerDisplayed = true;
  };

  @action('MessengerStore.hideMessenger') hideMessenger = async () => {
    this.isMessengerDisplayed = false;
  };

  @action('MessengerStore.showMessageMultiSelect') showMessageMultiSelect = (type) => {
    const { messageStore } = this.stores;
    messageStore.clearSelectedMessages();
    this.currentMessageMultiSelectOption = type;
  };

  @action('MessengerStore.hideMessageMultiSelect')
  hideMessageMultiSelect = () => {
    const { messageStore } = this.stores;
    this.currentMessageMultiSelectOption = '';
    messageStore.clearSelectedMessages();
  };

  @action('MessengerStore.openAdminOrgSelectModal') openAdminOrgSelectModal = () => {
    this.isAdminOrgSelectorOpen = true;
  };

  @action('MessengerStore.closeAdminOrgSelectModal') closeAdminOrgSelectModal = () => {
    this.isAdminOrgSelectorOpen = false;
  };

  @action('MessengerStore.openPatientSettings') openPatientSettings = () => {
    const { patientAdminStore } = this.stores;
    const { isPatientAdmin } = this.getAdminRolesInOrganization();
    patientAdminStore.recalculateCurrentPatientAdminPage(
      this.currentOrganizationId,
      isPatientAdmin
    );

    this.isPatientSettingsOpen = true;
    this.currentAppSelected = AppTypes.PATIENT_SETTINGS;
  };

  @action('MessengerStore.openVirtualWaitingRoom') openVirtualWaitingRoom = () => {
    this.isVirtualWaitingRoomOpen = true;
    this.currentAppSelected = AppTypes.VIRTUAL_WAITING;
  };

  @action('MessengerStore.openPatientDirectory') openPatientDirectory = () => {
    this.isPatientDirectoryOpen = true;
    this.currentAppSelected = AppTypes.PATIENT_DIRECTORY;
  };

  @action('MessengerStore.openBroadcastList') openBroadcastList = () => {
    this.appResetter();
    this.isBroadcastListOpen = true;
    this.currentAppSelected = AppTypes.BROADCAST_LISTS;
  };

  @action('MessengerStore.openCollaboration') openCollaboration = (selection, entityId) => {
    this.prepForAppSwitch(AppTypes.COLLABORATION);
    this.appResetter();
    this.collabSelection = selection;
    this.collabSelectionId = entityId;
    this.isCollaborationOpen = true;
    this.currentAppSelected = AppTypes.COLLABORATION;
  };

  @action('MessengerStore.unsetCollabSelection') unsetCollabSelection = () => {
    this.collabSelection = undefined;
    this.collabSelectionId = undefined;
  };

  @action('MessengerStore.openAdmin') openNewAdmin = () => {
    this.appResetter();
    this.isNewAdminOpen = true;
    this.currentAppSelected = AppTypes.SETTINGS;
  };

  @action('MessengerStore.closeCollaboration') closeCollaboration = () => {
    this.isCollaborationOpen = false;
  };

  @action('MessengerStore.closeVirtualWaitingRoom') closeVirtualWaitingRoom = () => {
    this.isVirtualWaitingRoomOpen = false;
  };

  @action('MessengerStore.closePatientDirectory') closePatientDirectory = () => {
    this.isPatientDirectoryOpen = false;
  };

  @action('MessengerStore.closeVirtualWaitingRoom') closeNewAdmin = () => {
    this.isNewAdminOpen = false;
  };

  @action('MessengerStore.closePatientSettings') closePatientSettings = () => {
    this.isPatientSettingsOpen = false;
  };

  @action('MessengerStore.closeBroadcastList') closeBroadcastList = () => {
    this.isBroadcastListOpen = false;
  };

  @action('MessengerStore.loadAll') loadAll = async () => {
    await this.displayEulas();
    await this.preloadAll();

    runInAction(() => {
      this.clearPreloaded();
      this.isLoading = false;
      this.stores.userStore.findMyProfilesForAllOrganizations();
    });
  };

  async displayEulas() {
    const eulas = await this.stores.organizationStore.getPendingEulas();
    if (eulas.length) {
      this.stores.modalStore.openModal('eula', { eulas });
    }
  }

  @computed get organizations() {
    return this.organizationIds.map((id) => this.entityStore.organization.getById(id));
  }

  @computed get currentOrganization() {
    const id = this.currentOrganizationId;

    return id && this.organizationIds.includes(id)
      ? this.entityStore.organization.getById(id)
      : null;
  }

  @action('MessengerStore.setOrganizationIds') setOrganizationIds = (organizationIds) => {
    const organizations = organizationIds
      .map((id) => this.entityStore.organization.getById(id))
      .filter(Boolean);
    this.organizationIds = map(sortBy(organizations, 'name'), 'id');
  };

  @action('MessengerStore.setProduct') setProduct = (product) => {
    this.product = product;
  };

  @computed get activeProcessingEvents() {
    return this.product ? this.product.activeProcessingEvents : FROZEN_EMPTY_ARRAY;
  }

  @computed get condensedReplays() {
    return !!(this.client && this.client.config.condensedReplays);
  }

  @computed get shouldIgnoreProcessingEvents() {
    for (const event of this.activeProcessingEvents) {
      if (
        event.actionType === 'ORGANIZATION_MESSAGE_STATUS' ||
        event.actionType === 'CONVERSATION_MESSAGE_STATUS' ||
        event.actionType === 'GROUP_REPLAY'
      ) {
        return true;
      }
    }
    return false;
  }

  @computed get totalUnreadAlertsCount() {
    return this.product ? this.product.unreadAlertsCount : 0;
  }

  @computed get totalUnreadCount() {
    return this.conversationsLoaded && this.product ? this.product.unreadCount : 0;
  }

  @computed get totalUnreadPriorityCount() {
    return this.product ? this.product.unreadPriorityCount : 0;
  }

  @computed get totalUnreadVWRCount() {
    return this.product && this.product.unreadVWRCount ? this.product.unreadVWRCount : 0;
  }

  @computed get otherOrganizationsAlertsUnreadCount() {
    let total = 0;

    for (const organization of this.organizations) {
      if (organization !== this.currentOrganization) {
        total += organization.unreadAlertsCount;
      }
    }

    return total;
  }

  @computed get otherOrganizationsUnreadCount() {
    let total = 0;

    for (const organization of this.organizations) {
      if (organization !== this.currentOrganization) {
        total += organization.unreadCount;
      }
    }

    return total;
  }

  @computed get otherOrganizationsUnreadPriorityCount() {
    let total = 0;

    for (const organization of this.organizations) {
      if (organization !== this.currentOrganization) {
        total += organization.unreadPriorityCount;
      }
    }

    return total;
  }

  @computed get otherOrganizationsUnreadVWRCount() {
    let total = 0;

    for (const organization of this.organizations) {
      if (organization !== this.currentOrganization) {
        total += organization.virtualWaitingRoomUnreadCount;
      }
    }

    return total;
  }

  @computed get isInfoPaneOpen() {
    return !!this.infoPane;
  }

  @computed get showRoleBanner() {
    const { networkStore, roleStore } = this.stores;
    const { hasRoles } = roleStore;
    const { isProviderNetwork } = networkStore;

    return this.conversationsLoaded && !this.isLoading && hasRoles && isProviderNetwork;
  }

  @computed get isAccessibilityEnabled() {
    return (
      this.stores.featureStore.featureFlags?.ACCESSIBILITY ||
      this.params.allowAccessibility === 'true'
    );
  }

  @computed get isDisplayNameEnabled() {
    return (
      this.stores.featureStore.featureFlags?.USE_DISPLAY_NAME ||
      this.params.useDisplayName === 'true'
    );
  }

  @computed get isCommandBotEnabled() {
    return this.stores.featureStore.featureFlags?.COMMAND_BOT;
  }

  @computed get isGiphyBotEnabled() {
    return (
      this.stores.featureStore.featureFlags?.GIPHY_BOT ||
      this.params.allowGiphyBot === 'true' ||
      this.stores.localStore.expertMode
    );
  }

  @computed get isAvailabilityFeatureFlagEnabled() {
    return (
      this.stores.featureStore.featureFlags?.AVAILABILITY ||
      this.params.allowAvailability === 'true'
    );
  }

  @computed get isExtendedAutoForwardOptionsEnabled() {
    return (
      this.isAvailabilityFeatureFlagEnabled &&
      this.currentOrganization &&
      this.currentOrganization.dndAutoForwardEntities &&
      (this.stores.featureStore.featureFlags?.EXTEND_AUTO_FORWARD_OPTIONS ||
        this.params.extendAutoForwardOptions === 'true')
    );
  }

  @computed get isNoSearchResultsOnEmptyQueryEnabled() {
    return (
      this.stores.featureStore.featureFlags?.NO_SEARCH_RESULTS_ON_EMPTY_QUERY ||
      this.params.noSearchResultsOnEmptyQuery === 'true'
    );
  }

  @computed get isSukiEnabled() {
    return this.stores.featureStore.featureFlags?.ENABLE_SUKI || this.params.enableSuki === 'true';
  }

  @computed get isPresenceFeatureFlagEnabled() {
    return this.stores.featureStore.featureFlags?.PRESENCE || this.params.allowPresence === 'true';
  }

  @computed get isPresenceFeatureAllowed() {
    return this.isPresenceFeatureFlagEnabled || this.isAvailabilityFeatureFlagEnabled;
  }

  @computed get isEmailInviteFeatureFlagEnabled() {
    return (
      this.stores.featureStore.featureFlags?.emailInvite || this.params.allowEmailInvite === 'true'
    );
  }

  @computed get isDisableLinksFeatureFlagEnabled() {
    return (
      this.stores.featureStore.featureFlags?.DISABLE_LINK || this.params.allowDisableLink === 'true'
    );
  }

  @computed get isCsvPatientUploadFlagEnabled() {
    const flags = this.stores.featureStore.featureFlags || {};
    return flags['csv-patient-upload'] || this.params.allowCsvPatientUpload === 'true';
  }

  @computed get isAllowNewAdminFeatureFlagEnabled() {
    if (this.params.forceOldAdmin === 'true') return false;
    return (
      this.stores.featureStore.featureFlags?.allowNewAdmin || this.params.allowNewAdmin === 'true'
    );
  }

  @computed get isPersonaAdminFeatureFlagEnabled() {
    return (
      this.params.allowPersonaAdmin === 'true' ||
      this.stores.featureStore.featureFlags?.allowPersonaAdmin
    );
  }

  @computed get isCollapsibleRosterEnabled() {
    return (
      this.params.collapsibleRoster === 'true' ||
      this.stores.featureStore.featureFlags?.COLLAPSIBLE_ROSTER
    );
  }

  @computed get isMouseActivityLogoutEnabled() {
    return (
      this.stores.featureStore.featureFlags?.READ_MOUSE_ACTIVITY_LOGOUT ||
      this.params.readMouseActivityLogout === 'true'
    );
  }

  @computed get isMfaEnabled() {
    return this.stores.featureStore.featureFlags?.MFA || this.params.allowMfa === 'true';
  }

  @computed get isVWREnabledForCurrentOrganization() {
    return this.currentOrganization && this.currentOrganization.isPatientVirtualWaitingRoomEnabled;
  }

  @computed get isRolesTransitionFeatureFlagEnabled() {
    return this.stores.featureStore.featureFlags?.RTU || this.params.allowRTU === 'true';
  }

  @computed get isReactionsEnabled() {
    return this.currentOrganization && this.currentOrganization.emojiReactions;
  }

  @computed get isDarkModeFeatureFlagEnabled() {
    return this.stores.featureStore.featureFlags?.DARK_MODE || this.params.allowDarkMode === 'true';
  }

  @computed get isDarkModeOn() {
    return (
      this.isAppDarkModeSupported &&
      this.isDarkModeFeatureFlagEnabled &&
      this.stores.sessionStore.isDarkModeSwitchEnabled
    );
  }

  @computed get isBroadcastListSettingsAllowed() {
    if (this.currentOrganization?.isContacts) {
      return false;
    }
    return (
      this.stores.featureStore.featureFlags?.PROVIDER_BL_CD ||
      !this.currentOrganization?.hideNewPblUi
    );
  }

  @computed get isPatientContextAllowed() {
    return this.currentOrganization?.isPatientContextEnabled;
  }

  @computed get showCareTeamPatientProfile() {
    const { showCareTeamAssignments } = this.params;
    return (
      this.stores.featureStore.featureFlags?.CARE_TEAM_ASSIGNMENTS ||
      showCareTeamAssignments === 'true'
    );
  }

  @computed get shouldValidateSearchResults() {
    return this.stores.featureStore.featureFlags?.SHOULD_VALIDATE_SEARCH_RESULTS;
  }

  @computed get allowMTOrgFeatureFlagEnabled() {
    const { allowMTOrg } = this.params;
    return this.stores.featureStore.featureFlags?.MTORG || allowMTOrg === 'true';
  }

  @computed get isConversationExpirationFlagEnabled() {
    const { allowConversationExpiration } = this.params;
    return (
      this.stores.featureStore.featureFlags?.ALLOW_CONVERSATION_EXPIRATION ||
      allowConversationExpiration === 'true'
    );
  }

  @computed get isAlertLifespanFlagEnabled() {
    const { allowAlertLifespan } = this.params;
    return (
      this.stores.featureStore.featureFlags?.ALLOW_ALERT_LIFESPAN || allowAlertLifespan === 'true'
    );
  }

  @computed get isDoNotExpireFlagEnabled() {
    const { allowDoNotExpire } = this.params;
    return (
      this.stores.featureStore.featureFlags?.ALLOW_DO_NOT_EXPIRE || allowDoNotExpire === 'true'
    );
  }

  @computed get isConversationExpirationEnabled() {
    this.currentOrganization &&
      this.currentOrganization.conversationExpiration &&
      this.isConversationExpirationFlagEnabled;
  }

  @computed get isConversationDoNotExpireEnabled() {
    return (
      this.currentOrganization &&
      this.currentOrganization.preserveGroupConversation &&
      this.isDoNotExpireFlagEnabled
    );
  }

  @computed get isNewUserSettingsLayoutEnabled() {
    const { allowNewUserSettingsLayout } = this.params;
    return (
      (this.currentOrganization?.personaManagement && this.isPersonaAdminFeatureFlagEnabled) ||
      this.stores.featureStore.featureFlags?.NEW_USER_SETTINGS_LAYOUT ||
      allowNewUserSettingsLayout === 'true'
    );
  }

  @computed get allowMentionsFeatureFlagEnabled() {
    const { allowMentions } = this.params;
    return this.stores.featureStore.featureFlags?.CONVERSATION_MENTIONS || allowMentions === 'true';
  }

  @computed get allowTypedMentions() {
    const { allowTypedMentions } = this.params;
    return (
      this.isMentionsEnabled &&
      (this.stores.featureStore.featureFlags?.CONVERSATION_TYPED_MENTIONS ||
        allowTypedMentions === 'true')
    );
  }

  @computed get isMentionsEnabled() {
    return (
      !!this.currentOrganization &&
      !!this.currentOrganization.mentions &&
      this.allowMentionsFeatureFlagEnabled
    );
  }

  @computed get isWarnIEUsersFeatureFlagEnabled() {
    const { allowWarnIeUsers } = this.params;
    return this.stores.featureStore.featureFlags?.WARN_IE_USERS || allowWarnIeUsers === 'true';
  }

  @computed get isIeEOLFeatureFlagEnabled() {
    const { allowIeEOL } = this.params;

    return this.stores.featureStore.featureFlags?.IE_EOL || allowIeEOL === 'true';
  }

  @computed get allowMTOrg() {
    return this.allowMTOrgFeatureFlagEnabled && this.currentOrganization.mtorgEnabled;
  }

  @computed get allowProviderBroadcastListAdSync() {
    const { allowAdSync } = this.params;
    return (
      this.currentOrganization?.broadcastListAdSync &&
      (this.stores.featureStore.featureFlags['allow-ad-sync'] || allowAdSync === 'true')
    );
  }

  @computed get allowOneTimePasswordLogin() {
    const { allowOTP } = this.params;
    return this.stores.featureStore.featureFlags?.OTP_LOGIN || allowOTP === 'true';
  }

  @computed get allowPBXRoles() {
    const { allowPBXRoles } = this.params;
    const pbxFeatureFlagEnabled =
      this.stores.featureStore.featureFlags?.PBX_ROLES || allowPBXRoles === 'true';
    return pbxFeatureFlagEnabled && this.currentOrganization.featurePbxIntegration;
  }

  @computed get allowScimAdminSetting() {
    const { allowScimAdminSetting } = this.params;
    return (
      this.stores.featureStore.featureFlags?.ALLOW_SCIM_ADMIN_SETTING ||
      allowScimAdminSetting === 'true'
    );
  }

  @computed get showOtpSetup() {
    return this.allowOneTimePasswordLogin && this.currentOrganization?.otpAllowConfigure;
  }

  @computed get isUniversalTaggingAllowed() {
    return this.stores.featureStore.featureFlags?.UT || this.params.allowUT === 'true';
  }

  @computed get isGroupAlertsAllowed() {
    return this.stores.featureStore.featureFlags?.GROUP_ALERTS || this.params.allowGA === 'true';
  }

  @computed get isGroupAlertsVCAllowed() {
    return (
      this.stores.featureStore.featureFlags?.GROUP_ALERTS_VIEW_CONVERSATION ||
      this.params.allowGAVC === 'true'
    );
  }

  @computed get isBroadcastListAdmin() {
    return this.isProviderBLAdminInOrganization();
  }

  @computed get isRolesAdmin() {
    return this.isRolesAdminInOrganization();
  }

  @computed get allowRolesPerformance() {
    return (
      this.params.allowRolesPerformance === 'true' ||
      this.stores.featureStore.featureFlags?.ROLES_PERFORMANCE
    );
  }

  @computed get isNewConversationUIFeatureFlagEnabled() {
    const { allowNewConversationUI } = this.params;
    return (
      this.stores.featureStore.featureFlags?.NEW_CONVERSATION_UI ||
      allowNewConversationUI === 'true'
    );
  }

  @computed get isKeywordInboxSearchFeatureFlagEnabled() {
    const { allowKeywordInboxSearch } = this.params;
    return (
      this.stores.featureStore.featureFlags?.KEYWORD_INBOX_SEARCH ||
      allowKeywordInboxSearch === 'true'
    );
  }

  @action('MessengerStore.showInfoPane') showInfoPane = (infoPane) => {
    this.infoPane = infoPane;
  };

  @action('MessengerStore.closeInfoPane') closeInfoPane = () => {
    const { groupEditorStore } = this.stores;
    this.infoPane = null;

    if (groupEditorStore?.group) {
      groupEditorStore.closeGroupEditor();
    }
  };

  @action('MessengerStore.stopCurrentAction') stopCurrentAction = () => {
    const { composeMessageStore } = this.stores;

    if (this.isProfileOpen) {
      this.closeProfile();
    } else if (composeMessageStore.isComposing) {
      composeMessageStore.stopComposing();
    } else if (this.isSearching) {
      this.stopSearching();
    } else if (this.isPrintModeOpen) {
      this.closePrintMode();
    } else if (this.isInfoPaneOpen) {
      this.closeInfoPane();
    }
  };

  @action('MessengerStore.setCurrentOrganizationId')
  setCurrentOrganizationId = (organizationId) => {
    const { entityStore, trackerStore } = this.stores;

    if (this.currentOrganizationId !== organizationId) {
      trackerStore.logPendoAnalytics({
        account: {
          'Organization Name': entityStore.organization.getById(organizationId).name,
          organizationId,
        },
        pathName: 'Messenger',
      });
    }
    this.currentOrganizationId = organizationId;
    this.stores.featureStore.registerNewLDUserKey(organizationId);
    this.stores.sessionStore.resetAutoLogout();
    this.stores.roleStore.loadSavedRoleIds(organizationId);
    this.stores.teamStore.loadSavedTeamIds(organizationId);
    Sentry.setTag('organizationId', organizationId);
  };

  @action('MessengerStore.startSearching') startSearching = () => {
    this.closeProfile();
    this.closeAdminIframe();
    this.closeNewAdmin();
    this.isSearching = true;
    this.stores.composeMessageStore.stopComposing();
  };

  @action('MessengerStore.stopSearching') stopSearching = () => {
    const { conversationStore } = this.stores;
    conversationStore.patientFilteredConversations = [];
    conversationStore.patientFilteredEntity = null;
    conversationStore.setPatientFilteredConversations = false;
    this.isSearching = false;
  };

  @action('MessengerStore.enableClientRender') enableClientRender = () => {
    this.isStaticRender = false;
  };

  @action('MessengerStore.enableStaticRender') enableStaticRender = () => {
    this.isStaticRender = true;
  };

  @action('MessengerStore.closeProfile') closeProfile = () => {
    this.isProfileOpen = false;
    this.stores.userStore.closeMobileQRLogin();
  };

  @action('MessengerStore.openProfile') openProfile = () => {
    const { trackerStore } = this.stores;
    this.isProfileOpen = true;
    this.stores.sessionStore.resetAutoLogout();
    trackerStore.logPendoAnalytics({ pathName: 'Profile Settings' });
  };

  @action('MessengerStore.openPatientUserSettings')
  openPatientUserSettings = () => {
    const { trackerStore } = this.stores;
    this.isPatientUserSettingsOpen = true;
    trackerStore.logPendoAnalytics({ pathName: 'Patient Network User Settings' });
  };

  @action('MessengerStore.closePatientUserSettings')
  closePatientUserSettings = () => {
    this.isPatientUserSettingsOpen = false;
  };

  @action('MessengerStore.openAdminIframe') openAdminIframe = (option) => {
    this.isIframeOpen = true;
    this.currentAppSelected = option;
    this.events.emit('openAdminIframe');
  };

  @action('MessengerStore.openMessages') openMessages = () => {
    const { conversationStore, roleStore } = this.stores;

    this.prepForAppSwitch('Messages');

    this.closePatientUserSettings();
    this.currentAppSelected = AppTypes.MESSAGES;

    const { currentConversation } = conversationStore;

    if (currentConversation?.featureService === 'vwr') {
      runInAction(() => {
        this.isSwitchingApp = true;
      });
      setTimeout(() => {
        runInAction(() => {
          this.isSwitchingApp = false;
        });
      }, DELAY_VWR_TO_MESSENGER);
    }
  };

  @action('MessengerStore.closeAdminIframe') closeAdminIframe = () => {
    this.isIframeOpen = false;
  };

  @action('MessengerStore.openPrintMode') openPrintMode = async () => {
    const { conversationStore } = this.stores;
    const { currentConversationId, findConversation } = conversationStore;

    this.isLoadingPrintConversation = true;
    this.isPrintModeOpen = true;

    try {
      await this.client.conversations.selectConversation(currentConversationId, {
        anchorPoint: 'CONVERSATION_END',
        fetchAllItems: true,
        markAsRead: false,
        maxItemsPerBatch: 400, // RW-3664: ~3-4 second fetch time
        isConversationBeingSelectedForPrintMode: true,
      });
    } catch (inErr) {
      console.error(inErr);
    }

    const conversation = await findConversation(currentConversationId, { ensureEntities: false });
    this.stores.entityStore.syncOne(conversation);

    runInAction(() => {
      this.isLoadingPrintConversation = false;
      this.stores.sessionStore.resetAutoLogout();
    });

    window.pendo?.stopGuides?.();
  };

  @action('MessengerStore.closePrintMode') closePrintMode = () => {
    this.isPrintModeOpen = false;
    this.stores.sessionStore.resetAutoLogout();
    window.pendo?.startGuides?.();
  };

  @action('MessengerStore.showAlertConversation') showAlertConversation = (conversation) => {
    const { setFilter, setFilterBar } = this.stores.rosterStore;
    this.stores.modalStore.closeModal();
    this.switchApp('Messages');
    setFilter('Inbox');
    setFilterBar('Left');
    this.stores.conversationStore.selectConversation(conversation);
  };

  @action('MessengerStore.changeOrganizationFromUI')
  changeOrganizationFromUI = (organization) => {
    const {
      canAdminRoles,
      forumsEnabled,
      id: organizationId,
      rolesEnabled,
      showTeams,
    } = organization;
    const { changeIframeOrg } = this.stores.adminConsoleStore;
    const { changeReactIframeOrg } = this.stores.reactAdminStore;
    const { destroy, search } = this.stores.patientStore;
    const { selectedFilter, setFilter, setFilterBar } = this.stores.rosterStore;

    if (this.currentOrganizationId === organizationId) {
      return;
    }

    const { isPatientAdmin } = this.getAdminRolesInOrganization(organizationId);
    this.stores.composeMessageStore.isComposing = false;
    this.stores.networkStore.isProviderNetwork = true;
    this.setCurrentOrganizationId(organizationId);
    this.prepForAppSwitch();

    this.stores.trackerStore.compareUnreadMessages();

    if (selectedFilter === 'Forums') {
      if (!forumsEnabled) {
        setFilter('Inbox');
      } else {
        this.stores.groupStore.refreshForums();
      }
    } else if (selectedFilter === 'Teams') {
      if (!showTeams) {
        setFilter('Inbox');
      } else {
        this.stores.teamStore.refreshTeamsByOrg();
      }
    }

    if (this.isIframeOpen) {
      if (this.shouldChangeIframeOrg(organization)) {
        if (this.currentAppSelected === AppTypes.ANALYTICS) {
          changeReactIframeOrg(organization);
          this.openAdminIframe(AppTypes.ANALYTICS);
        } else if (this.currentAppSelected === AppTypes.ROLES) {
          if (canAdminRoles && rolesEnabled) {
            this.openAdminIframe(AppTypes.ROLES);
          }
          changeReactIframeOrg(organization);
        } else {
          changeIframeOrg(organizationId);
        }
      } else {
        this.currentAppSelected = AppTypes.MESSAGES;
        this.closeAdminIframe();
        setFilterBar('Left');
      }
    } else if (this.currentAppSelected === AppTypes.PATIENT_SETTINGS) {
      if (isPatientAdmin) {
        this.openPatientSettings();
        destroy();
        search();
      } else {
        this.closePatientSettings();
        this.currentAppSelected = AppTypes.MESSAGES;
      }
    } else if (this.currentAppSelected === AppTypes.ROLES && canAdminRoles && rolesEnabled) {
      this.openAdminIframe(AppTypes.ROLES);
    } else if (this.currentAppSelected === AppTypes.VIRTUAL_WAITING) {
      this.currentAppSelected = AppTypes.MESSAGES;
      this.openMessages();
    } else if (this.currentAppSelected === AppTypes.BROADCAST_LISTS) {
      if (!this.isBroadcastListSettingsAllowed) {
        this.currentAppSelected = AppTypes.MESSAGES;
        this.openMessages();
      } else {
        this.openBroadcastList();
      }
    } else if (this.currentAppSelected === AppTypes.COLLABORATION) {
      if ((canAdminRoles && rolesEnabled) || showTeams) {
        this.openCollaboration();
      } else {
        this.currentAppSelected = AppTypes.MESSAGES;
        this.openMessages();
      }
    } else if (this.currentAppSelected === AppTypes.PATIENT_DIRECTORY) {
      this.currentAppSelected = AppTypes.MESSAGES;
      this.openMessages();
    } else {
      setFilterBar('Left');
    }
  };

  @action('MessengerStore.switchApp') switchApp = (appName) => {
    const { rosterStore } = this.stores;

    this.prepForAppSwitch(appName);
    if (!this.isUniversalTaggingAllowed && appName === 'Roles') {
      this.openAdminIframe('Roles');
    } else if (appName === 'Messages') {
      this.openMessages();
      rosterStore.clearFilter();
    } else if (appName === 'Patient Settings') {
      this.openPatientSettings();
    } else if (appName === 'Virtual Waiting') {
      this.openVirtualWaitingRoom();
    } else if (appName === 'Broadcast Lists') {
      this.openBroadcastList();
    } else if (
      appName === 'Roles & Teams' ||
      (this.isUniversalTaggingAllowed && appName === 'Roles') ||
      appName === 'Teams'
    ) {
      this.openCollaboration();
    } else if (appName === 'Patient Directory') {
      this.openPatientDirectory();
    } else {
      if (this.isAllowNewAdminFeatureFlagEnabled && appName === 'Settings') {
        this.openNewAdmin();
      } else {
        this.openAdminIframe(appName);
      }
    }

    this._trackSwitchAppPendo(appName);
  };

  _trackSwitchAppPendo = (appName) => {
    const { networkStore, trackerStore } = this.stores;
    let pathName = '';

    if (networkStore.isProviderNetwork === false && appName === 'Messages') {
      appName = 'Patient Network Messenger';
    }

    if (appName === 'Patient Settings') {
      pathName = 'patientsList';
    } else if (appName === 'Settings') {
      pathName = 'Users';
    } else if (['Roles', 'Teams'].includes(appName)) {
      appName = 'Roles & Teams';
    } else if (appName === 'Analytics') {
      pathName = 'Trends';
    }

    trackerStore.logPendoAnalytics({ parentPathName: appName, pathName });
  };

  @action('MessengerStore.prepForAppSwitch') prepForAppSwitch = (anAppName) => {
    const { composeMessageStore } = this.stores;

    const appName = anAppName || this.currentAppSelected;

    if (appName !== 'Patient Settings') {
      this.closePatientSettings();
    }
    if (appName !== 'Virtual Waiting') {
      this.closeVirtualWaitingRoom();
    }
    if (appName !== 'Broadcast Lists') {
      this.closeBroadcastList();
    }
    if (appName !== 'Collaboration') {
      this.closeCollaboration();
    }
    if (appName !== 'Patient Directory') {
      this.closePatientDirectory();
    }
    if (appName !== 'Settings' && appName !== 'Analytics') {
      this.closeAdminIframe();
    }
    if (appName !== 'Settings' && this.isAllowNewAdminFeatureFlagEnabled) {
      this.closeNewAdmin();
    }
    this.hideMessageMultiSelect();
    this.stopSearching();
    composeMessageStore.stopComposing();
    this.closeInfoPane();
    this.closePrintMode();
    this.closeProfile();
    this.stores.sessionStore.resetAutoLogout();
  };

  @action('MessengerStore.quickReplyKey') quickReplyKey = (network, orgIdOverride) => {
    const {
      networkStore: { isProviderNetwork },
      messengerStore: { currentOrganizationId },
      conversationStore: { currentConversation },
    } = this.stores;
    const isVWRConversation =
      currentConversation &&
      currentConversation.featureService &&
      currentConversation.featureService === 'vwr';
    let isProviderContext = isProviderNetwork && !isVWRConversation;
    if (network) isProviderContext = network === 'provider';
    return isProviderContext ? 'provider' : `patient.${orgIdOverride || currentOrganizationId}`;
  };

  @action('MessengerStore.getPreferences') getPreferences = async () => {
    const key = this.quickReplyKey();
    const replies = get(this.quickReplies, key);
    if (!replies) await this.fetchPreferences();
    return get(this.quickReplies, key);
  };

  @action('MessengerStore.fetchPreferences') fetchPreferences = async () => {
    const {
      networkStore: { isProviderNetwork },
      messengerStore: { currentOrganizationId },
      conversationStore: { currentConversation },
    } = this.stores;

    const isVWRConversation =
      currentConversation &&
      currentConversation.featureService &&
      currentConversation.featureService === 'vwr';
    const apiSource = isProviderNetwork && !isVWRConversation ? 'users' : 'organizations';
    const sourceId =
      isProviderNetwork && !isVWRConversation ? this.client.currentUserId : currentOrganizationId;
    const response = await this.client[apiSource].getPreferences(sourceId);
    set(
      this.quickReplies,
      this.quickReplyKey(),
      get(
        response,
        `${isProviderNetwork && !isVWRConversation ? '' : 'network.patient.'}quick_replies`,
        []
      ).map((text) => ({
        id: uuid(),
        text,
      }))
    );
  };

  @action('MessengerStore.editPreferences') editPreferences = async (quickReplies) => {
    const {
      messengerStore: { currentOrganizationId },
      networkStore: { isProviderNetwork },
    } = this.stores;
    const apiSource = isProviderNetwork ? 'users' : 'organizations';
    await this.client[apiSource].editPreferences({
      organizationId: currentOrganizationId,
      quickReplies: quickReplies.map((qr) => qr.text),
      userId: this.client.currentUserId,
    });
    set(this.quickReplies, this.quickReplyKey(), quickReplies);
    return;
  };

  shouldChangeIframeOrg = (organization) => {
    const { canAdminRoles, id: organizationId, rolesEnabled } = organization;
    const {
      isAdmin = false,
      isAnalyticsAdmin = false,
      isTTPlusReportingAdmin = false,
    } = this.getAdminRolesInOrganization(organizationId);
    const supportsRolesApp = canAdminRoles && rolesEnabled;

    return !!(
      (this.currentAppSelected === AppTypes.SETTINGS && isAdmin) ||
      (this.currentAppSelected === AppTypes.ANALYTICS &&
        (isAnalyticsAdmin || isTTPlusReportingAdmin)) ||
      (this.currentAppSelected === AppTypes.ROLES && supportsRolesApp)
    );
  };

  /*
    Product Permissions Table (https://docs.google.com/spreadsheets/d/1VorXJjxL8eAdUjyueGrDFXze2CU689QjdDP3bsh42jk)
    Analytics: https://docs.google.com/spreadsheets/d/1tkqtx8RB4UBM-X6hv5BChbydMJKBg-fGdaQY20AVbjA/edit#gid=0
      Permission      Analytics         Settings            Roles
      tt_reporting    x
      tt_ldap                           x
      tt_admin        x                 x                   x
      tt_super_admin  x                 x                   x

    productRoles mapping:
      isAdmin:          tt_admin
      isAnalyticsAdmin: tt_reporting
      isLdapAdmin:      tt_ldap
      isSuperAdmin:     tt_superAdmin
  */

  isProductAnalyticsAdmin = () => {
    const product = this.product;
    if (!product) return false;

    const { productRoles } = product;
    if (!productRoles) return false;

    const { isAdmin = false, isAnalyticsAdmin = false, isSuperAdmin = false } = productRoles;
    return isAdmin || isAnalyticsAdmin || isSuperAdmin;
  };

  isProductRolesAdmin = () => {
    const product = this.product;
    if (!product) return false;

    const { productRoles } = product;
    if (!productRoles) return false;

    const { isAdmin = false, isSuperAdmin = false } = productRoles;
    return isAdmin || isSuperAdmin;
  };

  @action('MessengerStore.removeOrgTabsBaseOnProductRoles')
  removeOrgTabsBaseOnProductRoles = () => {
    const isSuperAndLdapAdminNotAdmin =
      this.isProductAdmin && !this.isProductLdapAdmin && !this.isProductSuperAdmin;
    return [
      isSuperAndLdapAdminNotAdmin && 'Authentication',
      isSuperAndLdapAdminNotAdmin && 'Custom Directories',
      (this.isProductAdmin || this.isProductLdapAdmin) && !this.isProductSuperAdmin && 'Delete',
    ];
  };

  @computed get isProductSettingsAdmin() {
    const product = this.product;
    if (!product) return false;

    const { productRoles } = product;
    if (!productRoles) return false;

    const { isAdmin = false, isLdapAdmin = false, isSuperAdmin = false } = productRoles;
    return isAdmin || isLdapAdmin || isSuperAdmin;
  }

  @computed get isProductSuperAdmin() {
    const product = this.product;
    if (!product) return false;

    const { productRoles } = product;
    if (!productRoles) return false;

    const { isSuperAdmin = false } = productRoles;
    return isSuperAdmin;
  }

  @computed get isScimAdmin() {
    const { isScimAdmin } = this.getAdminRolesInOrganization();
    return isScimAdmin;
  }

  @computed get isProductAdmin() {
    const product = this.product;
    if (!product) return false;

    const { productRoles } = product;
    if (!productRoles) return false;

    const { isAdmin = false } = productRoles;
    return isAdmin;
  }

  @computed get isProductLdapAdmin() {
    return this.product?.productRoles?.isLdapAdmin ?? false;
  }

  _getScreenReaderAnnouncement = (newMessageInfo) => {
    let newMessageAnnouncement = '';

    if (newMessageInfo?.groupName) {
      newMessageAnnouncement = `${newMessageInfo?.messageSender} sent a new message in ${newMessageInfo?.groupName}. `;
    } else {
      if (newMessageInfo?.messageSender) {
        newMessageAnnouncement = `${newMessageInfo?.messageSender} sent you a new message. `;
      }
    }

    const newMessagesCount = this.totalUnreadAlertsCount + this.totalUnreadCount;

    if (newMessagesCount > 0) {
      newMessageAnnouncement += `You have ${newMessagesCount} unread ${
        newMessagesCount > 1 ? 'messages.' : 'message.'
      }`;
    }
    return newMessageAnnouncement;
  };

  _handleMessageNotification = () => {
    const { downloadedMessagesProgressStore } = this.stores;

    return debounce(async (message) => {
      const { downloadFinished } = downloadedMessagesProgressStore;
      if (!downloadFinished || !message.isUnread) return;
      const { group, senderRole } = message;
      const displayName = senderRole
        ? message.senderRole?.displayName
        : message.sender?.displayName;
      if (!displayName || displayName === 'Loading...') return;
      let groupName = null;
      if (group?.groupType && group.groupType === 'GROUP') {
        groupName = group.displayName;
      }
      const newMessageAnnouncement = this._getScreenReaderAnnouncement({
        messageSender: displayName,
        ...(groupName !== null && { groupName }),
      });
      this.setNewMessageAnnouncement({ key: message.id, text: newMessageAnnouncement });
    }, 400);
  };

  @action('MessengerStore.setNewMessageAnnouncement') setNewMessageAnnouncement = (
    newMessageAnnouncement
  ) => {
    this.newMessageAnnouncement = newMessageAnnouncement;
  };

  isAnalyticsAdminInOrganization = (organization = this.currentOrganization) => {
    if (this.isProductAnalyticsAdmin()) return true;

    const { userRoles = {} } = organization || {};
    const {
      isAdmin = false,
      reportingEnabled = false,
      reportingEnabledOnAllOrgs = false,
    } = userRoles;
    return isAdmin || reportingEnabled || reportingEnabledOnAllOrgs;
  };

  isTTPlusReportingAdminInOrganization = (organization = this.currentOrganization) => {
    const { userRoles = {} } = organization || {};
    const { tigerTouchPlusReportingEnabled = false } = userRoles;
    return tigerTouchPlusReportingEnabled;
  };

  isPatientDataReportAnalyticsAdminInOrganization = (organization = this.currentOrganization) => {
    const { userRoles = {} } = organization || {};
    const { patientDataReportAnalyticsEnabled = false } = userRoles;
    return patientDataReportAnalyticsEnabled;
  };

  patientContextReporting = (organization = this.currentOrganization) => {
    const { userRoles = {} } = organization || {};
    const { patientContextReporting = false } = userRoles;
    return patientContextReporting;
  };

  patientDataAuditAdmin = (organization = this.currentOrganization) => {
    const { userRoles = {} } = organization || {};
    const { patientDataAuditAdmin = false } = userRoles;
    return patientDataAuditAdmin;
  };

  isVirtualWaitingRoomAdminInOrganization = (organization = this.currentOrganization) => {
    const { userRoles = {} } = organization || {};
    const { isVirtualWaitingRoomAdmin = false } = userRoles;
    return isVirtualWaitingRoomAdmin;
  };

  isRolesAdminInOrganization = (organization = this.currentOrganization) => {
    if (this.isProductRolesAdmin()) return true;

    const { canAdminRoles = false, userRoles = {} } = organization || {};
    const { isRoleAdmin = false } = userRoles;
    return canAdminRoles && isRoleAdmin;
  };

  isSettingsAdminInOrganization = (organization = this.currentOrganization) => {
    if (this.isProductSettingsAdmin) return true;

    const { userRoles = {} } = organization || {};
    const { isAdmin = false, isHelpDesk = false } = userRoles;
    return isAdmin || isHelpDesk;
  };

  isScimAdminInOrganization = (organization = this.currentOrganization) => {
    const { userRoles = {} } = organization || {};
    const { isScimAdmin = false } = userRoles;
    return isScimAdmin;
  };

  isTeamAdminInOrganization = (organization = this.currentOrganization) => {
    const { userRoles = {}, showTeams = false } = organization || {};
    const { isTeamAdmin = false } = userRoles;
    return isTeamAdmin && showTeams;
  };

  isPatientAdminInOrganization = (organization = this.currentOrganization) => {
    const { userRoles = {}, isPatientNetworkEnabled = false } = organization || {};
    const { isPatientAdmin = false } = userRoles;
    return isPatientAdmin && isPatientNetworkEnabled;
  };

  isProviderBLAdminInOrganization = (organization = this.currentOrganization) => {
    if (this.isProductRolesAdmin()) return true;

    const { userRoles = {} } = organization || {};
    const { isAdmin = false, isProviderBroadcastListAdmin = false } = userRoles;
    return isAdmin || isProviderBroadcastListAdmin;
  };

  isAnyAdminInOrganization = (organizationId = this.currentOrganizationId) => {
    const {
      isAdmin = false,
      isAnalyticsAdmin = false,
      isPatientAdmin = false,
      isRolesAdmin = false,
      isTeamAdmin = false,
    } = this.getAdminRolesInOrganization(organizationId);

    return isAdmin || isAnalyticsAdmin || isRolesAdmin || isPatientAdmin || isTeamAdmin;
  };

  getAdminRolesInOrganization = (organizationId = this.currentOrganizationId) => {
    const organization =
      organizationId && this.organizationIds.includes(organizationId)
        ? this.entityStore.organization.getById(organizationId)
        : {};

    if (organization.isContacts !== false) {
      return {};
    }

    return {
      isAdmin: this.isSettingsAdminInOrganization(organization),
      isAnalyticsAdmin: this.isAnalyticsAdminInOrganization(organization),
      isPatientAdmin: this.isPatientAdminInOrganization(organization),
      isRolesAdmin: this.isRolesAdminInOrganization(organization),
      isTeamAdmin: this.isTeamAdminInOrganization(organization),
      isScimAdmin: this.isScimAdminInOrganization(organization),
      isTTPlusReportingAdmin: this.isTTPlusReportingAdminInOrganization(organization),
      isPatientDataReportAnalyticsAdmin:
        this.isPatientDataReportAnalyticsAdminInOrganization(organization),
      isVirtualWaitingRoomAdmin: this.isVirtualWaitingRoomAdminInOrganization(organization),
      isProviderBroadcastListAdmin: this.isProviderBLAdminInOrganization(organization),
    };
  };

  goToMyRoles = () => {
    const { changeSelectedCategory, openMyRoles } = this.stores.reactAdminStore;

    if (this.currentAppSelected === AppTypes.ROLES) {
      openMyRoles();
    } else {
      changeSelectedCategory('myRoles');
      this.switchApp(AppTypes.ROLES);
    }
  };

  @action('MessengerStore._onConversationsLoaded') _onConversationsLoaded = async () => {
    await this.preloaded;

    if (!this.currentOrganizationId) {
      this.setSignInOrganization();
    }

    this.entityStore.events.once('showMessenger', () => {
      runInAction(() => {
        this.conversationsLoaded = true;
        this.stores.trackerStore.compareUnreadMessages();
      });
    });

    this.entityStore._queueOtherEvent('showMessenger');
  };

  @action('MessengerStore._onAfterInject') _onAfterInject = (entity) => {
    const { $entityType: entityType } = entity;

    if (entityType === 'userRoles' || entityType === 'productRoles') {
      const { organizationId } = entity;

      if (
        organizationId === this.currentOrganizationId &&
        ((this.currentAppSelected === AppTypes.SETTINGS && !this.isSettingsAdminInOrganization()) ||
          (this.currentAppSelected === AppTypes.ANALYTICS &&
            !this.isAnalyticsAdminInOrganization() &&
            !this.isTTPlusReportingAdminInOrganization()))
      ) {
        this.openMessages();
      }

      if (
        organizationId === this.currentOrganizationId &&
        this.currentAppSelected === AppTypes.PATIENT_SETTINGS
      ) {
        const {
          patientAdminStore: { reactToPermissionChange },
        } = this.stores;
        reactToPermissionChange(this.currentOrganizationId, this.isPatientAdminInOrganization());
      }
    }

    if (entityType === 'organization') {
      const {
        $placeholder: isPlaceholder,
        canAdminRoles,
        id: organizationId,
        pending,
        rolesEnabled,
      } = entity;

      if (!pending && !isPlaceholder && !this.organizationIds.includes(organizationId)) {
        // the user was added to an organization, or approved for that organization
        this.setOrganizationIds([...this.organizationIds.slice(), organizationId]);
      }

      if (
        organizationId === this.currentOrganizationId &&
        this.currentAppSelected === AppTypes.ROLES &&
        (!canAdminRoles || !rolesEnabled)
      ) {
        this.openMessages();
      }

      if (
        organizationId === this.currentOrganizationId &&
        this.currentAppSelected === AppTypes.PATIENT_SETTINGS
      ) {
        const {
          patientAdminStore: { reactToPermissionChange },
        } = this.stores;
        reactToPermissionChange(this.currentOrganizationId, this.isPatientAdminInOrganization());
      }

      if (
        organizationId === this.currentOrganizationId &&
        this.currentAppSelected === AppTypes.VIRTUAL_WAITING &&
        !this.currentOrganization.isPatientVirtualWaitingRoomEnabled
      ) {
        this.openMessages();
      }
    }
  };

  @action('MessengerStore._onBeforeEject') _onBeforeEject = (entity) => {
    const { composeMessageStore, conversationStore, desktopAppStore, messageStore } = this.stores;
    const { currentPreviewConversation } = composeMessageStore;
    const { currentConversation, currentConversationId } = conversationStore;
    const { $entityType: entityType, id: entityId } = entity;

    if (currentConversation) {
      const currentConversationHasAnEjectedEntity =
        (entityType === 'user' || entityType === 'group') &&
        currentConversation.counterPartyId === entityId;
      const currentConversationWasEjected =
        entityType === 'conversation' && currentConversationId === entityId;

      if (currentConversationHasAnEjectedEntity || currentConversationWasEjected) {
        composeMessageStore.stopComposing();
        conversationStore.setCurrentConversationId(null);
      }
    }

    if (currentPreviewConversation) {
      const previewConversationHasAnEjectedEntity =
        (entityType === 'user' || entityType === 'group') &&
        currentPreviewConversation.counterPartyId === entityId;
      const previewConversationWasEjected =
        entityType === 'conversation' && currentPreviewConversation.id === entityId;

      if (previewConversationHasAnEjectedEntity || previewConversationWasEjected) {
        composeMessageStore.stopComposing();
      }
    }

    if (entityType === 'organization') {
      if (entityId === this.currentOrganizationId) {
        this.openMessages();
      }
      this.setOrganizationIds(this.organizationIds.filter((id) => id !== entityId));
    }

    if (entityType === 'message') {
      if (messageStore.selectedMessages[entityId]) {
        messageStore.hideForwardModal();
        messageStore.clearSelectedMessages();
      }

      if (desktopAppStore._sendNotification.cancel) {
        desktopAppStore._sendNotification.cancel();
      }
      desktopAppStore.recallNotification(entityId);
    }
  };

  @action('MessengerStore.setInfoPaneRef') setInfoPaneRef = (ref) => {
    this.infoPaneRef = ref;
  };

  @action('MessengerStore.setAppResetter') setAppResetter = (fn) => {
    this.appResetter = fn;
  };

  @action('MessengerStore.sendRequest') sendRequest = (method, url, { data, headers } = {}) => {
    return this.client.httpClient[method](url, {
      data,
      headers,
    });
  };
}
