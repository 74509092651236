import React, { useCallback, useState } from 'react';
import {
  TemplateVariable,
  templateVariables,
} from '../../../../models/enums/MessageTemplateVariables';
import BEM from '../../../bem';
import { ReactComponent as Dropdown } from '../../../images/pa-dropdown.svg';
import { insertTextAtSelection } from '../../../utils/insertTextAtSelection';
import CollapsingSearchBar from '../../CollapsingSearchBar';
import AccessibleList from 'common/components/AccessibleList';
import { useAppSelector } from 'redux-stores';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('TemplateVariables');

type TemplateVariablesProps = {
  bodyEdited: boolean;
  bodyRef: React.RefObject<HTMLTextAreaElement>;
  isDeliveryMethodSms: boolean;
  setBodyEdited: (bodyEdited: boolean) => void;
};

const TemplateVariables: React.FC<TemplateVariablesProps> = ({
  bodyEdited,
  bodyRef,
  isDeliveryMethodSms,
  setBodyEdited,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [query, setQuery] = useState('');
  const [variables, setVariables] = useState<TemplateVariable[]>(templateVariables);
  const { accessibilityMode } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));

  const handleVariableSelect = (variable: TemplateVariable) => {
    const text = `#${variable.display}`;
    const txtarea = bodyRef.current;
    if (!txtarea) return;

    insertTextAtSelection(txtarea, text);
    !bodyEdited && setBodyEdited(true);

    let event;
    if (typeof Event === 'function') {
      event = new Event('input', { bubbles: true });
    } else {
      event = document.createEvent('Event');
      event.initEvent('input', true, true);
    }

    txtarea.dispatchEvent(event);
    txtarea.blur();
    txtarea.focus();
  };

  const handleQueryChange = useCallback((newQuery: string) => {
    setQuery(newQuery);

    if (!newQuery) {
      setVariables(templateVariables);
      return;
    }

    setIsExpanded(true);

    setVariables(
      templateVariables.filter(({ description, display }) => {
        const lowerQuery = newQuery.toLowerCase();
        const lowerDesc = description.toLowerCase();
        const lowerDisp = display.toLowerCase();

        return lowerDesc.includes(lowerQuery) || lowerDisp.includes(lowerQuery);
      })
    );
  }, []);

  return (
    <div className={classes()}>
      <div className={classes('header')}>
        <div className={classes('leftHeader')}>Library</div>
        <div className={classes('rightHeader')}>
          <div>
            <CollapsingSearchBar
              isAlwaysOpen
              handleQueryChange={handleQueryChange}
              onOpen={() => setIsExpanded(true)}
              query={query}
              shouldShowSearchIcon={!query}
              theme={'dynamic-templates'}
            />
          </div>
          <button
            className={classes('dropdown', { isExpanded })}
            onClick={() => setIsExpanded(!isExpanded)}
            onKeyDown={(e) => {
              if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
                e.preventDefault();
                setIsExpanded(!isExpanded);
              }
            }}
            aria-label={isExpanded ? 'collapse library dropdown' : 'expand library dropdown'}
          >
            <Dropdown aria-hidden />
          </button>
        </div>
      </div>
      {isExpanded && (
        <div className={classes('list', { isExpanded })}>
          <AccessibleList
            focusableClasses={'.tc-TemplateVariables__listItem'}
            accessibilityMode={accessibilityMode}
            setStartElementOnChange={true}
          >
            {variables
              .filter((variable) => !isDeliveryMethodSms || !variable.pii)
              .map((variable) => (
                <div
                  className={classes('listItem')}
                  key={variable.id}
                  onClick={() => handleVariableSelect(variable)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') handleVariableSelect(variable);
                  }}
                  role="button"
                >
                  <div className={classes('title')}>{variable.display}</div>
                  <div className={classes('description')}>{variable.description}</div>
                  <div className={classes('example')}>{variable.example}</div>
                </div>
              ))}
          </AccessibleList>
          {variables.length === 0 && (
            <div className={classes('noResults')}>No Results. Please try again.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default TemplateVariables;
