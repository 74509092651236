import React, { useState, useCallback, useRef, useEffect } from 'react';
import moment, { Moment } from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import BEM from '../../bem';
import { ReactComponent as DropdownChevronSvg } from '../../images/dropdown-chevron.svg';
import { isToday, mobxInjectSelect, scheduledMessageFormats } from '../../utils';
import { ContextMenu } from '../ContextMenu';
import { TimeInputSelect, TimezoneContextMenu } from '../ScheduleMessageModal';
import { useAppSelector } from 'redux-stores';
import { KEYMAP } from 'common/constants';

const { DATE_FORMAT_WITH_DAY, MAX_DAYS } = scheduledMessageFormats;

const classes = BEM.with('AppointmentDateTimeForm');

type AppointmentDateTimeFormProps = {
  isIhis?: boolean;
  isAppointmentInPast: boolean;
};

type MobxProps = {
  appointment: {
    selectedStartDate: Moment | null;
    selectedTime: Moment | null;
    selectedTimezone: string;
  };
  updateAppointment: (field: string, value: string | moment.Moment | null) => void;
  bulkUpdateAppointment: (update: Record<string, unknown>) => void;
};

function AppointmentDateTimeForm({
  appointment,
  bulkUpdateAppointment,
  isAppointmentInPast,
  isIhis = false,
  updateAppointment,
}: MobxProps & AppointmentDateTimeFormProps) {
  const { selectedStartDate, selectedTime, selectedTimezone } = appointment;
  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const timezoneMenuButtonRef = useRef(null);
  const { accessibilityMode } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));

  const isOutOfStartDateSelectorRange = ((day: Moment) => {
    const startDateLimit = moment().startOf('day');
    const endDateLimit = moment().add(MAX_DAYS, 'days').endOf('day');

    return day.isBefore(startDateLimit) || day.isAfter(endDateLimit);
  }) as React.ComponentProps<typeof SingleDatePicker>['isOutsideRange'];

  const setSelectedStartDate = useCallback(
    (startDate: moment.Moment) => {
      updateAppointment('selectedStartDate', startDate);

      if (selectedTime) {
        if (isToday(startDate) && moment.now() > selectedTime.valueOf()) {
          updateAppointment('selectedTime', null);
        } else {
          updateAppointment(
            'selectedTime',
            moment(selectedTime)
              .year(startDate.year())
              .month(startDate.month())
              .date(startDate.date())
          );
        }
      }
    },
    [selectedTime, updateAppointment]
  );

  const setSelectedTime = useCallback(
    (time: string | moment.Moment) => {
      updateAppointment('selectedTime', time);
    },
    [updateAppointment]
  );

  const setTimezone = useCallback(
    (timezone: string) => {
      const newStart: Moment = moment(selectedTime || selectedStartDate).utcOffset(
        moment(selectedTime || selectedStartDate)
          .tz(timezone)
          .utcOffset(),
        true
      );

      const update: {
        selectedStartDate?: Record<string, unknown> | moment.Moment;
        selectedTime?: Record<string, unknown> | null | moment.Moment;
        selectedTimezone: string;
      } = {
        selectedTimezone: timezone,
      };
      if (newStart.isValid()) {
        if (selectedStartDate) {
          update.selectedStartDate = newStart;
        }
        if (selectedTime) {
          update.selectedTime = newStart;
        }
      }
      bulkUpdateAppointment(update);
    },
    [bulkUpdateAppointment, selectedStartDate, selectedTime]
  );

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
      setDatePickerFocused(true);
    }
    if (e.key === KEYMAP.ESCAPE) {
      setDatePickerFocused(false);
    }
  };

  const handleKeyDownOnCalendar = (e: KeyboardEvent) => {
    if (e.key === KEYMAP.ESCAPE) {
      backFocusToCalendarInput();
      e.preventDefault();
    }
  };

  function handleOnBlur(event: FocusEvent, calendar: HTMLElement) {
    const target = event.relatedTarget as Node;
    if (!target) return;
    const isOutside = !calendar?.contains(target);
    if (isOutside) {
      setDatePickerFocused(false);
    }
  }

  const backFocusToCalendarInput = () => {
    if (accessibilityMode) {
      (document.querySelector('input[class*="DateInput_input"]') as HTMLElement).focus();
      setDatePickerFocused(false);
    }
  };

  useEffect(() => {
    const calendarSelector = document.querySelector('.DayPicker_focusRegion') as HTMLElement;
    const onBlurHandler = (event: FocusEvent) => {
      handleOnBlur(event, calendarSelector);
    };

    if (datePickerFocused) {
      calendarSelector?.addEventListener('keydown', handleKeyDownOnCalendar);
      calendarSelector?.addEventListener('focusout', onBlurHandler);
    }
    return () => {
      calendarSelector?.removeEventListener('keydown', handleKeyDownOnCalendar);
      calendarSelector?.removeEventListener('focusout', onBlurHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePickerFocused]);

  useEffect(() => {
    const calendarIconButton = document.querySelector(
      'button[class*="SingleDatePickerInput_calendarIcon"]'
    ) as HTMLElement;
    const calendarInput = document.querySelector('.DateInput_input') as HTMLElement;
    if (accessibilityMode) {
      if (calendarIconButton) {
        calendarIconButton.tabIndex = -1;
      }
      calendarInput?.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      calendarIconButton.tabIndex = 0;
    };
  }, [accessibilityMode]);

  return (
    <div className={classes()}>
      <div className={classes('date-time-timezone-section')}>
        <div className={classes('date-section')}>
          <div className={classes('inner-labels')}>Date</div>
          <SingleDatePicker
            customInputIcon={
              <DropdownChevronSvg
                className={classes('chevron-icon', { active: datePickerFocused })}
                onClick={(event) => {
                  if (datePickerFocused) {
                    event.stopPropagation();
                    setDatePickerFocused(false);
                  }
                }}
              />
            }
            date={selectedStartDate}
            displayFormat={DATE_FORMAT_WITH_DAY}
            focused={datePickerFocused}
            hideKeyboardShortcutsPanel
            id="start-date-picker"
            inputIconPosition="after"
            isOutsideRange={isOutOfStartDateSelectorRange}
            noBorder
            numberOfMonths={1}
            onDateChange={(date) => {
              if (!date) return;
              setSelectedStartDate(date);
              backFocusToCalendarInput();
              setDatePickerFocused(false);
            }}
            onFocusChange={({ focused }: { focused: boolean }) => {
              if (!accessibilityMode) {
                if (datePickerFocused !== focused) setDatePickerFocused(focused);
              }
            }}
            placeholder="Select Date"
            readOnly
            verticalSpacing={0}
          />
        </div>
        <div className={classes('time-section')}>
          <div className={classes('inner-labels', { timeLabel: true })}>Time</div>
          <TimeInputSelect
            isAppointmentInPast={isAppointmentInPast}
            selectedStartDate={selectedStartDate}
            selectedTime={selectedTime}
            setNextButtonDisabled={() => {}}
            setSelectedTime={setSelectedTime}
            timeBuffer={30}
            timezone={selectedTimezone}
            accessibilityMode={accessibilityMode}
          />
        </div>
        <div className={classes('timezone-section')}>
          <div className={classes('inner-labels')}>Timezone</div>
          <ContextMenu
            event="click"
            offsetY={6}
            position="bottominnerleft"
            relativeTo={timezoneMenuButtonRef.current}
            theme="vertical"
            accessibilityMode={accessibilityMode}
            menu={
              <TimezoneContextMenu
                onTimezoneSelected={(timezone) => {
                  setTimezone(timezone);
                  if (accessibilityMode && timezoneMenuButtonRef?.current) {
                    (timezoneMenuButtonRef?.current as HTMLElement).focus();
                  }
                }}
                selectedTime={selectedTime || selectedStartDate}
                buttonRef={timezoneMenuButtonRef}
                selectedTimezone={selectedTimezone}
                accessibilityMode={accessibilityMode}
              />
            }
          >
            <button
              className={classes('timezone-menu-btn', {
                placeholder: !selectedTimezone,
              })}
              disabled={isIhis}
              data-test-id={'timezone-menu-button'}
              ref={timezoneMenuButtonRef}
            >
              {selectedTimezone &&
                moment(selectedTime || selectedStartDate || {})
                  .tz(selectedTimezone)
                  .zoneAbbr()}
              <DropdownChevronSvg />
            </button>
          </ContextMenu>
        </div>
      </div>
    </div>
  );
}

export default mobxInjectSelect<AppointmentDateTimeFormProps, MobxProps>({
  appointmentsStore: ['appointment', 'bulkUpdateAppointment', 'updateAppointment'],
})(AppointmentDateTimeForm);
