import { action, makeObservable } from 'mobx';
import { Stores, TCClient } from '../types';

export default class PresenceStore {
  client: TCClient;
  userSubscriptions: { [userid: string]: number };
  stores: Stores;

  constructor({ client, stores }: { client: TCClient; stores: Stores }) {
    makeObservable(this);
    this.client = client;
    this.userSubscriptions = {};
    this.stores = stores;
  }

  mounted() {}

  @action('PresenceStore.subscribeUser') subscribeUser = (userid: string) => {
    const { messengerStore } = this.stores;
    if (!messengerStore.isPresenceFeatureAllowed) {
      return;
    }
    if (this.userSubscriptions[userid] === undefined) this.userSubscriptions[userid] = 0;
    this.userSubscriptions[userid]++;
    if (this.userSubscriptions[userid] === 1) {
      this.client.presence.addUser(userid);
    }
  };

  @action('PresenceStore.unsubscribeUser') unsubscribeUser = (userid: string) => {
    const { messengerStore } = this.stores;
    if (!messengerStore.isPresenceFeatureAllowed) {
      return;
    }
    if (this.userSubscriptions[userid] === undefined) return;
    this.userSubscriptions[userid]--;
    if (!this.userSubscriptions[userid]) {
      delete this.userSubscriptions[userid];
      this.client.presence.removeUser(userid);
    }
  };
}
