import { useEffect } from 'react';
import KeywordInputSearch from './KeywordInputSearch';
import KeywordSearchResultsDetails from './KeywordSearchResultsDetails';
import KeywordSearchFirstLevel from './KeywordSearchFirstLevel';
import BEM from 'common/bem';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';
import { ReactComponent as ArrowBackIcon } from 'common/images/arrow_back.svg';
import { DotsIndicator } from 'common/components';

const classes = BEM.with('KeywordSearchSideBar');

const {
  setKeywordSearchMode,
  setPreviousConversationId,
  setSelectedConversationId,
  setSelectedMessageId,
  setKeywordSearchTerm,
} = actions;

type MobxProps = {
  setCurrentConversationId: (id: string) => void;
};

const KeywordSearchSideBar = ({ setCurrentConversationId }: MobxProps) => {
  const dispatch = useAppDispatch();
  const { previousConversationId, selectedConversationId, isFetching, keywordSearchTerm } =
    useAppSelector((state) => state.keywordSearch);

  useEffect(
    function cleanUp() {
      return () => {
        dispatch(setKeywordSearchMode(false));
        dispatch(setPreviousConversationId(''));
        dispatch(setSelectedMessageId(''));
        dispatch(setSelectedConversationId(''));
        dispatch(setKeywordSearchTerm(''));
      };
    },
    [dispatch]
  );

  return (
    <div className={classes()}>
      <div className={classes('search-container')}>
        {selectedConversationId && (
          <ArrowBackIcon
            className={classes('arrow-back')}
            onClick={() => {
              dispatch(setSelectedConversationId(''));
            }}
          />
        )}
        <KeywordInputSearch isAConversationSelected={selectedConversationId !== ''} />
        {selectedConversationId === '' && (
          <p
            className={classes('label')}
            onClick={() => {
              dispatch(setKeywordSearchMode(false));
              setCurrentConversationId(previousConversationId);
            }}
          >
            CANCEL
          </p>
        )}
      </div>
      {isFetching && keywordSearchTerm && (
        <div className={classes('results-loader')}>
          <DotsIndicator className={classes('dots')} size={13} speed={0.5} />
        </div>
      )}
      <div style={{ position: 'relative', height: '100%', overflow: 'hidden' }}>
        <KeywordSearchFirstLevel />
        {selectedConversationId && <KeywordSearchResultsDetails />}
      </div>
    </div>
  );
};

export default mobxInjectSelect<null, MobxProps>({
  conversationStore: ['setCurrentConversationId'],
})(KeywordSearchSideBar);
