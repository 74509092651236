import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { pick } from 'ramda';
import propTypes from '../propTypes';
import BEM from '../bem';

const classes = BEM.with('UserName');

class UserName extends Component {
  static propTypes = {
    ariaLabel: PropTypes.string,
    botRole: propTypes.role,
    className: PropTypes.string,
    displayName: PropTypes.string,
    firstName: PropTypes.string,
    includeRoleOwner: PropTypes.bool,
    includeRoleTag: PropTypes.bool,
    isRoleBot: PropTypes.bool,
    lastName: PropTypes.string,
    onClick: PropTypes.func,
    roleName: PropTypes.string,
    isCurrentUser: PropTypes.bool,
  };

  static defaultProps = {
    isCurrentUser: false,
  };

  _handleKeyDown = (e) => {
    if (e.key === ' ' || e.key === 'Enter') {
      this.props.onClick();
    }
  };

  render() {
    const {
      ariaLabel,
      botRole,
      className,
      displayName,
      firstName,
      includeRoleOwner,
      includeRoleTag,
      isRoleBot,
      lastName,
      onClick,
      roleName,
      isCurrentUser,
    } = this.props;

    const firstAndLast = [firstName, lastName].filter(Boolean).join(' ');
    const title = [firstAndLast, displayName, roleName].filter(Boolean).join(' - ');
    const name = roleName && displayName ? `${roleName} (${displayName})` : roleName || displayName;
    const dataTestId = 'conversation-header-name';

    let nameFragment;
    if (includeRoleOwner && isRoleBot && botRole) {
      const roleMembers = botRole.members;

      let ownerName = 'No one is on duty';
      if (roleMembers.length > 0) {
        ownerName = roleMembers[0].displayName;
      }

      nameFragment = (
        <div className={classes()} data-test-id={dataTestId}>
          <div aria-label={ariaLabel} className={className} onClick={onClick} title={title}>
            {name}
          </div>
          {includeRoleTag && botRole.tag && (
            <div className={classes('tag-name')}>{botRole.tag.displayName}</div>
          )}
          <div
            className={classes('owner-name', {
              empty: roleMembers.length === 0,
            })}
          >
            {ownerName}
          </div>
        </div>
      );
    } else if (isCurrentUser) {
      nameFragment = (
        <div className={classes()} data-test-id={dataTestId}>
          <span aria-label={ariaLabel} className={className} onClick={onClick} title={title}>
            {name + ' (You)'}
          </span>
        </div>
      );
    } else {
      nameFragment = (
        <span
          aria-label={ariaLabel}
          className={classNames(classes(), className)}
          onClick={onClick}
          title={title}
          onKeyDown={this._handleKeyDown}
          data-test-id={dataTestId}
        >
          {name}
        </span>
      );
    }

    return nameFragment;
  }
}

const ObservedUserName = observer(UserName);
const ROLE_PROPS = ['$placeholder', 'displayName'];
const USER_PROPS = ['$placeholder', 'displayName', 'firstName', 'lastName'];
const GROUP_PROPS = ['$placeholder', 'displayName'];

class UserNameContainer extends Component {
  static propTypes = {
    role: PropTypes.shape({
      displayName: PropTypes.string,
    }),
    user: PropTypes.shape({
      botRole: propTypes.role,
      displayName: PropTypes.string,
      firstName: PropTypes.string,
      isRoleBot: PropTypes.bool,
      lastName: PropTypes.string,
    }),
    group: PropTypes.shape({
      displayName: PropTypes.string,
    }),
  };

  static defaultProps = {
    includeRoleOwner: false,
  };

  render() {
    const {
      ariaLabel,
      className,
      includeRoleOwner = false,
      includeRoleTag = false,
      isCurrentUser,
      onClick,
    } = this.props;
    let { role, user, group } = this.props;
    if (!role) role = {};
    if (!user) user = {};
    if (!group) group = {};

    let displayName, roleName;
    const { botRole, firstName, isRoleBot, lastName } = user;

    if (role.displayName && user.displayName) {
      roleName = `${role.displayName} (${user.displayName})`;
    } else if (role.displayName) {
      roleName = role.displayName;
    } else if (user.displayName) {
      displayName = user.displayName;
    } else if (group.displayName) {
      displayName = group.displayName;
    }

    this.roleFields = pick(ROLE_PROPS, role);
    this.userFields = pick(USER_PROPS, user);
    this.groupFields = pick(GROUP_PROPS, group);

    return (
      <ObservedUserName
        {...{
          ariaLabel,
          botRole,
          className,
          displayName,
          firstName,
          includeRoleOwner,
          includeRoleTag,
          isCurrentUser,
          isRoleBot,
          lastName,
          onClick,
          roleName,
        }}
      />
    );
  }
}

export default observer(UserNameContainer);
