import EventEmitter from 'events';
import { observable, action, runInAction, computed, makeObservable } from 'mobx';
import queue from 'emitter-queue';
import uuid from 'uuid';
import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  BasicQueryStringUtils,
  FetchRequestor,
  RedirectRequestHandler,
  TokenRequest,
} from '@openid/appauth/built';
import * as Sentry from '@sentry/react';

import { adjustQueryParams, phone } from '../common/utils';
import { DATitles, LoginErrors, SignInStep } from '../models/enums';
import { refreshParams } from '../params';

const CANARY_ENVS = ['prod-a', 'prod-c'];
const GRANT_TYPE_AUTHORIZATION_CODE = 'authorization_code';
const IDP_AUTH_REQUEST_RESPONSE_TYPE = 'code';
const IDP_AUTH_REQUEST_SCOPE = 'openid profile';
const LOGOUT_TIMEOUT = 30000;
const MAX_LOGIN_ATTEMPTS = 5;
const PROD_API = 'https://api.tigertext.me';
const REDIRECT_TO_CONSOLE_URL = 'https://home.tigertext.com?tt-rollback=true';
const SINGLE_SCREEN_SIGN_IN_PATHNAME = /login.html$/;

export const IDP_CLIENT_SECRETS = {
  PROD: '9TytAuziLbGqv5unBAOvBQAvYN27vcJT1LtU5U26TXw3X876KH5nS8Vzi5wTXtdAqiyv5O1BZUWbIGen5',
  UAT: 'tbTRXF15i9iuzc2Ttqeiu5Jrz7R8ZO0zje0RFj8ruzdXlbhTiRnP6mFdtfjKHJJOOMy93V7vVU8SayJ9Z',
  DEV: 'fvrV99zX3pp1L035jPKOSSFtcLtHyvJDc0vKxaOrOzAQKNh7b5u1djj0SoAXcEjtt5rUceDGussA74hHt',
};
export const IDP_OPEN_ID_ISSUER_URLS = {
  PROD: 'https://idp.tigerconnect.com',
  UAT: 'https://idp.uat.tigerconnect.com',
  DEV: 'https://idp.env7.tigerconnect.com',
};
export const PROD_API_ENVS = ['prod', 'production', ...CANARY_ENVS];
export const UAT_API_ENV = 'uat';

function getErrorCode(err) {
  return (err && (err.code || err.message)) || LoginErrors.UNKNOWN_ERROR;
}

class NonHashQueryStringUtils extends BasicQueryStringUtils {
  parse(input) {
    return super.parse(input, false);
  }
}

export default class SessionStore {
  @observable accessibilityMode = false;
  @observable allowServerSelection = false;
  @observable checkLoginError = null;
  @observable currentUserId = null;
  @observable accountsConnectedWithSamePhoneNumberErrMessage =
    'Oops! Something went wrong, please contact your administrator for further assistance.';
  @observable externalLogoutUrl = null;
  @observable forgotPasswordServer = null;
  @observable isCheckLoginLoading = false;
  @observable initialBaseUrl = null;
  @observable isIdpUser = false;
  @observable isPendoInitialize = false;
  @observable isSamlUser = false;
  @observable isSessionExpired = false;
  @observable isIdpSessionExpired = false;
  @observable isSignInLoading = false;
  @observable isSignOutLoading = false;
  @observable isSingleScreenSignIn = false;
  @observable loginAttemptsLeft = MAX_LOGIN_ATTEMPTS;
  @observable prefilledUserName = null;
  @observable processingMagicToken = false;
  @observable redirectToConsoleUrl = REDIRECT_TO_CONSOLE_URL;
  @observable serverLocation = null;
  @observable signInError = null;
  @observable signInStep = SignInStep.USERNAME;
  @observable signInUsername = null;
  @observable signOutError = null;
  @observable daNotConnectBadge = true;
  @observable.shallow locations = [];
  @observable isDarkModeSwitchEnabled = false;
  @observable showDebugUI = false;

  autoLogoutTimer = null;
  events = queue(new EventEmitter());
  idpClientConfig = null;
  idpServerConfig = null;
  openIdIssuerUrl = null;
  sessionStartTime = null;

  constructor({ client, entityStore, params, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
    this.params = params;

    this.forgotPasswordServer = this.client.forgotPasswordUrl;
  }

  mounted() {
    this.initialBaseUrl = this.client.config.baseUrl;
    const { localStore } = this.stores;
    this.client.on('events:disconnected', this._onDisconnected);
    this.client.on('events:connected', this._onConnected);
    this.client.on('signedIn', this._onSignedIn);
    this.client.on('signedOut', this._onSignedOut);

    if (SINGLE_SCREEN_SIGN_IN_PATHNAME.test(window.location.pathname)) {
      this.isSingleScreenSignIn = true;
      return;
    }

    if (this.params.user_name) {
      this.prefilledUserName = this.params.user_name;
    }

    const previousUsername = localStore.username;
    if (previousUsername) {
      this.setSignInStep(SignInStep.PASSWORD);
      this.setSignInUsername(previousUsername);
    } else {
      this.setSignInStep(SignInStep.USERNAME);
    }

    const country = localStore.country;
    if (country) {
      this.serverLocation = country;
      localStore.setClearDom(true);
    }

    this.idpClientConfig = this._getIdpClientConfiguration();
    this.openIdIssuerUrl = this._getOpenIdIssuerUrl();

    if (this.params.expiredSession === 'true') {
      this.updateQueryParams({ removeValues: ['expiredSession'] });
      this.setIdpSessionExpired(true);
    }

    this.isDarkModeSwitchEnabled = localStore.getStorageValue('isDarkMode') === true;
    this.showDebugUI = localStore.getStorageValue('showDebugUI') === true;
  }

  dispose() {
    this.client.removeListener('events:disconnected', this._onDisconnected);
    this.client.removeListener('signedIn', this._onSignedIn);
    this.client.removeListener('signedOut', this._onSignedOut);
  }

  @action('SessionStore.toggleDebugUI') toggleDebugUI = () => {
    this.showDebugUI = !this.showDebugUI;
    this.stores.localStore.setStorageValue('showDebugUI', this.showDebugUI);
  };

  @action('SessionStore.autoCheckLogin') autoCheckLogin = () => {
    const { code } = this.params;
    const userName = this.prefilledUserName;

    if (!code && !!userName) {
      this.checkLogin(userName);
    }
  };

  @action('SessionStore._onDisconnected') _onDisconnected = ({ isRecoverable }) => {
    if (!isRecoverable) {
      this.expireSession({ fromServer: true });
    }
  };

  @action('SessionStore._onConnected') _onConnected = () => {
    const { desktopAppStore, messengerStore } = this.stores;
    const { setBadgeCount, setIsDaDisconnect } = desktopAppStore;
    const { totalUnreadAlertsCount, totalUnreadCount, totalUnreadVWRCount } = messengerStore;

    setIsDaDisconnect(false);
    setBadgeCount({ unreadCount: totalUnreadAlertsCount + totalUnreadCount + totalUnreadVWRCount });
  };

  @action('SessionStore._onSignedIn') _onSignedIn = (sdkUser) => {
    const user = this.entityStore.syncOne(sdkUser);
    const { localStore } = this.stores;
    const { pendingLoginUserId, pendingLoginUsername } = localStore;

    this.setCurrentUserId(user.id);
    this.startAutoLogoutTimer();
    this.isSamlUser = user.extAuth;

    if (this.isSamlUser && user.persistUsername) {
      if (pendingLoginUserId === user.id) {
        localStore.setUsername(pendingLoginUsername);
      }
    } else if (this.signInUsername) {
      localStore.setUsername(this.signInUsername);
    }
    localStore.removePendingLoginUserId();
    localStore.removePendingLoginUsername();

    this.events.emit('signedIn', user);

    localStore.removeClearDom(true);
    this.updateQueryParams({ removeValues: ['country', 'magic_login', 'password', 'userId'] });
  };

  @action('SessionStore._onSignedOut') _onSignedOut = async ({
    fromServer = false,
    externalLogoutUrl,
  } = {}) => {
    Sentry.setUser({ id: null });

    const { localStore } = this.stores;
    if (externalLogoutUrl) {
      this.externalLogoutUrl = externalLogoutUrl;
    }

    if (fromServer) {
      try {
        await this.expireSession({ fromServer });
      } catch (error) {
        this.stores.trackerStore.send({
          flushImmediately: true,
          message: 'expireSession',
          payload: { expireSession: error?.message || 'UnknownExpireSessionError' },
        });
      }
      localStore.removeUsername();
    } else {
      this.setCurrentUserId(null);
      this.cancelAutoLogoutTimer();
    }

    this.events.emit('signedOut', { fromServer });
  };

  @action('SessionStore.expireSession') expireSession = async ({ fromServer = false } = {}) => {
    const { desktopAppStore, messengerStore } = this.stores;

    if (!this.isIdpUser) {
      this.setSessionExpired(true);
    }

    desktopAppStore.disconnectBadgeCount();
    this.setCurrentUserId(null);
    messengerStore.setProduct(null);
    this.cancelAutoLogoutTimer();

    this.events.emit('sessionExpired', { fromServer });

    if (this.isIdpUser) {
      this.setIdpSessionExpired(true);
      try {
        await this.performIdpEndSessionRequest();
      } catch (error) {
        this.stores.trackerStore.send({
          flushImmediately: true,
          message: 'performIdpEndSessionRequest',
          payload: {
            idpEndSessionError: { error: error?.message || 'UnknownIdpEndSessionError' },
          },
        });
      }
    }
  };

  @action('SessionStore.setAccessibilityMode') setAccessibilityMode = (value) => {
    this.accessibilityMode = value;
  };

  @action('SessionStore.signInWithMagicToken') signInWithMagicToken = async (magicLogin) => {
    const { localStore } = this.stores;
    let session;

    localStore.removeUsername();
    this.currentUserId = null;
    this.isSamlUser = true;
    this.isSignInLoading = true;
    this.setProcessingToken(true);
    this.signInError = null;

    this.events.emit('externalLogin', { magicLogin });

    try {
      const magicToken = JSON.parse(atob(magicLogin)).magic_token;
      session = await this.client.signInWithMagicToken(magicToken);
      this._setSentryUser(session);
    } catch (err) {
      console.error(err);

      runInAction(() => {
        localStore.setClearDom(true);
        this.updateQueryParams({ removeValues: ['country', 'magic_login', 'password', 'userId'] });
        this.setSignInStep(SignInStep.USERNAME);
        this.checkLoginError = LoginErrors.MAGIC_LOGIN_FAILED;
        this.isSamlUser = false;
        this.isSignInLoading = false;
        this.setProcessingToken(false);
        this.events.emit('signInError', this.checkLoginError);
      });
    }

    runInAction(() => {
      this.setSignInUsername(null);
    });

    return session;
  };

  @computed get authValues() {
    const { key, secret } = this.client.getAuth();
    const basicToken = btoa(key + ':' + secret);
    const bearerToken = this.idpClientConfig.jwtTokenHint;
    return { basicToken, bearerToken };
  }

  @action('SessionStore.signInWithJwtToken') signInWithJwtToken = async (code) => {
    const { localStore } = this.stores;
    let session;

    localStore.removeUsername();
    this.currentUserId = null;
    this.isIdpUser = true;
    this.isSignInLoading = true;
    this.signInError = null;
    this.setProcessingToken(true);

    try {
      if (!this.idpServerConfig) {
        this.idpServerConfig = await this._getIdpConfiguration();
      }

      if (!this.idpServerConfig) {
        throw new Error('Unknown server configuration');
      }

      let codeVerifier;
      const { clientId, clientSecret, redirectUri } = this.idpClientConfig;
      const authorizationHandler = new RedirectRequestHandler(
        undefined,
        new NonHashQueryStringUtils()
      );
      const notifier = new AuthorizationNotifier();

      authorizationHandler.setAuthorizationNotifier(notifier);
      notifier.setAuthorizationListener((request, _, error) => {
        if (error) {
          console.error(error);
          return;
        }

        codeVerifier = request?.internal?.code_verifier;
      });

      await authorizationHandler.completeAuthorizationRequestIfPossible();

      if (code && codeVerifier) {
        const request = new TokenRequest({
          client_id: clientId,
          code,
          extras: {
            client_secret: clientSecret,
            code_verifier: codeVerifier,
          },
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          redirect_uri: redirectUri,
        });

        const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
        const { accessToken, idToken } = await tokenHandler.performTokenRequest(
          this.idpServerConfig,
          request
        );

        this.idpClientConfig.jwtTokenHint = idToken;
        session = await this.client.signInWithJwtToken(accessToken);
        this._setSentryUser(session);
      } else {
        throw new Error('Missing code or code_verifier');
      }
    } catch (err) {
      console.error(err);

      runInAction(() => {
        localStore.setClearDom(true);
        this.setSignInStep(SignInStep.USERNAME);
        this.isIdpUser = false;
        this.isSignInLoading = false;
        this.checkLoginError = LoginErrors.UNKNOWN_ERROR;
        this.events.emit('signInError', this.checkLoginError);
        this.setProcessingToken(false);
      });
    }

    runInAction(() => {
      const stateParams = this.convertStateParams();
      this.updateQueryParams({
        removeValues: ['code', 'featureBranch', 'scope', 'session_state', 'state'],
        addValues: stateParams,
      });
      this.setSignInUsername(null);
    });

    return session;
  };

  convertStateParams = () => {
    const IGNORE_STATE_PARAMS = { baseUrl: true, featureBranch: true };
    const params = [];
    let stateParams = {};

    if (this.params && this.params.state) {
      try {
        stateParams = JSON.parse(this.params.state);
      } catch (e) {
        console.error('failed to parse state query params');
      }
    }

    for (const [key, value] of Object.entries(stateParams)) {
      if (!(key in this.params) && !IGNORE_STATE_PARAMS[key]) {
        params.push(`${key}=${value}`);
      }
    }

    return params;
  };

  updateQueryParams = ({ removeValues = [], addValues = [] } = {}) => {
    const changes = { remove: removeValues, add: addValues };
    const queryStr = adjustQueryParams(window.location.search, changes);

    // Workaround for Firefox redirect issue (https://bugzilla.mozilla.org/show_bug.cgi?id=1422334)
    // eslint-disable-next-line no-self-assign
    window.location.hash = window.location.hash;

    window.history.replaceState(
      null,
      null,
      queryStr.length > 0 ? `?${queryStr}` : window.location.pathname
    );

    refreshParams();
  };

  @action('SessionStore.setCurrentUserId') setCurrentUserId = (userId) => {
    this.currentUserId = userId;
  };

  // Used for QA purposes only
  @action('SessionStore.setRedirectToConsoleUrl') setRedirectToConsoleUrl = (url) => {
    this.redirectToConsoleUrl = url;
  };

  @action('SessionStore.setSessionExpired') setSessionExpired = (val) => {
    this.isSessionExpired = val;
    this.stores.desktopAppStore.setIsDaDisconnect(true);
  };

  @action('SessionStore.setIdpSessionExpired') setIdpSessionExpired = (val) => {
    this.isIdpSessionExpired = val;
    this.stores.desktopAppStore.setIsDaDisconnect(true);
  };

  @action('SessionStore.setSignInLoading') setSignInLoading = (val) => {
    this.isSignInLoading = val;
  };

  @action('SessionStore.setSignOutLoading') setSignOutLoading = (val) => {
    this.isSignOutLoading = val;
  };

  @action('SessionStore.setSignInStep')
  setSignInStep = (signInStep) => {
    const { localStore, trackerStore } = this.stores;
    this.signInStep = signInStep;
    this.events.queue('setSignInStep', signInStep);

    if (!this.isPendoInitialize) {
      const pathName = signInStep === SignInStep.USERNAME ? 'Login Username' : 'Login Password';
      trackerStore.logPendoAnalytics({
        initalizePendo: true,
        pathName,
      });
      this.isPendoInitialize = true;
    }

    if (signInStep === SignInStep.USERNAME) {
      trackerStore.logPendoAnalytics({ pathName: 'Login Username' });
      this.isCheckLoginLoading = false;
      this.updateQueryParams({ removeValues: ['country', 'magic_login', 'password', 'userId'] });
      this.signInError = null;
      this.serverLocation = null;

      if (this.initialBaseUrl !== this.client.config.baseUrl) {
        this.client.configure({ baseUrl: this.initialBaseUrl });
      }
      localStore.removeAutoLoginUserId();
      localStore.removeUsername();
      localStore.removeCountry();
    } else if (signInStep === SignInStep.PASSWORD) {
      trackerStore.logPendoAnalytics({ pathName: 'Login Password' });
    }
  };

  @action('SessionStore.setSignInUsername') setSignInUsername = (username) => {
    if (username && phone.isPhone(username)) {
      username = phone.formatPhone(username);
    }

    this.signInUsername = username;
  };

  @computed get isSignedIn() {
    return !!this.currentUserId;
  }

  @computed get currentUser() {
    return this.currentUserId ? this.entityStore.getById('user', this.currentUserId) : null;
  }

  @computed get currentUserDnd() {
    return !!this.currentUser?.dnd;
  }

  @action('SessionStore.signIn') signIn = async (username, password, { udid } = {}) => {
    username = username ? username.trim() : null;
    this.currentUserId = null;
    this.isSignInLoading = true;
    this.signInError = null;
    this.setSignInUsername(username);

    if (!username) {
      this.isSignInLoading = false;
      this.signInError = LoginErrors.USERNAME_MISSING;
      this.events.emit('signInError', this.signInError);
      return;
    }

    if (!password) {
      this.isSignInLoading = false;
      this.signInError = LoginErrors.PASSWORD_MISSING;
      this.events.emit('signInError', this.signInError);
      return;
    }

    try {
      const session = await this.client.signIn(username, password, {
        udid,
        refreshUser: false,
        ...(this.stores.launchStore?.jwt
          ? {
              jwt: this.stores.launchStore?.jwt,
            }
          : {}),
      });
      this._setSentryUser(session);
      // setSignInLoading(false) is called by MessengerStore after data preload has started
      return session;
    } catch (err) {
      const { loginAttemptsLeft = null } = err;
      console.error(err);

      runInAction(() => {
        this.isSignInLoading = false;
        this.loginAttemptsLeft = err.status === 429 ? 0 : loginAttemptsLeft;
        this.signInError = getErrorCode(err);
        this.events.emit('signInError', this.signInError);
      });
      return;
    }
  };

  @action('SessionStore.checkLogin') checkLogin = async (
    username,
    { checkOnly = false, country = null, fromReload = false, returnErrors = false } = {}
  ) => {
    const { localStore } = this.stores;

    username = username ? username.trim() : null;
    this.checkLoginError = null;
    this.isCheckLoginLoading = true;

    if (!username) {
      this.checkLoginError = LoginErrors.USERNAME_MISSING;
      this.isCheckLoginLoading = false;
      this.events.emit('signInError', this.checkLoginError);
      return false;
    }

    let userExists = false;

    try {
      const data = await this.client.users.checkLogin(username, {
        udid: uuid(),
      });
      const locations = data
        .map(({ base_url: baseUrl, name: country, users, idp_enabled: isIdpEnabled }) => ({
          baseUrl,
          country,
          users,
          isIdpEnabled,
        }))
        .filter(({ users }) => users?.length > 0);
      const isIdpUser = data && data.length > 0 && data.filter((env) => env.idp_enabled).length > 0;

      if (isIdpUser && localStore.username === username) {
        localStore.removeUsername();
        this.setSignInUsername(null);
        this.setSignInStep(SignInStep.USERNAME);
        return false;
      }

      if (locations.length === 1 && locations[0].country === 'USA' && isIdpUser) {
        const { base_url: baseUrl } = data[0];

        await this.handleIdpLogin(username, { baseUrl });
        return true;
      }

      userExists = data.some((env) => env.users.length > 0);

      if (userExists) {
        const isExternalLogin = this.handleExternalLogin({
          envs: data,
          username,
        });

        if (isExternalLogin) return true;
      }

      runInAction(() => {
        this.locations = locations;

        if (
          this.locations.length === 1 &&
          this.locations[0].baseUrl !== this.client.config.baseUrl
        ) {
          this.selectLocation(this.locations[0]);
          return false;
        } else if (this.locations.length > 1) {
          this.setPreviousLocation();
        }
      });
    } catch (err) {
      console.error(err);
      const errorCode = getErrorCode(err);
      runInAction(() => {
        if (err.status === 400) {
          this.checkLoginError = LoginErrors.ACCOUNTS_CONNECTED_WITH_SAME_PHONE_NUMBER;
          if (err.text) this.accountsConnectedWithSamePhoneNumberErrMessage = err.text;
        } else if (this.isCheckLoginLoading) {
          this.checkLoginError = errorCode;
        }
        this.isCheckLoginLoading = false;
      });
      if (returnErrors) {
        return errorCode;
      } else {
        return false;
      }
    }

    if (!checkOnly && this.isCheckLoginLoading) {
      runInAction(() => {
        this.isCheckLoginLoading = false;

        if (!userExists) {
          if (fromReload) {
            this.setSignInStep(SignInStep.USERNAME);
          } else {
            this.checkLoginError = LoginErrors.USER_NOT_FOUND;
          }
        } else {
          this.setSignInUsername(username);

          if (!fromReload && !country && this.locations.length > 1) {
            this.allowServerSelection = true;
          } else {
            if (country) {
              const { localStore } = this.stores;
              this.serverLocation = country;
              localStore.setCountry(country);
            }
            this.forgotPasswordServer = this.client.forgotPasswordUrl;
            this.allowServerSelection = false;
            this.setSignInStep(SignInStep.PASSWORD);
          }
        }
      });
    }

    if (!userExists) {
      this.events.emit('signInError', this.checkLoginError);
    }

    return userExists;
  };

  setPreviousLocation = () => {
    const { localStore } = this.stores;
    const country = localStore.country;

    if (country) {
      const prevLocation = this.locations.find((location) => location.country === country);

      if (prevLocation) {
        this.selectLocation(prevLocation);
      }
    }
  };

  handleExternalLogin = ({ envs, username }) => {
    const { localStore } = this.stores;

    for (const { users } of envs) {
      // we only request one user, so only one user can ever be returned
      const user = users[0];

      if (user.preferred_web_client === 'web_console') {
        this._setWindowLocation(
          `${this.redirectToConsoleUrl}&username=${encodeURIComponent(username)}`
        );
        return true;
      }

      for (const { method, url } of user.auth) {
        if (method === 'external') {
          this.events.emit('externalRedirect', { url });
          localStore.setPendingLoginUserId(user.token);
          localStore.setPendingLoginUsername(username);
          localStore.removeAutoLoginUserId();
          localStore.removeUsername();
          this._setWindowLocation(url);

          return true;
        }
      }
    }

    return false;
  };

  _getIdpClientConfiguration = () => {
    const { apiEnv, localMessengerUrl } = this.client.config;
    const { hostname, pathname } = window.location;

    let clientId = `web-${apiEnv}`;
    let clientSecret = IDP_CLIENT_SECRETS.DEV;
    let redirectUri = `https://${hostname}${pathname}`.replace('index.html', '');

    if (PROD_API_ENVS.includes(apiEnv)) {
      clientId = 'web-prod';
      clientSecret = IDP_CLIENT_SECRETS.PROD;
    } else if (apiEnv === UAT_API_ENV) {
      clientSecret = IDP_CLIENT_SECRETS.UAT;
    }

    if (localMessengerUrl) {
      redirectUri = `${localMessengerUrl}?apiEnv=${apiEnv}`;
    } else if (pathname.includes('/feature/')) {
      redirectUri = 'https://login.tigerconnect.xyz/app/messenger/';
    }

    return {
      clientId,
      clientSecret,
      postLogoutRedirectUri: redirectUri,
      redirectUri: redirectUri,
    };
  };

  _getOpenIdIssuerUrl = () => {
    const { apiEnv } = this.client.config;

    if (PROD_API_ENVS.includes(apiEnv)) {
      return IDP_OPEN_ID_ISSUER_URLS.PROD;
    } else if (apiEnv === UAT_API_ENV) {
      return IDP_OPEN_ID_ISSUER_URLS.UAT;
    }

    return IDP_OPEN_ID_ISSUER_URLS.DEV;
  };

  _getIdpConfiguration = async () => {
    let configuration;

    try {
      configuration = await AuthorizationServiceConfiguration.fetchFromIssuer(
        this.openIdIssuerUrl,
        new FetchRequestor()
      );
    } catch (err) {
      console.error(err);
      this.checkLoginError = LoginErrors.UNKNOWN_ERROR;
      this.events.emit('signInError', this.checkLoginError);
      return;
    }

    return configuration;
  };

  _setSentryUser = (session) => {
    const { user = {} } = session || {};
    if (user?.id) Sentry.setUser({ id: user.id });
  };

  handleIdpLogin = async (username, { baseUrl } = {}) => {
    if (!this.idpServerConfig) {
      this.idpServerConfig = await this._getIdpConfiguration();
    }

    if (!this.idpServerConfig) {
      console.error('Unknown service configuration');
      this.checkLoginError = LoginErrors.UNKNOWN_ERROR;
      this.events.emit('signInError', this.checkLoginError);
      return;
    }

    if (baseUrl) {
      this.params.baseUrl = baseUrl;
    }

    try {
      const authorizationHandler = new RedirectRequestHandler();
      const { clientId, redirectUri } = this.idpClientConfig;

      const request = new AuthorizationRequest({
        client_id: clientId,
        extras: { login_hint: username },
        redirect_uri: redirectUri,
        response_type: IDP_AUTH_REQUEST_RESPONSE_TYPE,
        scope: IDP_AUTH_REQUEST_SCOPE,
        state: JSON.stringify(this.params),
      });

      if (this.stores.desktopAppStore.setDATitle) {
        this.stores.desktopAppStore.setDATitle(DATitles.PASSWORD);
      }
      authorizationHandler.performAuthorizationRequest(this.idpServerConfig, request);
    } catch (err) {
      console.error(err);
      this.checkLoginError = LoginErrors.UNKNOWN_ERROR;
      this.events.emit('signInError', this.checkLoginError);
      if (this.stores.desktopAppStore.setDATitle) {
        this.stores.desktopAppStore.setDATitle(DATitles.LOGIN);
      }
      return;
    }
  };

  _setWindowLocation = (url) => {
    window.location = url;
  };

  @action('SessionStore.authenticate') authenticate = async (key, secret) => {
    this.isSignInLoading = true;
    this.currentUserId = null;
    this.signInError = null;

    try {
      const user = await this.client.authenticate(key, secret);
      // setSignInLoading(false) is called by MessengerStore after data preload has started
      // setCurrentUser is called from 'signedIn' event
      return user;
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.isSignInLoading = false;
        this.signInError = getErrorCode(err);
      });
      return;
    }
  };

  @action('SessionStore.signOut') signOut = async ({
    ignoreErrors = true,
    returnErrors = false,
    wipeSession = true,
    idpEndSession = true,
  } = {}) => {
    const { localStore, trackerStore } = this.stores;
    const expireTimeout = setTimeout(this.expireSession, LOGOUT_TIMEOUT);

    this.isSignOutLoading = true;
    this.setSignInUsername(null);
    trackerStore.clearPendoSession();
    this.signOutError = null;

    if (wipeSession) {
      localStore.removeAutoLoginUserId();
      localStore.removeUsername();
      localStore.removeCountry();

      if (this.stores.desktopAppStore.isDesktopApp) {
        await this.stores.desktopAppStore.signOut();
      }
    }

    try {
      const user = await this.client.signOut({ clearStore: false });
      if (this.isIdpUser && idpEndSession) {
        await this.performIdpEndSessionRequest();
      }

      runInAction(() => {
        this.isSignOutLoading = false;
        clearTimeout(expireTimeout);
      });

      return user;
    } catch (err) {
      console.error(err);
      const signOutError = getErrorCode(err);

      runInAction(() => {
        this.isSignOutLoading = false;
        this.signOutError = signOutError;
        if (ignoreErrors) {
          this.currentUserId = null;
        }
        clearTimeout(expireTimeout);
      });

      if (returnErrors) {
        return signOutError;
      } else {
        return;
      }
    }
  };

  performIdpEndSessionRequest = async () => {
    const { localMessengerUrl } = this.client.config;
    let postLogoutRedirectUri;

    if (!this.idpServerConfig) {
      this.idpServerConfig = await this._getIdpConfiguration();
    }

    if (!this.idpServerConfig || !this.idpClientConfig.jwtTokenHint) {
      console.error('Unknown service configuration');

      const isEmptyIdpServerConfig = Boolean(this.idpServerConfig);
      const isEmptyJwtTokenHint = Boolean(this.idpClientConfig.jwtTokenHint);

      this.stores.trackerStore.send({
        flushImmediately: true,
        message: 'performIdpEndSessionRequest',
        payload: { idpEndSessionError: { isEmptyIdpServerConfig, isEmptyJwtTokenHint } },
      });

      return;
    }

    if (localMessengerUrl && this.isIdpSessionExpired) {
      postLogoutRedirectUri = `${this.idpClientConfig.postLogoutRedirectUri}&expiredSession=true`;
    } else if (this.isIdpSessionExpired) {
      postLogoutRedirectUri = `${this.idpClientConfig.postLogoutRedirectUri}?expiredSession=true`;
    } else {
      postLogoutRedirectUri = this.idpClientConfig.postLogoutRedirectUri;
    }

    setTimeout(() => {
      this._setWindowLocation(
        `${this.idpServerConfig.endSessionEndpoint}?id_token_hint=${encodeURIComponent(
          this.idpClientConfig.jwtTokenHint
        )}&post_logout_redirect_uri=${encodeURIComponent(postLogoutRedirectUri)}`
      );
    }, 0);
  };

  @action('SessionStore.signOutAllDevices') signOutAllDevices = async () => {
    const expireTimeout = setTimeout(this.expireSession, LOGOUT_TIMEOUT);
    const { localStore } = this.stores;
    localStore.removeAutoLoginUserId();
    localStore.removeUsername();
    localStore.removeCountry();

    try {
      await this.client.signOutAllDevices();
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.currentUserId = null;
        this.isSignOutLoading = false;
        this.signOutError = getErrorCode(err);
      });
    }

    clearTimeout(expireTimeout);
  };

  @action('SessionStore.startAutoLogoutTimer') startAutoLogoutTimer = () => {
    if (this.autoLogoutTimer) this.cancelAutoLogoutTimer();
    this.resetAutoLogout();
    this.autoLogoutTimer = setTimeout(this._checkAutoLogoutTimer, 1000);
  };

  @action('SessionStore.resetAutoLogout') resetAutoLogout = () => {
    const now = new Date();
    this.sessionStartTime = now;
  };

  _checkAutoLogoutTimer = () => {
    const { callStore, desktopAppStore, messengerStore } = this.stores;
    const { isDesktopApp } = desktopAppStore;
    const { organizations } = messengerStore;
    const now = new Date();
    let smallestLogoutMinutes;
    let smallestExtendedSession;

    for (const organization of organizations) {
      const { autoLogoutMinutes, desktopExtendedSession, isContacts } = organization;
      if (isContacts) continue;
      if (
        autoLogoutMinutes &&
        (smallestLogoutMinutes === undefined || autoLogoutMinutes < smallestLogoutMinutes)
      ) {
        smallestLogoutMinutes = autoLogoutMinutes;
      }
      if (
        desktopExtendedSession &&
        desktopExtendedSession > 0 &&
        (smallestExtendedSession === undefined || desktopExtendedSession < smallestExtendedSession)
      ) {
        smallestExtendedSession = desktopExtendedSession;
      }
    }

    let logoutMinutes = smallestLogoutMinutes;
    if (isDesktopApp && smallestExtendedSession && smallestExtendedSession > 0) {
      // smallestExtendedSession is an integer value representing days
      logoutMinutes = smallestExtendedSession * 24 * 60;
    }

    if (!logoutMinutes || callStore.currentCall) {
      this.resetAutoLogout();
    } else if (now - this.sessionStartTime > logoutMinutes * 1000 * 60) {
      this.expireSession();
      return;
    }

    this.autoLogoutTimer = setTimeout(this._checkAutoLogoutTimer, 1000 * 60);
  };

  @action('SessionStore.cancelAutoLogoutTimer') cancelAutoLogoutTimer = () => {
    if (!this.autoLogoutTimer) return;
    clearTimeout(this.autoLogoutTimer);
    this.autoLogoutTimer = null;
  };

  @action('SessionStore.selectLocation') selectLocation = async (selectedLocation) => {
    const { apiEnv: currentApiEnv, baseUrl: currentBaseUrl } = this.client.config;
    const { baseUrl: selectedBaseUrl, country, isIdpEnabled } = selectedLocation;
    const isCanary = selectedBaseUrl === PROD_API && CANARY_ENVS.includes(currentApiEnv);
    const { localStore } = this.stores;

    if (!isCanary) {
      const apiMatch = selectedBaseUrl.match(/api-(.*)\.tigertext\./);
      const selectedApiEnv = apiMatch && apiMatch.length > 0 ? apiMatch[1] : currentApiEnv;

      if (selectedBaseUrl !== currentBaseUrl) {
        this.client.configure({ baseUrl: selectedBaseUrl });
      }

      if (selectedApiEnv !== currentApiEnv) {
        this.client.configure({ apiEnv: selectedApiEnv });
      }
    }

    this.serverLocation = country;
    localStore.setCountry(country);

    if (country === 'USA' && isIdpEnabled) {
      await this.handleIdpLogin(this.signInUsername, { baseUrl: selectedBaseUrl });
    } else {
      this.allowServerSelection = false;
      this.setSignInStep(SignInStep.PASSWORD);
    }
  };

  @action('SessionStore.setProcessingToken') setProcessingToken = (isProcessing) => {
    this.processingMagicToken = isProcessing;
  };

  @action('SessionStore.relayError') relayError = ({ error, info }) => {
    this.events.emit('errorCaught', { error, info });
  };

  @action('SessionStore.setDarkMode') setDarkMode = async (isDarkModeSwitchEnabled = false) => {
    const { localStore } = this.stores;
    localStore.setStorageValue('isDarkMode', isDarkModeSwitchEnabled);
    this.isDarkModeSwitchEnabled = localStore.getStorageValue('isDarkMode');
  };
}
