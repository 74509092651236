import { useCallback } from 'react';
import { ReactComponent as MentionsSvg } from '../../images/mentions.svg';
import BEM from '../../bem';
import { actions, useAppDispatch } from '../../../redux-stores';
import { Conversation, Group, Role, User } from '../../../types';
import { mobxInjectSelect } from '../../utils';

const classes = BEM.with('MentionsButton');

const { setModal, setMessageBodyInputFocus } = actions;

type MentionsButtonProps = {
  onClick: (user: User | Role) => {};
};

type MobxProps = {
  currentConversation: Conversation;
  ensureForumMembers: (group: Group) => Promise<void>;
  isComposing: boolean;
};

const MentionsButton = ({
  currentConversation,
  ensureForumMembers,
  isComposing,
  onClick,
}: MentionsButtonProps & MobxProps) => {
  const dispatch = useAppDispatch();

  const onButtonClick = useCallback(() => {
    if (isComposing) dispatch(setMessageBodyInputFocus(true));

    if (currentConversation && currentConversation.counterParty?.groupType === 'FORUM') {
      ensureForumMembers(currentConversation.counterParty);
    }

    dispatch(setModal({ name: 'mentionMember', data: { onClick } }));
  }, [currentConversation, dispatch, ensureForumMembers, isComposing, onClick]);

  return (
    <button className={classes()} data-test-id={'mentions-member-button'} onClick={onButtonClick}>
      <MentionsSvg className={classes('image')} />
    </button>
  );
};

export default mobxInjectSelect<MentionsButtonProps, MobxProps>({
  composeMessageStore: ['isComposing'],
  conversationStore: ['currentConversation'],
  groupStore: ['ensureForumMembers'],
})(MentionsButton);
