import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from 'common/utils';
import propTypes from 'common/propTypes';
import { MessageForm } from 'common/components/MessageForm';

function NewConvMessageForm({
  currentOrganization,
  currentUser,
  clearNewMessagesIndicator,
  conversationOrigin,
  currentOrganizationId,
  currentPreviewConversation,
  getRoleById,
  isCreateGroupModeOn,
  isProviderNetwork,
  isTeamActivation,
  markConversationAsRead,
  newGroupName,
  selectedRecipients,
  senderId,
  sendMessageToConversation,
  sendMessageToDistributionList,
  sendMessageToGroupOfUsers,
  sendMessageToPatientGroup,
  sendMessageToRole,
  sendMessageToUser,
  accessibilityMode,
}) {
  const { selectedPatientReference } = useSelector(
    ({ patientContext: { selectedPatientReference } }) => ({
      selectedPatientReference,
    })
  );

  if (!currentOrganization) return null;

  const allowedSenders = isProviderNetwork ? currentOrganization.allowedSenders : [currentUser];
  const allowAttachments = isProviderNetwork ? true : currentOrganization.isPFAttachmentAllowed;
  let alwaysEscalate = false;
  if (
    selectedRecipients &&
    selectedRecipients.length === 1 &&
    selectedRecipients[0].$entityType === 'role'
  ) {
    const { escalationPolicy } = selectedRecipients[0];
    if (escalationPolicy) alwaysEscalate = escalationPolicy.alwaysEscalate;
  }

  async function _sendNewMessage(
    body,
    { attachmentFiles, escalate, membersMentionInConversation, priority } = {}
  ) {
    if (!senderId) return;
    const recipientUserIds = selectedRecipients.map((user) => user.id);
    const sendOptions = {
      attachmentFiles,
      escalate,
      groupName: newGroupName || undefined,
      isTeamActivation,
      membersMentionInConversation,
      organizationId: currentOrganizationId,
      origin: conversationOrigin,
      priority,
      senderId,
      setConversationToCurrent: true,
      patientContextId: selectedPatientReference?.uuid,
    };
    if (isCreateGroupModeOn || selectedPatientReference?.uuid) {
      await sendMessageToGroupOfUsers(recipientUserIds, body, sendOptions);
    } else if (currentPreviewConversation) {
      await sendMessageToConversation(currentPreviewConversation.id, body, sendOptions);
      clearNewMessagesIndicator(currentPreviewConversation);
      await markConversationAsRead(currentPreviewConversation);
    } else if (
      recipientUserIds.length === 1 &&
      selectedRecipients[0].$entityType === 'distributionList'
    ) {
      const distributionListId = recipientUserIds[0];
      await sendMessageToDistributionList(distributionListId, body, sendOptions);
    } else if (!isProviderNetwork) {
      await sendMessageToPatientGroup(recipientUserIds, body, sendOptions);
    } else if (recipientUserIds.length === 1 && selectedRecipients[0].$entityType !== 'team') {
      const recipientId = recipientUserIds[0];
      const role = getRoleById(recipientId);
      if (role) {
        await sendMessageToRole(recipientId, body, sendOptions);
      } else {
        await sendMessageToUser(recipientId, body, sendOptions);
      }
    } else {
      await sendMessageToGroupOfUsers(recipientUserIds, body, sendOptions);
    }
  }

  return (
    <MessageForm
      allowAttachments={allowAttachments}
      allowedSenders={allowedSenders}
      alwaysEscalate={alwaysEscalate}
      hasRecipients={selectedRecipients.length > 0}
      organization={currentOrganization}
      sendMessage={_sendNewMessage}
      showSendProgress={true}
      tabIndex={accessibilityMode ? 0 : -1}
    />
  );
}

NewConvMessageForm.propTypes = {
  clearNewMessagesIndicator: PropTypes.func.isRequired,
  conversationOrigin: PropTypes.string.isRequired,
  currentOrganization: propTypes.organization,
  currentOrganizationId: PropTypes.string,
  currentPreviewConversation: propTypes.conversation,
  currentUser: propTypes.user.isRequired,
  isProviderNetwork: PropTypes.bool.isRequired,
  isTeamActivation: PropTypes.bool.isRequired,
  markConversationAsRead: PropTypes.func.isRequired,
  newGroupName: PropTypes.string,
  selectedRecipients: propTypes.counterPartyArray.isRequired,
  senderId: PropTypes.string,
  sendMessageToConversation: PropTypes.func.isRequired,
  sendMessageToDistributionList: PropTypes.func.isRequired,
  sendMessageToGroupOfUsers: PropTypes.func.isRequired,
  sendMessageToRole: PropTypes.func.isRequired,
  sendMessageToUser: PropTypes.func.isRequired,
  accessibilityMode: PropTypes.bool,
};

export default mobxInjectSelect({
  composeMessageStore: [
    'conversationOrigin',
    'currentPreviewConversation',
    'newGroupName',
    'isTeamActivation',
    'senderId',
    'isCreateGroupModeOn',
  ],
  conversationStore: ['clearNewMessagesIndicator', 'markConversationAsRead'],
  messageStore: [
    'sendMessageToConversation',
    'sendMessageToDistributionList',
    'sendMessageToGroupOfUsers',
    'sendMessageToPatientGroup',
    'sendMessageToRole',
    'sendMessageToUser',
  ],
  messengerStore: ['currentOrganization', 'currentOrganizationId'],
  networkStore: ['isProviderNetwork'],
  roleStore: ['getRoleById'],
  sessionStore: ['currentUser', 'accessibilityMode'],
})(NewConvMessageForm);
