import { makeObservable, observable } from 'mobx';

export default class StaticStore {
  DevTools = () => null;
  @observable.shallow inlineImages = {};
  @observable passwordFormClassName = 'tc-SignInForm__hidden';
  @observable signInFormContainerClassName = 'tc-SignInForm__sign-in-container--hidden';
  @observable signInFormLoadingIndicatorClassName = 'tc-SignInForm__loading-indicator--hidden';
  @observable usernameFormClassName = '';
  isDevelopment = false;

  constructor() {
    makeObservable(this);
  }
}

const staticStore = new StaticStore();
export { staticStore };
