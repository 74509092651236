import React from 'react';
import classNames from 'classnames';
import BEM from 'common/bem';
import { ReactComponent as SearchCancelSvg } from 'common/images/search-cancel.svg';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';
const { setKeywordSearchTerm } = actions;

const classes = BEM.with('KeywordInput');

const KeywordInputSearch = ({ isAConversationSelected }: { isAConversationSelected: boolean }) => {
  const placeholder = 'Search Inbox';
  const { keywordSearchTerm } = useAppSelector((state) => state.keywordSearch);
  const dispatch = useAppDispatch();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setKeywordSearchTerm(event.target.value));
  };

  const renderNoEditableSearchTerm = () => {
    return (
      <div className={classes('no-editable-container')}>
        <div className={classes('search-term')}>{`"${keywordSearchTerm}`}</div>
        <div className={classes('in-inbox')}>" in Inbox</div>
      </div>
    );
  };

  const renderInputSearch = () => (
    <div className={classes('input-container')}>
      <input
        className={classes('input')}
        onChange={handleSearch}
        onKeyDown={(e) => e.key !== 'Escape' && e.stopPropagation()}
        placeholder={placeholder}
        type="text"
        value={keywordSearchTerm}
        aria-label="keyword input search"
        autoFocus
      />
      {keywordSearchTerm.length >= 1 && (
        <button
          className={classes('btn')}
          onClick={() => {
            dispatch(setKeywordSearchTerm(''));
          }}
          aria-label="keyboard input clear"
        >
          <div
            className={classes('icon-container', {
              cancel: true,
            })}
          >
            <SearchCancelSvg className={classes('icon', { cancel: true })} />
          </div>
        </button>
      )}
    </div>
  );

  return (
    <div className={classNames(classes('', { container: !isAConversationSelected }))}>
      {isAConversationSelected ? renderNoEditableSearchTerm() : renderInputSearch()}
    </div>
  );
};

export default KeywordInputSearch;
