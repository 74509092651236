import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../bem';
import mobxInjectSelect from '../../../utils/mobxInjectSelect';
import { ScrollingList } from '../../';
import { PatientAdminCard } from '.';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';

const BASE_GAP = 58;
const GAP_BETWEEN_CARDS = 11;
const LINE_CONTACT_HEIGHT = 39;
const ROW_HEIGHT = 100;
const OPTED_OUT_HEIGHT = 10;

const classes = BEM.with('PatientAdminList');

class PatientAdminList extends Component {
  static propTypes = {
    continuation: PropTypes.string.isRequired,
    destroy: PropTypes.func.isRequired,
    isSearching: PropTypes.bool.isRequired,
    isPatientSearchOpen: PropTypes.bool.isRequired,
    patientList: PropTypes.array.isRequired,
    search: PropTypes.func.isRequired,
    showRoleBanner: PropTypes.bool.isRequired,
    setSelectedPatientId: PropTypes.func.isRequired,
  };

  state = {
    accessibilityMode: false,
  };

  componentDidMount() {
    const { destroy, search } = this.props;
    destroy();
    search();
  }

  componentWillUnmount() {
    const { destroy } = this.props;
    destroy();
  }

  _setScrollbars = (ref) => {
    this.scrollbars = ref;
  };

  _loadPatientsWithContinuation = () => {
    const { continuation, search } = this.props;
    search({ continuation });
  };

  _getRowHeight = (props) => {
    const { index } = props;
    const { patientList } = this.props;

    if (patientList.length === index) {
      return ROW_HEIGHT;
    }

    const currCard = patientList[index];

    let BASE = BASE_GAP + GAP_BETWEEN_CARDS;

    if (currCard.patient.smsOptedOut) {
      BASE += OPTED_OUT_HEIGHT;
    }

    if (currCard.patient.contacts.length < 2) {
      BASE += (currCard.patient.contacts.length + 1) * LINE_CONTACT_HEIGHT;
    } else if (currCard && currCard.patient && currCard.patient.contacts) {
      BASE += currCard.patient.contacts.length * LINE_CONTACT_HEIGHT;
    }

    for (const contact of currCard.patient.contacts) {
      if (contact.smsOptedOut) {
        BASE += OPTED_OUT_HEIGHT;
      }
    }

    if (this.scrollbars) this.scrollbars.recomputeRowHeights(index);
    return BASE;
  };

  _renderRow = (props) => {
    if (!props) return null;

    const { key, index, style, isVisible, isScrolling } = props;
    const { continuation, patientList, setSelectedPatientId } = this.props;

    if (patientList.length === index) {
      return <div key={key} style={style} />;
    }

    const patient = patientList[index];
    if (!isScrolling && continuation && isVisible && index === patientList.length - 1) {
      this._loadPatientsWithContinuation();
    }

    return (
      <div key={key} className={classes('patient-card')} style={style}>
        <PatientAdminCard
          patientList={patientList}
          contactNumbers={patient.patient.contacts.length}
          entity={patient}
          index={index}
          recomputeRowHeights={this.scrollbars.recomputeRowHeights}
          setSelectedPatientId={setSelectedPatientId}
          accessibilityMode={this.state.accessibilityMode}
        />
      </div>
    );
  };

  render() {
    const { input, isSearching, isPatientSearchOpen, patientList, showRoleBanner } = this.props;
    let topAlignment;
    if (isPatientSearchOpen && showRoleBanner) {
      topAlignment = 'searchAndBanner';
    } else if (isPatientSearchOpen) {
      topAlignment = 'search';
    } else if (showRoleBanner) {
      topAlignment = 'banner';
    }

    let noResults;
    if (patientList.length === 0 && input.length > 0 && !isSearching) {
      noResults = `No patient found named "${input}"`;
    }

    return (
      <div className={classes()}>
        <div className={classes('patient-admin-list-container', { topAlignment })}>
          <div className={classes('patient-admin-list')}>
            {patientList.length > 0 && (
              <ScrollingList
                focusableClasses={['.tc-PatientAdminCard__patient-admin-container']}
                focusableChildrenClasses={[
                  '.tc-PatientAdminCardDetails__option-button',
                  '.tc-PatientAdminCardDetails__option-button-delete',
                  '.tc-PatientAdminCardDetails__option-button-about',
                  '.tc-PatientAdminCard__add-contact-row',
                  '.tc-PatientAdminCardContactDetails',
                ]}
                ref={this._setScrollbars}
                rowCount={patientList.length + 1}
                rowHeight={this._getRowHeight}
                rowRenderer={(props) => this._renderRow(props)}
                tabIndex={-1}
                accessibilityMode={this.state.accessibilityMode}
                focusCentered={true}
              />
            )}
            {noResults && <div className={classes('text-container')}> {noResults} </div>}
            {isSearching && <div className={classes('text-container')}>Loading...</div>}
          </div>
        </div>
        <ReduxEscapeHatch
          ref={(ref) => {
            this.reduxEscapeHatch = ref;
            if (
              this.reduxEscapeHatch &&
              this.reduxEscapeHatch?.accessibilityMode !== this.state.accessibilityMode
            ) {
              this.setState({
                accessibilityMode: this.reduxEscapeHatch?.accessibilityMode,
              });
            }
          }}
        />
      </div>
    );
  }
}

export default mobxInjectSelect({
  patientStore: [
    'continuation',
    'destroy',
    'input',
    'isSearching',
    'isPatientSearchOpen',
    'search',
    'patientList',
  ],
  messengerStore: ['showRoleBanner'],
})(PatientAdminList);
