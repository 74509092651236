import EventEmitter from 'events';
import { action, observable, runInAction, makeObservable } from 'mobx';
import queue from 'emitter-queue';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/switchMap';
import * as Sentry from '@sentry/react';

import {
  DEFAULT_CONTACTS_SEARCH_FIELDS,
  DEFAULT_SEARCH_FIELDS,
  DEFAULT_PATIENT_SEARCH_FIELDS,
  filterSearchResults,
  processSearchResults,
} from '../common/utils/searchResults';
import { updateEntitySortMap } from '../common/utils/searchParity';
import SearchTypes from '../models/enums/SearchTypes';
import { SearchType } from '../js-sdk/src/models/enums';

export default class RecipientPickerStore {
  events = queue(new EventEmitter());
  @observable input = '';
  @observable isSearching = false;
  @observable.shallow searchResults = [];
  @observable noResults = false;
  @observable searchErrorOccurred = false;

  _scrollContinuation = null;
  _scrollCachedProps = null;

  entitySortOrg = null;
  entitySortMap = new Map();

  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  _initializeSearcher = () => {
    this._searcher = Observable.fromEvent(this.events, 'search')
      .debounceTime(200)
      .switchMap(this._search)
      .subscribe(
        action(({ results = null, isNewQuery = true }) => {
          runInAction(() => {
            if (!results) {
              this.searchResults = [];
              return;
            }
            this.searchResults = isNewQuery
              ? results
              : results.length > 0 && this.searchResults.concat(results);
          });
        })
      );
  };

  _disposeSearcher = () => {
    if (this._searcher) {
      this._searcher.unsubscribe();
      this._searcher = null;
      this.isSearching = false;
      this.searchResults = [];

      this._scrollContinuation = null;
      this._scrollCachedProps = null;
      this.noResults = false;
    }
  };

  @action('RecipientPickerStore.clearSearchResults')
  clearSearchResults = () => {
    this._disposeSearcher();
    this.input = '';
  };

  @action('RecipientPickerStore.searchForRelations')
  searchForRelations = (selectedRecipient, organizationId) => {
    const relationOptions = [];

    const { patient } = selectedRecipient;
    const { user, userId } = patient;

    if (selectedRecipient.isPatientContact) {
      relationOptions.push({
        disabled: patient.smsOptedOut,
        entity: user,
        organizationId,
        label: user.displayName,
        value: userId,
      });
      if (patient.contacts.length > 1) {
        patient.contacts.forEach((contact) => {
          if (contact.user.id !== selectedRecipient.id) {
            relationOptions.push({
              disabled: contact.smsOptedOut,
              entity: contact.user,
              organizationId,
              label: contact.user.displayName,
              value: contact.userId,
            });
          }
        });
      }
    } else if (selectedRecipient.isPatient && patient.contacts.length > 0) {
      patient.contacts.forEach((contact) => {
        relationOptions.push({
          disabled: contact.smsOptedOut,
          entity: contact.user,
          organizationId,
          label: contact.user.displayName,
          value: contact.userId,
        });
      });
    }

    return relationOptions;
  };

  @action('RecipientPickerStore.fetchBLMembersToAdd') fetchBLMembersToAdd = async ({
    continuation,
    enabledCapabilities,
    excludeIds = [],
    excludeIntraTeams,
    excludeMyRoles,
    excludeRoles,
    excludeSelf,
    excludeTeams,
    organizationId,
    relationOptions,
    text = this.input,
  }) => {
    const { messengerStore, roleStore } = this.stores;
    const { myRoleIds } = roleStore;
    const { isPresenceFeatureFlagEnabled } = messengerStore;
    const rolesToExclude = excludeMyRoles ? myRoleIds : [];

    const { metadata, results } = await this.client.search.query({
      version: 'SEARCH_PARITY',
      enabledCapabilities,
      continuation,
      excludeEmptyLists: false,
      excludeIds,
      excludeSelf,
      organizationId,
      query: {
        [DEFAULT_SEARCH_FIELDS]: text,
      },
      sort: isPresenceFeatureFlagEnabled ? ['presence_status', 'display_name'] : ['display_name'],
      types: [SearchType.USER],
    });

    const toReturn = results
      .filter(({ entity, entityType }) => entity && entityType !== 'tag')
      .map(({ entity }) => entity)
      .filter(
        filterSearchResults([...excludeIds, ...rolesToExclude], {
          excludeIntraTeams,
          excludeRoles,
          excludeTeams,
        })
      );

    const res = processSearchResults(toReturn, {
      input: text,
      isPatientSearch: false,
      organizationId,
      relationOptions,
    });

    return {
      metadata,
      results: res.results,
    };
  };

  @action('RecipientPickerStore.search') search = (props) => {
    const {
      excludeBroadcastsWhenSenderIsRole,
      excludeTeams,
      organization,
      sender,
      text = this.input,
    } = props;
    let { searchTypes } = props;

    const { forumsEnabled } = organization || {};
    if (!forumsEnabled && searchTypes.includes(SearchTypes.FORUM)) {
      searchTypes = searchTypes.filter((type) => type !== SearchTypes.FORUM);
    }

    if (
      excludeBroadcastsWhenSenderIsRole &&
      sender &&
      sender.isRoleBot &&
      searchTypes.includes(SearchTypes.DISTRIBUTION_LIST)
    ) {
      searchTypes = searchTypes.filter((type) => type !== SearchTypes.DISTRIBUTION_LIST);
    }

    if (excludeTeams) {
      searchTypes = searchTypes.filter((type) => type !== SearchTypes.TEAM);
    }

    this.stores.sessionStore.resetAutoLogout();

    if (this.input !== text) {
      this.input = text;
    }
    if (!organization || !searchTypes || searchTypes.length === 0) {
      this._disposeSearcher();
      this.searchResults = [];
    } else {
      runInAction(() => {
        this.searchErrorOccurred = false;
        this.isSearching = true;
      });
      if (!this._searcher) this._initializeSearcher();
      this.events.queue('search', { ...props, searchTypes, text: text.trim() });
    }
  };

  @action('RecipientPickerStore.scrollSearch') scrollSearch = () => {
    if (!this._scrollContinuation) return;
    runInAction(() => {
      this.searchErrorOccurred = false;
      this.isSearching = true;
    });
    return this.search({ ...this._scrollCachedProps, isNewQuery: false });
  };

  // Warning: Since this function is on a delay, caution is needed when relying on variables outside of this scope. The values may not be expected.
  _search = async (props) => {
    const {
      enabledCapabilities,
      excludeIds = [],
      excludeIntraTeams,
      excludeMyRoles,
      excludeRoles,
      excludeTeams,
      excludeSelf,
      selectedPatientReferenceId,
      isShareBLSearch,
      organization,
      searchTypes,
      text,
      userRolesInOrg,
      isNewQuery = true,
    } = props;
    const { id: organizationId, isContacts } = organization;
    const { composeMessageStore, messengerStore, networkStore } = this.stores;
    const { currentAppSelected, isPresenceFeatureFlagEnabled, showCareTeamPatientProfile } =
      messengerStore;
    const { isProviderNetwork } = networkStore;
    const { isAddFamilyAndContacts, selectedRecipients } = composeMessageStore;

    const isPatientSearch = !isProviderNetwork || currentAppSelected === 'PatientSettings';

    const searchFields = isPatientSearch
      ? DEFAULT_PATIENT_SEARCH_FIELDS
      : isContacts
      ? DEFAULT_CONTACTS_SEARCH_FIELDS
      : DEFAULT_SEARCH_FIELDS;

    const query = { [searchFields]: text };

    if (Array.isArray(userRolesInOrg) && userRolesInOrg.length) {
      query['roles_in_org'] = userRolesInOrg.join(',');
    }

    const sortKeys = ['name'];
    if (isPresenceFeatureFlagEnabled) sortKeys.push('presenceStatus');

    runInAction(() => {
      if (isNewQuery) {
        this._scrollContinuation = null;
        this._scrollCachedProps = null;
        this.noResults = false;
      }
    });

    try {
      const { results, metadata } = await this.client.search.query({
        version: 'SEARCH_PARITY',
        enabledCapabilities,
        excludeEmptyLists: isPatientSearch,
        excludeIds,
        excludeSelf,
        isShareBLSearch,
        organizationId,
        query,
        sort: sortKeys,
        types: searchTypes,
        ...(selectedPatientReferenceId &&
          showCareTeamPatientProfile && {
            pinOnTop: {
              patient_context_ids: selectedPatientReferenceId,
            },
          }),
        ...(this._scrollContinuation && {
          continuation: this._scrollContinuation,
        }),
      });
      if (!isContacts) {
        if (!metadata.entityOrder) {
          const e = new Error('No entityOrder in metadata');
          Sentry.captureException(e);
        }
        const currentOrganizationId = this.stores.messengerStore.currentOrganizationId;
        const { entitySortOrg, entitySortMap } = updateEntitySortMap({
          currentOrganizationId,
          entitySortMap: this.entitySortMap,
          entitySortOrg: this.entitySortOrg,
          isNewQuery,
          metadata,
        });
        this.entitySortOrg = entitySortOrg;
        this.entitySortMap = entitySortMap;
      }

      const { roleStore, trackerStore, composeMessageStore } = this.stores;
      const { myRoleIds } = roleStore;
      const rolesToExclude = excludeMyRoles ? myRoleIds : [];

      const toReturn = this.entityStore.getOrSync(
        results
          .filter(({ entity, entityType }) => entity && entityType !== 'tag')
          .map(({ entity }) => entity)
          .filter(
            filterSearchResults([...excludeIds, ...rolesToExclude], {
              excludeIntraTeams,
              excludeRoles,
              excludeTeams,
            })
          )
      );

      let relationOptions;
      if (isAddFamilyAndContacts) {
        relationOptions = this.searchForRelations(selectedRecipients[0], organizationId);
      }

      runInAction(() => {
        this._scrollContinuation = metadata.continuation;
        this._scrollCachedProps = props;
        if (metadata.totalHits === 0) {
          this.noResults = true;
        }
        this.isSearching = false;
      });

      const searchResults = processSearchResults(toReturn, {
        input: text,
        isPatientSearch,
        organizationId,
        relationOptions,
        isNewQuery,
      });
      if (text !== '' && searchResults.results.length > 0 && composeMessageStore.isComposing) {
        trackerStore.trackingInboxSearchPendo('compose');
      }

      return searchResults;
    } catch (err) {
      runInAction(() => {
        this.isSearching = false;
        this.searchErrorOccurred = true;
      });
      console.error(err);
      return {};
    }
  };
}
