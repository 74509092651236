import EventEmitter from 'events';
import { action, computed, extendObservable, observable, runInAction, makeObservable } from 'mobx';
import queue from 'emitter-queue';
import uuid from 'uuid';
import { FROZEN_EMPTY_ARRAY, attachments, attachmentType, phone } from '../common/utils';
import {
  Attachments,
  PatientSidebarTabs,
  PerformanceKPITypes,
  PfCallOrigins,
  SearchTypes,
} from '../models/enums';

const DEFAULT_CONVERSATION_ORIGIN = 'Compose';
const FILE_EXTENSION_REGEX = /\.([^.]+)$/;
const NEW_CONVERSATION_ID = '__newConversation';

const IEConvertedContentTypes = [
  '',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

class ComposeMessageState {
  @observable body = '';
  @observable isEscalated = false;
  @observable priority = 'NORMAL';
  @observable senderId = null;
  @observable.shallow attachmentDescriptors = [];

  constructor() {
    makeObservable(this);
  }
}

function loadLocalFilePathWithFileReader(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );
    reader.addEventListener(
      'error',
      (e) => {
        reject(e);
      },
      false
    );
    reader.readAsDataURL(file);
  });
}

export default class ComposeMessageStore {
  events = queue(new EventEmitter());
  @observable conversationOrigin = DEFAULT_CONVERSATION_ORIGIN;
  @observable currentPreviewConversationId = null;
  @observable selectorDefaultsToUser = false;
  @observable isComposing = false;
  @observable composingNewPatientConversationFromSearch = false;
  @observable isExistingPatientConversationsOpen = false;
  @observable isComposeTo = false;
  @observable isComposeFromLink = false;
  @observable isSending = false;
  @observable isCommandEditorOpen = false;
  @observable lastSenderId = null;
  @observable stateByConversation = new Map();
  @observable hasMessageFormIndicator = false;
  @observable newGroupName = '';
  @observable isCreateGroupModeOn = false;
  @observable.shallow composeEntity = null;
  @observable.shallow existingPatientConversations = [];
  @observable.shallow existingPatientConversationsTarget = null;
  @observable.shallow selectedRecipients = FROZEN_EMPTY_ARRAY;
  @observable.shallow patientReferenceSelected = null;

  constructor({ client, entityStore, params, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.params = params;
    this.stores = stores;
  }

  @computed get getCurrentConversationState() {
    const { conversationStore } = this.stores;

    const key = conversationStore.currentConversationId || NEW_CONVERSATION_ID;
    let state = this.stateByConversation.get(key);
    if (!state) {
      state = new ComposeMessageState();
      runInAction(() => {
        this.stateByConversation.set(key, state);
      });
    }
    return state;
  }

  @computed get isTeamActivation() {
    const sender = this.getCurrentConversationState.sender || {};

    return (
      this.selectedRecipients.length > 0 &&
      this.selectedRecipients[0] &&
      this.selectedRecipients[0].$entityType === 'team' &&
      !this.selectedRecipients[0].memberIds.includes(sender.id)
    );
  }

  @computed get isAddFamilyAndContacts() {
    const { networkStore } = this.stores;
    const { isProviderNetwork } = networkStore;
    return !isProviderNetwork && this.isComposing && this.selectedRecipients.length >= 1;
  }

  @computed get attachmentDescriptors() {
    const { attachmentDescriptors } = this.getCurrentConversationState;
    return attachmentDescriptors;
  }

  @computed get body() {
    const { body } = this.getCurrentConversationState;
    return body;
  }

  @computed get canEscalate() {
    const { conversationStore, messengerStore } = this.stores;
    const { currentOrganization } = messengerStore;
    if (!currentOrganization) return false;

    const { currentConversation } = conversationStore;
    const { composeEntity, currentPreviewConversation, selectedRecipients } = this;

    if (currentConversation) {
      return this.canEscalateMessages(currentConversation, currentOrganization);
    } else if (currentPreviewConversation) {
      return this.canEscalateMessages(currentPreviewConversation, currentOrganization);
    } else if (
      (composeEntity && composeEntity.$entityType === 'role' && composeEntity.escalationPolicy) ||
      (selectedRecipients.length === 1 &&
        selectedRecipients[0].$entityType === 'role' &&
        selectedRecipients[0].escalationPolicy)
    ) {
      return currentOrganization.canEscalateMessages;
    } else {
      return false;
    }
  }

  canEscalateMessages(conversation, organization) {
    const { counterParty, counterPartyType } = conversation;

    const isRoleP2P =
      counterPartyType === 'group' && counterParty && counterParty.groupType === 'ROLE_P2P';
    let escalationPolicy;
    if (isRoleP2P) {
      const { p2pRecipient } = counterParty;
      escalationPolicy = p2pRecipient && p2pRecipient.escalationPolicy;
    }

    return !!(isRoleP2P && !!escalationPolicy && organization && organization.canEscalateMessages);
  }

  @computed get currentPreviewConversation() {
    if (!this.currentPreviewConversationId) return null;

    return this.entityStore.conversation.getById(this.currentPreviewConversationId);
  }

  @computed get hasAttachments() {
    return this.attachmentDescriptors.length !== 0;
  }

  @computed get isEscalated() {
    const { conversationStore } = this.stores;
    const { currentConversation } = conversationStore;
    const { canEscalate, selectedRecipients } = this;

    if (!canEscalate) return false;

    let alwaysEscalate = false;
    if (currentConversation) {
      const { counterParty } = currentConversation;
      const { p2pRecipient } = counterParty;

      if (p2pRecipient && p2pRecipient.escalationPolicy) {
        const { escalationPolicy } = p2pRecipient;
        alwaysEscalate = escalationPolicy.alwaysEscalate;
      }
    } else if (
      selectedRecipients &&
      selectedRecipients.length === 1 &&
      selectedRecipients[0].$entityType === 'role'
    ) {
      const { escalationPolicy } = selectedRecipients[0];
      if (escalationPolicy) alwaysEscalate = escalationPolicy.alwaysEscalate;
    }
    if (alwaysEscalate) return true;

    const { isEscalated } = this.getCurrentConversationState;
    return isEscalated;
  }

  @computed get isNewConversation() {
    const { conversationStore } = this.stores;
    return !conversationStore.currentConversationId;
  }

  @computed get priority() {
    const { priority } = this.getCurrentConversationState;
    return priority;
  }

  @computed get sender() {
    const { senderId } = this.getCurrentConversationState;
    return senderId ? this.entityStore.getById('user', senderId) : null;
  }

  @computed get senderId() {
    const { senderId } = this.getCurrentConversationState;
    return senderId;
  }

  @action('ComposeMessageStore.getConversationState') getConversationState = (conversationId) => {
    const conversationState = this.stateByConversation.get(conversationId);
    if (conversationState) {
      return conversationState;
    } else {
      return { attachmentDescriptors: [], body: '', priority: 'NORMAL' };
    }
  };

  @action('ComposeMessageStore.addAttachments') addAttachments = async (files) => {
    if (!files) return;

    const { attachmentDescriptors } = this.getCurrentConversationState;

    for (let file of files) {
      if (typeof DataTransferItem !== 'undefined' && file instanceof DataTransferItem) {
        if (file.kind === 'file') {
          file = file.getAsFile();
        } else {
          file = null;
        }

        if (!file) {
          continue;
        }
      }

      if (file.size > Attachments.MAX_FILE_SIZE_BYTES) {
        this.stores.modalStore.openModal('rejectedFileSize', {
          maxFileSize: `${Attachments.MAX_FILE_SIZE_MB}MB`,
        });
      } else {
        const id = `react:${uuid.v4()}`;
        const attachmentDescriptor = observable({ file, id });

        if (attachmentType(file.type) === 'Image') {
          const localPath = await loadLocalFilePathWithFileReader(file);
          runInAction(() => {
            extendObservable(attachmentDescriptor, { localPath });
            attachmentDescriptors.push(attachmentDescriptor);
          });
        } else {
          if (IEConvertedContentTypes.includes(attachmentDescriptor.file.type)) {
            const ext = this._getFileExtensionFromName(attachmentDescriptor.file.name);

            if (ext === 'xls' || ext === 'csv') {
              attachmentDescriptor.file = this.client.file.createBlob(
                ext,
                attachmentDescriptor.file
              );
            }
          }
          runInAction(() => {
            attachmentDescriptors.push(attachmentDescriptor);
          });
        }
      }
    }
  };

  _getFileExtensionFromName(fileName) {
    if (!fileName) {
      return '';
    }
    return fileName.split('.').pop().toLowerCase();
  }

  @action('ComposeMessageStore.rejectAttachments') rejectAttachments = async (files) => {
    const { modalStore } = this.stores;
    if (!files) return;

    const validFiles = files.filter(({ name: filename }) => {
      const extMatch = filename && filename.match(FILE_EXTENSION_REGEX);
      const ext = extMatch && extMatch[1] && extMatch[1].toLowerCase();

      return !ext || attachments.getMimeEntryByExtension(ext);
    });
    this.addAttachments(validFiles);

    if (validFiles.length !== files.length) {
      modalStore.openModal('rejectedFileFormat');
    }
  };

  @action('ComposeMessageStore.removeAttachment') removeAttachment = (attachmentDescriptor) => {
    const currentConversationState = this.getCurrentConversationState;
    const index = currentConversationState.attachmentDescriptors.indexOf(attachmentDescriptor);
    if (index > -1) {
      currentConversationState.attachmentDescriptors.splice(index, 1);
    }
  };

  @action('ComposeMessageStore.resetMessage') resetMessage = ({
    attachmentDescriptors = FROZEN_EMPTY_ARRAY,
    body = '',
    isEscalated = false,
    priority = 'NORMAL',
  } = {}) => {
    const { sessionStore } = this.stores;
    const currentConversationState = this.getCurrentConversationState;

    currentConversationState.attachmentDescriptors = attachmentDescriptors;
    currentConversationState.body = body;
    currentConversationState.isEscalated = isEscalated;
    currentConversationState.priority = priority;

    sessionStore.resetAutoLogout();
  };

  @action('ComposeMessageStore.toggleCommandEditor') toggleCommandEditor = () => {
    if (this.isCommandEditorOpen) {
      this.resetMessage();
    }
    this.isCommandEditorOpen = !this.isCommandEditorOpen;
  };

  @action('ComposeMessageStore.stashMessage') stashMessage = () => {
    const currentConversationState = this.getCurrentConversationState;
    const { body = '', isEscalated = false, priority = 'NORMAL' } = currentConversationState;
    let { attachmentDescriptors } = currentConversationState;
    attachmentDescriptors = attachmentDescriptors
      ? attachmentDescriptors.slice()
      : FROZEN_EMPTY_ARRAY;

    this.resetMessage();

    return {
      attachmentDescriptors,
      body,
      isEscalated,
      priority,
    };
  };

  @action('ComposeMessageStore.setBody') setBody = async (body = '') => {
    const { sessionStore } = this.stores;
    const currentConversationState = this.getCurrentConversationState;
    currentConversationState.body = body;
    sessionStore.resetAutoLogout();
  };

  @action('ComposeMessageStore.setComposeEntity') setComposeEntity = (entity) => {
    this.composeEntity = entity;
  };

  @action('ComposeMessageStore.setCurrentPreviewConversation')
  setCurrentPreviewConversation = (conversation) => {
    if (this.isCreateGroupModeOn || this.patientReferenceSelected) {
      this.currentPreviewConversationId = null;
    } else {
      this.currentPreviewConversationId = conversation ? conversation.id : null;
    }
  };

  @action('ComposeMessageStore.setConversationOrigin')
  setConversationOrigin = (conversationOrigin) => {
    this.conversationOrigin = conversationOrigin;
  };

  @action('ComposeMessageStore.setLastSenderId') setLastSenderId = (userId) => {
    this.lastSenderId = userId;
  };

  @action('ComposeMessageStore.setMessageFormIndicator')
  setMessageFormIndicator = (isOn) => {
    this.hasMessageFormIndicator = isOn;
  };

  @action('ComposeMessageStore.setNewGroupName') setNewGroupName = (newGroupName) => {
    this.newGroupName = newGroupName;

    if (this.stores.messengerStore.isNewConversationUIFeatureFlagEnabled && this.newGroupName) {
      this.setCurrentPreviewConversation();
      this.setIsCreateGroupModeOn(true);
    } else if (
      this.stores.messengerStore.isNewConversationUIFeatureFlagEnabled &&
      !this.newGroupName
    ) {
      this.selectRecipients(this.selectedRecipients);
      this.setIsCreateGroupModeOn(false);
    }
  };

  @action('ComposeMessageStore.setPriority') setPriority = (priority) => {
    const currentConversationState = this.getCurrentConversationState;
    currentConversationState.isEscalated = false;
    currentConversationState.priority = priority;
  };

  @action('ComposeMessageStore.setEscalated') setEscalated = (isEscalated) => {
    const currentConversationState = this.getCurrentConversationState;
    currentConversationState.isEscalated = isEscalated;
    currentConversationState.priority = 'NORMAL';
  };

  @action('ComposeMessageStore.setSenderId') setSenderId = (userId) => {
    const currentConversationState = this.getCurrentConversationState;
    currentConversationState.senderId = userId;
    currentConversationState.sender = userId ? this.entityStore.getById('user', userId) : null;
  };

  @action('ComposeMessageStore.setIsSending') setIsSending = (isSending) => {
    this.isSending = isSending;
  };

  @action('ComposeMessageStore.setSelectedRecipients') setSelectedRecipients = (entities) => {
    this.selectedRecipients = entities;
  };

  @action('ComposeMessageStore.setPatientReferenceSelected')
  setPatientReferenceSelected = (patientReference) => {
    this.patientReferenceSelected = patientReference;
    if (patientReference) this.setCurrentPreviewConversation();
  };

  @action('ComposeMessageStore.setIsCreateGroupModeOn')
  setIsCreateGroupModeOn = (isCreateGroupModeOn) => {
    this.isCreateGroupModeOn = isCreateGroupModeOn;
    if (!this.stores.messengerStore.isNewConversationUIFeatureFlagEnabled) {
      this.setNewGroupName('');
      this.setCurrentPreviewConversation();
    }
  };

  @action('ComposeMessageStore.composeNewMessage') composeNewMessage = (user) => {
    const { messengerStore, networkStore, patientStore, rosterStore, trackerStore } = this.stores;
    const { isProviderNetwork } = networkStore;
    const { performanceKpiStart } = trackerStore;
    if (!messengerStore.currentOrganization) return;

    if (!this.isComposeTo) performanceKpiStart(PerformanceKPITypes.COMPOSE);

    if (isProviderNetwork) {
      rosterStore.setFilter('Inbox');
      rosterStore.setFilterBar('Left');
    } else {
      patientStore.setSidebarTab(PatientSidebarTabs.MESSAGES);
    }

    messengerStore.hideMessageMultiSelect();
    this.isComposeTo = true;
    this.setSelectorDefaultsToUser(true);
    this.startComposing({ isComposing: true });
    if (user) this.selectRecipients([user]);
    this.setIsCreateGroupModeOn(false);
    messengerStore.stopSearching();
  };

  @action('ComposeMessageStore.startComposing') startComposing = ({
    conversation,
    entity,
    isComposing = false,
    composingNewPatientConversationFromSearch = false,
    previewConversation,
  }) => {
    const { conversationStore, messengerStore } = this.stores;

    this.composingNewPatientConversationFromSearch = !!composingNewPatientConversationFromSearch;
    this.isComposing = !!isComposing;
    messengerStore.closeProfile();
    messengerStore.closeAdminIframe();
    conversationStore.setCurrentConversationId(conversation ? conversation.id : null);
    this.setComposeEntity(entity || null);
    this.setCurrentPreviewConversation(previewConversation || null);
    messengerStore.closeInfoPane();
  };

  @action('ComposeMessageStore.stopComposing') stopComposing = () => {
    const { messengerStore } = this.stores;

    this.isComposing = false;
    this.composingNewPatientConversationFromSearch = false;
    this.setComposeEntity(null);
    this.setConversationOrigin(DEFAULT_CONVERSATION_ORIGIN);
    this.setCurrentPreviewConversation(null);
    messengerStore.closeInfoPane();
    this.isComposeTo = false;
    this.hideExistingPatientConversations();
    this.setSelectedRecipients([]);
    if (this.isNewConversation) {
      this.resetMessage();
      this.setSelectorDefaultsToUser(false);
    }
  };

  @action('ComposeMessageStore.composeToSearchResult')
  composeToSearchResult = async (entity) => {
    const { conversationStore, groupStore, messengerStore, rosterStore } = this.stores;
    const { $entityType: entityType, isPatient, isPatientContact } = entity;
    const { currentOrganization } = messengerStore;

    rosterStore.setFilterBarByEntity(entity);

    let conversation;
    if (entityType === 'team') {
      if (entity.groupId && entity.hasCurrentUserOrRole) {
        const intraTeamGroup = await groupStore.findGroup(entity.groupId);
        conversation = intraTeamGroup?.conversation;
      }
    } else if (!(isPatient || isPatientContact)) {
      conversation = await conversationStore.findConversationForEntity(entity, {
        canCreateGroup: true,
        organization: currentOrganization,
      });
    }

    const composingNewPatientConversationFromSearch =
      !conversation && (isPatient || isPatientContact);

    runInAction(() => {
      if (conversation) {
        this.startComposing({ conversation, isComposing: false });
        conversationStore.fetchTimelineSelectedConversation(conversation);
      } else {
        if (
          entityType === 'user' ||
          entityType === 'role' ||
          entityType === 'team' ||
          entityType === 'distributionList'
        ) {
          this.setSelectedRecipients([entity]);
        }

        this.setSelectorDefaultsToUser(true);
        this.startComposing({
          entity,
          isComposing: true,
          composingNewPatientConversationFromSearch,
        });
      }
    });

    messengerStore.stopSearching();
  };

  @action('ComposeMessageStore.composeToEntityV2')
  composeToEntityV2 = async (entity) => {
    const { conversationStore, messengerStore, sessionStore } = this.stores;
    const { currentUser } = sessionStore;
    const { currentOrganization } = messengerStore;

    if (!entity || currentUser.id === entity.id || currentUser.roleIds.includes(entity.id)) return;

    messengerStore.openMessages();

    if (entity.isRoleBot) {
      entity = entity.botRole;
    }

    const conversation = await conversationStore.findConversationForEntity(entity, {
      canCreateGroup: true,
      organization: currentOrganization,
    });
    runInAction(() => {
      this.setSelectedRecipients([entity]);
      this.isComposeTo = true;
      this.setIsComposeFromLink(true);
      this.startComposing({ isComposing: true, previewConversation: conversation });
      if (conversation) {
        conversationStore.fetchTimelineSelectedConversation(conversation);
      }
    });
  };

  @action('ComposeMessageStore.composeToEntity')
  composeToEntity = async (entity, { origin } = {}) => {
    const { $entityType: entityType, id } = entity;
    const {
      conversationStore,
      messengerStore,
      networkStore,
      patientStore,
      roleStore,
      rosterStore,
      sessionStore,
      userStore,
    } = this.stores;
    const { isProviderNetwork, switchNetwork } = networkStore;
    const { currentOrganization } = messengerStore;
    const { currentUser } = sessionStore;
    let conversation;
    let conversations;

    if (entityType === 'role') {
      entity = roleStore.getRoleById(id);
    } else {
      entity = userStore.getUserById(id);
      if (entity && entity.isRoleBot) {
        entity = entity.botUser;
      }
    }

    if (!entity || currentUser.id === entity.id || currentUser.roleIds.includes(entity.id)) return;

    const isPatientEntity = entity.isPatient || entity.isPatientContact;
    if (!isPatientEntity && !isProviderNetwork) {
      switchNetwork();
    }

    messengerStore.openMessages();
    rosterStore.setFilterBarByEntity(entity);

    if (!isPatientEntity) {
      conversation = await conversationStore.findConversationForEntity(entity, {
        canCreateGroup: true,
        organization: currentOrganization,
      });
    } else if (!isProviderNetwork) {
      conversations = await conversationStore.findAllGroupConversationsWithMembers(
        [entity.id],
        currentOrganization.id
      );
    }

    runInAction(() => {
      if (isPatientEntity) {
        this.isComposeTo = true;
        this.setSelectorDefaultsToUser(true);
        this.setSelectedRecipients([entity]);
        this.startComposing({ isComposing: true });
        this.showExistingPatientConversations(entity);
        if (origin !== PfCallOrigins.PROFILE && conversations && conversations.length > 0) {
          conversation = this.getLatestPatientP2PConversation(conversations);
          this.setCurrentPreviewConversation(conversation);
        }
        patientStore.setSidebarTab(PatientSidebarTabs.MESSAGES);
        messengerStore.stopSearching();
      } else if (conversation) {
        this.startComposing({ conversation, isComposing: false });
        conversationStore.fetchTimelineSelectedConversation(conversation);
      } else {
        this.setSelectedRecipients([entity]);
        this.startComposing({ entity, isComposing: true });
      }
    });
  };

  @action('ComposeMessageStore.composeToContacts') composeToContacts = async (phoneNumber) => {
    const { messengerStore, userStore } = this.stores;
    const { changeOrganizationFromUI, isPresenceFeatureFlagEnabled, organizations } =
      messengerStore;
    const contactOrganization = organizations.find(({ isContacts }) => isContacts);
    phoneNumber = phone.normalizePhoneUS(phoneNumber);

    await changeOrganizationFromUI(contactOrganization);

    const [userId] = await this.client.conversations.resolveMembers(
      [phoneNumber],
      contactOrganization.id
    );
    let user;

    if (!phone.isPhone(userId)) {
      user = await userStore.findUser(userId, contactOrganization.id);
    } else {
      const { results } = await this.client.search.query({
        version: 'LEGACY',
        excludeIds: [this.currentUserId],
        organizationId: contactOrganization.id,
        query: { displayName: phoneNumber },
        sort: isPresenceFeatureFlagEnabled ? ['presenceStatus', 'displayName'] : ['displayName'],
        types: [SearchTypes.USER],
      });
      if (results.length > 0) {
        user = results[0].entity;
      }
    }

    if (user) this.composeToEntity(user);
  };

  @action('ComposeMessageStore.selectRecipients') selectRecipients = async (entities) => {
    const { conversationStore, messengerStore, networkStore } = this.stores;
    const { currentOrganization } = messengerStore;
    const { isProviderNetwork } = networkStore;
    let conversation;
    let conversations;
    let entityType;

    if (!isProviderNetwork && entities.length === 1 && entities[0].$entityType === 'group') {
      const entity = entities[0];
      conversations = await conversationStore.findAllGroupConversationsWithMembers(
        [entity.id],
        currentOrganization.id
      );
    } else if (entities.length === 1) {
      const entity = entities[0];
      entityType = entity.$entityType;
      conversation = await conversationStore.findConversationForEntity(entity, {
        canCreateGroup: true,
        organization: currentOrganization,
      });
    } else if (entities.length > 1) {
      conversation = await conversationStore.findGroupConversationWithMembers(
        entities,
        currentOrganization,
        this.senderId,
        { includeForums: false }
      );
    }

    runInAction(() => {
      const isNewConversationUIEnabled =
        this.stores.messengerStore.isNewConversationUIFeatureFlagEnabled;
      const isCreateGroupModeOff = !this.isCreateGroupModeOn;
      const isSingleEntity = entities.length < 2;

      if (
        isCreateGroupModeOff &&
        isSingleEntity &&
        ((isNewConversationUIEnabled && this.newGroupName) || !isNewConversationUIEnabled)
      ) {
        this.setNewGroupName('');
      }

      if (isProviderNetwork && entityType === 'group') {
        this.stopComposing();
        conversationStore.setCurrentConversationId(conversation ? conversation.id : null);
      } else {
        if (conversations && conversations.length > 0) {
          conversation = this.getLatestPatientP2PConversation(conversations);
        }
        this.setCurrentPreviewConversation(conversation);
        this.setSelectedRecipients(entities);
      }
      if (conversation) {
        conversationStore.fetchTimelineSelectedConversation(conversation);
      }
    });
  };

  getLatestPatientP2PConversation = (conversations) => {
    let latestConv = null;
    let biggestSortNumber = 0;
    for (let idx = 0; idx < conversations.length; idx++) {
      const sortNumber =
        conversations[idx].lastMessage && conversations[idx].lastMessage.sortNumber;
      if (conversations[idx].counterParty.memberCount === 2 && sortNumber) {
        if (biggestSortNumber < sortNumber) {
          biggestSortNumber = sortNumber;
          latestConv = conversations[idx];
        }
      }
    }
    return latestConv;
  };

  @action('ComposeMessageStore.showExistingPatientConversations')
  showExistingPatientConversations = async (selected) => {
    const { conversationStore, messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    const conversations = await conversationStore.findAllGroupConversationsWithMembers(
      selected.id,
      currentOrganizationId
    );

    if (conversations.length === 0) {
      this.hideExistingPatientConversations();
      return;
    }

    for (const conversation of conversations) {
      const { counterParty } = conversation;
      const { memberIds } = counterParty;
      await this.syncGroupMembers(memberIds);
    }

    runInAction(() => {
      this.existingPatientConversations = conversations;
      this.existingPatientConversationsTarget = selected;
      this.isExistingPatientConversationsOpen = true;
    });
  };

  @action('ComposeMessageStore.hideExistingPatientConversations')
  hideExistingPatientConversations = () => {
    this.existingPatientConversations = [];
    this.existingPatientConversationsTarget = null;
    this.isExistingPatientConversationsOpen = false;
  };

  @action('ComposeMessageStore.syncGroupMembers') syncGroupMembers = async (memberIds) => {
    const { messengerStore, userStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    const needsSync = [];
    for (const memberId of memberIds) {
      const member = userStore.getUserById(memberId);
      const hasProfile = member && Object.keys(member.profileByOrganizationId).length > 0;

      if (!member || member.$placeholder || !hasProfile) {
        needsSync.push(memberId);
      }
    }

    if (needsSync.length === 0) return;

    const updatedUsers = await Promise.all(
      needsSync.map((userId) =>
        this.client.users.find(userId, {
          bypassCache: true,
          organizationId: currentOrganizationId,
        })
      )
    );

    this.entityStore.sync(updatedUsers.filter(Boolean));
  };

  @action('ComposeMessageStore.showEscalationModalIfShould')
  showEscalationModalIfShould = () => {
    const { localStore, modalStore } = this.stores;
    const { escalationModalShown, setEscalationModalShown } = localStore;
    const { openModal } = modalStore;

    if (!escalationModalShown) {
      setEscalationModalShown(true);
      openModal('escalationEnabled');
    }
  };

  @action('ComposeMessageStore.showPriorityModalIfShould')
  showPriorityModalIfShould = () => {
    const { localStore, modalStore } = this.stores;
    const { priorityModalShown, setPriorityModalShown } = localStore;
    const { openModal } = modalStore;

    if (!priorityModalShown) {
      setPriorityModalShown(true);
      openModal('priorityMessage');
    }
  };

  @action('ComposeMessageStore.setSelectorDefaultsToUser')
  setSelectorDefaultsToUser = (defaultsToUser) => {
    this.selectorDefaultsToUser = defaultsToUser;
  };

  @action('ComposeMessageStore.getDefaultUser') getDefaultUser = ({
    allowedSenders,
    setSenderId,
  }) => {
    const defaultUser = allowedSenders.find((allowedSender) => allowedSender.isRoleBot === false);
    if (defaultUser) {
      this.setSelectorDefaultsToUser(false);
      if (setSenderId) this.setSenderId(defaultUser.id);
      return defaultUser.id;
    }
  };

  @action('ComposeMessageStore.setDefaultSender') setDefaultSender = (composeEntity) => {
    const { conversationStore, messengerStore, sessionStore } = this.stores;
    const { currentConversation } = conversationStore;
    const { currentOrganization } = messengerStore;
    const { currentUserId } = sessionStore;

    const currentConversationState = this.getCurrentConversationState;
    const sender = currentConversationState.sender;

    const allowedSenders = currentConversation
      ? currentConversation.allowedSenders
      : currentOrganization.allowedSenders;

    const idOfSender = (sender) => (sender.$entityType === 'role' ? sender.botUser.id : sender.id);
    const senderIdAllowed = (id) =>
      id && allowedSenders.find((sender) => idOfSender(sender) === id);
    const senderAllowed = sender && sender.id && senderIdAllowed(sender.id);

    if (allowedSenders.length === 0) {
      this.setSenderId(null);
      return null;
    }

    if (
      composeEntity &&
      composeEntity.botUserId &&
      sender &&
      composeEntity.botUserId === sender.id
    ) {
      this.setSenderId(currentUserId);
      return currentUserId;
    }

    if (!this.lastSenderId || this.selectorDefaultsToUser) {
      const defaultUserId = this.getDefaultUser({
        allowedSenders,
        setSenderId: true,
      });
      if (defaultUserId) return defaultUserId;
    }

    if (senderAllowed) {
      this.setSenderId(sender.id);
      return sender.id;
    }

    const lastSenderAllowed = this.lastSenderId && senderIdAllowed(this.lastSenderId);
    const newSenderId = lastSenderAllowed ? this.lastSenderId : idOfSender(allowedSenders[0]);
    this.setSenderId(newSenderId);

    return newSenderId;
  };

  @action('ComposeMessageStore.composeFromNewGroup') composeFromNewGroup = async () => {
    runInAction(() => {
      this.isComposeTo = true;
      this.startComposing({ isComposing: true });
      this.setIsCreateGroupModeOn(true);
    });
  };

  @action('ComposeMessageStore.setIsComposeFromLink') setIsComposeFromLink = (
    isComposeFromLink
  ) => {
    this.isComposeFromLink = isComposeFromLink;
  };
}
