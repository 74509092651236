import React, { useEffect } from 'react';
import 'react-dates/initialize';
import * as Sentry from '@sentry/react';
import throttle from 'lodash.throttle';

import MessengerFrame from './MessengerFrame';
import Favicon from './Favicon';
import RootModal from './RootModal';
import GeneralStatusBar from './GeneralStatusBar';
import OptInBanner from './OptInBanner';
import NetworkStatusBar from './NetworkStatusBar';
import MessengerLoadingOverlay from './MessengerLoadingOverlay';
import AppSelectorButtons from './AppSelectorButtons';
import GlobalKeyMap from './GlobalKeyMap';
import Messenger from './Messenger';
import ValidationError from './ValidationError';
import { ErrorBoundaryFallback } from './ErrorBoundary';

import LaunchServiceFlow from 'common/components/LaunchServiceFlow/LaunchServiceFlow';
import BEM from 'common/bem';
import { Collaboration, NetworkLoading, PatientUserSettings } from 'common/components';
import { PrintMode } from 'common/components/PrintMode';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';
import BroadcastListSettings from 'common/components/BroadcastListSettings';
import NewAdmin from 'admin';
import PatientSettings from 'common/components/PatientSettings/PatientSettings';
import VirtualWaitingRoom from 'common/components/VirtualWaitingRoom';
import AdminIframe from 'common/components/AdminIframe/AdminIframe';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';
import VideoCall from 'video-call';
import AppContextProvider from 'contexts/App/context';
import PatientDirectory from 'common/components/PatientContext/PatientDirectory';
import { Profile } from 'common/components/Profile';
import OperatorConsoleFlow from 'common/components/OperatorConsoleFlow';
import waitForElement from 'common/utils/waitForElement';

const {
  setCurrentOrganizationId,
  setIsPatientContextEnabled,
  setIsCareTeamAllowed,
  setSidebarCollapse,
} = actions;
const { setupGlobalListeners } = thunk;

const classes = BEM.with('AppSelector');

const SIDEBAR_COLLAPSE_MIN_WIDTH = 950;

type AppSelectorProps = {};

type MobxProps = {
  collabReloadKey?: number;
  currentOrganizationId: string;
  hideMessenger: () => void;
  isBroadcastListOpen: boolean;
  isCollapsibleRosterEnabled: boolean;
  isCollaborationOpen: boolean;
  isDarkModeOn: boolean;
  isIframeOpen: boolean;
  isNetworkLoading: boolean;
  isNewAdminOpen: boolean;
  isPatientContextAllowed: boolean;
  isPatientDirectoryOpen: boolean;
  isPatientSettingsOpen: boolean;
  isPatientUserSettingsOpen: boolean;
  isPrintModeOpen: boolean;
  isProfileOpen: boolean;
  isVirtualWaitingRoomOpen: boolean;
  launchServiceFlow: boolean;
  loadAll: () => void;
  OCError: string;
  operatorConsole: () => void;
  operatorConsoleFlow: boolean;
  relayError: () => void;
  setAccessibilityMode: (value: boolean) => void;
  setCurrentUserId: (id: unknown) => void;
  setIsMessengerHidden: (isHidden: boolean) => void;
  showCareTeamPatientProfile: boolean;
  showDebugUI: boolean;
  signOut: (req: {
    ignoreErrors: boolean;
    returnErrors: boolean;
    wipeSession: boolean;
  }) => Promise<string>;
};

function AppSelector({
  OCError,
  collabReloadKey,
  currentOrganizationId,
  hideMessenger,
  isBroadcastListOpen,
  isCollapsibleRosterEnabled,
  isCollaborationOpen,
  isDarkModeOn,
  isIframeOpen,
  isNetworkLoading,
  isNewAdminOpen,
  isPatientContextAllowed,
  isPatientDirectoryOpen,
  isPatientSettingsOpen,
  isPatientUserSettingsOpen,
  isPrintModeOpen,
  isProfileOpen,
  isVirtualWaitingRoomOpen,
  launchServiceFlow,
  loadAll,
  operatorConsole,
  operatorConsoleFlow,
  relayError,
  setAccessibilityMode,
  setCurrentUserId,
  setIsMessengerHidden,
  showCareTeamPatientProfile,
  showDebugUI,
  signOut,
}: AppSelectorProps & MobxProps) {
  const dispatch = useAppDispatch();
  const { accessibilityMode, sidebarCollapsed } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
    sidebarCollapsed: ui.sidebarCollapsed,
  }));

  useEffect(() => {
    if (!isCollapsibleRosterEnabled) return;
    const _windowResized = throttle(() => {
      dispatch(setSidebarCollapse(window.innerWidth < SIDEBAR_COLLAPSE_MIN_WIDTH));
    }, 100);
    _windowResized();
    window.addEventListener('resize', _windowResized);
    return () => {
      window.removeEventListener('resize', _windowResized);
    };
  }, [dispatch, isCollapsibleRosterEnabled]);

  useEffect(() => {
    if (!isCollapsibleRosterEnabled) return;
    waitForElement('._pendo-badge').then((pendoBadge: HTMLElement | null) => {
      if (!pendoBadge) return;
      if (sidebarCollapsed) {
        pendoBadge.style.bottom = '75px';
      } else {
        pendoBadge.style.bottom = '10px';
      }
    });
  }, [isCollapsibleRosterEnabled, sidebarCollapsed, currentOrganizationId]);

  useEffect(() => {
    loadAll();

    return () => {
      hideMessenger();
    };
  }, [loadAll, hideMessenger]);

  useEffect(() => {
    if (currentOrganizationId && operatorConsoleFlow) {
      operatorConsole();
    }
  }, [currentOrganizationId, operatorConsole, operatorConsoleFlow]);

  useEffect(
    function setupPermissions() {
      dispatch(setIsPatientContextEnabled(isPatientContextAllowed));
      dispatch(setIsCareTeamAllowed(showCareTeamPatientProfile));
    },
    [dispatch, isPatientContextAllowed, showCareTeamPatientProfile]
  );

  useEffect(
    function setupSession() {
      dispatch(setCurrentOrganizationId(currentOrganizationId));
    },
    [dispatch, currentOrganizationId]
  );

  useEffect(
    function setupListeners() {
      dispatch(setupGlobalListeners(null));
    },
    [dispatch]
  );

  if (isDarkModeOn) {
    document.body.classList.add('theme-dark');
  } else {
    document.body.classList.remove('theme-dark');
  }

  setAccessibilityMode(accessibilityMode);
  if (accessibilityMode) {
    document.body.classList.add('a11y');
  } else {
    document.body.classList.remove('a11y');
  }

  function _faviconSelector(totalUnreadCount: number) {
    return totalUnreadCount > 0 ? 'favicons/favicon-alert.png' : 'favicons/favicon.png';
  }

  if (OCError) {
    return <ValidationError />;
  }

  const otherApp = isNetworkLoading ? (
    <NetworkLoading />
  ) : operatorConsoleFlow ? (
    <OperatorConsoleFlow />
  ) : isPatientUserSettingsOpen ? (
    <PatientUserSettings />
  ) : isProfileOpen ? (
    <Profile />
  ) : isPatientSettingsOpen ? (
    <PatientSettings />
  ) : isVirtualWaitingRoomOpen ? (
    <VirtualWaitingRoom />
  ) : isBroadcastListOpen ? (
    <BroadcastListSettings />
  ) : isCollaborationOpen ? (
    <Collaboration key={collabReloadKey} />
  ) : isPatientDirectoryOpen ? (
    <PatientDirectory />
  ) : isNewAdminOpen ? (
    <NewAdmin currentOrganizationId={currentOrganizationId} />
  ) : isIframeOpen ? (
    <AdminIframe />
  ) : isPrintModeOpen ? (
    <PrintMode />
  ) : null;

  const isHidden = !isNetworkLoading && !!otherApp;
  setIsMessengerHidden(isHidden);

  return (
    <Sentry.ErrorBoundary
      fallback={(props) => (
        <ErrorBoundaryFallback
          {...props}
          relayError={relayError}
          signOut={signOut}
          setCurrentUserId={setCurrentUserId}
        />
      )}
    >
      <AppContextProvider>
        <GlobalKeyMap
          className={classes({ showDebugUI, collapsed: sidebarCollapsed })}
          isHidden={isHidden}
        >
          <Favicon faviconSelector={_faviconSelector} />
          <div className={classes('app-selector-buttons')}>
            <AppSelectorButtons />
          </div>
          <MessengerFrame className={classes('main-window')}>
            {!isVirtualWaitingRoomOpen && <Messenger isHidden={isHidden} />}
            {launchServiceFlow && <LaunchServiceFlow />}
            {otherApp}
          </MessengerFrame>
          <MessengerLoadingOverlay />
          <NetworkStatusBar />
          <OptInBanner />
          <GeneralStatusBar />
          <RootModal />
          <VideoCall />
        </GlobalKeyMap>
      </AppContextProvider>
    </Sentry.ErrorBoundary>
  );
}

export default mobxInjectSelect<AppSelectorProps, MobxProps>({
  collaborationStore: ['collabReloadKey'],
  launchStore: ['launchServiceFlow'],
  messengerStore: [
    'currentOrganizationId',
    'hideMessenger',
    'isBroadcastListOpen',
    'showCareTeamPatientProfile',
    'isCollapsibleRosterEnabled',
    'isCollaborationOpen',
    'isDarkModeOn',
    'isIframeOpen',
    'isPatientContextAllowed',
    'isPatientSettingsOpen',
    'isPatientUserSettingsOpen',
    'isPatientDirectoryOpen',
    'isPrintModeOpen',
    'isProfileOpen',
    'isNewAdminOpen',
    'isVirtualWaitingRoomOpen',
    'loadAll',
    'setIsMessengerHidden',
  ],
  networkStore: ['isNetworkLoading'],
  operatorConsoleStore: ['operatorConsole', 'operatorConsoleFlow', 'OCError'],
  sessionStore: [
    'relayError',
    'showDebugUI',
    'setAccessibilityMode',
    'setCurrentUserId',
    'signOut',
  ],
})(AppSelector);
