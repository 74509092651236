import EventEmitter from 'events';
import { action, computed, observable, makeObservable } from 'mobx';

export default class DownloadedMessagesProgressStore {
  @observable downloadMessage = '';
  @observable downloadFinished = false;
  @observable offlineMessageCount = 0;
  @observable startTime = null;

  _downloadRate = null;
  messageDownloadTime = null;

  constructor({ stores }) {
    makeObservable(this);
    this.stores = stores;
    this.events = new EventEmitter();
  }

  mounted() {
    this.stores.entityStore.events.on('advisory', this._onAdvisoryEvents);
  }

  @computed get downloadedCount() {
    const downloaded = this.stores.entityStore.message.length;
    if (this.offlineMessageCount > 0) {
      return downloaded > this.offlineMessageCount ? this.offlineMessageCount : downloaded;
    } else {
      return downloaded;
    }
  }

  @action('DownloadedMessagesProgressStore.setDownloadFinished')
  setDownloadFinished(downloadFinished) {
    this.downloadFinished = downloadFinished;
    downloadFinished && this.events.emit('downloadFinished');
  }

  @action('DownloadedMessagesProgressStore.setOfflineMessageCount')
  setOfflineMessageCount(offlineMessageCount) {
    this.offlineMessageCount = offlineMessageCount;
  }

  @action('DownloadedMessagesProgressStore._onAdvisoryEvents')
  _onAdvisoryEvents = (event) => {
    switch (event.type) {
      case 'replayStart':
        if (this.offlineMessageCount === 0) {
          this.startTime = performance.now();
          this.setOfflineMessageCount(event.data.replayMessageCount);
        }
        break;

      case 'replayStop':
        if (!this.downloadFinished) {
          this.setDownloadFinished(true);
          this.messageDownloadTime = performance.now() - this.startTime;
          this._downloadRate = (this.downloadedCount * 1000) / this.messageDownloadTime;
          this.downloadMessage = `Downloaded ${
            this.downloadedCount
          } messages in ${this.messageDownloadTime.toFixed(
            1
          )} ms, rate = ${this._downloadRate.toFixed(1)} / sec`;
          console.log(this.downloadMessage);
        }
        break;
      default:
    }
  };
}
