import React, { Fragment } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from '@tigerconnect/web-component-library';
import CallLog from './CallLog/CallLog';
import { AlertsFilterList } from './Alerts';
import { mobxInjectSelect } from 'common/utils';
import {
  ConversationList,
  DndRoster,
  NewItemButton,
  PatientSearchBar,
  PatientSidebarTabPicker,
  PatientConversationList,
  RosterFilterBar,
} from 'widgets/messenger/components/Sidebar';
import { AppTypes } from 'models/enums';
import propTypes from 'common/propTypes';
import BEM from 'common/bem';
import PatientSidebarTabs from 'models/enums/PatientSidebarTabs';
import { actions, useAppDispatch } from 'redux-stores';

const classes = BEM.with('Sidebar');
const { setKeywordSearchMode, setPreviousConversationId } = actions;

const Sidebar = ({
  currentAppSelected,
  currentConversationId,
  currentConversation,
  currentOrganization,
  currentUser,
  hideDnd,
  isAvailabilityFeatureFlagEnabled,
  isKeywordInboxSearchFeatureFlagEnabled,
  selectedFilterBar,
  isProviderNetwork,
  isPFVideoCallEnabled,
  setCurrentConversationId,
  setPatientFilteredConversations,
  sidebarTab: patientSidebarTab,
  stopComposing,
}) => {
  const dispatch = useAppDispatch();

  const handleKeywordSearchMode = debounce(() => {
    dispatch(setPreviousConversationId(currentConversationId));
    dispatch(setKeywordSearchMode(true));
    stopComposing();
    setCurrentConversationId(null);
  }, 100);

  const renderSearchFragment = () => {
    const showDnd = currentUser.dnd && !hideDnd;
    return (
      <div
        className={classes('icon-container', {
          'negative-margin': !showDnd && selectedFilterBar !== 'Alerts',
        })}
        onClick={handleKeywordSearchMode}
      >
        <Button shape="circle" color="primary" icon="search" aria-label="inbox search" />
      </div>
    );
  };

  const _renderRoster = () => {
    let contentFragment = (
      <ConversationList
        currentConversation={currentConversation}
        selectedFilterBar={selectedFilterBar}
      />
    );
    if (selectedFilterBar === 'Alerts') {
      contentFragment = <AlertsFilterList />;
    }

    if (isProviderNetwork) {
      return (
        <Fragment>
          <div
            className={classes('header', {
              'flex-layout': isKeywordInboxSearchFeatureFlagEnabled,
            })}
          >
            <div
              className={classes('roster-container', {
                narrow: isKeywordInboxSearchFeatureFlagEnabled,
              })}
            >
              <RosterFilterBar currentOrganization={currentOrganization} />
            </div>
            {isKeywordInboxSearchFeatureFlagEnabled && (
              <div className={classes('search-container')}>{renderSearchFragment()}</div>
            )}
          </div>
          {currentAppSelected === AppTypes.MESSAGES && (
            <DndRoster
              title={isAvailabilityFeatureFlagEnabled ? "You're Unavailable" : 'Do Not Disturb'}
            />
          )}
          {contentFragment}
          <NewItemButton currentOrganization={currentOrganization} />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {setPatientFilteredConversations && <PatientSearchBar />}
          {!setPatientFilteredConversations && isPFVideoCallEnabled && <PatientSidebarTabPicker />}
          {currentAppSelected === 'Messages' && <DndRoster title={'You are set as "Away"'} />}
          {setPatientFilteredConversations || patientSidebarTab === PatientSidebarTabs.MESSAGES ? (
            <PatientConversationList />
          ) : (
            <CallLog />
          )}
        </Fragment>
      );
    }
  };

  return (
    <section aria-labelledby="conversation-list-heading" className={classes()}>
      <h2 id="conversation-list-heading" className="screen-reader-only">
        Conversation List
      </h2>
      {_renderRoster()}
    </section>
  );
};

Sidebar.propTypes = {
  currentAppSelected: PropTypes.string.isRequired,
  currentConversationId: PropTypes.string,
  currentOrganization: propTypes.organization,
  currentUser: propTypes.user,
  hideDnd: PropTypes.bool,
  isAvailabilityFeatureFlagEnabled: PropTypes.bool.isRequired,
  isKeywordInboxSearchFeatureFlagEnabled: PropTypes.bool,
  isPFVideoCallEnabled: PropTypes.bool.isRequired,
  selectedFilterBar: PropTypes.string.isRequired,
  setCurrentConversationId: PropTypes.func,
  sidebarTab: PropTypes.string.isRequired,
};

export default mobxInjectSelect({
  callStore: ['isPFVideoCallEnabled'],
  conversationStore: [
    'currentConversationId',
    'setCurrentConversationId',
    'setPatientFilteredConversations',
    'currentConversation',
  ],
  messengerStore: [
    'currentAppSelected',
    'currentOrganization',
    'isAvailabilityFeatureFlagEnabled',
    'isKeywordInboxSearchFeatureFlagEnabled',
  ],
  composeMessageStore: ['stopComposing'],
  networkStore: ['isProviderNetwork'],
  patientStore: ['sidebarTab'],
  rosterStore: ['selectedFilterBar'],
  sessionStore: ['currentUser'],
  userStore: ['hideDnd'],
})(Sidebar);
