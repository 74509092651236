import React, { useState, PropsWithChildren, ReactElement, useEffect } from 'react';
import { default as RCSlider } from 'rc-slider';

import spriteIcons from '../../images/sprite_icons.png';

import styles from './Slider.module.css';
import 'rc-slider/assets/index.css';

export type SliderOption<Value = number> = {
  label: string;
  value: Value;
};

export type SliderProps<Value = number> = {
  options?: SliderOption<Value>[];
  onChange?: (selectedOption: Value, index: number) => void;
  initialIndex?: number;
  initialLabel?: string;
  dataTestId?: string;
  disabled?: boolean;
};

const HANDLE_STYLE = {
  background: `url(${spriteIcons}) -1px -1685px no-repeat`,
  cursor: 'default',
  width: '30px',
  height: '30px',
  marginTop: '-13px',
  border: 'none',
  boxShadow: 'none',
};

const TRACK_STYLE = {
  backgroundColor: 'rgb(233, 28, 44)',
};

const RAIL_STYLE = {
  backgroundColor: 'rgb(168, 168, 168)',
  borderRadius: 'none',
};

const calculateStepSize = (numberOfOptions: number) => {
  if (numberOfOptions <= 0) return 1;
  return 100 / numberOfOptions;
};

// Without the dangling comma the compiler doesn't know that this is a generic type param
export const Slider = <Value,>({
  dataTestId,
  disabled = false,
  initialLabel,
  initialIndex = 0,
  options = [
    { label: '5 minutes', value: 5 },
    { label: '10 minutes', value: 10 },
    { label: '15 minutes', value: 15 },
    { label: '30 minutes', value: 30 },
    { label: '45 minutes', value: 45 },
    { label: '1 hour', value: 60 },
    { label: '2 hours', value: 120 },
    { label: '4 hours', value: 240 },
    { label: '6 hours', value: 360 },
    { label: '12 hours', value: 720 },
    { label: '1 day', value: 1440 },
    { label: '2 days', value: 2880 },
    { label: '4 days', value: 5760 },
    { label: '5 days', value: 7200 },
    { label: '10 days', value: 14400 },
    { label: '20 days', value: 28800 },
    { label: '30 days', value: 43200 },
  ] as unknown as SliderOption<Value>[],
  onChange,
}: PropsWithChildren<SliderProps<Value>>): ReactElement | null => {
  const [selectedOptionLabel, setSelectedOptionLabel] = useState(
    initialLabel || (options.length > 0 ? options[0].label : '')
  );

  const stepSize = calculateStepSize(options.length - 1);
  const [sliderValue, setSliderValue] = useState(initialIndex * stepSize);

  useEffect(() => {
    setSliderValue(initialIndex * stepSize);
    const index = Math.round(sliderValue / stepSize);
    setSelectedOptionLabel(options[index]?.label);
  }, [initialIndex, stepSize, options, sliderValue]);

  const handleOnChange = (slideValue: number) => {
    const index = Math.round(slideValue / stepSize);
    onChange && onChange(options[index].value, index);
    setSelectedOptionLabel(options[index]?.label);
  };

  return (
    <div className={styles.container} data-test-id={dataTestId}>
      <RCSlider
        value={sliderValue}
        handleStyle={HANDLE_STYLE}
        step={stepSize}
        trackStyle={TRACK_STYLE}
        railStyle={RAIL_STYLE}
        onChange={handleOnChange}
        disabled={disabled}
      />
      <div className={styles.label}>{selectedOptionLabel}</div>
    </div>
  );
};
