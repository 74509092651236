import React, { useEffect, useRef, useState } from 'react';
import difference from 'lodash/difference';
import _ from 'lodash';
import { Button } from '@tigerconnect/web-component-library';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BEM from 'common/bem';
import SearchTypes, {
  GROUP_MEMBER_SEARCH_TYPES,
  PROVIDER_SEARCH_TYPES,
} from 'models/enums/SearchTypes';
import { ReactComponent as CreateGroupIcon } from 'common/images/create-group-button-icon.svg';
import { ReactComponent as AddGroupImageIcon } from 'common/images/add-group-image-icon.svg';
import { ReactComponent as PatientReferenceIcon } from 'common/images/patient-reference-button-icon.svg';
import { ReactComponent as CloseImageIcon } from 'common/images/close-icon-pbx.svg';

import propTypes from 'common/propTypes';
import { mobxInjectSelect, searchPlaceholder } from 'common/utils';
import { NewMessageRecipientPicker } from 'common/components';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';
import { PatientReferenceCard } from 'common/components/PatientContext';
import { Images } from 'models/enums';

const classes = BEM.with('NewMessageHeader');

const { setModal, setSelectedPatientReference } = actions;
const { fetchPatientCareTeamThunk } = thunk;

function NewMessageHeader({
  allowPatientBroadcastLists,
  currentOrganizationId,
  findUser,
  isCreateGroupModeOn,
  isComposeFromLink,
  isNewConversationUIFeatureFlagEnabled,
  isNoSearchResultsOnEmptyQueryEnabled,
  isProviderNetwork,
  isTeamActivation,
  newGroupName,
  selectedRecipients,
  selectRecipients,
  sender,
  setNewGroupName,
  setPatientReferenceSelected,
  setIsCreateGroupModeOn,
  setAvatar,
  stopComposing,
  openModal,
}) {
  const dispatch = useAppDispatch();
  const imageInputRef = useRef(null);
  const [avatarDetails, setAvatarDetails] = useState(null);
  const [showAddCareTeamButton, setShowAddCareTeamButton] = useState(true);
  const [recipientList, setRecipientList] = useState([]);
  const [careTeamMembersList, setCareTeamMembersList] = useState([]);
  const {
    isPatientContextEnabled,
    selectedPatientReference,
    patientCareTeamCompose,
    isCareTeamAllowed,
    sidebarCollapsed,
  } = useAppSelector(
    ({
      ui,
      patientContext: {
        selectedPatientReference,
        isPatientContextEnabled,
        patientCareTeamCompose,
        isCareTeamAllowed,
      },
    }) => ({
      sidebarCollapsed: ui.sidebarCollapsed,
      selectedPatientReference,
      isPatientContextEnabled,
      patientCareTeamCompose,
      isCareTeamAllowed,
    })
  );

  useEffect(
    function cleanup() {
      return () => {
        dispatch(setModal(undefined));
        dispatch(setSelectedPatientReference(undefined));
        setIsCreateGroupModeOn(false);
        setNewGroupName('');
        setAvatar(null);
      };
    },
    [dispatch, setIsCreateGroupModeOn, setAvatar, setNewGroupName]
  );

  const isPatientOrContact =
    selectedRecipients.length > 0 &&
    (selectedRecipients[0].isPatient || selectedRecipients[0].isPatientContact);

  const isBroadcastList =
    selectedRecipients.length === 1 &&
    selectedRecipients[0].$entityType === SearchTypes.DISTRIBUTION_LIST;

  let searchTypes = PROVIDER_SEARCH_TYPES;
  if (!isProviderNetwork) {
    searchTypes = [SearchTypes.PATIENT];

    if (allowPatientBroadcastLists) {
      searchTypes.push(SearchTypes.PATIENT_DISTRIBUTION_LIST);
    }
  } else if (isCreateGroupModeOn) {
    searchTypes = GROUP_MEMBER_SEARCH_TYPES;
  }

  useEffect(() => {
    setPatientReferenceSelected(selectedPatientReference);
  }, [selectedPatientReference, setPatientReferenceSelected]);

  const _handleSelectRecipients = (selected) => {
    setRecipientList(selected);
    selectRecipients(selected);
  };

  const _onTab = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const _openNewGroup = () => {
    setIsCreateGroupModeOn(true);
  };

  const _openPatientReferenceModal = () => {
    dispatch(setModal({ name: 'patientReferenceSelect' }));
  };

  const findPatientCareTeam = async (patientCareTeamMembers) => {
    const items = [];
    await Promise.all(
      patientCareTeamMembers.hits.map(async (hit) => {
        try {
          const member = await findUser(hit.accountToken, currentOrganizationId);
          items.push(member);
        } catch (e) {
          console.error('User not found', e);
        }
      })
    );
    return items;
  };

  const findCareTeamMembers = async () => {
    dispatch(
      fetchPatientCareTeamThunk({
        patientContextId: selectedPatientReference.uuid,
        isRequestedFromCompose: true,
      })
    );
  };

  useEffect(() => {
    const careTeamResponse = async () => {
      const careTeam = await findPatientCareTeam(patientCareTeamCompose);
      const filteredCareTeam = careTeam.filter((member) => member !== undefined);
      setCareTeamMembersList(filteredCareTeam);
    };
    if (selectedPatientReference && isPatientContextEnabled && isCareTeamAllowed) {
      careTeamResponse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patientCareTeamCompose,
    selectedPatientReference,
    isPatientContextEnabled,
    isCareTeamAllowed,
  ]);

  const addCareTeamToCompose = () => {
    const recipientArray = _.uniqBy([...recipientList, ...careTeamMembersList], 'id');
    selectRecipients(recipientArray);
  };

  useEffect(() => {
    if (selectedPatientReference && isCareTeamAllowed) findCareTeamMembers();
  }, [selectedPatientReference, isCareTeamAllowed]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const careTeamMemberIdArray = careTeamMembersList.map((member) => member.id);
    const selectedRecipientMemberIdArray = selectedRecipients.map((recipient) => recipient.id);
    if (
      isPatientContextEnabled &&
      selectedPatientReference &&
      careTeamMembersList.length &&
      difference(careTeamMemberIdArray, selectedRecipientMemberIdArray).length
    ) {
      setShowAddCareTeamButton(true);
    } else {
      setShowAddCareTeamButton(false);
    }
  }, [careTeamMembersList, selectedRecipients, isPatientContextEnabled, selectedPatientReference]); //eslint-disable-line react-hooks/exhaustive-deps

  function _addGroupImage(e) {
    const image = e.target.files[0];
    e.target.value = null;
    if (!image.type.includes('image/')) return;
    if (image.size > Images.MAX_AVATAR_FILE_SIZE_BYTES) {
      openModal('rejectedFileSize', {
        maxFileSize: `${Images.MAX_AVATAR_FILE_SIZE_MB}MB`,
      });
    } else {
      setAvatar(image);
      setAvatarDetails({ name: image.name, src: URL.createObjectURL(image) });
    }
  }

  function removeAvatar() {
    setAvatar(null);
    setAvatarDetails(null);
  }

  const isCreatingGroup =
    isCreateGroupModeOn ||
    selectedRecipients?.length > 1 ||
    selectedPatientReference ||
    isTeamActivation;

  const groupInputVisibility =
    isNewConversationUIFeatureFlagEnabled || (!isPatientOrContact && isCreatingGroup);

  const isGroupActionsVisible =
    !isNewConversationUIFeatureFlagEnabled && !isBroadcastList && !isTeamActivation;

  return (
    <div className={classes('')}>
      <div className={classes('NewMessageHeader-container')}>
        <div className={classes('input-holder')}>
          <div className={classes('input-container')}>
            <label aria-label={'Conversation New Message'} className={classes('label')}>
              To:
            </label>
            <div className={classes('input')}>
              <NewMessageRecipientPicker
                autoFocus={!isComposeFromLink}
                className={
                  isProviderNetwork
                    ? 'new-message'
                    : isPatientOrContact
                    ? 'new-message-patient-compose'
                    : 'new-message-patient'
                }
                onChange={_handleSelectRecipients}
                onTab={_onTab}
                placeholder={searchPlaceholder.getRecipientPickerSearchPlaceholder({
                  allowPatientBroadcastLists,
                  isProviderNetwork,
                })}
                selectedPatientReferenceId={
                  selectedPatientReference && selectedPatientReference.uuid
                }
                sender={sender}
                searchTypes={searchTypes}
                selected={selectedRecipients}
                openOnType={isNoSearchResultsOnEmptyQueryEnabled}
              />
            </div>
          </div>
          {groupInputVisibility && (
            <>
              <div className={classes('input-container')}>
                <label aria-label={'Conversation New Message'} className={classes('label')}>
                  {isNewConversationUIFeatureFlagEnabled ? 'Topic:' : 'Name:'}
                </label>
                <input
                  className={classes('input', { isPatientOrContact })}
                  onChange={(e) => setNewGroupName(e.target.value)}
                  onKeyDown={(e) => e.stopPropagation()}
                  placeholder={
                    isNewConversationUIFeatureFlagEnabled
                      ? 'Type a topic or name for group'
                      : 'Edit name of this group'
                  }
                  value={newGroupName}
                />
              </div>
              {avatarDetails && (
                <div className={classes('avatar-container')}>
                  <label className={classes('label')}>Cover:</label>
                  <div className={classes('avatar-preview')}>
                    <img src={avatarDetails.src} alt={avatarDetails.name} /> {avatarDetails.name}
                    <div
                      className={classes('avatar-preview-cancel')}
                      onClick={() => removeAvatar()}
                    >
                      <CloseImageIcon />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {isProviderNetwork && (
          <>
            {isPatientContextEnabled && !selectedPatientReference && (
              <button
                className={classes('header-action-button')}
                onClick={_openPatientReferenceModal}
                data-test-id="addPatientReference"
              >
                <div
                  className={classNames(
                    classes('header-action-button-icon'),
                    classes('patient-reference-icon')
                  )}
                >
                  <PatientReferenceIcon />
                </div>
                Add Patient Reference
              </button>
            )}
            {showAddCareTeamButton && isCareTeamAllowed && (
              <button
                className={classes('header-action-button')}
                onClick={() => {
                  addCareTeamToCompose();
                }}
                data-test-id="addPatientCareTeam"
              >
                <div
                  className={classNames(
                    classes('header-action-button-icon'),
                    classes('patient-reference-icon')
                  )}
                >
                  <PatientReferenceIcon />
                </div>
                Add Care Team
              </button>
            )}
            {isGroupActionsVisible && (
              <>
                {!isCreatingGroup ? (
                  <button
                    className={classes('header-action-button')}
                    id={'compose-create-new-group'}
                    onClick={_openNewGroup}
                    data-test-id="conversationNewGroup"
                  >
                    <div className={classes('header-action-button-icon')}>
                      <CreateGroupIcon />
                    </div>
                    Create a Group
                  </button>
                ) : (
                  <>
                    <button
                      className={classes('header-action-button')}
                      id={'compose-add-group-image'}
                      onClick={() => {
                        imageInputRef.current.click();
                      }}
                      data-test-id="addGroupImage"
                    >
                      <div className={classes('header-action-button-icon')}>
                        <AddGroupImageIcon />
                      </div>
                      Add Group Image
                    </button>
                    <input
                      className={classes('add-group-image-input')}
                      type="file"
                      ref={imageInputRef}
                      onChange={_addGroupImage}
                      accept="image/*"
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      {selectedPatientReference && (
        <div className={classes('patient-reference-card-container')}>
          <PatientReferenceCard patient={selectedPatientReference} hasCancel />
        </div>
      )}
      {sidebarCollapsed && (
        <div onClick={stopComposing} className={classes('collapsed-stop-composing-button')}>
          <Button
            color={isProviderNetwork ? 'neutral' : 'patient'}
            shape="circle"
            icon="cancel"
            aria-label="close compose"
          />
        </div>
      )}
    </div>
  );
}

NewMessageHeader.propTypes = {
  allowPatientBroadcastLists: PropTypes.bool.isRequired,
  isProviderNetwork: PropTypes.bool.isRequired,
  newGroupName: PropTypes.string,
  selectedRecipients: propTypes.counterPartyArray,
  selectRecipients: PropTypes.func.isRequired,
  sender: propTypes.counterParty,
  setNewGroupName: PropTypes.func.isRequired,
  isTeamActivation: PropTypes.bool,
};

export default mobxInjectSelect({
  createGroupStore: ['setAvatar'],
  composeMessageStore: [
    'newGroupName',
    'sender',
    'selectedRecipients',
    'selectRecipients',
    'setNewGroupName',
    'setPatientReferenceSelected',
    'setIsCreateGroupModeOn',
    'stopComposing',
    'isCreateGroupModeOn',
    'isTeamActivation',
    'isComposeFromLink',
  ],
  messengerStore: [
    'currentOrganizationId',
    'isNoSearchResultsOnEmptyQueryEnabled',
    'isNewConversationUIFeatureFlagEnabled',
  ],
  modalStore: ['openModal'],
  networkStore: ['isProviderNetwork'],
  patientAdminStore: ['allowPatientBroadcastLists'],
  userStore: ['findUser'],
})(NewMessageHeader);
