import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';

import { mobxInjectSelect } from '../../common/utils';
import {
  Conversation,
  Visitor,
  VWR,
  VWRStaff,
  VisitStatus,
  ActionStatus,
  VirtualWaitingRoomStatus,
} from '../../types';

import TCClient from '../../client';
import { VWRAction, UpdateVisitAssignment, UpdateVisitorStatus, UpdateVisitCall } from './actions';
import { vwrReducer } from './reducer';
import { hydrateVWR, changeSelectedRoom, changeSelectedVisitor, getRoomList } from './thunk';

type FindConversationOptions = {
  groupId?: string;
  organizationId?: string;
};

export type MobxProps = {
  currentOrganizationId: string;
  currentUserId: string;
  findConversation: (
    conversationId: string,
    options: FindConversationOptions
  ) => Promise<Conversation>;
  selectConversation: (conversation: Record<string, unknown> | null) => Promise<void>;
  conversations: Conversation[];
  vwrNotification: { roomId: string; conversationId: string; visitorAccountToken: string } | null; // TODO TYPE can be any vwr event that needs to dispatch a notification
  clearVwrNotification: () => void;
};

export type VWRState = {
  loadedStatus: ActionStatus;
  mobxProps: MobxProps;
  roomList: VWR[];
  selectedRoom: VWR | null;
  selectedStatus: VisitStatus;
  staff: VWRStaff[];
  userHasRoomsAvailable: boolean;
  visitors: {
    count: number;
    hasAllVisitors: boolean;
    page: number;
    visitors: Visitor[];
    selectedVisitor: Visitor | null;
  };
};

export type VWRDispatch = React.Dispatch<VWRAction>;

type VwrContext = {
  state: VWRState;
  dispatch: VWRDispatch;
};

const VWRContext = React.createContext<VwrContext>({} as VwrContext);

export const useVWRContext = () => useContext(VWRContext);

type VWRContextProviderProps = { children: React.ReactNode };

const VWRContextProvider: React.FC<VWRContextProviderProps & MobxProps> = ({
  children,
  clearVwrNotification,
  conversations,
  currentOrganizationId,
  currentUserId,
  findConversation,
  selectConversation,
  vwrNotification,
}) => {
  const conversationsRef = useRef<Conversation[]>(conversations);
  const [hydrateComplete, setHydrateComplete] = useState(false);
  const initialState: VWRState = {
    loadedStatus: 'PENDING',
    mobxProps: {
      clearVwrNotification,
      currentUserId,
      conversations,
      currentOrganizationId,
      findConversation,
      selectConversation,
      vwrNotification,
    },
    roomList: [],
    selectedRoom: null,
    selectedStatus: 'INCOMPLETE',
    staff: [],
    userHasRoomsAvailable: false,
    visitors: {
      count: 0,
      hasAllVisitors: false,
      page: 0,
      selectedVisitor: null,
      visitors: [],
    },
  };

  const [state, dispatch] = useReducer(vwrReducer, initialState);

  useEffect(() => {
    const hydrate = async () => {
      try {
        await hydrateVWR(dispatch, {
          clearVwrNotification,
          currentUserId,
          conversations: conversationsRef.current,
          currentOrganizationId,
          findConversation,
          selectConversation,
          vwrNotification: null,
        });
        setHydrateComplete(true);
      } catch (e) {}
    };

    hydrate();

    return () => {
      setHydrateComplete(false);
    };
  }, [
    clearVwrNotification,
    currentOrganizationId,
    currentUserId,
    findConversation,
    selectConversation,
  ]);
  useEffect(() => {
    async function addNewVisitor(newVisitor: Visitor) {
      const existingReason = state.selectedRoom?.visitReasons?.find(
        (r) => r.reasonValue === newVisitor.reason
      );
      if (!existingReason && state.selectedRoom) {
        await changeSelectedRoom(dispatch, state.selectedRoom.id, {
          selectConversation,
          currentOrganizationId,
          findConversation,
          conversations,
        });
      } else {
        dispatch({ type: 'PREPEND_VISITOR', payload: newVisitor });
      }
    }

    function visitorStatusUpdate(payload: UpdateVisitorStatus['payload']) {
      dispatch({ type: 'UPDATE_STATUS', payload });
    }

    function visitAssignmentUpdate(payload: UpdateVisitAssignment['payload']) {
      dispatch({ type: 'UPDATE_ASSIGNMENT', payload });
    }

    function visitCallUpdate(payload: UpdateVisitCall['payload']) {
      dispatch({ type: 'UPDATE_CALL', payload });
    }

    function roomUpdate({
      roomId,
      status,
      version,
    }: {
      roomId: string;
      status: VirtualWaitingRoomStatus;
      version: number;
    }) {
      dispatch({
        type: 'UPDATE_ROOM',
        payload: { roomId, status, version },
      });
    }

    function staffUpdate({
      staff,
      roomId,
      version,
    }: {
      staff: VWRStaff[];
      roomId: string;
      version: number;
    }) {
      async function handleBeingAddedToANewRoom() {
        if (staff.find(({ accountToken }) => accountToken === currentUserId)) {
          await changeSelectedRoom(dispatch, roomId, {
            selectConversation,
            currentOrganizationId,
            findConversation,
            conversations,
          });
        }
      }

      async function handleBeingRemovedFromARoom() {
        const newRoomList = await getRoomList(dispatch, currentOrganizationId);
        if (!staff.find(({ accountToken }) => accountToken === currentUserId)) {
          if (newRoomList.length > 0 && roomId === state.selectedRoom?.id) {
            await changeSelectedRoom(dispatch, newRoomList[0].id, {
              selectConversation,
              currentOrganizationId,
              findConversation,
              conversations,
            });
          }
        }
      }
      if (state.roomList?.length === 0) {
        handleBeingAddedToANewRoom();
      } else {
        handleBeingRemovedFromARoom();
      }

      dispatch({
        type: 'UPDATE_STAFF',
        payload: { staff, roomId, version },
      });
    }

    TCClient.virtualWaitingRoom.on('vwr:visitor:add', addNewVisitor);
    TCClient.virtualWaitingRoom.on('vwr:room:update', roomUpdate);
    TCClient.virtualWaitingRoom.on('vwr:status:update', visitorStatusUpdate);
    TCClient.virtualWaitingRoom.on('vwr:staff:update', staffUpdate);
    TCClient.virtualWaitingRoom.on('vwr:assignment:update', visitAssignmentUpdate);
    TCClient.virtualWaitingRoom.on('vwr:call:update', visitCallUpdate);

    return () => {
      TCClient.virtualWaitingRoom.off('vwr:visitor:add', addNewVisitor);
      TCClient.virtualWaitingRoom.off('vwr:room:update', roomUpdate);
      TCClient.virtualWaitingRoom.off('vwr:status:update', visitorStatusUpdate);
      TCClient.virtualWaitingRoom.off('vwr:staff:update', staffUpdate);
      TCClient.virtualWaitingRoom.off('vwr:assignment:update', visitAssignmentUpdate);
      TCClient.virtualWaitingRoom.off('vwr:call:update', visitCallUpdate);
    };
  }, [
    clearVwrNotification,
    conversations,
    currentUserId,
    currentOrganizationId,
    findConversation,
    selectConversation,
    state.selectedRoom,
    vwrNotification,
    state.staff,
    state.roomList,
    dispatch,
  ]);

  useEffect(() => {
    async function navigateToRoom(dispatch: VWRDispatch, roomId: string, mobxProps: MobxProps) {
      await changeSelectedRoom(dispatch, roomId, mobxProps);
    }

    async function navigateToVisit(
      dispatch: VWRDispatch,
      selectedVisitor: Visitor,
      mobxProps: MobxProps
    ) {
      await changeSelectedVisitor(dispatch, selectedVisitor, mobxProps);
    }

    const mobxProps = {
      clearVwrNotification,
      currentUserId,
      currentOrganizationId,
      findConversation,
      selectConversation,
      conversations,
      vwrNotification,
    };

    if (
      vwrNotification &&
      state.selectedRoom !== null &&
      state.selectedRoom.id !== vwrNotification.roomId &&
      hydrateComplete
    ) {
      navigateToRoom(dispatch, vwrNotification?.roomId, mobxProps);
    }
    const shouldSelectVisit = vwrNotification && hydrateComplete;
    const selectedVisitor = state.visitors.visitors.find(
      (visitor) => visitor.visitorAccountToken === vwrNotification?.visitorAccountToken
    );
    if (shouldSelectVisit && selectedVisitor) {
      navigateToVisit(dispatch, selectedVisitor, mobxProps);
      clearVwrNotification();
    }
  }, [
    hydrateComplete,
    state.visitors.visitors,
    state.visitors.selectedVisitor,
    state.visitors,
    clearVwrNotification,
    state.selectedRoom,
    state.staff,
    vwrNotification,
    conversations,
    currentUserId,
    currentOrganizationId,
    findConversation,
    selectConversation,
  ]);

  const value = { state, dispatch };

  return <VWRContext.Provider value={value}>{children}</VWRContext.Provider>;
};

export default mobxInjectSelect<VWRContextProviderProps, MobxProps>({
  messengerStore: ['currentOrganizationId'],
  conversationStore: ['findConversation', 'selectConversation', 'conversations'],
  desktopAppStore: ['vwrNotification', 'clearVwrNotification'],
  sessionStore: ['currentUserId'],
})(VWRContextProvider);
