import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { pick } from 'ramda';
import shallowequal from 'shallowequal';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import BEM from '../../bem';
import { FROZEN_EMPTY_OBJECT, email, isTokenTigerPage, phone } from '../../utils';
import RoleIndicator from '../Roles/RoleIndicator';
import { AvatarImage } from '..';
import { Role } from '../../../types';
import { PresenceIndicator } from '../PresenceIndicator';
import { User } from './UserAvatar';
import { getUserInitials } from 'common/utils/getUserInitials';

const classes = BEM.with('AvatarImage');
const userAvatarClasses = BEM.with('UserAvatar');

const MODIFIERS_WITH_INITIALS = { initials: true };
const USER_PROPS = [
  '$notFound',
  '$placeholder',
  'avatarUrl',
  'displayName',
  'dnd',
  'firstName',
  'lastName',
  'presenceStatus',
];

type UserAvatarPresenceProps = {
  avatarUrl?: string;
  className?: string;
  currentOrganizationId: string;
  disableInitials?: boolean;
  displayName?: string;
  indicatorSize?: string;
  inverse?: boolean;
  isExtendedAutoForwardOptionsEnabled?: boolean;
  isMuted?: boolean;
  isSearch?: boolean;
  role?: Role;
  shouldUseMinWidth?: boolean;
  showDndAfStatus?: boolean;
  showPresenceIndicator?: boolean;
  showRoleIndicator?: boolean;
  size?: string | number;
  user: User;
};

type MobxProps = {
  subscribeUser: (userid: string) => void;
  unsubscribeUser: (userid: string) => void;
};

class UserAvatarPresence extends Component<UserAvatarPresenceProps & MobxProps> {
  static propTypes = {
    className: PropTypes.string,
    isMuted: PropTypes.bool,
    indicatorSize: PropTypes.string,
    shouldUseMinWidth: PropTypes.bool,
    showPresenceIndicator: PropTypes.bool,
    showRoleIndicator: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  userFields: Record<string, unknown> = {};
  _initialsStyle = {};

  componentDidUpdate(prevProps: UserAvatarPresenceProps) {
    if (prevProps.user.id !== this.props.user.id && !this.props.isSearch) {
      prevProps.user.id && this.props.unsubscribeUser(prevProps.user.id);
      this.props.user.id && this.props.subscribeUser(this.props.user.id);
    }
  }

  componentDidMount() {
    this.props.user.id && !this.props.isSearch && this.props.subscribeUser(this.props.user.id);
  }

  componentWillUnmount() {
    this.props.user.id && !this.props.isSearch && this.props.unsubscribeUser(this.props.user.id);
  }

  render() {
    const {
      avatarUrl,
      className,
      currentOrganizationId,
      disableInitials = false,
      displayName,
      indicatorSize = 'MEDIUM',
      isExtendedAutoForwardOptionsEnabled,
      isMuted = false,
      role,
      showDndAfStatus,
      showPresenceIndicator = false,
      showRoleIndicator = false,
      size,
      user,
      shouldUseMinWidth,
      isSearch,
      unsubscribeUser,
      subscribeUser,
      ...restProps
    } = this.props;

    const hasPresenceIndicator =
      showPresenceIndicator &&
      !user.isPatient &&
      !user.isPatientContact &&
      !user.isVisitor &&
      !(user.id && isTokenTigerPage(user.id));

    const isDndAfEnabledForCurrentOrg = isExtendedAutoForwardOptionsEnabled
      ? user?.profileByOrganizationId &&
        user?.profileByOrganizationId[currentOrganizationId]?.dndAutoForwardEntities?.length > 0
      : user?.profileByOrganizationId &&
        user?.profileByOrganizationId[currentOrganizationId]?.autoForwardReceiverIds?.length > 0;

    const appendChildren = showRoleIndicator ? (
      <RoleIndicator role={role} />
    ) : hasPresenceIndicator ? (
      <PresenceIndicator
        dnd={user.dnd}
        presence={user.presenceStatus}
        showDndAfStatus={showDndAfStatus || isDndAfEnabledForCurrentOrg}
        size={indicatorSize}
        userId={user.id}
      />
    ) : null;

    this.userFields = pick(USER_PROPS, user);

    let initials;
    let modifiers = FROZEN_EMPTY_OBJECT;
    let overrideChildren = null;
    const canShowInitials = !disableInitials && (typeof size !== 'number' || size > 20);

    if (
      canShowInitials &&
      !user.removedFromOrg &&
      !user.$placeholder &&
      !user.$notFound &&
      !user.avatarUrl &&
      user.displayName &&
      !phone.isPhone(user.displayName) &&
      !email.isEmail(user.displayName) &&
      (initials = getUserInitials(user))
    ) {
      modifiers = MODIFIERS_WITH_INITIALS;
      const newInitialsStyle: { fontSize?: number } = {};
      if (typeof size === 'number') {
        newInitialsStyle.fontSize = size < 50 ? Math.round(size * 0.47) : 24;
      }
      if (!shallowequal(this._initialsStyle, newInitialsStyle)) {
        this._initialsStyle = newInitialsStyle;
      }

      overrideChildren = (
        <span className={classes('initials')} style={this._initialsStyle}>
          {initials}
        </span>
      );
    }

    let entityType = 'user';

    const isPatientOrContact = user.isPatient || user.isPatientContact;
    if (isPatientOrContact) {
      entityType = (user?.patientContact || user?.patient)?.smsOptedOut
        ? 'singleProviderSmsOptedOut'
        : 'patientMessaging';
    }

    return (
      <AvatarImage
        {...restProps}
        appendChildren={appendChildren}
        avatarUrl={avatarUrl || user.avatarUrl}
        className={classNames(className, userAvatarClasses())}
        displayName={displayName || user.displayName}
        entityType={entityType}
        forceDefault={isPatientOrContact || user.removedFromOrg}
        isDisabled={user.isDisabled}
        isMuted={isMuted}
        modifiers={modifiers}
        shouldUseMinWidth={shouldUseMinWidth}
        size={size}
      >
        {overrideChildren}
      </AvatarImage>
    );
  }
}

export default mobxInjectSelect<UserAvatarPresenceProps, MobxProps>({
  presenceStore: ['subscribeUser', 'unsubscribeUser'],
})(UserAvatarPresence);
