import React, { useEffect, useState } from 'react';

import TCClient from '../../../client';
import BEM from '../../bem';
import BasicModal from '../BasicModal';
import { mobxInjectSelect, formatPhoneWithDash } from '../../utils';
import { Conversation, Visitor, VWRStaff, VisitStatus, VisitStatuses } from '../../../types';
import { useVWRContext } from '../../../contexts/VirtualWaitingRoom';
import PFCallOrigins from '../../../models/enums/PfCallOrigins';

import { ReactComponent as DropdownChevronSvg } from '../../images/dropdown-chevron.svg';
import { ReactComponent as PatientSvg } from '../../images/default-avatar--patientCare.svg';
import { ConversationPane } from '../../../widgets/messenger/components/ConversationPane';
import { ReactComponent as VideoIcon } from '../../images/icon-video.svg';
import InformProviderModal from './InformProviderModal';
import DropdownList from './DropdownList';

type ModalOptionsProps = {
  content: JSX.Element;
};

export type RoomProps = {};

type MobxProps = {
  markConversationAsRead: (c: Conversation) => Promise<void>;
  openModal: (modalName: string, options?: ModalOptionsProps) => void;
  videoCallSetUp: (user: Record<string, unknown>, origin: string) => void;
  isPFVideoCallEnabled: boolean;
  currentConversation: Conversation;
};

const statusList = Object.keys(VisitStatuses)
  .filter((key) => key !== 'INCOMPLETE')
  .map((id) => ({
    id: id as VisitStatus,
    name: VisitStatuses[id as VisitStatus],
  }));

const classes = BEM.with('VirtualWaitingRoomRoom');
const successModalClasses = BEM.with('VirtualWaitingRoomRoom');

function Room({
  markConversationAsRead,
  currentConversation,
  openModal,
  videoCallSetUp,
  isPFVideoCallEnabled,
}: RoomProps & MobxProps) {
  const {
    dispatch,
    state: {
      mobxProps: { currentOrganizationId },
      selectedRoom,
      staff,
      visitors: { selectedVisitor },
    },
  } = useVWRContext();
  const isCompletedStatus = selectedVisitor?.status === 'COMPLETED';

  const [isDropdownShowing, setIsDropdownShowing] = useState<boolean>(false);
  const [assignedStaff, setAssignedStaff] = useState<VWRStaff>();
  const [isCompletedModalOpen, setIsCompletedModalOpen] = useState<boolean>(false);
  const [isProviderModalOpen, setIsProviderModalOpen] = useState(false);
  const [informState, setInformState] = useState<'inform' | 'cancel'>();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  useEffect(() => {
    const initialSelectedStaff = staff.find(
      ({ accountToken }) => accountToken === selectedVisitor?.assignedStaffAccountId
    );
    setAssignedStaff(initialSelectedStaff);
  }, [selectedVisitor, staff]);

  useEffect(() => {
    if (selectedVisitor) {
      const state =
        selectedVisitor.activeCall && selectedVisitor.status !== 'COMPLETED' ? 'cancel' : 'inform';
      setInformState(state);
    }
  }, [selectedVisitor]);

  function handleStatusSelection({ index }: { index: number }) {
    if (statusList[index].id === 'COMPLETED') {
      toggleCompletedModal();
    } else {
      updateVisit('status', statusList[index].id);
    }
    toggleDropdown();
  }

  async function confirmCompletedPatient() {
    const err = await updateVisit('status', 'COMPLETED');
    const _err = await cancelInformProvider();
    if (!err && !_err) {
      toggleCompletedModal();
      openSuccessModal();
      if (selectedVisitor?.conversation) {
        await markConversationAsRead(selectedVisitor.conversation);
      }
    }
  }

  async function updateVisit<Field extends keyof Visitor>(
    targetField: Field,
    value: Visitor[Field]
  ) {
    let error = true;
    if (selectedVisitor) {
      try {
        const result = await TCClient.virtualWaitingRoom.updateVisit(currentOrganizationId, {
          roomId: selectedRoom?.id,
          visitId: selectedVisitor.id,
          options: {
            [targetField]: value,
            version: selectedVisitor.version,
          },
        });

        const updatedVisitor: Visitor = {
          ...selectedVisitor,
          [targetField]: value,
          version: result.version,
        };

        dispatch({ type: 'UPDATE_SELECTED_VISITOR', payload: updatedVisitor });

        error = false;
      } catch (e) {
        console.error(e);
        openModal('failure');
      }
      return error;
    }
  }

  function toggleDropdown() {
    setIsDropdownShowing(!isDropdownShowing);
  }

  function toggleCompletedModal() {
    setIsCompletedModalOpen(!isCompletedModalOpen);
  }

  function openSuccessModal() {
    if (openModal) {
      openModal('createSuccessModal', {
        content: _generateSuccessContent(),
      });
    }
  }

  function openCallModal() {
    if (isCompletedStatus) return;
    if (selectedVisitor) {
      const entity = {
        ...selectedVisitor,
        displayName: selectedVisitor.visitorFullName,
        id: selectedVisitor.visitorAccountToken,
        recipientId: selectedVisitor.visitorAccountToken,
        recipientName: selectedVisitor.visitorFullName,
      };
      videoCallSetUp(entity, PFCallOrigins.VWR_CONVERSATION);
    }
  }

  const cancelInformProvider = async () => {
    let error = true;
    if (selectedRoom && selectedVisitor && selectedVisitor.activeCall) {
      try {
        await TCClient.virtualWaitingRoom.markCallAsCompleted(currentOrganizationId, {
          callId: selectedVisitor.activeCall.id,
          reason: 'COMPLETED',
          roomId: selectedRoom.id,
          target: 'vwr_staff',
          visitId: selectedVisitor.id,
        });
        const updatedVisitor = { ...selectedVisitor, activeCall: null };
        dispatch({ type: 'UPDATE_SELECTED_VISITOR', payload: updatedVisitor });
        setInformState('inform');
        error = false;
      } catch {
        console.error('Failed to cancel inform');
      }
      setIsCancelModalOpen(false);
      return error;
    }
  };

  const selectedStatus = selectedVisitor?.status ?? 'NEW';
  const dropdownHeader = (
    <div
      className={classes('status-button', {
        disabled: isCompletedStatus,
      })}
      onClick={toggleDropdown}
    >
      <div className={classes('status')}>{VisitStatuses[selectedStatus]}</div>
      <DropdownChevronSvg className={classes('dropdown-svg')} />
    </div>
  );

  const _generateSuccessContent = () => (
    <div className={successModalClasses()}>
      <div className={successModalClasses('success-header')}>Success!</div>
      <div className={successModalClasses('success-text')}>
        <span
          className={successModalClasses('success-name')}
        >{`${selectedVisitor?.visitorFullName}'s `}</span>
        {'visit has been successfully completed.'}
      </div>
    </div>
  );

  return (
    <div className={classes()}>
      <div className={classes('header')}>
        <div className={classes('left-container')}>
          <div className={classes('name-container')}>
            <div className={classes('avatar-container')}>
              <PatientSvg className={classes('patient-icon')} />
            </div>
            <div className={classes('name')}>{selectedVisitor?.visitorFullName}</div>
            {isPFVideoCallEnabled && (
              <button
                className={classes('video-icon', {
                  isCompletedStatus: isCompletedStatus,
                })}
                onClick={openCallModal}
                aria-label="Open call modal"
              >
                <VideoIcon aria-hidden />
              </button>
            )}
          </div>
          <div className={classes('details-container')}>
            <div className={classes('mobile-container')}>
              <div className={classes('details-header')}>MOBILE PHONE</div>
              {selectedVisitor && (
                <div className={classes('mobile-phone')}>
                  {formatPhoneWithDash(selectedVisitor?.visitorMobilePhone)}
                </div>
              )}
            </div>
            <div>
              <div className={classes('details-header')}>STAFF</div>
              <div className={classes('assignment-container')}>
                <DropdownList
                  disabled={isCompletedStatus}
                  width={230}
                  shouldScroll
                  shouldShowSearch
                  values={staff.map(({ accountToken: id, name }) => ({ id, name }))}
                  selectedValue={{ id: assignedStaff?.accountToken }}
                  setSelectedValue={({ id }) => {
                    if (staff) {
                      const selectedStaff = staff.find(({ accountToken }) => accountToken === id);
                      if (selectedStaff) {
                        setAssignedStaff(selectedStaff);
                        updateVisit('assignedStaffAccountId', selectedStaff.accountToken);
                      }
                    }
                  }}
                  primaryHeader={
                    <div className={classes('assignment-button')}>
                      <div className={classes('assignment')}>
                        {assignedStaff ? assignedStaff.name : 'UNASSIGNED'}
                      </div>
                      {!isCompletedStatus && (
                        <DropdownChevronSvg className={classes('dropdown-svg')} />
                      )}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes('right-container')}>
          <DropdownList
            disabled={isCompletedStatus}
            primaryHeader={dropdownHeader}
            selectedValue={{ id: selectedVisitor?.status }}
            setSelectedValue={handleStatusSelection}
            values={statusList}
            width={152}
          />
          {informState === 'inform' && (
            <button
              onClick={() => setIsProviderModalOpen(true)}
              className={classes('inform-button', {
                disabled: isCompletedStatus,
              })}
              disabled={isCompletedStatus}
            >
              <div>Send Invite</div>
            </button>
          )}
          {informState === 'cancel' && (
            <button
              onClick={() => setIsCancelModalOpen(true)}
              className={classes('inform-button', {
                disabled: isCompletedStatus,
                cancel: true,
              })}
              disabled={isCompletedStatus}
            >
              <div>Cancel Invite</div>
            </button>
          )}
        </div>
      </div>
      <InformProviderModal
        isOpen={isProviderModalOpen}
        onClose={({ informSuccess }) => {
          setIsProviderModalOpen(false);
          if (informSuccess) {
            setInformState('cancel');
          }
        }}
      />
      <BasicModal
        ariaLabelBody={'Confirm Cancel Invite Info'}
        ariaLabelCancelButton={'Confirm Cancel Invite Cancel'}
        ariaLabelCloseButton={'Confirm Cancel Invite Close'}
        ariaLabelHeader={'Confirm Cancel Invite Header'}
        ariaLabelSubmitButton={'Confirm Cancel Invite'}
        bodyText={`This will end an active call between patient and staff abruptly. Do you wish to proceed?`}
        headerText={'Cancel Invite'}
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        onSubmit={cancelInformProvider}
        size={'medium'}
        theme={'danger'}
        submitText={'CONFIRM'}
      />
      <BasicModal
        ariaLabelBody={'Confirm Patient Completion info'}
        ariaLabelCancelButton={'Confirm Patient Completion Cancel'}
        ariaLabelCloseButton={'Confirm Patient Completion Close'}
        ariaLabelHeader={'Confirm Patient Completion Header'}
        ariaLabelSubmitButton={'Confirm Patient Completion'}
        bodyText={`Do you want to move ${selectedVisitor?.visitorFullName} to completed state?`}
        headerText={'Confirm Patient Completion'}
        isOpen={isCompletedModalOpen}
        onClose={toggleCompletedModal}
        onSubmit={confirmCompletedPatient}
        size={'medium'}
        submitText={'CONFIRM'}
        theme={'patient'}
      />
      <ConversationPane
        currentConversation={currentConversation}
        hideHeader
        hideFooter={isCompletedStatus}
      />
    </div>
  );
}

export default mobxInjectSelect<RoomProps, MobxProps>({
  conversationStore: ['markConversationAsRead', 'currentConversation'],
  callStore: ['videoCallSetUp', 'isPFVideoCallEnabled'],
  modalStore: ['openModal'],
})(Room);
