import { useEffect, useRef } from 'react';
import KeywordMessageBubble from './KeywordMessageBubble';
import BEM from 'common/bem';
import mobxInjectSelect from 'common/utils/mobxInjectSelect';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';
import {
  KeywordSearchSecondLevelResultValue,
  KeywordSearchSecondLevelResult,
} from 'js-sdk/src/apis/SearchAPI';
import { useRTKQueryInfiniteResults } from 'common/hooks/useRTKQueryInfiniteResults';
import { useSearchAllQuery } from 'redux-stores/KeywordSearch/keywordSearchSecondaryApi';

const classes = BEM.with('KeywordSearchSideBar');

const { setIsFetching } = actions;

type MobxProps = {
  currentUserId: string;
  currentOrganizationId: string;
  myRoleIds: string[];
};

const KeywordSearchResultsDetails = ({
  currentOrganizationId,
  currentUserId,
  myRoleIds,
}: MobxProps) => {
  const keywordSearchSeeAllRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const { selectedConversationId, keywordSearchTerm } = useAppSelector(
    (state) => state.keywordSearch
  );

  const queryArguments = {
    searchValue: keywordSearchTerm,
    orgId: currentOrganizationId,
    conversationId: selectedConversationId,
  };

  const { results, isFetching, response } = useRTKQueryInfiniteResults(
    useSearchAllQuery,
    {
      args: queryArguments,
    },
    { ref: keywordSearchSeeAllRef }
  );
  const { continuation } = response?.metadata || {};

  useEffect(() => {
    if (isFetching && continuation === undefined) {
      dispatch(setIsFetching(true));
    } else {
      dispatch(setIsFetching(false));
    }
  }, [isFetching, dispatch, continuation]);

  const renderMessageResults = () => {
    return (
      <div className={classes('conversation-result-seeAll')}>
        <div className={classes('message-results')}>
          {results?.map((result: KeywordSearchSecondLevelResult) => {
            if (result?.values) {
              return result.values.map((value: KeywordSearchSecondLevelResultValue) => {
                return (
                  <KeywordMessageBubble
                    key={value.entity.message_id}
                    messageId={value.entity.message_id}
                    conversationMetadata={value.conversation_metadata}
                    currentUserId={currentUserId}
                    senderToken={value.entity.sender_token}
                    myRoleIds={myRoleIds}
                    body={value.entity.body}
                    senderDisplayName={value.entity.sender_display_name}
                    createdTime={value.entity.created_time_server}
                    isGroup={value.entity.is_group}
                    highlight={value.entity.highlight}
                    isSecondLevel
                  />
                );
              });
            }
            return null;
          })}
        </div>
      </div>
    );
  };

  const hasSelectedConversation = Boolean(selectedConversationId && selectedConversationId.trim());

  return (
    <div
      className={classes('results-container', {
        block: hasSelectedConversation,
        none: !hasSelectedConversation,
      })}
      ref={keywordSearchSeeAllRef}
    >
      {renderMessageResults()}
    </div>
  );
};

export default mobxInjectSelect<{}, MobxProps>({
  roleStore: ['myRoleIds'],
  sessionStore: ['currentUserId'],
  messengerStore: ['currentOrganizationId'],
})(KeywordSearchResultsDetails);
