import React, { useCallback, useEffect, useRef } from 'react';
import { sortBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { MapStateToProps, reduxInjectSelect } from '../../../utils/reduxInjectSelect';
import BEM from '../../../bem';
import { DotsIndicator, ScrollingList } from '../..';
import InlineModal from '../Modals/InlineModal';
import { actions, ReduxState, thunk } from '../../../../redux-stores';
import { defaultNewRole } from '../../../../redux-stores/Collaboration/roles';
import { defaultNewTeam } from '../../../../redux-stores/Collaboration/teams';
import { CollabEntity, Tag } from '../../../../types';
import { CollaborationCategories, CollaborationTabs } from '../../../../models/enums';
import { CollaborationTab } from '../../../../models/enums/CollaborationTabs';
import { shouldFetchMore } from '../../../../redux-stores/Collaboration/util';
import EntitiesListItem from './EntitiesListItem';

const { selectEntity, setActiveTab, setCheckedEntities, setNewEntityType } = actions;
const { fetchEntities } = thunk;
const classes = BEM.with('CollaborationRoles');
const modalClasses = BEM.with('CollaborationInlineModal');
const { ALL, MYROLES, MYTEAMS, NOTTAGGED } = CollaborationCategories;
const { ALL: ALLTAB, ACTIVEROLES, ACTIVETEAMS, ROLES, SAVED, TEAMS } = CollaborationTabs;

const reduxSelectors = {
  collab: [
    'isRolesAdmin',
    'isRolesEnabled',
    'isTeamAdmin',
    'isTeamsEnabled',
    'loadRefresh',
    'selectedOrgId',
  ],
  entities: [
    'activeTab',
    'checkedEntityIds',
    'entityResultsCount',
    'isLoadingEntities',
    'entitiesById',
    'entityContinuation',
    'entitiesSearchQuery',
    'newEntityType',
    'searchKey',
    'selectedEntity',
  ],
  tags: ['checkedTagsById', 'selectedCategory', 'selectedTag', 'filteredTags'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type EntitiesListProps = {};

function EntitiesList({
  activeTab,
  checkedEntityIds,
  checkedTagsById,
  isRolesAdmin,
  isRolesEnabled,
  isTeamAdmin,
  isTeamsEnabled,
  isLoadingEntities: loading,
  loadRefresh,
  entitiesById,
  entityContinuation,
  entitiesSearchQuery,
  newEntityType,
  searchKey,
  selectedCategory,
  selectedOrgId,
  selectedEntity,
  selectedTag,
  filteredTags,
}: ReduxProps & EntitiesListProps) {
  const dispatch = useDispatch();

  const roleListRef = useRef<ScrollingList>(null);
  const lastFetchedContinuation = useRef<string | undefined>(undefined);
  let filterTabs: CollaborationTab[] = [ALLTAB, ROLES, TEAMS];

  if (selectedCategory === MYROLES) filterTabs = [ACTIVEROLES, SAVED];
  if (selectedCategory === MYTEAMS) filterTabs = [ACTIVETEAMS, SAVED];

  const newEntity: CollabEntity[] =
    newEntityType === 'role' ? [defaultNewRole] : newEntityType === 'team' ? [defaultNewTeam] : [];
  const hideNewEntityPlaceholder =
    (newEntityType === 'role' && activeTab === TEAMS) ||
    (newEntityType === 'team' && activeTab === ROLES);
  const results: CollabEntity[] = hideNewEntityPlaceholder
    ? Object.values(entitiesById)
    : newEntity.concat(
        sortBy(Object.values(entitiesById), (c: CollabEntity) => c.displayName?.toLocaleLowerCase())
      );

  const performFetch = useCallback(
    (continuation: string | undefined) => {
      lastFetchedContinuation.current = continuation;
      fetchEntities(dispatch, selectedOrgId, {
        activeTab,
        ...(continuation ? { continuation } : null),
        isRolesEnabled,
        isTeamsEnabled,
        name: entitiesSearchQuery || '',
        category: selectedCategory,
        tagIds:
          selectedCategory === MYROLES || selectedCategory === MYTEAMS
            ? filteredTags.map((t: Tag) => t.id)
            : selectedTag
            ? [selectedTag.id]
            : Object.keys(checkedTagsById),
      });
    },
    [
      activeTab,
      checkedTagsById,
      dispatch,
      entitiesSearchQuery,
      filteredTags,
      isRolesEnabled,
      isTeamsEnabled,
      selectedCategory,
      selectedOrgId,
      selectedTag,
    ]
  );

  useEffect(() => {
    if (!selectedOrgId) return;
    performFetch(undefined);
  }, [
    activeTab,
    checkedTagsById,
    dispatch,
    entitiesSearchQuery,
    filteredTags,
    isRolesEnabled,
    isTeamsEnabled,
    performFetch,
    searchKey,
    selectedCategory,
    selectedOrgId,
    selectedTag,
  ]);

  useEffect(() => {
    if (roleListRef?.current) {
      roleListRef?.current?.recomputeRowHeights();
    }
  }, [newEntityType, results]);

  const openEntity = (id: string) => {
    dispatch(selectEntity(entitiesById[id]));
  };

  const toggleEntity = useCallback(
    (entityId: string, isChecked: boolean) => {
      let newCheckedEntities = [];
      if (isChecked) {
        newCheckedEntities = checkedEntityIds.filter((id) => id !== entityId);
      } else {
        newCheckedEntities = [...checkedEntityIds, entityId];
      }
      dispatch(setCheckedEntities(newCheckedEntities));
    },
    [checkedEntityIds, dispatch]
  );

  const rowRenderer = ({
    key,
    index,
    style,
  }: {
    key: string;
    index: number;
    style?: Record<string, unknown>;
  }) => {
    if (
      shouldFetchMore(index, results.length, entityContinuation, lastFetchedContinuation.current)
    ) {
      performFetch(entityContinuation);
    }

    if (!results[index]) return <div key={key} style={style}></div>;

    const isRoleAndCanEdit = results[index].$entityType === 'role' && isRolesAdmin;
    const isTeamAndCanEdit = results[index].$entityType === 'team' && isTeamAdmin;

    return (
      <div key={key} style={style}>
        <EntitiesListItem
          hasCheck={isRoleAndCanEdit || isTeamAndCanEdit}
          hideNewEntityPlaceholder={hideNewEntityPlaceholder}
          isChecked={checkedEntityIds.includes(results[index].id)}
          newEntityType={index === 0 ? newEntityType : undefined}
          key={results[index]?.id}
          selected={
            selectedEntity?.id === results[index]?.id ||
            (!hideNewEntityPlaceholder && !!newEntityType && index === 0)
          }
          selectedCategory={selectedCategory}
          select={openEntity}
          toggle={toggleEntity}
          id={results[index]?.id}
          entity={results[index]}
        />
      </div>
    );
  };

  const renderFilterTypes = filterTabs
    .filter((tabName) => {
      if (tabName === TEAMS) return isTeamsEnabled;
      else if (tabName === ROLES) return isRolesEnabled;
      else return true;
    })
    .map((tabName) => (
      <div
        key={tabName}
        className={classes('collaboration-filters-tab', {
          selected: tabName === activeTab,
        })}
        id={`roles-and-teams-${tabName.toLowerCase()}`}
        data-test-id={`${tabName.toLowerCase()}-filter-tab`}
        onClick={() => {
          dispatch(setActiveTab(tabName));
        }}
      >
        {tabName}
      </div>
    ));

  return (
    <div className={classes('roles-list-container')} data-test-id="entities list container">
      {(selectedCategory === MYROLES ||
        selectedCategory === MYTEAMS ||
        renderFilterTypes.length > 2) && (
        <div className={classes('filters-tab-list')}>{renderFilterTypes}</div>
      )}
      <div className={classes('batch-actions-container')}>
        {checkedEntityIds.length > 0 && (
          <div className={classes('batch-actions')}>
            <div className={classes('deselect')} onClick={() => dispatch(setCheckedEntities([]))}>
              <input name="deselect" type="checkbox" checked readOnly />
              <label htmlFor="deselect">Deselect</label>
            </div>
          </div>
        )}
      </div>
      {!loading && results.length !== 0 && (
        <div className={classes('scrolling-list-container')}>
          <ScrollingList
            ref={roleListRef}
            rowRenderer={rowRenderer}
            rowCount={results.length + 2}
            rowHeight={70}
          />
        </div>
      )}
      {(loadRefresh || loading) && (
        <div className={classes('loading-holder')}>
          <DotsIndicator color="#828282" size={12} marginTop={30} marginRight={5} />
        </div>
      )}
      {!loading &&
        !newEntityType &&
        results.length === 0 &&
        (entitiesSearchQuery || selectedCategory === MYROLES || selectedCategory === MYTEAMS ? (
          <div className={classes('no-results')}>{`No results${
            entitiesSearchQuery ? ` for "${entitiesSearchQuery}"` : ''
          } found`}</div>
        ) : (
          <InlineModal id="new-role">
            <div className={modalClasses('inline-header')}>
              {activeTab === TEAMS
                ? 'TEAMS'
                : activeTab === ROLES
                ? 'ROLES'
                : selectedCategory === ALL
                ? 'ALL'
                : 'THIS TAG IS EMPTY'}
            </div>
            <div className={modalClasses('body-text')}>
              {!isRolesEnabled && !isTeamsEnabled && (
                <p>Roles and Teams are not enabled for this organization</p>
              )}
              {selectedCategory === ALL &&
                (isRolesEnabled || isTeamsEnabled) &&
                `Currently this organization has no ${
                  activeTab === TEAMS
                    ? 'tagged teams'
                    : activeTab === ROLES
                    ? 'tagged roles'
                    : 'tagged items'
                }.`}
              {selectedCategory === NOTTAGGED &&
                `There are currently no ${
                  activeTab === ALLTAB ? 'items' : activeTab === ROLES ? 'roles' : 'teams'
                } without a tag.`}
              {selectedCategory === '' &&
                `Currently this tag has no ${
                  activeTab === ALLTAB ? 'items' : activeTab === ROLES ? 'roles' : 'teams'
                } in it.`}
            </div>
            {activeTab !== 'Teams' && isRolesAdmin && isRolesEnabled && (
              <button
                className={modalClasses('button')}
                onClick={() => dispatch(setNewEntityType('role'))}
                data-test-id="create new role"
              >
                {'Create a new role'}
              </button>
            )}
            {activeTab !== ROLES && isTeamAdmin && isTeamsEnabled && (
              <button
                className={modalClasses('button')}
                onClick={() => dispatch(setNewEntityType('team'))}
                data-test-id="create new team"
              >
                {'Create a new team'}
              </button>
            )}
          </InlineModal>
        ))}
    </div>
  );
}

export default reduxInjectSelect<EntitiesListProps, ReduxProps, ReduxState>(reduxSelectors)(
  EntitiesList
);
