import { action, observable, makeObservable } from 'mobx';
import { FROZEN_EMPTY_ARRAY } from '../common/utils';

export default class TypingStatusStore {
  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  mounted() {
    this.client.on('typing:change', this._onTypingChange);
    this._byContextId = new Map();
  }

  dispose() {
    this.client.removeListener('typing:change', this._onTypingChange);
  }

  @action('TypingStatusStore.startTyping') startTyping = async (counterPartyId, { senderId }) => {
    await this.client.typingStatus.startTyping(counterPartyId, {
      senderId,
      organizationId: this.stores.messengerStore.currentOrganizationId,
    });
  };

  _onTypingChange = action(({ contextId, contextType, isTyping, userId }) => {
    if (!this._byContextId.has(contextId)) {
      if (contextType === this.client.enums.TypingContext.GROUP) {
        this._byContextId.set(contextId, observable({ typers: [] }));
      } else {
        this._byContextId.set(contextId, {
          [userId]: observable({ typers: [] }),
        });
      }
    }

    let state;
    if (contextType === this.client.enums.TypingContext.GROUP) {
      state = this._byContextId.get(contextId);
    } else {
      const orgState = this._byContextId.get(contextId);
      if (!orgState[userId]) orgState[userId] = observable({ typers: [] });
      state = orgState[userId];
    }

    const { typers } = state;
    if (isTyping) {
      if (!typers.includes(userId)) {
        typers.push(userId);
        state.typers = typers;
      }
    } else {
      const index = typers.indexOf(userId);
      if (index !== -1) {
        typers.splice(index, 1);
        state.typers = typers;
      }
    }
  });

  isUserTyping = (userId: string) => {
    const { currentOrganizationId: organizationId } = this.stores.messengerStore;
    if (!this._byContextId.has(organizationId)) {
      this._byContextId.set(organizationId, {
        [userId]: observable({ typers: [] }),
      });
    }

    const orgState = this._byContextId.get(organizationId);
    if (!orgState[userId]) orgState[userId] = observable({ typers: [] });

    return orgState[userId].typers.includes(userId);
  };

  getGroupTyperIds = (groupId: string) => {
    if (!this._byContextId.has(groupId)) {
      this._byContextId.set(groupId, observable({ typers: [] }));
    }

    return this._byContextId.get(groupId).typers;
  };

  getTypersForCounterParty = (counterParty) => {
    if (!counterParty) return FROZEN_EMPTY_ARRAY;
    const { currentRolesIds } = this.stores.roleStore;
    const typers = [];

    const typerIds =
      counterParty.$entityType === 'group'
        ? this.getGroupTyperIds(counterParty.id)
        : this.isUserTyping(counterParty.id)
        ? [counterParty.id]
        : FROZEN_EMPTY_ARRAY;

    if (!typerIds) return FROZEN_EMPTY_ARRAY;

    for (const typerId of typerIds) {
      const user = this.entityStore.user.getById(typerId);
      if (!user) continue;
      if (user.botRoleId) {
        if (!currentRolesIds.includes(user.botRoleId)) {
          typers.push(user);
        }
      } else {
        typers.push(user);
      }
    }

    return typers;
  };
}
