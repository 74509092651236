import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Card } from '@tigerconnect/web-component-library';

import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';

import { ReactComponent as CloseButtonSvg } from '../../images/close-icon.svg';
import { BROADCAST_LIST_SEARCH_TYPES } from '../../../models/enums/SearchTypes';
import { useInfiniteSearchResults } from '../../hooks';
import { DotsIndicator } from '../index';
import ProviderSearchBoxResult from './ProviderSearchBoxResult';
const MIN_PROVIDER_RESULT = 20;

const classes = BEM.with('ProviderSearchBox');
const ProviderSearchBox = ({
  currentMemberIds,
  currentOrganization,
  clearSearchResults,
  isSearching,
  pendingMemberIds,
  search,
  fetchBLMembersToAdd,
  togglePendingMembers,
}) => {
  const [isFilteringSearchResults, setIsFilteringSearchResults] = useState(true);
  const [filteredEntitiesList, setFilteredEntitiesList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  let searchResultFragment;
  const showLoadingText = isSearching || isFilteringSearchResults;

  const { canLoadMore, resetSearch, results, scrollContainerRef, updateOptions } =
    useInfiniteSearchResults(fetchBLMembersToAdd, {
      organizationId: currentOrganization.id,
    });

  const searchForEntities = ({ target: { value } } = { target: { value: '' } }) => {
    setSearchQuery(value);
  };

  const loadMoreResults = useRef(() => {
    updateOptions({
      organizationId: currentOrganization.id,
    });
  });

  useEffect(() => {
    setIsFilteringSearchResults(true);

    const filteredEntities = [];
    const membersMap = currentMemberIds.reduce((map, id) => {
      map[id] = true;
      return map;
    }, {});

    results.forEach(({ entity }) => {
      if (!membersMap[entity.id] && !membersMap[entity.botUserId]) {
        filteredEntities.push(entity);
      }
    });
    if (filteredEntities.length < MIN_PROVIDER_RESULT && canLoadMore) {
      loadMoreResults.current(filteredEntities);
    } else {
      setFilteredEntitiesList(filteredEntities);
      setIsFilteringSearchResults(false);
    }
  }, [canLoadMore, currentMemberIds, results]);

  const reloadSearch = (options) => {
    updateOptions(options);
    resetSearch();
    clearSearchResults();
  };

  useEffect(() => {
    reloadSearch({
      organizationId: currentOrganization.id,
    });
    search({
      text: searchQuery,
      searchTypes: BROADCAST_LIST_SEARCH_TYPES,
      organization: currentOrganization,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, search, searchQuery]);

  if (showLoadingText) {
    searchResultFragment = <div className={classes('no-search-results')}>Loading...</div>;
  } else if (filteredEntitiesList.length > 0) {
    const groupedEntities = filteredEntitiesList.reduce((all, current) => {
      const types = all.map((v) => v.entity_type);
      const index = types.indexOf(current.$entityType);
      if (index !== -1) all[index].values.push(current);
      else
        all.push({
          entity_type: current.$entityType,
          values: [current],
        });
      return all;
    }, []);
    searchResultFragment = groupedEntities.map((entities) => {
      if (entities.entity_type === 'user') {
        return (
          <Card header="Individuals" theme="provider" type="category" key={'Individuals'}>
            {entities.values.map((individual, index) => (
              <ProviderSearchBoxResult
                isFirstEntry={index === 0}
                user={individual}
                key={individual.id}
                toggle={togglePendingMembers}
                pendingMemberIds={pendingMemberIds}
                entityType="user"
              />
            ))}
          </Card>
        );
      } else {
        return (
          <Card header="Roles" theme="provider" type="category" key={'Roles'}>
            {entities.values.map((role, index) => (
              <ProviderSearchBoxResult
                isFirstEntry={index === 0}
                user={role}
                key={role.id}
                toggle={togglePendingMembers}
                pendingMemberIds={pendingMemberIds}
                entityType="role"
              />
            ))}
          </Card>
        );
      }
    });
  } else {
    let noResultsText = 'No results found';
    if (searchQuery) {
      noResultsText += ` for "${searchQuery}"`;
    }

    searchResultFragment = <div className={classes('no-search-results')}>{noResultsText}</div>;
  }

  return (
    <div className={classes()} ref={scrollContainerRef}>
      <div className={classes('search-container')}>
        <div className={classes('search-text')}>
          <input
            aria-label="search bar"
            onChange={searchForEntities}
            placeholder="Search"
            type="text"
            value={searchQuery}
            autoFocus
          />
        </div>
        <div
          className={classes('close-container', {
            hide: searchQuery.length === 0,
          })}
          onClick={() => setSearchQuery('')}
        >
          <CloseButtonSvg />
        </div>
      </div>
      <div className={classes('search-results')}>{searchResultFragment}</div>
      {canLoadMore && !showLoadingText && (
        <div className={classes('loading')}>
          <DotsIndicator size={13} speed={0.5} />
        </div>
      )}
    </div>
  );
};

ProviderSearchBox.propTypes = {
  currentMemberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  clearSearchResults: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  fetchBLMembersToAdd: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  togglePendingMembers: PropTypes.func.isRequired,
  pendingMemberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default mobxInjectSelect({
  messengerStore: ['currentOrganization'],
  recipientPickerStore: ['clearSearchResults', 'isSearching', 'search', 'fetchBLMembersToAdd'],
})(ProviderSearchBox);
