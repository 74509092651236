import ReactDOM from 'react-dom';
import { action, observable, makeObservable } from 'mobx';

const HEADER_HEIGHT = 213;

export default class ProfileStore {
  @observable scrollbar = null;

  elements = {
    About: null,
    AccountSettings: null,
    DASettings: null,
    ExpertSettings: null,
    ProfileInfo: null,
    StatusSettings: null,
  };

  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  @action('ProfileStore.setScrollbars') setScrollbars = async (ref) => {
    this.scrollbar = ref;
  };

  @action('ProfileStore.setScrollbarsElement') setScrollbarsElement = async (ref, elementName) => {
    // eslint-disable-next-line react/no-find-dom-node
    const elementRef = ReactDOM.findDOMNode(ref);
    this.elements[elementName] = elementRef;
  };

  @action('ProfileStore.scrollToElement') scrollToElement = (elementName, scrollOptions) => {
    const element = this.elements[elementName];
    if (!element) return;

    const elementTop = element.getBoundingClientRect().top;
    this.scrollToPosition(elementTop, scrollOptions);
  };

  @action('ProfileStore.scrollToPosition') scrollToPosition = (
    elementTop,
    { offset = 0, shouldSpring = true } = {}
  ) => {
    const scrollbarValues = this.scrollbar.scrollbars.getValues();
    const scrollTop = elementTop + scrollbarValues.scrollTop - HEADER_HEIGHT - offset;
    this.scrollbar.scrollTop(scrollTop, { shouldSpring });
  };
}
