import { action, makeObservable } from 'mobx';

export default class OrganizationStore {
  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  @action('OrganizationStore.findAll') findAll = async () => {
    const organizations = await this.client.organizations.findAll({
      filter: { onlyApproved: true },
    });
    return this.entityStore.sync(organizations);
  };

  @action('OrganizationStore.find') find = async (organizationId: string) => {
    const organization = await this.client.organizations.find(organizationId);
    return this.entityStore.syncOne(organization);
  };

  @action('OrganizationStore.findEulaContent') findEulaContent = async (eulaUrl: string) => {
    const content = await this.client.organizations.findEulaContent(eulaUrl);
    return content;
  };

  @action('OrganizationStore.getOrganizationById') getOrganizationById = (
    organizationId: string
  ) => {
    return this.entityStore.organization.getById(organizationId);
  };

  @action('OrganizationStore.getPendingEulas') getPendingEulas = async () => {
    const eulas = await this.client.organizations.getPendingEulas();
    return eulas;
  };
}
