import { action, observable, makeObservable } from 'mobx';

export default class EntityListStore {
  EntityClass;
  currentCycle = {};
  dictionary = {};
  @observable length = 0;

  constructor(EntityClass, { entityStore }) {
    makeObservable(this);
    this.EntityClass = EntityClass;
    this.EntityClass.entityStore = entityStore;
    this.entityStore = entityStore;
  }

  @action('EntityListStore.sync') sync(entity, deepSync = false) {
    this._sync(entity, deepSync);
  }

  _sync = (entity, deepSync = false) => {
    let entry = this.dictionary[entity.id];
    if (!entry) {
      this.length++;
      entry = new this.EntityClass(entity.id);
      this.dictionary[entity.id] = entry;
    }

    if (!this.currentCycle[entity.id]) {
      this.entityStore._relationsUpdatedCount++;
      this.currentCycle[entity.id] = true;
      entry.updateFromEntity(entity, deepSync);
    }

    return entry;
  };

  resetCycles = () => {
    this.currentCycle = {};
  };

  @action('EntityListStore.remove') remove(entity) {
    this._remove(entity);
  }

  _remove = (entity) => {
    const entry = this.dictionary[entity.id];
    if (!entry) return false;
    this.length--;
    delete this.dictionary[entity.id];
  };

  getAll() {
    return Object.values(this.dictionary);
  }

  getById(id) {
    return this.dictionary[id];
  }
}
