import React, { useState, useRef } from 'react';
import trim from 'lodash/trim';

import { ActionBar } from '../../shared-components/ActionBar/ActionBar';
import { CtaButton } from '../../shared-components/CtaButton/CtaButton';
import { Input } from '../../shared-components/Input/Input';
import { PageHeader } from '../../shared-components/PageHeader/PageHeader';
import { Link } from '../../shared-components/Link/Link';

import TCClient from '../../../client';

import useClickOutside from '../../../common/hooks/useClickOutside';
import styles from './AddUser.module.css';
import { PasswordToolTip } from './PasswordToolTip';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const { setUsersPageView } = actions;

const FIELD_KEYS = [
  {
    label: 'First Name',
    fieldKey: 'firstName',
    isRequired: true,
  },
  {
    label: 'Last Name',
    fieldKey: 'lastName',
    isRequired: true,
  },
  {
    label: 'Display Name',
    fieldKey: 'displayName',
    isRequired: true,
  },
  {
    label: 'Email Address',
    fieldKey: 'emailAddress',
    isRequired: true,
  },
  {
    label: 'Password',
    fieldKey: 'password',
    isRequired: false,
  },
  {
    label: 'Title',
    fieldKey: 'title',
    isRequired: false,
  },
  {
    label: 'Department',
    fieldKey: 'department',
    isRequired: false,
  },
] as const;

const initialFields = {
  firstName: '',
  lastName: '',
  displayName: '',
  emailAddress: '',
  password: '',
  title: '',
  department: '',
};

type FieldKey = (typeof FIELD_KEYS)[number]['fieldKey'];

export default function AddUser() {
  const selectedOrganization = useAppSelector((state) => state.admin.selectedOrganization);
  const [fields, setFields] = useState<{ [key in FieldKey]: string }>(initialFields);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPasswordToolTip, setShowPasswordToolTip] = useState(false);
  const passwordInput = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  async function handleSubmit() {
    const doesntMeetPasswordRequirements =
      fields.password &&
      (fields.password.length < 8 ||
        !/[a-z]/.test(fields.password) ||
        !/\d/.test(fields.password) ||
        !/\W/.test(fields.password) ||
        !/[A-Z]/.test(fields.password));

    if (isSubmitting) return;
    if (!areAllRequiredFieldsCompleted()) {
      alert('Please Check Required* Fields.');
      return;
    }

    if (doesntMeetPasswordRequirements) {
      alert(
        'A lowercase\n' +
          'An uppercase\n' +
          'A number\n' +
          'A special character: e.g. ! @ # $ % ^ etc.\n' +
          'Minimum of 8 characters'
      );
      return;
    }
    try {
      setIsSubmitting(true);
      await TCClient.adminUsers.createUser({
        ...fields,
        organizationId: selectedOrganization.token,
      });
      setFields(initialFields);
      alert('User was successfully created.');
    } catch (e) {
      alert('Error when creating user.');
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  }

  function areAllRequiredFieldsCompleted() {
    for (let i = 0; i < FIELD_KEYS.length; i++) {
      const field = FIELD_KEYS[i];
      if (!trim(fields[field.fieldKey]) && field.isRequired) {
        return false;
      }
    }

    return true;
  }

  useClickOutside([passwordInput], () => {
    setShowPasswordToolTip(false);
  });

  return (
    <div className={styles.root}>
      <PageHeader
        label={'Users'}
        subLabel={'New User'}
        onClick={() => {
          dispatch(setUsersPageView({ selectedUsersPageView: 'users' }));
        }}
      />
      <div className={styles.headerSeparator} />
      <ActionBar tabButtons={[{ label: 'User Settings' }]} />
      <div>
        {FIELD_KEYS.map(({ label, fieldKey, isRequired }) => (
          <div className={styles.field}>
            <div className={styles.label}>
              {label}
              {isRequired ? '*' : ''}
            </div>
            <Input
              customCSS={styles.input}
              value={fields[fieldKey]}
              inputRef={label === 'Password' ? passwordInput : undefined}
              onFocus={() => {
                if (label === 'Password') {
                  setShowPasswordToolTip(true);
                } else {
                  setShowPasswordToolTip(false);
                }
              }}
              onInputChange={(e) => {
                setFields({ ...fields, [fieldKey]: e.target.value });
              }}
            />
            {label === 'Password' && showPasswordToolTip && (
              <div className={styles.passwordToolTipContainer}>
                <PasswordToolTip value={fields[fieldKey]} />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <CtaButton label={'Create User'} primary size={'large'} onClick={handleSubmit} />
        <div className={styles.canceContainer}>
          <Link
            onClick={() => {
              dispatch(setUsersPageView({ selectedUsersPageView: 'users' }));
            }}
          >
            Cancel
          </Link>
        </div>
      </div>
    </div>
  );
}
