import React, { useState } from 'react';
import Linkify from '../../../../common/components/Linkify/Linkify';

import AlertActionButton from './AlertActionButton';
import AlertBoldTextView from './AlertBoldTextView';
import AlertCardV2 from './AlertCardV2';
import AlertCompletedItem from './AlertCompletedItem';
import AlertEscalateHeader from './AlertEscalateHeader';
import AlertNurseHeader from './AlertNurseHeader';
import AlertRoleInfo from './AlertRoleInfo';

import BEM from 'common/bem';
import { mobxInjectSelect } from 'common/utils';
import { AlertComponent, Message } from 'types';

const classes = BEM.with('AlertCard');
const DISABLED_ACTION_TYPES = ['call'];

const AlertTextView = ({ component }: { component: AlertComponent }) => (
  <div className={classes('text-view')}>
    <Linkify properties={{ rel: ['noopener noreferrer'], target: '_blank' }}>
      {component.value}
    </Linkify>
  </div>
);
const ALERT_CARD_COMPONENTS = {
  bold_text_view: AlertBoldTextView,
  completed_item: AlertCompletedItem,
  escalate_header: AlertEscalateHeader,
  nurse_header: AlertNurseHeader,
  role_info: AlertRoleInfo,
  text_view: AlertTextView,
} as const;

type MobxProps = {
  isGroupAlertsAllowed: boolean;
};

export type AlertCardProps = {
  isExpanded?: boolean;
  message: Message;
  numLinesToClipContentAt?: number;
  showConversationLink?: boolean;
  showButtons?: boolean;
};

const AlertCard = ({
  isExpanded,
  isGroupAlertsAllowed,
  showConversationLink,
  showButtons,
  message,
  numLinesToClipContentAt,
}: AlertCardProps & MobxProps) => {
  const [shouldDisableAllButtons, setShouldDisableAllButtons] = useState(false);

  const { alertCard, createdAt } = message;
  if (!alertCard) return null;

  if (isGroupAlertsAllowed) {
    return (
      <AlertCardV2
        isExpanded={isExpanded}
        message={message}
        numLinesToClipContentAt={numLinesToClipContentAt}
        showConversationLink={showConversationLink}
        showButtons={showButtons}
      />
    );
  }

  const onActionClick = () => {
    setShouldDisableAllButtons(true);
  };

  const onActionFinish = (enableButtons = true) => {
    enableButtons && setShouldDisableAllButtons(false);
  };

  const alertCardComponents = [];

  for (const component of alertCard) {
    alertCardComponents.push(
      renderComponent(component, {
        createdAt: createdAt.toISOString(),
        messageId: message.id,
        onActionClick,
        onActionFinish,
        shouldDisableAllButtons,
      })
    );
  }

  return <div className={classes()}>{alertCardComponents}</div>;
};

const renderComponent = (
  component: AlertComponent,
  {
    createdAt,
    messageId,
    onActionClick,
    onActionFinish,
    shouldDisableAllButtons,
  }: {
    createdAt: string;
    messageId: string;
    onActionClick: () => void;
    onActionFinish: () => void;
    shouldDisableAllButtons: boolean;
  }
) => {
  const key = component.type + component.value;
  const buttonProps = {
    messageId,
    onActionClick,
    onActionFinish,
  };

  if (component.type === 'button_view') {
    return (
      <div key={key} className={classes('alerts-list-buttons')}>
        <AlertActionButton
          component={component}
          isDisabled={
            shouldDisableAllButtons || DISABLED_ACTION_TYPES.includes(component.action_type)
          }
          {...buttonProps}
        />
      </div>
    );
  } else if (component.type === 'two_button_view') {
    const { data } = component;
    if (!data) return null;

    const actionButtons = [];
    let twoButtonKey = key;
    for (const button of data) {
      const buttonKey = button.type + button.value;
      twoButtonKey += buttonKey;

      actionButtons.push(
        <AlertActionButton
          key={buttonKey}
          component={button}
          isDisabled={shouldDisableAllButtons || DISABLED_ACTION_TYPES.includes(button.action_type)}
          {...buttonProps}
        />
      );
    }

    if (actionButtons.length === 0) return null;

    return (
      <div key={twoButtonKey} className={classes('alerts-list-buttons')}>
        {actionButtons}
      </div>
    );
  }

  const AlertCardComponent = ALERT_CARD_COMPONENTS[component.type];

  if (AlertCardComponent) {
    return <AlertCardComponent key={key} component={component} createdAt={createdAt} />;
  }
};

export default mobxInjectSelect<AlertCardProps, MobxProps>({
  messengerStore: ['isGroupAlertsAllowed'],
})(AlertCard);
