import React, { useState, useEffect, useRef } from 'react';

import { mobxInjectSelect } from '../../../../common/utils';
import { PageHeader } from '../../../shared-components/PageHeader/PageHeader';
import { ActionBar } from '../../../shared-components/ActionBar/ActionBar';
import {
  PermissionMap,
  UserData,
  UserInfo,
  UserSettings,
  UserOrganizationSettings,
} from '../../../types';

import TCClient from '../../../../client';
import { Toast } from '../../../shared-components/Toast/Toast';
import settingsDiff from '../../../utils/settingsDiff';
import avatarNoIconBG from '../../../images/test_avatar_noicon_bg.png';
import { CtaButton } from '../../../shared-components/CtaButton/CtaButton';
import { getUserPermissions } from './UserSettingsForm/UserSettings';

import UserSettingsForm from './UserSettingsForm';
import UserInfoForm from './UserInfoForm';

import {
  Api,
  Devices,
  CustomDirectories,
  Pager,
  ProductRoles,
  RemoveUser,
  ResetPassword,
  UnlockUser,
  UserPermissions,
} from './views';

import styles from './index.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const { setUsersPageView } = actions;

type View =
  | 'API'
  | 'Devices'
  | 'Custom Directories'
  | 'Pager'
  | 'Product Roles'
  | 'Remove User'
  | 'Reset Password'
  | 'Unlock User'
  | 'User Permissions';

const permissionedTabs = [
  'Reset Password',
  'Unlock User',
  'Pager',
  'API',
  'Remove User',
  'Devices',
  'Custom Directories',
  'User Permissions',
  'Product Roles',
] as const;

type PermissionedTab = (typeof permissionedTabs)[number];

const tabToPermission: { [k in PermissionedTab]: string } = {
  'Custom Directories': 'assign_search_groups',
  'Product Roles': 'available_roles',
  'Remove User': 'delete_user',
  'Reset Password': 'reset_password',
  'Unlock User': 'isLocked',
  'User Permissions': 'display_roles',
  API: 'api',
  Devices: 'devices',
  Pager: 'pager',
};

type MobxProps = {
  allowOneTimePasswordLogin: boolean;
  featureFlags: Record<string, unknown>;
  getURLParams: () => Record<string, string>;
  isNewUserSettingsLayoutEnabled: boolean;
  isProductSettingsAdmin: boolean;
  isPersonaAdminFeatureFlagEnabled: boolean;
};

const User = ({
  allowOneTimePasswordLogin,
  featureFlags,
  getURLParams,
  isNewUserSettingsLayoutEnabled,
  isProductSettingsAdmin,
  isPersonaAdminFeatureFlagEnabled,
}: MobxProps) => {
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector((state) => state.admin.selectedUser);
  const selectedOrganization = useAppSelector((state) => state.admin.selectedOrganization);
  const orgLevelSettings = useAppSelector((state) => state.orgSettings.settings);

  const headerRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLDivElement>(null);

  const [view, setView] = useState<View | null>(null);
  const [tabs, setTabs] = useState<{ label: View }[]>([]);

  const [userData, setUserData] = useState<UserData>();

  const [userInfo, setUserInfo] = useState<UserInfo>({
    displayName: selectedUser.displayName,
    title: selectedUser.title,
    department: selectedUser.department,
  });
  const [userInfoCopy, setUserInfoCopy] = useState<UserInfo>(userInfo);
  const [unlinkPhoneNumber, setUnlinkPhoneNumber] = useState('');

  const [userSettings, setUserSettings] = useState<UserSettings>();
  const [userSettingsCopy, setUserSettingsCopy] = useState<UserSettings>();
  const [userPermissions, setUserPermissions] = useState<PermissionMap>();
  const [userPermissionsCopy, setUserPermissionsCopy] = useState<PermissionMap>();

  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);
  const [isFailureToastOpen, setIsFailureToastOpen] = useState(false);

  const [orgSettings, setOrgSettings] = useState<UserOrganizationSettings>();

  const urlParams = getURLParams();

  const isPersonaBasedManagementAllowed =
    isPersonaAdminFeatureFlagEnabled &&
    !!orgLevelSettings.personaManagement &&
    orgLevelSettings.personaManagement !== 'off';

  const closeView = () => {
    setView(null);
    setSelectedTabIndex(undefined);
  };

  const currentTab = (v: View | null): JSX.Element => {
    switch (v) {
      case 'API':
        return <Api onClose={closeView} />;
      case 'Custom Directories':
        return <CustomDirectories onClose={closeView} />;
      case 'Devices':
        return <Devices onClose={closeView} />;
      case 'Pager':
        return <Pager onClose={closeView} />;
      case 'Product Roles':
        return <ProductRoles onClose={closeView} />;
      case 'Remove User':
        return <RemoveUser onClose={closeView} />;
      case 'Reset Password':
        return userData ? (
          <ResetPassword onClose={closeView} email={userData?.emailAddresses[0]?.emailAddress} />
        ) : (
          <></>
        );
      case 'Unlock User':
        return (
          <UnlockUser
            onClose={closeView}
            isLocked={userData?.isLocked}
            userToken={selectedUser.id}
          />
        );
      case 'User Permissions':
        return (
          <UserPermissions
            onClose={closeView}
            isPersonaMappingExcluded={userSettings?.personaMappingExclusion}
            isPersonaBasedManagementAllowed={isPersonaBasedManagementAllowed}
          />
        );
      default:
        return <></>;
    }
  };

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>();

  useEffect(() => {
    async function unlinkPhone() {
      await TCClient.adminUsers.unlinkPhoneNumber({
        userId: selectedUser.id,
        organizationId: selectedOrganization.token,
        phoneNumber: unlinkPhoneNumber,
      });
      setUnlinkPhoneNumber('');
    }

    async function getUserSettings() {
      const user_data = (await TCClient.adminUsers.getAccountSettings({
        userId: selectedUser.id,
        organizationId: selectedOrganization.token,
      })) as UserData;

      setUserData(user_data);
    }

    if (unlinkPhoneNumber) {
      unlinkPhone();
    }
    getUserSettings();
  }, [unlinkPhoneNumber, selectedOrganization.token, selectedUser.id]);

  useEffect(() => {
    const userId = TCClient.currentUser.id;
    const getUserPermissions = async () => {
      const user_data = (await TCClient.adminUsers.getAccountSettings({
        userId,
        organizationId: selectedOrganization.token,
      })) as UserData;

      const userPermissions =
        user_data.permissions.organization.find(
          (o) => o.organizationId === selectedOrganization.token
        )?.permission ?? [];

      const productPermissions = user_data.permissions.product.permission || [];

      const _tabs: { label: View }[] = permissionedTabs
        .filter((t) => {
          if (t === 'Unlock User') {
            return (
              (orgSettings?.multiFactorAuthSetting !== 'off' &&
                (featureFlags?.MFA || urlParams.allowMfa)) ||
              (allowOneTimePasswordLogin && orgLevelSettings.otpAllowConfigure)
            );
          }
          if (t === 'User Permissions') return !isNewUserSettingsLayoutEnabled;
          const permission = tabToPermission[t as PermissionedTab];
          const userPermission = userPermissions.includes(permission);
          const productPermission = productPermissions.includes(permission);
          if (t === 'Devices') return true;
          return productPermission || userPermission;
        })
        .map((t) => ({ label: t }));

      setTabs(_tabs);
    };

    getUserPermissions();
  }, [
    selectedOrganization.token,
    orgSettings,
    urlParams,
    featureFlags,
    allowOneTimePasswordLogin,
    orgLevelSettings.otpAllowConfigure,
    isNewUserSettingsLayoutEnabled,
  ]);

  useEffect(() => {
    const onScroll = () => {
      if (headerRef.current && tabRef.current) {
        const headerBounds = headerRef.current.getBoundingClientRect();
        const navBounds = tabRef.current.getBoundingClientRect();
        if (headerBounds.bottom > navBounds.top) {
          headerRef.current.setAttribute('sticky', 'sticky');
        } else {
          headerRef.current.removeAttribute('sticky');
        }
      }
    };

    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const saveProfile = async () => {
    const userInfoDiff = settingsDiff(userInfo, userInfoCopy);
    const userSettingsDiff =
      userSettings && userSettingsCopy && settingsDiff(userSettings, userSettingsCopy);
    const userPermissionsDiff =
      userPermissions && userPermissionsCopy && settingsDiff(userPermissions, userPermissionsCopy);

    try {
      if (Object.keys(userInfoDiff).length > 0) {
        await TCClient.adminUsers.updateUserInfo({
          userInfoDiff,
          orgToken: selectedOrganization.token,
          userId: selectedUser.id,
        });
        setUserInfoCopy(userInfo);
      }
      if (userSettingsDiff && Object.keys(userSettingsDiff).length > 0) {
        await TCClient.adminUsers.updateUserSettings({
          changedUserSettings: userSettingsDiff as UserSettings,
          orgToken: selectedOrganization.token,
          userId: selectedUser.id,
        });
        const user_settings = (await TCClient.adminUsers.getUserSettings({
          userId: selectedUser.id,
          organizationId: selectedOrganization.token,
        })) as UserSettings;
        setUserSettings(user_settings);
        setUserSettingsCopy(user_settings);
      }

      if (userPermissionsDiff && Object.keys(userPermissionsDiff).length > 0) {
        await TCClient.adminUsers.updateUserPermissionRoles({
          orgId: selectedOrganization.token,
          userId: selectedUser.id,
          diffPermissions: userPermissionsCopy,
          permissions: userPermissions,
        });
        const permissions = await getUserPermissions(
          selectedOrganization.token,
          selectedUser.id,
          orgLevelSettings
        );
        setUserPermissions(permissions);
        setUserPermissionsCopy(permissions);
      }
      setIsSuccessToastOpen(true);
    } catch (e) {
      setIsFailureToastOpen(true);
    }
  };

  const cancelSave = async () => {
    setUserInfo(userInfoCopy);
    setUserSettings(userSettingsCopy);
    dispatch(setUsersPageView({ selectedUsersPageView: 'users' }));
  };

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer} ref={headerRef}>
        <div className={styles.header}>
          <PageHeader
            label={'Users'}
            subLabel={`${selectedUser.firstName} ${selectedUser.lastName}`}
            onClick={() => {
              dispatch(setUsersPageView({ selectedUsersPageView: 'users' }));
            }}
          />
          <img
            alt={'avatar'}
            src={selectedUser?.avatarUrl || avatarNoIconBG}
            className={styles.smallAvatar}
          />
        </div>
        <div className={styles.actionButtons}>
          <CtaButton label={'Cancel'} size={'small'} onClick={cancelSave} />{' '}
          <CtaButton label={'Save Profile'} primary onClick={saveProfile} />
        </div>
      </div>
      <ActionBar<View>
        forwardRef={tabRef}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={(tabButton, index) => {
          setView(tabButton.label);
          setSelectedTabIndex(index);
        }}
        tabButtons={tabs}
      />
      {currentTab(view)}
      {userData && (
        <UserInfoForm
          isPersonaBasedManagementAllowed={isPersonaBasedManagementAllowed}
          isProductSettingsAdmin={isProductSettingsAdmin}
          setUnlinkPhoneNumber={setUnlinkPhoneNumber}
          selectedUser={selectedUser}
          userData={userData}
          userInfo={userInfo}
          userSettings={userSettings}
          setUserSettings={setUserSettings}
          setUserInfo={setUserInfo}
        />
      )}
      <UserSettingsForm
        isNewUserSettingsLayoutEnabled={isNewUserSettingsLayoutEnabled}
        userSettings={userSettings}
        setUserSettings={setUserSettings}
        setUserSettingsCopy={setUserSettingsCopy}
        userPermissions={userPermissions}
        setUserPermissions={setUserPermissions}
        setUserPermissionsCopy={setUserPermissionsCopy}
        selectedOrganization={selectedOrganization}
        selectedUser={selectedUser}
        orgSettings={orgSettings}
        setOrgSettings={setOrgSettings}
        urlParams={urlParams}
        featureFlags={featureFlags}
      />
      <Toast
        type="SUCCESS"
        message="Account changes were successfully saved."
        open={isSuccessToastOpen}
        onClose={() => {
          setIsSuccessToastOpen(false);
        }}
      />
      <Toast
        type="FAILURE"
        message="There was a problem updating your account settings. Please try again."
        open={isFailureToastOpen}
        onClose={() => {
          setIsFailureToastOpen(false);
        }}
      />
    </div>
  );
};

export default mobxInjectSelect<{}, MobxProps>({
  featureStore: ['featureFlags', 'getURLParams'],
  messengerStore: [
    'allowOneTimePasswordLogin',
    'isNewUserSettingsLayoutEnabled',
    'isProductSettingsAdmin',
    'isPersonaAdminFeatureFlagEnabled',
  ],
})(User);
