import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';

const classes = BEM.with('Admin');

class Admin extends Component {
  static propTypes = {
    currentOrganizationId: PropTypes.string.isRequired,
    destroyIframe: PropTypes.func.isRequired,
    getIframe: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    updateIframe: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { currentOrganizationId, getIframe, type } = this.props;
    getIframe(this.element, type, currentOrganizationId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentOrganizationId, type, updateIframe } = nextProps;
    if (this.props.type !== type) {
      updateIframe(type, currentOrganizationId);
    }
  }

  componentWillUnmount() {
    const { destroyIframe } = this.props;
    destroyIframe();
  }

  render() {
    return <div className={classes()} ref={this._setElement} />;
  }

  _setElement = (ref) => {
    this.element = ref;
  };
}

export default mobxInjectSelect({
  adminConsoleStore: ['destroyIframe', 'getIframe', 'updateIframe'],
  messengerStore: ['currentOrganizationId'],
})(Admin);
