import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Async } from 'react-select-plus';
import msStyles from '../../../../../../admin/shared-components/MultiSelect/MultiSelect.module.css';
import styles from './PersonaSelect.module.css';
import { PersonaRowData, PersonaSearchResponse } from 'admin/types';
import { mobxInjectSelect } from 'common/utils';
import { useAppSelector } from 'redux-stores';

type MobxProps = {
  findPersonas: (params: {
    query: string;
    page: number;
    pageSize: number;
    selectedOrganizationId: string;
  }) => Promise<PersonaSearchResponse>;
};

type PersonaSelectProps = {
  dataTestId?: string;
  onChange?: (selectedOption: PersonaRowData) => void;
  placeholder?: string;
};

const PersonaSelect = ({
  dataTestId,
  findPersonas,
  onChange,
  placeholder = 'Select your Permission Group...',
}: PersonaSelectProps & MobxProps) => {
  const selectRef = useRef<{ input?: { input?: HTMLInputElement } }>();
  const [selectValue, setSelectValue] = useState<PersonaRowData>();
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);

  useEffect(() => {
    if (!dataTestId) return;
    selectRef.current?.input?.input?.setAttribute('data-test-id', dataTestId);
  }, [dataTestId]);

  const onValueChange = (option: PersonaRowData) => {
    setSelectValue(option);
    onChange && onChange(option);
  };

  return (
    <Async
      className={classnames(msStyles.multiSelect, styles.personaSelect)}
      autoBlur={true}
      escapeClearsValue={false}
      cache={false}
      menuStyle={{
        overflow: 'scroll',
        height: 200,
      }}
      onChange={onValueChange}
      openOnClick={true}
      optionClassName={msStyles.multiSelectOption}
      labelKey="name"
      valueKey="name"
      loadOptions={async (
        x: string,
        callback: (error: string | null, data: { options: PersonaRowData[] }) => void
      ) => {
        const { data } = await findPersonas({
          page: 0,
          query: x,
          pageSize: 50,
          selectedOrganizationId,
        });
        callback(null, { options: data });
      }}
      placeholder={placeholder}
      ref={selectRef}
      searchable={true}
      value={selectValue}
    />
  );
};

export default mobxInjectSelect<PersonaSelectProps, MobxProps>({
  personaStore: ['findPersonas'],
})(PersonaSelect);
