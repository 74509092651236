import { action, observable, runInAction, makeObservable } from 'mobx';
import { attachmentType } from '../common/utils';
import Images from '../models/enums/Images';

class AttachmentDownloadState {
  @observable errorCode = null;
  @observable errorMessage = null;
  @observable fileBlob = null;
  @observable isDownloading = false;
  @observable isLoading = false;
  @observable url = null;

  constructor() {
    makeObservable(this);
  }
}

export default class MessageAttachmentStore {
  @observable _byKey = new Map();
  @observable currentMessageAttachment = null;

  constructor({ client, entityStore, modalStore }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.modalStore = modalStore;
  }

  getDownloadState = (messageId, attachmentId) => {
    const key = [messageId, attachmentId].join(':');
    let val = this._byKey.get(key);
    if (!val) {
      val = new AttachmentDownloadState();
      runInAction(() => {
        this._byKey.set(key, val);
      });
    }
    return val;
  };

  @action('MessageAttachmentStore.downloadAttachmentUrl')
  downloadAttachmentUrl = async (messageId, attachmentId) => {
    const attachmentDownloadState = this.getDownloadState(messageId, attachmentId);
    if (!attachmentDownloadState.url) attachmentDownloadState.isLoading = true;

    try {
      const { blob, url } = await this.client.messages.downloadAttachmentBlobAndUrl(
        messageId,
        attachmentId
      );
      runInAction(() => {
        attachmentDownloadState.isLoading = false;
        attachmentDownloadState.url = url;
        attachmentDownloadState.fileBlob = blob;
      });
      return url;
    } catch (err) {
      runInAction(() => {
        attachmentDownloadState.isLoading = false;
        attachmentDownloadState.errorCode = err.code;
        attachmentDownloadState.errorMessage = err.message;
      });
    }
  };

  @action('MessageAttachmentStore.downloadAttachment')
  downloadAttachment = async (messageId, attachmentId) => {
    const attachmentDownloadState = this.getDownloadState(messageId, attachmentId);
    if (!attachmentDownloadState.url) attachmentDownloadState.isDownloading = true;

    try {
      if (attachmentId.includes('local:')) {
        const { attachments } = this.client.messages.getById(messageId);
        const [firstAttachment] = attachments;

        if (!attachmentDownloadState.fileBlob && attachmentId !== firstAttachment.id) {
          attachmentId = firstAttachment.id;
        } else {
          const { name, contentType } = firstAttachment;

          return this.client.file.downloadFile({
            attachmentContentType: contentType,
            blob: attachmentDownloadState.fileBlob,
            fileName: name,
          });
        }
      }
      await this.client.messages.downloadAttachment(messageId, attachmentId);
      runInAction(() => {
        attachmentDownloadState.isDownloading = false;
      });
    } catch (err) {
      runInAction(() => {
        attachmentDownloadState.isDownloading = false;
        attachmentDownloadState.errorCode = err.code;
        attachmentDownloadState.errorMessage = err.message;
      });
    }
  };

  @action('MessageStore.findAttachment') findAttachment = async (messageId, attachmentId) => {
    await this.client.messages.findAttachment(messageId, attachmentId);
  };

  @action('MessengerStore.closeMessageAttachmentModal')
  closeMessageAttachmentModal = () => {
    this.currentMessageAttachment = null;
  };

  @action('MessengerStore.openMessageAttachmentModal')
  openMessageAttachmentModal = (imageState) => {
    this.currentMessageAttachment = imageState;
    this.modalStore.openModal('messageAttachment');
  };

  @action('MessengerStore.isInlineImage') isInlineImage = (attachment) => {
    const { contentType, size } = attachment;
    return size <= Images.MAX_DISPLAY_SIZE_BYTES && attachmentType(contentType) === 'Image';
  };

  @action('MessengerStore.isInlineVideo') isInlineVideo = (attachment) => {
    const { contentType } = attachment;
    return attachmentType(contentType) === 'Video';
  };

  @action('MessengerStore.isInlineAudio') isInlineAudio = (attachment) => {
    const { contentType } = attachment;
    return attachmentType(contentType) === 'Audio';
  };

  @action('MessengerStore.isInlinePDF') isInlinePDF = (attachment) => {
    const { contentType } = attachment;
    return contentType === 'application/pdf';
  };
}
