import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

function mobxInjectSelect<
  PropTypes,
  StoreProvidedProps extends Record<string, unknown>
>(selectors: { [k: string]: (keyof StoreProvidedProps)[] }) {
  type CombinedProps = PropTypes & StoreProvidedProps;

  return (PassedComponent: React.FC<CombinedProps> | React.ComponentType<CombinedProps>) => {
    const selectorEntries = Object.entries(selectors);
    const storeKeys = Object.keys(selectors);

    let WrappedComponent: React.FC<CombinedProps> | React.ComponentType<CombinedProps>;
    if (!(PassedComponent.prototype && PassedComponent.prototype.render)) {
      WrappedComponent = (props) => <PassedComponent {...props} />;
    } else {
      WrappedComponent = PassedComponent;
    }

    const ObservedComponent = observer(WrappedComponent);
    const isStoreKey: { [k: string]: boolean } = {};

    for (const storeKey of storeKeys) {
      isStoreKey[storeKey] = true;
    }

    @inject(...storeKeys)
    @observer
    class MobxInjectSelectWrapper extends Component<PropTypes> {
      static displayName = `MobxInjectSelectWrapper(${
        PassedComponent.displayName || PassedComponent.name
      })`;

      render() {
        const propsWithoutStores = {} as {
          // TODO: https://tigertext.atlassian.net/browse/RW-4027
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          [k in keyof PropTypes]: React.ReactNode;
        };
        const selectors = {} as {
          // TODO: https://tigertext.atlassian.net/browse/RW-4027
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          [k in keyof StoreProvidedProps]: React.ReactNode;
        };

        for (const [key, value] of Object.entries(this.props)) {
          if (!isStoreKey[key]) {
            propsWithoutStores[key as keyof PropTypes] = value as React.ReactNode;
          }
        }

        const props = this.props;
        type PropKey = keyof typeof props;

        for (const [storeKey, selectFromStore] of selectorEntries) {
          const store = this.props[storeKey as PropKey];
          for (const key of selectFromStore) {
            selectors[key as keyof typeof selectors] = store[
              key as keyof typeof store
            ] as React.ReactNode;
          }
        }

        return <ObservedComponent {...propsWithoutStores} {...(selectors as CombinedProps)} />;
      }
    }

    return MobxInjectSelectWrapper;
  };
}

export default mobxInjectSelect;
