import { useState, useEffect, useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';

import { CtaButton } from '../../../../admin/shared-components/CtaButton/CtaButton';
import { ViewContainer } from '../../../shared-components/View/ViewContainer/ViewContainer';
import AddUsersSearchBox from '../../../../common/components/AddUsersSearchBox';
import styles from '../OrganizationSettings.module.css';
import { mobxInjectSelect, downloadFile } from '../../../../common/utils';
import { PBXLinesList, PBXResponse, PBXTab, User } from '../../../../types';
import TCClient from '../../../../client';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import { ReactComponent as DownloadButtonSvg } from './../../../../common/images/downloadPBX.svg';
import { ReactComponent as UploadButtonSvg } from './../../../../common/images/uploadPBX.svg';
import { ReactComponent as RefreshButtonSvg } from './../../../../common/images/refresh.svg';
import UserRoleAssigneeTable from './UserRoleAssigneeTable';
import { TabProps } from './';
import { useAppSelector } from 'redux-stores';

const PAGE_SIZE = 20;

type NurseCallStatus = {
  data: {
    status: boolean;
  };
};

type MobxProps = {
  openModal: (name: string, options?: unknown) => void;
  findUser: (targetId: string, organizationId: string) => User;
};

function PBX({ onClose, openModal, findUser }: TabProps & MobxProps) {
  const [category, setCategory] = useState<PBXTab>('User Lines');
  const [isAddUsersSearchBoxOpen, setIsAddUsersSearchBoxOpen] = useState(false);
  const [nurseCallStatus, setNurseCallStatus] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pbxLinesList, setPbxLinesList] = useState<PBXLinesList[]>([]);
  const [maxPages, setMaxPages] = useState<number>(1);
  const isCategoryNurseCall = category !== 'User Lines' && category !== 'Roles Lines';

  const currentOrganizationId = useAppSelector((state) => state.orgSettings.settings.id);

  const getAllPbxLinesInOrg = useCallback(
    async ({
      category,
      existingLines,
      pageNumber,
    }: {
      category: string;
      existingLines: PBXLinesList[];
      pageNumber: number;
    }) => {
      try {
        const { data } = await TCClient.pbx.getPbxLines({
          orgId: currentOrganizationId,
          page: pageNumber,
          size: PAGE_SIZE,
          targetType:
            category === 'User Lines' ? 'User' : category === 'Roles Lines' ? 'Role' : 'NurseCall',
        });
        const responseData = data as PBXResponse;
        const totalPages = responseData.metaData.totalPages;
        const content = responseData.content as PBXLinesList[];
        setPbxLinesList([...(existingLines || []), ...content]);
        setMaxPages(totalPages);
      } catch (err) {
        console.error(err);
      }
    },
    [currentOrganizationId]
  );

  const retrieveNurseCallUserStatus = useCallback(async () => {
    const { data } = await TCClient.pbx.retrieveNurseCallStatus({
      orgId: currentOrganizationId,
    });
    const status = data as NurseCallStatus;
    if (status) setNurseCallStatus(true);
  }, [currentOrganizationId]);

  useEffect(() => {
    setPbxLinesList([]);
    setSelected([]);
    setPageNumber(1);
    getAllPbxLinesInOrg({ category, existingLines: [], pageNumber: 1 });
    if (category === 'Nurse Call Users') retrieveNurseCallUserStatus();
  }, [currentOrganizationId, getAllPbxLinesInOrg, category, retrieveNurseCallUserStatus]);

  const unassignUsersOrRoles = (selected: string[]) => {
    openModal('removeRoleOrUserLine', {
      currentOrganizationId,
      lineType: category,
      pbxLinesList,
      getAllPbxLinesInOrg,
      findUser,
      selectedLines: selected,
      setPbxLinesList,
      setSelected,
    });
  };

  const toggleSelected = (token: string) => {
    if (selected.includes(token)) {
      setSelected(selected.filter((id) => id !== token));
    } else {
      setSelected([...selected, token]);
    }
  };

  const downloadCSV = () => {
    const csvContent = 'extension,assignee_token\n';
    downloadFile('template.csv', 'text/csv', csvContent);
  };

  return (
    <ViewContainer
      {...{
        header: 'PBX Lines',
        onClose,
      }}
    >
      <div>
        <div
          className={styles.refreshButton}
          onClick={() => {
            setPbxLinesList([]);
            setSelected([]);
            setPageNumber(1);
            getAllPbxLinesInOrg({ category, existingLines: [], pageNumber: 1 });
          }}
        >
          <ToolTip text="Refresh lines" textAlign={'center'} location={'bottom'}>
            <RefreshButtonSvg />
          </ToolTip>
        </div>
        <div className={styles.pbxOuterContainer}>
          <div className={styles.pbxLinesContainer}>
            {(['User Lines', 'Roles Lines', 'Nurse Call Users'] as PBXTab[]).map((line) => {
              return (
                <button
                  className={classNames(
                    styles.titleButton,
                    category === line && styles.titleButtonActive
                  )}
                  data-test-id={line.split(' ').join('-').toLowerCase()}
                  onClick={() => setCategory(line)}
                  key={line}
                >
                  {line}
                </button>
              );
            })}
          </div>
        </div>
        <div>
          <div className={styles.outerFlexContainer}>
            <div className={styles.numbersHeader}>
              <div>{isCategoryNurseCall ? 'Nurse Call Users' : 'Numbers'}</div>
              {isCategoryNurseCall && nurseCallStatus && (
                <div
                  className={styles.unassignUsersButton}
                  onClick={() => setIsAddUsersSearchBoxOpen(true)}
                >
                  Add Users
                </div>
              )}
              {isAddUsersSearchBoxOpen && (
                <div>
                  <OutsideClickHandler onOutsideClick={() => setIsAddUsersSearchBoxOpen(false)}>
                    <AddUsersSearchBox
                      category={category}
                      setIsAddUsersSearchBoxOpen={setIsAddUsersSearchBoxOpen}
                      organizationId={currentOrganizationId}
                      getAllPbxLinesInOrg={getAllPbxLinesInOrg}
                    />
                  </OutsideClickHandler>
                </div>
              )}
            </div>
            {category === 'User Lines' && selected.length === 0 && (
              <div className={styles.bulkUpload}>
                <div className={styles.fileUpload}>
                  <button
                    className={styles.uploadButton}
                    onClick={() => openModal('uploadPBXLines')}
                  >
                    <div className={styles.buttonSvg}>
                      <UploadButtonSvg />
                    </div>
                    Click to upload
                  </button>
                </div>
                <div className={styles.fileUpload}>
                  <button
                    className={styles.downloadButton}
                    onClick={(e) => {
                      e.preventDefault();
                      downloadCSV();
                    }}
                  >
                    <div className={styles.buttonSvg}>
                      <DownloadButtonSvg />
                    </div>
                    <span className={styles.templateText}>Template</span>
                  </button>
                </div>
              </div>
            )}
            {selected.length > 0 && !isCategoryNurseCall && (
              <div
                className={styles.unassignUsersButton}
                onClick={() => unassignUsersOrRoles(selected)}
              >
                Unassign {category === 'User Lines' ? 'Users' : 'Roles'}
              </div>
            )}
            {selected.length > 0 && isCategoryNurseCall && (
              <div
                className={styles.unassignUsersButton}
                onClick={() => unassignUsersOrRoles(selected)}
              >
                Remove Users
              </div>
            )}
          </div>
          <div>
            <UserRoleAssigneeTable
              category={category}
              organizationId={currentOrganizationId}
              results={pbxLinesList}
              selected={selected}
              setPbxLinesList={setPbxLinesList}
              setSelected={setSelected}
              toggleSelected={toggleSelected}
            />
          </div>
        </div>
        {pageNumber < maxPages && (
          <div className={styles.outerContainerButton}>
            <CtaButton
              label={'Load More'}
              onClick={() => {
                const nextPage = pageNumber + 1;
                setPageNumber(nextPage);
                getAllPbxLinesInOrg({
                  category,
                  existingLines: pbxLinesList,
                  pageNumber: nextPage,
                });
              }}
              isInsertButton={true}
            ></CtaButton>
          </div>
        )}
      </div>
    </ViewContainer>
  );
}

export default mobxInjectSelect<TabProps, MobxProps>({
  modalStore: ['openModal'],
  userStore: ['findUser'],
})(PBX);
