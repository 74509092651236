import Camelizer from '../../../utils/Camelizer';
import {
  ServerUserSettings,
  UserSettings,
  ServerUserOrganizationSettings,
  UserOrganizationSettings,
  ServerUserData,
  UserInfo,
  ServerUserInfo,
  ServerAccountSearch,
  AccountSearch,
  ServerDevice,
  Device,
  ServerPendingUser,
  PendingUser,
  UserData,
  ServerAccountSettings,
} from './types';
import { fixArray, formatBooleanSettingOption, parseBooleanSettingOption } from './';

export function formatServerUserInfo(req: Partial<UserInfo>): Partial<ServerUserInfo> {
  return {
    ...(req.displayName && { display_name: req.displayName }),
    ...(req.title && { title: req.title }),
    ...(req.department && { department: req.department }),
  };
}

export function parseServerUserData(res: ServerUserData): UserData {
  const currentMembershipOrganization = fixArray(res?.membership?.organization);
  const currentPermissionsOrganization = fixArray(res?.permissions?.organization);
  const currentOrgSettings = fixArray(res?.org_settings);

  if (res.membership?.pending_organizations) {
    const pendingOrgs = fixArray(res?.membership.pending_organizations.organization);
    for (let i = 0; i < pendingOrgs.length; i++) {
      currentMembershipOrganization.push({ ...pendingOrgs[i], pending: true });
    }
  }

  return {
    ...(res.avatar && { avatar: res.avatar }),
    displayName: res.display_name,
    isLocked: parseBooleanSettingOption(res.is_locked),
    emailAddresses: fixArray(res.email_addresses).map((e) => ({
      emailAddress: e.email_address ? e.email_address.$t : '',
    })) as { emailAddress: string }[],
    organizationSettings: currentOrgSettings.map((o) => ({
      organizationId: o.organization_id,
      ...o,
    })),
    membership: {
      organization: currentMembershipOrganization.map((o) => ({
        name: o.name,
        token: o.token,
        totalMembers: parseInt(o.total_members, 10),
        admin: o.admin === 'true',
      })),
    },
    permissions: {
      organization: currentPermissionsOrganization.map((o) => ({
        organizationId: o.organization_id,
        ...o,
      })),
      product: { permission: res.permissions.product.permission },
    },
    phoneNumbers: res.phone_numbers?.map((o) => ({
      phoneNumber: o.phone_number?.map((o) => ({
        $t: o.$t,
        verified: o.verified,
      })),
    })),
    token: res.token,
    username: res.username,
  };
}

export function formatServerAccountSettings(
  req: UserSettings,
  defaultBoolToFalse = false
): ServerAccountSettings {
  return {
    alternative_ma_routing: formatBooleanSettingOption(req.isFastDeployEnabled),
    mfa: req.multiFactorAuthSetting,
    pbx_integration: req.enterprisePbxSetting,
    ...(req.personaMappingExclusion !== undefined && {
      persona_mapping_exclusion: `${req.personaMappingExclusion}`,
    }),
    pf_virtual_waiting_room: req.virtualWaitingRoomSetting,
    pf_quick_video: req.patientQuickCallVideoSetting,
    pf_quick_call: req.patientQuickCallSetting,
    pf_quick_add: req.patientQuickAddSetting,
    pf_auto_appointment_reminder: req.isPatientAppointmentRemindersEnabled,
    pf_broadcast: req.patientBroadcastingSetting,
    pf_schedule_messages: req.patientScheduledMessagesSetting,
    pf_patient_list_access: req.patientListAccessSetting,
    pf_video_call: req.patientVideoCallingSetting,
    pf_group_video_call: req.patientGroupVideoCallingSetting,
    pf_voip: req.patientVoiceCallingSetting,
    pf_group_audio_call: req.patientGroupVoiceCallingSetting,
    call_scheduler: req.tigerScheduleSetting,
    teams: req.teamsSetting,
    voip: formatBooleanSettingOption(req.isVoipCallingEnabled, defaultBoolToFalse),
    video_call: formatBooleanSettingOption(req.isVideoCallingEnabled, defaultBoolToFalse),
    patient_context: req.patientContextSetting,
    patient_network: req.patientEngagementSetting,
    click_to_call: formatBooleanSettingOption(req.isClickToCallEnabled, defaultBoolToFalse),
    auto_update: formatBooleanSettingOption(req.isDesktopAppAutoUpdateEnabled, defaultBoolToFalse),
    priority_messaging: formatBooleanSettingOption(
      req.priorityMessagingSetting,
      defaultBoolToFalse
    ),
    group_video_call: req.groupVideoCallingSetting,
    group_audio_call: req.groupAudioCallingSetting,
    delivery_escalation: formatBooleanSettingOption(
      req.areDeliveryEscalationsEnabled,
      defaultBoolToFalse
    ),
    repeat_push: formatBooleanSettingOption(req.areRepeatNotificationsEnabled, defaultBoolToFalse),
    autologout_time: req.autoLogout,
    pin_lock: req.pinLock,
    dor: formatBooleanSettingOption(req.isDeleteOnReadEnabled),
    ttl: req.messageLifespan,
    forward: formatBooleanSettingOption(req.isMessageForwardingEnabled),
  };
}

/**
 * Parses an unknown payload into a usable local initial state value given the response of an user settings call
 * @param serverResponse The raw value taken from a network request to retrieve a user's settings
 */
export function parseServerUserSettings(res: ServerUserSettings): UserSettings {
  return {
    isFastDeployEnabled: res.fast_deploy === '1',
    multiFactorAuthSetting: res.mfa,
    enterprisePbxSetting: res.pbx_integration,
    virtualWaitingRoomSetting: res.pf_virtual_waiting_room,
    patientQuickCallVideoSetting: res.pf_quick_video,
    patientQuickCallSetting: res.pf_quick_call,
    patientQuickAddSetting: res.pf_quick_add,
    isPatientAppointmentRemindersEnabled: res.pf_auto_appointment_reminder,
    patientBroadcastingSetting: res.pf_broadcast,
    patientContextSetting: res.patient_context,
    patientScheduledMessagesSetting: res.pf_schedule_messages,
    patientListAccessSetting: res.pf_patient_list_access,
    patientVideoCallingSetting: res.pf_video_call,
    patientGroupVideoCallingSetting: res.pf_group_video_call,
    patientVoiceCallingSetting: res.pf_voip,
    patientGroupVoiceCallingSetting: res.pf_group_audio_call,
    personaId: res.persona_id,
    personaName: res.persona_name,
    personaMappingExclusion: res.persona_mapping_exclusion === 'true',
    tigerScheduleSetting: res.call_scheduler,
    teamsSetting: res.teams,
    isVoipCallingEnabled: res.voip,
    isVideoCallingEnabled: res.video_call,
    patientEngagementSetting: res.patient_network,
    scimIntegration: res.scim_integration,
    isClickToCallEnabled: res.click_to_call,
    isDesktopAppAutoUpdateEnabled: res.auto_update,
    priorityMessagingSetting: res.priority_messaging,
    groupVideoCallingSetting: res.group_video_call,
    groupAudioCallingSetting: res.group_audio_call,
    areDeliveryEscalationsEnabled: res.delivery_escalation === '1',
    areRepeatNotificationsEnabled: res.repeat_push === '1',
    autoLogout: res.autologout_time,
    pinLock: res.pin_lock,
    isDeleteOnReadEnabled: res.dor === '1',
    messageLifespan: res.ttl,
    isMessageForwardingEnabled: res.forward === '1',
    id: res.id,
  };
}

export function formatServerUserOrganizationSettings(
  req: UserOrganizationSettings
): ServerUserOrganizationSettings {
  return {
    allow_voip: req.isVoipCallingEnabled,
    click_to_call: { click_to_call_status: req.clickToCallSetting.clickToCallStatusSetting },
    feature_call_scheduler: formatBooleanSettingOption(req.featureTigerSchedule),
    feature_click_to_call: formatBooleanSettingOption(req.featureClickToCall),
    feature_delivery_escalation: formatBooleanSettingOption(req.featureDeliveryEscalation),
    feature_group_audio_call: formatBooleanSettingOption(req.featureGroupAudioCalling),
    feature_group_video_call: formatBooleanSettingOption(req.featureGroupVideoCalling),
    feature_patient_context: formatBooleanSettingOption(req.featurePatientContext),
    feature_pf_patient_list_access: formatBooleanSettingOption(req.featurePatientListAccess),
    feature_patient_network: formatBooleanSettingOption(req.featurePatientEngagement),
    feature_pbx_integration: formatBooleanSettingOption(req.featureEnterprisePbx),
    feature_pf_broadcast: formatBooleanSettingOption(req.featurePatientBroadcasting),
    feature_pf_group_video_call: formatBooleanSettingOption(req.featurePatientGroupVideoCalling),
    feature_pf_quick_add: formatBooleanSettingOption(req.featurePatientQuickAdd),
    feature_pf_quick_call: formatBooleanSettingOption(req.featurePatientQuickCall),
    feature_pf_quick_video: formatBooleanSettingOption(req.featurePatientQuickCallVideo),
    feature_pf_schedule_messages: formatBooleanSettingOption(req.featurePatientScheduledMessages),
    feature_pf_virtual_waiting_room: formatBooleanSettingOption(
      req.featurePatientVirtualWaitingRoom
    ),
    feature_pf_voip: formatBooleanSettingOption(req.featurePatientVoip),
    feature_priority_messaging: formatBooleanSettingOption(req.featurePriorityMessaging),
    feature_teams: formatBooleanSettingOption(req.featureTeams),
    feature_video_call: formatBooleanSettingOption(req.featureVideoCall),
    call_scheduler: req.tigerScheduleSetting,
    group_audio_call: req.groupAudioCallingSetting,
    group_video_call: req.groupVideoCallingSetting,
    mfa: req.multiFactorAuthSetting,
    patient_context: req.patientContextSetting,
    pf_patient_list_access: req.patientListAccessSetting,
    patient_network: req.patientEngagementSetting,
    pbx_integration: req.pbxIntegrationSetting,
    pf_broadcast: req.patientBroadcastingSetting,
    pf_group_video_call: req.patientGroupVideoCallingSetting,
    pf_quick_add: req.patientQuickAddSetting,
    pf_quick_call: req.patientQuickCallSetting,
    pf_quick_video: req.patientQuickCallVideoSetting,
    pf_schedule_messages: req.patientScheduledMessagesSetting,
    pf_video_call: req.patientVideoCallingSetting,
    pf_virtual_waiting_room: req.patientVirtualWaitingRoomSetting,
    pf_voip: req.patientVoiceCallingSetting,
    priority_messaging: req.priorityMessagingSetting,
    teams: req.teamsSetting,
    video_call: formatBooleanSettingOption(req.isVideoCallEnabled),
  };
}

/**
 * Parses an unknown payload into a usable local initial state value given the response of an user settings call
 * @param serverResponse The raw value taken from a network request to retrieve a user's settings
 */
export function parseServerUserOrganizationSettings(
  res: ServerUserOrganizationSettings
): UserOrganizationSettings {
  return {
    isVoipCallingEnabled: res.allow_voip,
    clickToCallSetting: { clickToCallStatusSetting: res.click_to_call.click_to_call_status },
    featureClickToCall: parseBooleanSettingOption(res.feature_click_to_call),
    featureDeliveryEscalation: parseBooleanSettingOption(res.feature_delivery_escalation),
    featureGroupAudioCalling: parseBooleanSettingOption(res.feature_group_audio_call),
    featureGroupVideoCalling: parseBooleanSettingOption(res.feature_group_video_call),
    featurePatientEngagement: parseBooleanSettingOption(res.feature_patient_network),
    featureEnterprisePbx: parseBooleanSettingOption(res.feature_pbx_integration),
    featurePatientBroadcasting: parseBooleanSettingOption(res.feature_pf_broadcast),
    featurePatientContext: parseBooleanSettingOption(res.feature_patient_context),
    featurePatientGroupVideoCalling: parseBooleanSettingOption(res.feature_pf_group_video_call),
    featurePatientQuickAdd: parseBooleanSettingOption(res.feature_pf_quick_add),
    featurePatientQuickCall: parseBooleanSettingOption(res.feature_pf_quick_call),
    featurePatientQuickCallVideo: parseBooleanSettingOption(res.feature_pf_quick_video),
    featurePatientScheduledMessages: parseBooleanSettingOption(res.feature_pf_schedule_messages),
    featurePatientListAccess: parseBooleanSettingOption(res.feature_pf_patient_list_access),
    featurePatientVirtualWaitingRoom: parseBooleanSettingOption(
      res.feature_pf_virtual_waiting_room
    ),
    featurePatientVoip: parseBooleanSettingOption(res.feature_pf_voip),
    featurePriorityMessaging: parseBooleanSettingOption(res.feature_priority_messaging),
    featureVideoCall: parseBooleanSettingOption(res.feature_video_call),
    featureTeams: parseBooleanSettingOption(res.feature_teams),
    featureTigerSchedule: parseBooleanSettingOption(res.feature_call_scheduler),
    tigerScheduleSetting: res.call_scheduler,
    groupAudioCallingSetting: res.group_audio_call,
    groupVideoCallingSetting: res.group_video_call,
    multiFactorAuthSetting: res.mfa,
    patientContextSetting: res.patient_context,
    patientEngagementSetting: res.patient_network,
    patientListAccessSetting: res.pf_patient_list_access,
    pbxIntegrationSetting: res.pbx_integration,
    patientBroadcastingSetting: res.pf_broadcast,
    patientGroupVideoCallingSetting: res.pf_group_video_call,
    patientQuickAddSetting: res.pf_quick_add,
    patientQuickCallSetting: res.pf_quick_call,
    patientQuickCallVideoSetting: res.pf_quick_video,
    patientScheduledMessagesSetting: res.pf_schedule_messages,
    patientVideoCallingSetting: res.pf_video_call,
    patientVirtualWaitingRoomSetting: res.pf_virtual_waiting_room,
    patientVoiceCallingSetting: res.pf_voip,
    priorityMessagingSetting: res.priority_messaging,
    teamsSetting: res.teams,
    isVideoCallEnabled: parseBooleanSettingOption(res.video_call),
  };
}

export function parseServerAccountSearch(res: ServerAccountSearch): AccountSearch | undefined {
  const { entity } = res.entities;
  if (!entity) return;
  const {
    account_status: accountStatus,
    account_type: accountType,
    activation_time: activationTime,
    display_name: displayName,
    dnd,
    email_addresses,
    membership,
    org_settings,
    first_name: firstName,
    last_name: lastName,
    phone_numbers: phoneNumbers,
    status,
    token,
    verified,
  } = entity;
  const { email_address } = email_addresses
    ? email_addresses
    : {
        email_address: {
          $t: '',
          verified: '',
        },
      };

  return {
    entities: {
      entity: {
        accountStatus,
        accountType,
        activationTime,
        displayName,
        dnd,
        emailAddresses: {
          emailAddress: { text: email_address.$t, verified: email_address.verified },
        },
        firstName,
        lastName,
        membership: {
          organization: membership?.organization.map((o) => ({
            ...o,
            totalMembers: o.total_members,
          })),
        },
        organizationSettings: fixArray(org_settings).map((o) => ({
          ...o,
          organizationId: o.organization_id,
        })),
        phoneNumbers,
        status,
        token,
        verified,
      },
    },
  };
}

export function parseServerDevices(res: ServerDevice[]): Device[] {
  return res.map((d) => {
    return {
      id: d.resource,
      deviceType: d.device_type,
      lastLoginTime: d.last_login_time,
      productVersion: d.product_version,
    };
  });
}

export function parseServerPendingUsers(res: ServerPendingUser[]): PendingUser[] {
  return Camelizer.camelizeObject(res) as PendingUser[];
}
