import EventEmitter from 'events';
import { action, observable, makeObservable } from 'mobx';
import queue from 'emitter-queue';

export default class EscalationStore {
  @observable.shallow currentCancelEscalation = null;
  @observable.shallow currentRemoveEscalation = null;

  events = queue(new EventEmitter());

  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  @action('EscalationStore.acknowledgeEscalation')
  acknowledgeEscalation = async (escalationExecution) => {
    const { modalStore } = this.stores;

    try {
      const sdkEscalationExecution = await this.client.escalations.acknowledge(escalationExecution);
      return this.entityStore.syncOne(sdkEscalationExecution);
    } catch (err) {
      console.error(err);
      modalStore.openModal('failure');
    }
  };

  @action('EscalationStore.cancelEscalation') cancelEscalation = async (escalationExecution) => {
    await this.client.escalations.cancel(escalationExecution);
  };

  @action('EscalationStore.ensureEscalationExecution')
  ensureEscalationExecution = async (escalationExecution) => {
    await this.client.escalations.ensureEscalationExecution(escalationExecution);
  };

  @action('EscalationStore.escalateMessage') escalateMessage = async (messageId) => {
    const escalationExecution = await this.client.escalations.escalateMessage(messageId);
    const { originalMessage: message } = escalationExecution;

    this.events.emit('escalateMessage', { message });
  };

  @action('EscalationStore.removeEscalationGroup')
  removeEscalationGroup = async (escalationExecution) => {
    await this.client.escalations.removeEscalationGroup(escalationExecution);
  };

  @action('EscalationStore.selectEscalationGroupConversation')
  selectEscalationGroupConversation = (escalationExecution) => {
    const { conversationStore } = this.stores;
    const { group } = escalationExecution || {};
    if (!group || !group.conversation) return;

    conversationStore.selectConversationWithEntity({ group });

    this.events.emit('selectEscalationGroupConversation');
  };

  @action('EscalationStore.showCancelEscalationModal')
  showCancelEscalationModal = (escalationExecution) => {
    const { modalStore } = this.stores;
    this.currentCancelEscalation = escalationExecution;
    modalStore.openModal('cancelEscalation');
  };

  @action('EscalationStore.hideCancelEscalationModal')
  hideCancelEscalationModal = () => {
    this.currentCancelEscalation = null;
  };

  @action('EscalationStore.showEscalationPolicyInfoPane')
  showEscalationPolicyInfoPane = (escalationExecution) => {
    const { messengerStore } = this.stores;
    messengerStore.showInfoPane({
      escalationExecution,
      type: 'EscalationPolicyInfo',
    });

    this.events.emit('showEscalationPolicyInfoPane');
  };

  @action('EscalationStore.showRemoveEscalationGroupModal')
  showRemoveEscalationGroupModal = (escalationExecution) => {
    const { modalStore } = this.stores;

    this.currentRemoveEscalation = escalationExecution;
    modalStore.openModal('removeEscalationGroup');
  };

  @action('EscalationStore.hideRemoveEscalationGroupModal')
  hideRemoveEscalationGroupModal = () => {
    this.currentRemoveEscalation = null;
  };

  @action('EscalationStore.skipEscalation') skipEscalation = async (escalationExecution) => {
    await this.client.escalations.skip(escalationExecution);

    this.events.emit('skipEscalation');
  };
}
