import React, { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import { UnreadBadgeCount, Scrollbars } from '../../../../common/components';
import TCClient from '../../../../client';

import propTypes from 'common/propTypes';
import { mobxInjectSelect } from 'common/utils';
import BEM from 'common/bem';
import { ReactComponent as SearchCancelSvg } from 'common/images/search-cancel.svg';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';
import AccessibleList from 'common/components/AccessibleList';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('OrganizationSelector');

const ORGANIZATION_HEADER_OFFSET = 80;
const ORGANIZATION_PADDING_BOTTOM = 10;
const LIST_ITEM_HEIGHT = 33.6;

function getScrollContainerHeight(organizationIds) {
  if (window.innerHeight < ORGANIZATION_HEADER_OFFSET + LIST_ITEM_HEIGHT * organizationIds.length) {
    return window.innerHeight - ORGANIZATION_HEADER_OFFSET - ORGANIZATION_PADDING_BOTTOM;
  } else {
    if (organizationIds.length === 0) {
      return LIST_ITEM_HEIGHT + ORGANIZATION_PADDING_BOTTOM;
    }
    return LIST_ITEM_HEIGHT * organizationIds.length + ORGANIZATION_PADDING_BOTTOM;
  }
}

class OrganizationSelector extends Component {
  static propTypes = {
    changeOrganizationFromUI: PropTypes.func.isRequired,
    currentOrganizationId: PropTypes.string,
    organizations: propTypes.organizationArray,
    stopComposing: PropTypes.func.isRequired,
    accessibilityMode: PropTypes.bool,
  };

  state = {
    currentOrgList: this.props.organizations,
    scrollContainerHeight: getScrollContainerHeight(this.props.organizations),
    searchQuery: '',
  };

  constructor(props) {
    super(props);
    this.inputSearchRef = React.createRef();
    this.clearSearchButtonRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this._windowResized);
    this.inputSearchRef?.current?.focus();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._windowResized);
  }

  render() {
    const { currentOrganizationId, accessibilityMode } = this.props;
    const { currentOrgList, searchQuery, scrollContainerHeight } = this.state;
    return (
      <div
        className={classes()}
        handlers={this.handlers}
        ref={this._setNode}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            this.props.closeSelector();
            if (accessibilityMode) {
              document.getElementById('org-selector').focus();
            } else {
              this.reduxEscapeHatch.focusMessageBodyInput();
            }
          }
        }}
        tabIndex={-1}
      >
        <ReduxEscapeHatch
          ref={(ref) => {
            this.reduxEscapeHatch = ref;
          }}
        />

        <AccessibleList
          focusableClasses={[
            '.tc-OrganizationSelector__list-item',
            '.tc-OrganizationSelector__search',
          ]}
          className={classes('list')}
          value={currentOrganizationId}
          aria-labelledby="org-selector"
          accessibilityMode={accessibilityMode}
        >
          <div className={classes('search-container')} tabIndex={-1}>
            <input
              className={classes('search')}
              onChange={this._handleSearch}
              placeholder="Search"
              value={searchQuery}
              tabIndex={-1}
              type="text"
              aria-label="Search organization"
              ref={this.inputSearchRef}
              onKeyDown={(e) => {
                if (!accessibilityMode) return;
                if (e.key === KEYMAP.TAB) {
                  if (
                    accessibilityMode &&
                    this.clearSearchButtonRef?.current &&
                    searchQuery.length
                  ) {
                    this.clearSearchButtonRef?.current?.focus();
                  }
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              id="search-organization-input"
            />
            <button
              style={{ display: searchQuery.length > 0 ? 'flex' : 'none' }}
              className={classes('search-icon-container', { search: true })}
              onClick={() => {
                this._clearSearch();
                if (accessibilityMode) this.inputSearchRef?.current?.focus();
              }}
              aria-label="Clear search"
              ref={this.clearSearchButtonRef}
              onKeyDown={(e) => {
                if (e.key === KEYMAP.SPACE || e.key === KEYMAP.ENTER) {
                  e.stopPropagation();
                  e.preventDefault();
                  this._clearSearch();
                  if (accessibilityMode) this.inputSearchRef?.current?.focus();
                }
                if (e.key === KEYMAP.TAB && accessibilityMode) {
                  e.stopPropagation();
                  e.preventDefault();
                  this.inputSearchRef.current?.focus();
                }
              }}
            >
              <SearchCancelSvg className={classes('search-icon', { search: true })} aria-hidden />
            </button>
          </div>
          <div
            className={classes('scroll-container')}
            style={{
              height: scrollContainerHeight,
            }}
          >
            <Scrollbars shouldScrollOverflow={true} customStyle={{ display: 'inline-block' }}>
              {currentOrgList.length > 0 ? (
                currentOrgList.map((organization) => {
                  const {
                    displayName,
                    id,
                    unreadAlertsCount,
                    unreadCount,
                    unreadPriorityCount,
                    virtualWaitingRoomUnreadCount,
                  } = organization;
                  const isSelected = organization.id === currentOrganizationId;
                  const isPriority = unreadPriorityCount > 0;
                  const combinedUnreadCount =
                    unreadAlertsCount + unreadCount + virtualWaitingRoomUnreadCount;

                  return (
                    <li
                      className={classes('list-item', {
                        'is-priority': isPriority,
                      })}
                      key={id}
                      onClick={this._handleClick(organization)}
                      value={id}
                      role="presentation"
                      aria-atomic
                      onKeyDown={(e) => this._onKeyDown(e, organization)}
                      aria-label={
                        combinedUnreadCount > 0
                          ? `${displayName}. ${combinedUnreadCount} unread ${
                              combinedUnreadCount > 1 ? 'messages' : 'message'
                            }`
                          : displayName
                      }
                    >
                      <div
                        className={classes('list-item-name', {
                          'is-selected': isSelected,
                        })}
                      >
                        {displayName}
                      </div>
                      {combinedUnreadCount > 0 && (
                        <UnreadBadgeCount count={combinedUnreadCount} priority={isPriority} />
                      )}
                    </li>
                  );
                })
              ) : (
                <div className={classes('search-no-results')}>
                  No results found for &ldquo;{searchQuery}&rdquo;
                </div>
              )}
            </Scrollbars>
          </div>
        </AccessibleList>
      </div>
    );
  }

  _onKeyDown = (e, organization) => {
    if (e.key === KEYMAP.ENTER || e.key === KEYMAP.SPACE) {
      this._handleClick(organization)(e);
      document.getElementById('org-selector')?.focus();
    }
    if (e.key === KEYMAP.TAB) {
      e.preventDefault();
    }
    if (e.key === KEYMAP.ESCAPE) {
      this.props.closeSelector();
      if (this.props.accessibilityMode) {
        document.getElementById('org-selector')?.focus();
      } else {
        this.reduxEscapeHatch.focusMessageBodyInput();
      }
    }
  };

  _handleSearch = (e) => {
    const { organizations } = this.props;
    const { value } = e.target;
    const filterOrgList = organizations.sort(this._sortByDisplayName).filter((org) => {
      const organizationIds = TCClient.currentUser.organizationIds;
      const lowerCaseDisplayName = org.displayName.toLowerCase();
      const lowerCaseSearchQuery = value.toLowerCase();
      const filterOrg = organizationIds.filter((id) => id === org.id);
      return filterOrg.length ? lowerCaseDisplayName.includes(lowerCaseSearchQuery) : false;
    });
    this.setState({
      searchQuery: value,
      scrollContainerHeight: getScrollContainerHeight(filterOrgList),
      currentOrgList: filterOrgList,
    });
  };

  _clearSearch = () => {
    this.setState({
      searchQuery: '',
      scrollContainerHeight: getScrollContainerHeight(this.props.organizations),
      currentOrgList: this.props.organizations,
    });
    document.getElementById('search-organization-input').focus();
  };

  _handleClick = (organization) => {
    const { changeOrganizationFromUI, stopComposing, closeSelector } = this.props;

    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      closeSelector();
      changeOrganizationFromUI(organization);
      stopComposing();
    };
  };

  _windowResized = throttle(() => {
    const { currentOrgList } = this.state;

    if (
      window.innerHeight <
      ORGANIZATION_HEADER_OFFSET +
        ORGANIZATION_PADDING_BOTTOM +
        LIST_ITEM_HEIGHT * currentOrgList.length
    ) {
      this.setState({
        scrollContainerHeight:
          window.innerHeight - ORGANIZATION_HEADER_OFFSET - ORGANIZATION_PADDING_BOTTOM,
      });
    } else {
      this.setState({
        scrollContainerHeight:
          LIST_ITEM_HEIGHT * currentOrgList.length + ORGANIZATION_PADDING_BOTTOM,
      });
    }
  }, 100);

  _sortByDisplayName = (a, b) => {
    const nameA = (a && a.displayName) || '';
    const nameB = (b && b.displayName) || '';
    return nameA.localeCompare(nameB);
  };
}

export default mobxInjectSelect({
  composeMessageStore: ['stopComposing'],
  messengerStore: ['changeOrganizationFromUI', 'currentOrganizationId', 'organizations'],
  sessionStore: ['accessibilityMode'],
})(OrganizationSelector);
