import { MultiSelectOption } from '../types';

export default function multiSelectOptionFilter(
  applyAllOption: MultiSelectOption,
  options: MultiSelectOption[],
  filter: string,
  current: MultiSelectOption[]
) {
  const string = filter.toLocaleLowerCase();
  const filtered = options.filter(
    (option) =>
      !current.map((x) => x.value).includes(option.value) &&
      (!filter ||
        option.label.toLocaleLowerCase().indexOf(string) >= 0 ||
        option.value.toLocaleLowerCase().indexOf(string) >= 0)
  );
  return [applyAllOption, ...filtered];
}
