import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import styles from '../index.module.css';
import UserSettingsAndAccessForm from '../UserSettingsAndAccessForm';
import { getUserPermissions, userSettingsLabels } from './UserSettings';
import { renderSettings } from './RenderSettings';
import {
  UserSettings,
  UserOrganizationSettings,
  AdminStateSlice,
  PermissionMap,
} from 'admin/types';
import TCClient from 'client';
import { hasOrgFeatureOrSettings } from 'admin/utils/renderSettingConditions';

type UserSettingsFormProps = {
  isNewUserSettingsLayoutEnabled: boolean;
  selectedOrganization: AdminStateSlice['selectedOrganization'];
  selectedUser: AdminStateSlice['selectedUser'];
  userSettings?: UserSettings;
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettings | undefined>>;
  setUserSettingsCopy: React.Dispatch<React.SetStateAction<UserSettings | undefined>>;
  orgSettings?: UserOrganizationSettings;
  setOrgSettings: (os: UserOrganizationSettings) => void;
  featureFlags: Record<string, unknown>;
  urlParams: Record<string, string>;
  userPermissions: PermissionMap | undefined;
  setUserPermissions: React.Dispatch<React.SetStateAction<PermissionMap | undefined>>;
  setUserPermissionsCopy: React.Dispatch<React.SetStateAction<PermissionMap | undefined>>;
};
export default function UserSettingsForm({
  isNewUserSettingsLayoutEnabled,
  selectedOrganization,
  selectedUser,
  featureFlags,
  urlParams,
  userSettings,
  setUserSettings,
  setUserSettingsCopy,
  orgSettings,
  setOrgSettings,
  userPermissions,
  setUserPermissions,
  setUserPermissionsCopy,
}: UserSettingsFormProps) {
  useEffect(() => {
    async function getOrganizationSettings() {
      const org_settings = await TCClient.adminUsers.fetchOrganizationSettings({
        organizationId: selectedOrganization.token,
      });
      setOrgSettings(org_settings);

      const user_settings = (await TCClient.adminUsers.getUserSettings({
        userId: selectedUser.id,
        organizationId: selectedOrganization.token,
      })) as UserSettings;
      setUserSettings(user_settings);
      setUserSettingsCopy(user_settings);

      const user_permissions = await getUserPermissions(
        selectedOrganization.token,
        selectedUser.id
      );
      setUserPermissions(user_permissions);
      setUserPermissionsCopy(user_permissions);
    }
    try {
      getOrganizationSettings();
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [
    selectedOrganization.token,
    selectedUser.id,
    setOrgSettings,
    setUserPermissions,
    setUserPermissionsCopy,
    setUserSettings,
    setUserSettingsCopy,
  ]);

  if (!orgSettings || !userSettings) {
    return null;
  }

  if (isNewUserSettingsLayoutEnabled) {
    return (
      <UserSettingsAndAccessForm
        userPermissions={userPermissions}
        setUserPermissions={setUserPermissions}
        setUserSettings={setUserSettings}
        userSettings={userSettings}
      />
    );
  }

  const { settings, patientEngagementSettings } = userSettingsLabels(
    userSettings,
    orgSettings,
    setUserSettings,
    featureFlags,
    urlParams
  );

  return (
    <div className={styles.userSettingsForm}>
      {settings.map((setting, index) => {
        return renderSettings(orgSettings, setting, index);
      })}

      {hasOrgFeatureOrSettings(orgSettings, ['featurePatientEngagement']) && (
        <h3 className={styles.patientEngagementTitle}>TigerConnect Patient Engagement</h3>
      )}

      {patientEngagementSettings.map((setting, index) => {
        return renderSettings(orgSettings, setting, index);
      })}
    </div>
  );
}
