import EventEmitter from 'events';
import ReactDOM from 'react-dom';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import queue from 'emitter-queue';
import { difference } from 'ramda';

import { FROZEN_EMPTY_ARRAY, getRecipientEntity } from '../common/utils';
import { AppTypes } from '../models/enums';

export const ALL_IMAGES_LOADED_TIMEOUT = 1700;
export const ALL_CONTENT_SETTLED_TIMEOUT = 100;
const DIFFERENCE_FROM_WINDOW = 110;
const HEADER_HEIGHT = 143;
const LOAD_MESSAGE_INCREMENT = 15;
export const LOADING_DOTS_TIMEOUT = 215;
const PIXELS_PER_MESSAGE = 70;
const ROLE_BANNER_HEIGHT = 31;
const ROLE_HEADER_HEIGHT = 173;
const VWR_HEADER_HEIGHT = 178;

function getDefaultMessagesToShow() {
  return (
    (typeof window === 'undefined'
      ? 10
      : Math.ceil((window.innerHeight - DIFFERENCE_FROM_WINDOW) / PIXELS_PER_MESSAGE)) +
    LOAD_MESSAGE_INCREMENT
  );
}

export default class ConversationStore {
  events = queue(new EventEmitter());
  @observable currentConversationId = null;
  @observable currentConversationInitialUnreadCount = 0;
  @observable imagesLoaded = [];
  @observable imagesLoading = [];
  @observable isLoadingConversation = false;
  @observable loadingImagesInProgress = false;
  @observable messagesToShow = getDefaultMessagesToShow();
  @observable patientFilteredConversations = [];
  @observable patientFilteredEntity = null;
  @observable setPatientFilteredConversations = false;
  @observable scrollbar = null;
  @observable shouldMarkConversationAsRead = false;
  @observable shouldShowLimitedMessages = false;
  @observable showLoadingDots = false;
  @observable selectedMessageInitialOffset = 0;
  @observable.shallow currentDeleteConversation = null;
  @observable.shallow currentMuteConversation = null;
  @observable.shallow stickyFirstUnreadMessage = null;

  @observable elements = {
    NewMessageIndicator: null,
  };

  constructor({ client, entityStore, stores }) {
    makeObservable(this);
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;

    this.client.on(
      'conversation:clearTimeline',
      action((conversationId) => {
        this.fetchCurrentConversation(conversationId);
      })
    );
  }

  mounted() {
    const { composeMessageStore } = this.stores;

    this.entityStore.events.on(
      'conversations:loading:stop',
      action(() => {
        const conversationId =
          this.currentConversationId || composeMessageStore.currentPreviewConversationId;
        if (conversationId) {
          this.findConversation(conversationId);
        }
      })
    );
  }

  mobxInternalLog = (...args) => {
    const { featureStore, trackerStore } = this.stores;
    if (!featureStore?.featureFlags?.TRACK_CONVERSATION_HANGING) {
      return;
    }
    trackerStore.send({ message: 'trackConversationHang', payload: { log: args.join(' ') } });
  };

  fetchReactions = async (conversation, messagesAdded) => {
    const { timelineIds = [] } = conversation || {};
    const messageIds =
      messagesAdded || timelineIds.filter((messageId) => !messageId.includes('bang'));
    this.client.messages.getReactions(messageIds);
  };

  @computed get canAutoSelectConversation() {
    const { composeMessageStore, messengerStore } = this.stores;
    const { currentAppSelected } = messengerStore;
    return (
      !this.setPatientFilteredConversations &&
      !composeMessageStore.isComposing &&
      !messengerStore.isLoading &&
      messengerStore.conversationsLoaded &&
      currentAppSelected !== AppTypes.VIRTUAL_WAITING
    );
  }

  @computed get conversations() {
    const { messengerStore, networkStore } = this.stores;
    const { currentOrganization, currentAppSelected } = messengerStore;
    const { isProviderNetwork } = networkStore;

    if (currentOrganization && currentOrganization.conversations) {
      if (currentAppSelected === AppTypes.VIRTUAL_WAITING) {
        return currentOrganization.conversations.filter((conversation) => {
          return conversation.featureService === 'vwr';
        });
      }

      if (isProviderNetwork) {
        return currentOrganization.conversations.filter((conversation) => {
          if (!messengerStore.isGroupAlertsVCAllowed) {
            return (
              conversation.network === 'PROVIDER' &&
              conversation.counterParty?.metadata?.meta_type !== 'alert_conversation'
            );
          }
          return conversation.network === 'PROVIDER';
        });
      } else {
        if (this.setPatientFilteredConversations) {
          return this.patientFilteredConversations;
        } else {
          return currentOrganization.conversations.filter((conversation) => {
            return (
              conversation.network === 'PATIENT' &&
              conversation.featureService === 'patient_messaging'
            );
          });
        }
      }
    }

    return FROZEN_EMPTY_ARRAY;
  }

  @computed get hasConversations() {
    const { composeMessageStore } = this.stores;
    return this.conversations.length > 0 || composeMessageStore.isComposing;
  }

  @computed get hasCurrentConversation() {
    return !!this.currentConversation;
  }

  @computed get currentConversation() {
    return this.entityStore.conversation.getById(this.currentConversationId);
  }

  @computed get counterPartyProfile() {
    const { entityStore, messengerStore, userStore } = this.stores;
    const currentDndAutoForwardEntitiesMap = this.client.conversations.getDndAutoForwardEntitiesMap(
      messengerStore.currentOrganizationId
    );
    const currentDndAutoForwardReceiverMap = this.client.conversations.getDndAutoForwardReceiverMap(
      messengerStore.currentOrganizationId
    );
    if (!this.currentConversation) return undefined;

    const counterParty = this.currentConversation.counterParty;
    if (counterParty.$entityType !== 'user') return undefined;

    const id = `${counterParty.id}:${messengerStore.currentOrganizationId}`;
    const userProfile = entityStore.userProfile.getById(id);
    let currAutoForwardEntities = messengerStore.isExtendedAutoForwardOptionsEnabled
      ? userProfile?.dndAutoForwardEntities
      : userProfile?.autoForwardReceivers;
    if (!currAutoForwardEntities || currAutoForwardEntities?.length === 0) return {};

    if (messengerStore.isExtendedAutoForwardOptionsEnabled) {
      const userToken = `${currAutoForwardEntities[0].token}:${messengerStore.currentOrganizationId}`;
      if (userProfile?.dndAutoForwardEntities.length === 1) {
        if (currentDndAutoForwardEntitiesMap.has(userToken)) {
          currAutoForwardEntities = currentDndAutoForwardEntitiesMap.get(userToken);
        }
      }
      return {
        displayName: userStore.getAutoForwardEntityDisplayName(currAutoForwardEntities),
      };
    } else {
      const userId = `${currAutoForwardEntities[0].id}:${messengerStore.currentOrganizationId}`;
      if (currentDndAutoForwardReceiverMap.has(userId)) {
        currAutoForwardEntities = [currentDndAutoForwardReceiverMap.get(userId)];
      }
      return currAutoForwardEntities?.[0];
    }
  }

  @computed get scrollOffset() {
    const { messengerStore, composeMessageStore } = this.stores;
    const { showRoleBanner } = messengerStore;
    let headerHeight = HEADER_HEIGHT;

    const conversation = this.currentConversation || composeMessageStore.currentPreviewConversation;

    if (!conversation) return headerHeight;

    const { counterParty, counterPartyType, featureService } = conversation;
    if (counterPartyType && counterParty) {
      const { entityType } = getRecipientEntity({
        entity: counterParty,
        entityType: counterPartyType,
      });

      if (entityType === 'role') headerHeight = ROLE_HEADER_HEIGHT;
    }

    if (featureService === 'vwr') headerHeight = VWR_HEADER_HEIGHT;

    return showRoleBanner ? headerHeight + ROLE_BANNER_HEIGHT : headerHeight;
  }

  @action('ConversationStore.fetchCurrentConversation')
  fetchCurrentConversation = async (conversationId) => {
    const currentConversation =
      this.currentConversation || this.stores.composeMessageStore.currentPreviewConversation;
    if (!currentConversation) return;

    if (currentConversation.id !== conversationId) return;
    await this.fetchTimelineSelectedConversation(currentConversation);
  };

  @action('ConversationStore.checkConversationImagesStatus')
  checkConversationImagesStatus = (conversation) => {
    const { timeline = [] } = conversation || {};
    let done = false;
    this.mobxInternalLog(
      'checkConversationImagesStatus: building imageLoad arrays for',
      conversation?.counterParty?.displayName
    );
    for (const message of timeline) {
      if (message && message.attachments && message.attachments.length > 0) {
        message.attachments.forEach((attachment) => {
          if (this.stores.messageAttachmentStore.isInlineImage(attachment)) {
            this.imagesLoading.push(message.id);
          }
        });
      }
    }

    if (difference(this.imagesLoading, this.imagesLoaded).length === 0) {
      this.mobxInternalLog(
        'checkConversationImagesStatus: all images loaded for',
        conversation?.counterParty?.displayName
      );
      done = true;
      this.loadingImagesInProgress = false;
      this.events.emit('allImagesLoaded');
    } else {
      this.mobxInternalLog(
        'checkConversationImagesStatus: still loading for',
        conversation?.counterParty?.displayName
      );
      this.loadingImagesInProgress = true;
    }

    return done;
  };

  @action('ConversationStore.setImageLoaded') setImageLoaded = (messageId) => {
    if (this.imagesLoading.includes(messageId)) {
      this.imagesLoaded.push(messageId);
    }
    if (difference(this.imagesLoading, this.imagesLoaded).length === 0) {
      this.mobxInternalLog('setImageLoaded: all images loaded');
      this.loadingImagesInProgress = false;
      this.events.emit('allImagesLoaded');
    }
  };

  @action('ConversationStore.sentMessage') sentMessage = async (conversationId) => {
    const currentConversation =
      this.currentConversation || this.stores.composeMessageStore.currentPreviewConversation;

    if (currentConversation && currentConversation.id === conversationId) {
      if (!currentConversation.isLive) {
        await this.fetchTimelineSelectedConversation(currentConversation, {
          anchorPoint: 'CONVERSATION_END',
          markAsRead: true,
        });
      }
    }
  };

  @action('ConversationStore.setShouldShowLimitedMessages')
  setShouldShowLimitedMessages = (shouldShowLimitedMessages) => {
    this.shouldShowLimitedMessages = shouldShowLimitedMessages;
  };

  @action('ConversationStore.setCurrentConversationId')
  setCurrentConversationId = (conversationId) => {
    this.currentConversationId = conversationId;
  };

  @action('ConversationStore.clearNewMessagesIndicator')
  clearNewMessagesIndicator = (conversation) => {
    this.events.emit('clearNewMessagesIndicator', conversation);
  };

  @action('ConversationStore.setMessagesToShow') setMessagesToShow = (messagesToShow) => {
    this.messagesToShow = messagesToShow;
  };

  @action('ConversationStore.resetMessagesToShow') resetMessagesToShow = () => {
    this.messagesToShow = getDefaultMessagesToShow();
  };

  @action('ConversationStore.findAll') findAll = async () => {
    const conversations = await this.client.conversations.findAll();
    return this.entityStore.sync(conversations);
  };

  @action('ConversationStore.findConversation') findConversation = async (
    conversationId,
    options = {}
  ) => {
    try {
      const sdkConversation = await this.client.conversations.find(conversationId, options);
      return this.entityStore.syncOne(sdkConversation);
    } catch (e) {
      console.error(e);
    }
  };

  @action('ConversationStore.findConversationForEntity')
  findConversationForEntity = async (entity, { canCreateGroup = false, organization }) => {
    if (!organization) return;
    const { composeMessageStore, groupStore } = this.stores;
    const { $entityType: entityType, id: entityId, conversationId } = entity;
    const { id: orgId, isContacts } = organization;
    const senderId = composeMessageStore.setDefaultSender(entity);
    const currentSenderId = composeMessageStore.getCurrentConversationState.sender.id;
    const inSenderRole = !!this.client.roles.getById(senderId);
    let conversation;

    if (entityType === 'team' && entity.groupId && entity.memberIds.includes(currentSenderId)) {
      const intraTeamGroup = await groupStore.findGroup(entity.groupId);
      conversation = intraTeamGroup.conversation;
    } else if (entityType === 'group') {
      if (canCreateGroup) {
        conversation = await this.findOrCreateConversationWithListEntity(
          entityType,
          entityId,
          orgId,
          { conversationId }
        );
      } else {
        conversation = await this.findConversationWithListEntity(entityType, entityId, orgId);
      }
    } else if (entityType === 'distributionList') {
      conversation = await this.findConversationWithListEntity(entityType, entityId, orgId);
    } else if (entityType === 'role') {
      conversation = this.findP2PConversationWithRole(entity.botUserId, senderId);
    } else if (inSenderRole && !isContacts) {
      conversation = this.findP2PConversationWithRole(entityId, senderId);
    } else if (entityType === 'user') {
      conversation = await this.findDirectConversationWithUser(entityId, orgId);
    }

    return conversation;
  };

  @action('ConversationStore.findOrCreateConversationWithListEntity')
  findOrCreateConversationWithListEntity = (entityType, entityId, organizationId, options = {}) => {
    const { conversationId, shouldDisplay = false } = options;
    const result = this.client.conversations.getWithListEntity(entityType, entityId, {
      organizationId,
      createIfNotFound: true,
      conversationId,
      shouldDisplay,
    });
    return result ? this.entityStore.syncOne(result) : null;
  };

  @action('ConversationStore.findConversationWithListEntity')
  findConversationWithListEntity = async (entityType, entityId, organizationId) => {
    const result = this.client.conversations.getWithListEntity(entityType, entityId, {
      organizationId,
    });
    return result ? this.entityStore.syncOne(result) : null;
  };

  @action('ConversationStore.findOrCreateConversationWithUser')
  findOrCreateConversationWithUser = async (userId, organizationId) => {
    const result = this.client.conversations.getWithUser(userId, organizationId, {
      createIfNotFound: true,
    });
    return result ? this.entityStore.syncOne(result) : null;
  };

  @action('ConversationStore.findDirectConversationWithUser')
  findDirectConversationWithUser = async (userId, organizationId) => {
    const conv = this.client.conversations.getWithUser(userId, organizationId);

    if (conv) {
      return this.entityStore.syncOne(conv);
    } else {
      return null;
    }
  };

  @action('ConversationStore.findP2PConversationWithRole')
  findP2PConversationWithRole = async (recipientId, senderId) => {
    return this.client.conversations.findLocalRoleP2PConversation(recipientId, senderId);
  };

  @action('ConversationStore.findGroupConversationWithMembers')
  findGroupConversationWithMembers = async (
    members,
    organization,
    senderId,
    { includeForums = true } = {}
  ) => {
    const userIds = members.map(({ botUserId, id }) => botUserId || id);

    const results = await this.client.conversations.findAllGroupConversationsWithMembers(
      userIds,
      organization.id,
      {
        exact: true,
        includeForums,
        includeRoles: false,
        senderId,
      }
    );

    return results.length === 1 ? this.entityStore.syncOne(results[0]) : null;
  };

  @action('ConversationStore.selectConversation')
  selectConversation = async (
    conversation,
    { anchorPoint = 'FIRST_UNREAD_ITEM', markAsRead = false } = {}
  ) => {
    const sentryTransaction = this.stores.trackerStore.startSentryTransaction(
      'ConversationStore.selectConversation'
    );

    if (!conversation) {
      this.setCurrentConversationId(null);
      return;
    }
    if (this.currentConversation === conversation) {
      this.markConversationAsRead(conversation);
      this.scrollToBottom();
      return;
    }

    const { composeMessageStore, desktopAppStore, messengerStore } = this.stores;
    messengerStore.hideMessageMultiSelect();
    composeMessageStore.stopComposing();
    messengerStore.closeInfoPane();

    this.setCurrentConversationId(conversation.id);

    if (conversation.unreadCount === 0) {
      this.stores.trackerStore.compareUnreadMessages();
    }

    this.currentConversationInitialUnreadCount = conversation.unreadCount;
    this.setShouldShowLimitedMessages(
      this.hasMoreTimelineToShowTop(this.messagesToShow) ||
        this.hasMoreTimelineToShowBottom(this.messagesToShow)
    );

    this.shouldMarkConversationAsRead = markAsRead;
    this.events.emit('conversationSelected', { conversation });

    await this.fetchTimelineSelectedConversation(conversation, {
      anchorPoint,
      markAsRead,
    });

    if (markAsRead) {
      desktopAppStore.recallAllNotificationsForConversation(conversation.id);
    }

    sentryTransaction?.end();
  };

  @action('ConversationStore.selectConversationWithEntity')
  selectConversationWithEntity = async ({ group, message, markAsRead = false } = {}) => {
    const { composeMessageStore, conversationStore, messengerStore, modalStore, rosterStore } =
      this.stores;

    const conversation = message ? message.conversation : group ? group.conversation : null;
    if (!conversation) return;

    const { id: conversationId, organization } = conversation;
    const wasSelected = conversationId === conversationStore.currentConversationId;
    if (!organization || organization.$placeholder) return;

    messengerStore.openMessages();
    messengerStore.setCurrentOrganizationId(organization.id);
    modalStore.closeModal();
    rosterStore.clearFilter();
    messengerStore.closeInfoPane();
    composeMessageStore.stopComposing();
    this.setCurrentConversationId(conversationId);
    this.shouldMarkConversationAsRead = markAsRead;

    this.events.emit('conversationSelected', { conversation });

    if (wasSelected) {
      this.markConversationAsRead(conversation);
      this.scrollToBottom();
      return;
    }

    await this.fetchTimelineSelectedConversation(conversation, { markAsRead });
  };

  @action('ConversationStore.fetchTimelineSelectedConversation')
  fetchTimelineSelectedConversation = async (
    conversation,
    { anchorPoint = 'FIRST_UNREAD_ITEM', markAsRead = false } = {}
  ) => {
    const sentryTransaction = this.stores.trackerStore.startSentryTransaction(
      'ConversationStore.fetchTimelineSelectedConversation'
    );

    this.mobxInternalLog(
      'fetchTimelineSelectedConversation for',
      conversation?.counterParty?.displayName
    );
    const { composeMessageStore } = this.stores;
    const { firstUnreadMessage } = conversation;
    let contentSettledTimeout = null,
      err,
      stickyFirstUnreadMessage;

    this.isLoadingConversation = true;
    this.imagesLoading = [];
    this.imagesLoaded = [];
    this.loadingImagesInProgress = false;
    this.resetMessagesToShow();
    this.stickyFirstUnreadMessage = null;

    const loadingDotsTimeout = setTimeout(() => {
      runInAction(() => {
        if (this.imagesLoading.length > 0 || this.loadingImagesInProgress) {
          this.mobxInternalLog(
            'fetchTimelineSelectedConversation: showing loading dots for',
            conversation?.counterParty?.displayName
          );
          this.showLoadingDots = true;
        }
      });
    }, LOADING_DOTS_TIMEOUT);

    const resetState = () => {
      runInAction(() => {
        this.mobxInternalLog(
          'fetchTimelineSelectedConversation: resetState for',
          conversation?.counterParty?.displayName
        );
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.events.removeListener('allImagesLoaded', onImagesLoaded);
        clearTimeout(loadingDotsTimeout);

        if (contentSettledTimeout !== null) {
          clearTimeout(contentSettledTimeout);
          contentSettledTimeout = null;
        }

        this.loadingImagesInProgress = false;
        this.isLoadingConversation = false;
        this.showLoadingDots = false;
      });
    };

    const onImagesLoaded = () => {
      this.mobxInternalLog(
        'fetchTimelineSelectedConversation: onImagesLoaded for',
        conversation?.counterParty?.displayName
      );
      if (contentSettledTimeout !== null) {
        clearTimeout(contentSettledTimeout);
      }

      contentSettledTimeout = setTimeout(() => {
        resetState();
      }, ALL_CONTENT_SETTLED_TIMEOUT);
    };

    if (
      conversation.id !== this.currentConversationId &&
      conversation.id !== composeMessageStore.currentPreviewConversationId
    ) {
      this.mobxInternalLog('convID mismatch');
      runInAction(() => resetState());
      return;
    }

    this.events.removeListener('allImagesLoaded', onImagesLoaded);
    this.events.once('allImagesLoaded', onImagesLoaded);

    if (markAsRead) this.noteConversationAsRead(conversation);

    try {
      const returned = await this.client.conversations.selectConversation(conversation.id, {
        anchorPoint,
        markAsRead,
        minItemsToFetch: this.messagesToShow,
      });
      stickyFirstUnreadMessage = returned.previousFirstUnreadMessage;
      if (stickyFirstUnreadMessage) {
        stickyFirstUnreadMessage = this.entityStore.syncOne(stickyFirstUnreadMessage);
      }
      this.setStickyFirstUnreadMessage(stickyFirstUnreadMessage);
    } catch (inErr) {
      this.mobxInternalLog('sdk conversation model returned with error');
      err = inErr;
      console.error(err);
      resetState();
      return;
    }

    this.mobxInternalLog(
      'getting SDK conversation for ',
      conversation?.counterParty?.displayName,
      conversation.id
    );
    const sdkConversation = this.client.conversations.getById(conversation.id);
    this.mobxInternalLog('checking image status');
    const done = this.checkConversationImagesStatus(sdkConversation);
    if (sdkConversation) this.entityStore.syncOne(sdkConversation);

    if (!done) {
      const contentTime = firstUnreadMessage ? ALL_IMAGES_LOADED_TIMEOUT : 0;
      contentSettledTimeout = setTimeout(() => {
        this.mobxInternalLog(
          'fetchTimelineSelectedConversation: contentSettled timeout for',
          conversation?.counterParty?.displayName
        );
        resetState();
      }, contentTime + ALL_CONTENT_SETTLED_TIMEOUT);
    }

    if (this.stores.messengerStore.isReactionsEnabled && conversation.timeline.length > 0) {
      await this.fetchReactions(conversation);

      if (
        conversation.counterPartyType === 'group' ||
        conversation.counterPartyType === 'distributionList'
      ) {
        const { memberIds, organizationId } = conversation.counterParty;
        this.client.users.ensureUsers(memberIds, organizationId);
      }
    }

    sentryTransaction?.end();
  };

  @action('ConversationStore.setStickyFirstUnreadMessage')
  setStickyFirstUnreadMessage = (firstUnreadMessage) => {
    this.stickyFirstUnreadMessage = firstUnreadMessage;
  };

  @action('ConversationStore.removeConversation')
  removeConversation = async (conversation) => {
    if (!conversation) return;

    await this.client.conversations.remove(conversation.id);
  };

  @action('ConversationStore.scrollToBottom') scrollToBottom = () => {
    this.scrollbar && this.scrollbar.scrollToBottom();
  };

  @action('ConversationStore.scrollToBottomIfPresent')
  scrollToBottomIfPresent = () => {
    this.scrollbar && this.scrollbar.scrollToBottom();
  };

  @action('ConversationStore.showUserConversationInfoPane')
  showUserConversationInfoPane = ({ conversation, user }) => {
    const { messengerStore } = this.stores;
    messengerStore.showInfoPane({
      conversation,
      type: 'UserConversationInfo',
      user,
    });
  };

  @action('ConversationStore.muteConversation') muteConversation = async (
    conversationId,
    durationInMinutes
  ) => {
    await this.client.conversations.mute(conversationId, durationInMinutes);
    this.events.emit('mute', { conversationId, durationInMinutes });
  };

  @action('ConversationStore.unmuteConversation') unmuteConversation = async (conversationId) => {
    await this.client.conversations.unmute(conversationId);
  };

  @action('ConversationStore.markConversationAsRead')
  markConversationAsRead = async (conversation) => {
    if (!conversation) return;
    const { id: conversationId } = conversation;
    const shouldMark = this.noteConversationAsRead(conversation);

    if (shouldMark) {
      const { desktopAppStore } = this.stores;

      await this.client.conversations.markAsRead(conversationId);
      desktopAppStore.recallAllNotificationsForConversation(conversationId);
    }
  };

  @action('ConversationStore.noteConversationAsRead') noteConversationAsRead = (conversation) => {
    const { id: conversationId, markableAsReadMessages, unreadCount } = conversation;
    let shouldMark;

    if (this.client.config.condensedReplays) {
      shouldMark = unreadCount > 0;
      this.events.emit('markConversationAsRead', {
        conversation,
        conversationId,
        didMark: shouldMark,
        unreadCount,
      });
    } else {
      const markableCount = markableAsReadMessages.length;
      shouldMark = markableCount > 0;
      this.events.emit('markConversationAsRead', {
        conversation,
        conversationId,
        didMark: shouldMark,
        unreadCount: markableCount,
      });
    }

    return shouldMark;
  };

  @action('ConversationStore.showMuteModal') showMuteModal = (conversation) => {
    const { modalStore } = this.stores;
    this.currentMuteConversation = conversation;
    modalStore.openModal('mute');
  };

  @action('ConversationStore.hideMuteModal') hideMuteModal = () => {
    this.currentMuteConversation = null;
  };

  @action('ConversationStore.showDeleteConversationModal')
  showDeleteConversationModal = (conversation) => {
    const { modalStore } = this.stores;

    this.currentDeleteConversation = conversation;
    modalStore.openModal('deleteConversation');
  };

  @action('ConversationStore.hideDeleteConversationModal')
  hideDeleteConversationModal = () => {
    this.currentDeleteConversation = null;
  };

  @action('ConversationStore.getConversationById') getConversationById = (id) => {
    return this.entityStore.conversation.getById(id);
  };

  @action('ConversationStore.togglePinned') togglePinned = () => {
    const { localStore } = this.stores;
    const { pinnedConversationIds } = localStore;
    if (!this.currentConversationId) return;

    if (pinnedConversationIds.includes(this.currentConversationId)) {
      this.unpinConversation();
    } else {
      this.pinConversation();
    }
  };

  @action('ConversationStore.pinConversation') pinConversation = () => {
    const { localStore } = this.stores;
    const { pinnedConversationIds, setPinnedConversationIds } = localStore;
    if (!this.currentConversationId) return;

    if (!pinnedConversationIds.includes(this.currentConversationId)) {
      pinnedConversationIds.push(this.currentConversationId);
    }

    setPinnedConversationIds(pinnedConversationIds);
  };

  @action('ConversationStore.unpinConversation') unpinConversation = () => {
    const { localStore } = this.stores;
    const { pinnedConversationIds, setPinnedConversationIds } = localStore;
    if (!this.currentConversationId) return;

    pinnedConversationIds.remove(this.currentConversationId);

    setPinnedConversationIds(pinnedConversationIds);
  };

  @action('ConversationStore.fetchAllAlertMessagesForOrganization')
  fetchAllAlertMessagesForOrganization = async (organizationId) => {
    return await this.client.conversations.fetchAllAlertMessagesForOrganization(organizationId);
  };

  @action('ConversationStore.fetchTimeline') fetchTimeline = async (
    conversationId,
    { anchorPoint = 'CONVERSATION_END', continuation, maxItems = LOAD_MESSAGE_INCREMENT }
  ) => {
    const { isReactionsEnabled } = this.stores.messengerStore;
    const { itemsAdded, messagesToAdd } = await this.client.conversations.fetchTimeline(
      conversationId,
      {
        anchorPoint,
        continuation,
        maxItems,
        shouldReturnMessagesAdded: true,
      }
    );

    const messageIds = messagesToAdd?.map((item) => item.id);

    if (isReactionsEnabled) {
      const conversation = this.getConversationById(conversationId);
      if (conversation && conversation.timeline.length > 0) {
        await this.fetchReactions(conversation, messageIds);
      }
    }

    return itemsAdded;
  };

  @action('ConversationStore.showPatientFilteredConversations')
  showPatientFilteredConversations = (entity) => {
    const { composeMessageStore } = this.stores;

    let sharedConversations = [];
    let smsOptedOut = false;
    if (entity.isPatient && entity.patient) {
      sharedConversations = entity.patient.sharedConversations;
      smsOptedOut = entity.patient.smsOptedOut;
    } else if (entity.isPatientContact && entity.patientContact) {
      sharedConversations = entity.patientContact.sharedConversations;
      smsOptedOut = entity.patientContact.smsOptedOut;
    }

    if (sharedConversations.length > 0) {
      runInAction(() => {
        this.patientFilteredConversations = sharedConversations;
        this.patientFilteredEntity = entity;
        this.setPatientFilteredConversations = true;
      });
    } else if (!smsOptedOut) {
      composeMessageStore.composeToSearchResult(entity);
    }
  };

  @action('ConversationStore.hidePatientFilteredConversations')
  hidePatientFilteredConversations = async ({ continueSearch = true } = {}) => {
    const { messengerStore } = this.stores;

    runInAction(() => {
      this.patientFilteredConversations = [];
      this.patientFilteredEntity = null;
      this.setPatientFilteredConversations = false;

      if (!continueSearch) {
        messengerStore.stopSearching();
      }
    });
  };

  @action('ConversationStore.findAllGroupConversationsWithMembers')
  findAllGroupConversationsWithMembers = async (
    memberIds,
    orgId,
    { exact = false, localOnly = false } = {}
  ) => {
    const results = await this.client.conversations.findAllGroupConversationsWithMembers(
      memberIds,
      orgId,
      { exact, localOnly }
    );

    return this.entityStore.sync(results);
  };

  @action('ConversationStore.setScrollbarsElement') setScrollbarsElement = (ref, elementName) => {
    // eslint-disable-next-line react/no-find-dom-node
    const elementRef = ReactDOM.findDOMNode(ref);
    if (elementName === 'firstMessageSelected') {
      this.selectedMessageInitialOffset = ref.getBoundingClientRect().top;
    }
    this.elements[elementName] = elementRef;
  };

  @action('ConversationStore.hasElement') hasElement = (elementName) => {
    return !!this.elements[elementName];
  };

  @action('ConversationStore.scrollToElement') scrollToElement = (elementName, scrollOptions) => {
    const element = this.elements[elementName];
    if (!element) return;
    const elementTop = element.getBoundingClientRect().top;
    this.scrollToPosition(elementTop, scrollOptions);
  };

  @action('ConversationStore.scrollToPosition') scrollToPosition = (
    elementTop,
    { customOffset = 0 } = {}
  ) => {
    if (!this.scrollbar) return;

    const scrollbarValues = this.scrollbar.scrollbar.getValues();
    if (!!scrollbarValues) {
      const scrollTop = elementTop + scrollbarValues.scrollTop - this.scrollOffset - customOffset;
      this.scrollbar.scrollbar.scrollTop(scrollTop);
    }
  };

  @action('ConversationStore.setScrollbars') setScrollbars = (ref) => {
    this.scrollbar = ref;
  };

  @action('hasMoreTimelineToShowTop') hasMoreTimelineToShowTop = (numOfMessagesToShow) => {
    if (!this.currentConversation) return false;

    const { timeline } = this.currentConversation;
    if (
      timeline.length === 0 ||
      numOfMessagesToShow >= timeline.length ||
      timeline.length - numOfMessagesToShow < 0
    ) {
      return false;
    }

    if (this.stickyFirstUnreadMessage) {
      const firstUnreadIdx = timeline.length - this.currentConversationInitialUnreadCount;
      if (firstUnreadIdx - numOfMessagesToShow < 0) {
        return false;
      }

      const firstInTimelineAfterTruncation =
        timeline[Math.max(0, firstUnreadIdx - numOfMessagesToShow)];
      return firstInTimelineAfterTruncation !== timeline[0];
    }

    const firstInTimelineAfterTruncation =
      timeline[Math.max(0, timeline.length - numOfMessagesToShow)];
    return firstInTimelineAfterTruncation !== timeline[0];
  };

  @action('hasMoreTimelineToShowBottom') hasMoreTimelineToShowBottom = (numOfMessagesToShow) => {
    if (!this.stickyFirstUnreadMessage || !this.currentConversation) return false;

    const { timeline } = this.currentConversation;
    if (timeline.length === 0 || numOfMessagesToShow >= timeline.length) return false;

    const firstUnreadIdx = timeline.length - this.currentConversationInitialUnreadCount;
    if (firstUnreadIdx + numOfMessagesToShow >= timeline.length) {
      return false;
    }

    const lastInTimelineAfterTruncation =
      timeline[Math.min(timeline.length - 1, firstUnreadIdx + numOfMessagesToShow)];
    return lastInTimelineAfterTruncation !== timeline[timeline.length - 1];
  };

  @action('ConversationStore.navigateToConversation') navigateToConversation = async (
    entityType,
    id,
    organizationId
  ) => {
    const conversationKey = this.client.conversations.getConversationKey(
      entityType,
      id,
      organizationId
    );

    if (this.currentConversationId !== conversationKey) {
      this.setCurrentConversationId(null);
      this.setCurrentConversationId(conversationKey);
      await this.fetchCurrentConversation(conversationKey);
    }
  };
}
