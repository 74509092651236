import PropTypes from 'prop-types';
import { PropTypes as MobXReactPropTypes } from 'mobx-react';

import { MessageTemplateRepositories } from '../models/enums/MessageTemplateRepositories';

const { arrayOrObservableArrayOf, observableArray, observableMap } = MobXReactPropTypes;

const idType = PropTypes.string;
const shape = (schema) => PropTypes.shape(schema);

const ON = false;

const createEntityPropTypes = (schema) => {
  const shapePropType = ON ? shape(schema) : PropTypes.object;

  return {
    schema,
    shape: shapePropType,
    array: arrayOrObservableArrayOf(shapePropType),
  };
};

const escalationStatus = PropTypes.oneOf([
  'ACKNOWLEDGED',
  'CANCELLED',
  'IN_PROGRESS',
  'NO_RESPONSE',
]);
const messageRecipientStatus = PropTypes.oneOf(['NA', 'NEW', 'DELIVERED', 'READ', 'ACKNOWLEDGED']);
const messageSenderStatus = PropTypes.oneOf(['NA', 'NEW', 'SENDING', 'SENT', 'FAILED', 'RETRYING']);
const ref = PropTypes.oneOfType([PropTypes.node, PropTypes.object]);

const userPropTypes = createEntityPropTypes({
  id: idType.isRequired,
  displayName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
});

const counterPartyPropTypes = createEntityPropTypes({
  id: idType.isRequired,
  displayName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
});

const groupPropTypes = createEntityPropTypes({
  id: idType.isRequired,
  displayName: PropTypes.string.isRequired,
  members: userPropTypes.array,
  memberCount: PropTypes.number,
  avatarUrl: PropTypes.string,
});

const distributionListPropTypes = createEntityPropTypes({
  id: idType.isRequired,
  displayName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
});

const messageAttachmentPropTypes = createEntityPropTypes({
  id: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  contentType: PropTypes.string.isRequired,
  name: PropTypes.string,
  localPath: PropTypes.string,
});

const messagePropTypes = createEntityPropTypes({
  sender: userPropTypes.shape.isRequired,
  recipient: userPropTypes.shape,
  group: groupPropTypes.shape,
  senderStatus: messageSenderStatus.isRequired,
  isOutgoing: PropTypes.bool.isRequired,
  attachments: messageAttachmentPropTypes.array,
  // can't specify shape(organization) because of infinite loop
  senderOrganization: PropTypes.object,
  recipientOrganization: PropTypes.object,
});

const messageTemplatePropTypes = createEntityPropTypes({
  attachmentName: PropTypes.string,
  attachmentSize: PropTypes.number,
  attachmentType: PropTypes.string,
  body: PropTypes.string.isRequired,
  createdOn: PropTypes.string.isRequired,
  hasAttachment: PropTypes.bool.isRequired,
  network: PropTypes.string.isRequired,
  repository: PropTypes.oneOf(Object.values(MessageTemplateRepositories)).isRequired,
  title: PropTypes.string.isRequired,
  updatedOn: PropTypes.string.isRequired,
});

const conversationPropTypes = createEntityPropTypes({
  content: messagePropTypes.array.isRequired,
  counterParty: counterPartyPropTypes.shape.isRequired,
  id: idType.isRequired,
  lastMessage: messagePropTypes.shape,
  messages: messagePropTypes.array.isRequired,
});

const organizationPropTypes = createEntityPropTypes({
  id: idType.isRequired,
  name: PropTypes.string.isRequired,
  conversations: conversationPropTypes.array,
});

const escalationExecutionPropTypes = createEntityPropTypes({
  id: idType.isRequired,
  actionUserName: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  displayId: PropTypes.string.isRequired,
  path: PropTypes.arrayOf(PropTypes.object),
  status: escalationStatus.isRequired,
  targetMinutesToAcknowledge: PropTypes.number,
});

const rolePropTypes = createEntityPropTypes({
  id: idType.isRequired,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  memberIds: PropTypes.array.isRequired,
  organizationId: PropTypes.string.isRequired,
  tagId: PropTypes.string.isRequired,
});

const scheduledMessageStatePropTypes = createEntityPropTypes({
  attachments: PropTypes.arrayOf(messageAttachmentPropTypes.shape).isRequired,
  body: PropTypes.string.isRequired,
  noReply: PropTypes.bool.isRequired,
  recipients: PropTypes.arrayOf(
    PropTypes.oneOf([userPropTypes.shape, distributionListPropTypes.shape])
  ).isRequired,
  repeatEnabled: PropTypes.bool.isRequired,
  resetSchedule: PropTypes.string,
  scheduleOption: PropTypes.oneOf(['NOT_SELECTED', 'SEND_NOW', 'SET_DATE_TIME']).isRequired,
  selectedEndDate: PropTypes.instanceOf(Date),
  selectedStartDate: PropTypes.instanceOf(Date),
  selectedTime: PropTypes.instanceOf(Date),
  timezone: PropTypes.string,
});

const searchResultPropTypes = createEntityPropTypes({
  entity: PropTypes.object,
  label: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  value: idType.isRequired,
});

const teamPropTypes = createEntityPropTypes({
  id: idType.isRequired,
  canRequestToJoin: PropTypes.bool.isRequired,
  conversationId: idType,
  description: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  numMembers: PropTypes.number.isRequired,
  organizationId: idType.isRequired,
  serverId: idType.isRequired,
});

const userOrRolePropTypes = createEntityPropTypes({
  $entityType: PropTypes.oneOf(['role', 'user']).isRequired,
  id: idType.isRequired,
  displayName: PropTypes.string.isRequired,
});

const createExportObject = (name, propTypes) => ({
  [name]: propTypes.shape,
  [`${name}Schema`]: propTypes.schema,
  [`${name}Array`]: propTypes.array,
});

const propTypes = {
  arrayOrObservableArrayOf,
  idType,
  messageRecipientStatus,
  messageSenderStatus,
  observableArray,
  observableMap,
  ref,
  ...createExportObject('conversation', conversationPropTypes),
  ...createExportObject('counterParty', counterPartyPropTypes),
  ...createExportObject('distributionList', distributionListPropTypes),
  ...createExportObject('escalationExecution', escalationExecutionPropTypes),
  ...createExportObject('group', groupPropTypes),
  ...createExportObject('message', messagePropTypes),
  ...createExportObject('messageAttachment', messageAttachmentPropTypes),
  ...createExportObject('messageTemplate', messageTemplatePropTypes),
  ...createExportObject('organization', organizationPropTypes),
  ...createExportObject('role', rolePropTypes),
  ...createExportObject('scheduledMessageState', scheduledMessageStatePropTypes),
  ...createExportObject('searchResult', searchResultPropTypes),
  ...createExportObject('team', teamPropTypes),
  ...createExportObject('user', userPropTypes),
  ...createExportObject('userOrRole', userOrRolePropTypes),
};

export default propTypes;
