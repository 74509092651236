import { sdkApi } from '../api';
import TCClient from 'client';
import { SearchTypes } from 'models/enums';
import { SearchArgType, SearchResults } from 'types/api';
import { KeywordSearchSecondLevelResult } from 'js-sdk/src/apis/SearchAPI';
import { Camelizer } from 'js-sdk/src/utils';

const keywordSearchSecondaryApi = sdkApi.injectEndpoints({
  endpoints: (build) => ({
    searchAll: build.query<SearchResults<KeywordSearchSecondLevelResult>, SearchArgType>({
      queryFn: async ({ searchValue, orgId, continuation, conversationId }, _queryApi) => {
        const res = await TCClient.search.keywordSearch({
          organizationId: orgId,
          version: 'SEARCH_PARITY',
          types: [SearchTypes.MESSAGE],
          continuation: continuation || '',
          searchText: searchValue,
          conversationId: conversationId,
        });

        const results = res.results as KeywordSearchSecondLevelResult[];

        return {
          data: {
            results: results,
            metadata: Camelizer.camelizeObject(res.metadata),
          },
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { searchValue, orgId, conversationId } = queryArgs;
        return { searchValue, orgId, conversationId };
      },
      merge: (currentCache, newItems) => {
        if (newItems.metadata.firstHit === 1) {
          currentCache.results = newItems.results;
          currentCache.metadata = newItems.metadata;
        } else {
          currentCache.results.push(...newItems.results);
          currentCache.metadata = newItems.metadata;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return true;
      },
    }),
  }),
  overrideExisting: true,
});

export const { useSearchAllQuery } = keywordSearchSecondaryApi;
