import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { debounce } from 'lodash';
import TCClient from '../../../client';

import { ActionBar } from '../../shared-components/ActionBar/ActionBar';
import { CtaButton } from '../../shared-components/CtaButton/CtaButton';
import { PageHeader } from '../../shared-components/PageHeader/PageHeader';
import { Table, TableRow } from '../../shared-components/Table/Table';
import { Toast } from '../../shared-components/Toast/Toast';
import Modal from '../../shared-components/Modal/Modal';
import Forum from './Forum';

import styles from './Forums.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const forumHeaders = [
  { field: 'name', headerName: 'Forum Name' },
  { field: 'memberCount', headerName: '# of Members' },
];

type ForumType = {
  token: string;
  id: string;
  members: Array<string>;
  memberCount: number;
  name: string;
  avatar: string;
  avatarUrl: string;
};

const { setForumsPageView } = actions;

export default function Forums() {
  const dispatch = useAppDispatch();
  const { selectedOrganization, forumsView } = useAppSelector((state) => ({
    selectedOrganization: state.admin.selectedOrganization,
    forumsView: state.admin.selectedForumsPageView,
  }));
  const [selectedForum, setSelectedForum] = useState<TableRow>({} as TableRow);
  const [forumQuery, setForumQuery] = useState('');
  const [forumsList, setForumsList] = useState<Array<ForumType>>([]);
  const [forumsListContinuation, setForumsListContinuation] = useState('');
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);
  const [isDeleteForumModalOpen, setIsDeleteForumModalOpen] = useState(false);
  const lastForumQuery = useRef('');

  const getForums = useCallback(
    async (query: string, continuation: string) => {
      const setForumContinuation = continuation === '' ? '' : forumsListContinuation;
      try {
        const { continuation, results } = await TCClient.forums.findAllByOrg(
          selectedOrganization?.token,
          {
            query,
            continuation: setForumContinuation,
          }
        );
        setForumsListContinuation(continuation);
        if (query !== lastForumQuery.current) {
          setForumsList(results);
          lastForumQuery.current = query;
        } else if (forumsListContinuation) {
          setForumsList((oldForumList) => oldForumList.concat(results));
        } else {
          setForumsList(results);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [forumsListContinuation, selectedOrganization.token]
  );

  const getForumsDebounced = useRef(debounce(getForums, 250));

  const rowData: Array<TableRow> = useMemo(() => {
    return forumsList.map((entity: ForumType) => {
      return {
        memberCount: entity.memberCount,
        id: entity.id,
        name: entity.name,
        avatar: entity.avatarUrl,
      };
    });
  }, [forumsList]);

  useEffect(() => {
    setForumQuery('');
    if (forumsView === 'forums') {
      getForumsDebounced.current('', '');
      setSelectedForum({} as TableRow);
    }
  }, [forumsView]);

  function showForumList() {
    dispatch(setForumsPageView({ selectedForumsPageView: 'forums' }));
  }

  function showForumView(row: TableRow) {
    dispatch(setForumsPageView({ selectedForumsPageView: 'forum' }));
    setSelectedForum(row);
  }

  function handlePagination() {
    getForums(forumQuery, forumsListContinuation);
  }

  async function handleDeleteForum() {
    try {
      await TCClient.adminOrganizations.deleteForum({
        organizationId: selectedOrganization.token,
        forumId: selectedForum.id,
      });
    } catch (e) {
      console.error(e);
    }
    showForumList();
    setIsDeleteForumModalOpen(false);
  }

  const setQueryText = useCallback((text: string) => {
    setForumQuery(text);
    getForumsDebounced.current(text, '');
  }, []);

  function renderView() {
    switch (forumsView) {
      case 'forums':
        return (
          <>
            <PageHeader label={'Forums'} />
            <ActionBar
              hasSearch={true}
              searchValue={forumQuery}
              onInputChange={(e) => {
                setQueryText(e.target.value);
              }}
              handleClearClick={() => {
                setQueryText('');
              }}
              dataTestId={'searchForum'}
            />
            <div className={styles.forumsCreate}>
              <CtaButton
                label={'Create New Forum'}
                onClick={() => {
                  dispatch(setForumsPageView({ selectedForumsPageView: 'forum' }));
                }}
                primary={true}
              />
            </div>
            <div
              style={{
                fontFamily: 'Open Sans, sans-serif',
              }}
              className={styles.forumsTable}
            >
              <Table
                columns={forumHeaders}
                rows={rowData}
                onRowEdit={(row) => {
                  showForumView(row);
                }}
                onRowDelete={(row) => {
                  setIsDeleteForumModalOpen(true);
                  setSelectedForum(row);
                }}
                actionsOrder={[
                  { name: 'Edit', type: 'edit' },
                  { name: 'Delete', type: 'delete' },
                ]}
                onPaginate={handlePagination}
                paginated={!!forumsListContinuation}
              />
            </div>
            {rowData?.length === 0 && <div style={{ marginTop: 20 }}>No Results.</div>}
          </>
        );
      case 'forum':
        return (
          <Forum
            selected={selectedForum}
            handleSuccess={() => {
              showForumList();
              setIsSuccessToastOpen(true);
            }}
            handleCancel={showForumList}
            organizationId={selectedOrganization?.token}
          />
        );
    }
  }

  return (
    <div className={styles.forumsContainer}>
      {renderView()}
      <Toast
        type="SUCCESS"
        message="Forum successfully created."
        open={isSuccessToastOpen}
        onClose={() => {
          setIsSuccessToastOpen(false);
        }}
      />
      <Modal
        body={'Are you sure you want to delete this forum?'}
        isOpen={isDeleteForumModalOpen}
        closeButton={'Cancel'}
        canClose
        doneButton={'Delete'}
        header={'Delete Forum'}
        modalSize={'medium'}
        isWarningButtonPresent={true}
        onClose={() => {
          setIsDeleteForumModalOpen(false);
          setSelectedForum({} as TableRow);
        }}
        onSuccess={handleDeleteForum}
      />
    </div>
  );
}
